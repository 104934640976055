import { useTranslation } from 'react-i18next';
import { SignUpFreelancerRepository } from '@/pages/auth/sign-up/sign-up-freelancer//signUpFrelancerRepository';
import { useQuery } from '@apollo/client';
import { expertisesGQL } from '@/gql/global-queries/expertisesGQL';
import SelectMultiTags from '@/components/SelectMultiTags';


export default function SignUpFreelanceStepExpertise() {
    const { t } = useTranslation();
    const repository = new SignUpFreelancerRepository();
    const expertisesQuery = useQuery( expertisesGQL );

    return (
        <SelectMultiTags defaultSelectedIds={ repository.expertiseIds || [] }
                         tags={ expertisesQuery?.data?.expertises?.map( ( expertise ) => ( {
                             id: expertise.id,
                             label: t( 'translation:enum.expertises.' + expertise.i18n.toUpperCase() )
                         } ) ) }
                         onTagsSelected={ ( ids ) => repository.expertiseIds = ids }
                         loading={ expertisesQuery.loading } />
    );
}


