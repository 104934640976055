import { gql } from '@apollo/client';

export const ReviewAndValidateProjectGQL = gql`
    mutation ReviewAndValidateProjectGQL($args: CreateRateInput!) {
        reviewAndValidateProject(args: $args) {
            id
            status
            freelancerHasReviewed
            clientHasReviewed
            clientInvoiceUrl
            validatedByClientAt
            validatedByFreelancerAt
            channelId
            createdAt
        }
    }
`;