import { Outlet } from 'react-router-dom';

import { FreelancerHeader } from '@/pages/freelancer/layout/header/FreelancerHeader';
import FreelancerNavBarTouch from '@/pages/freelancer/layout/nav-bar/FreelancerNavBarTouch';
import LayoutVisibilityHook from '@/hooks/layoutVisibilityHook';
import { useQuery } from '@apollo/client';
import { FreelancerProfileGQL } from '@/gql/global-queries/freelancerProfileGQL';
import { useEffect, useRef } from 'react';
import { SessionRepository } from '@/core/auth/sessionRepository';
import ChatHook from '@/components/chat/hooks/ChatHook';
import PhoneNumberVerification from '@/components/PhoneNumberVerification';
import { Freelancer } from '@/gql/graphql';

export default function FreelancerRoot() {

    const sessionRepository = new SessionRepository();

    const { isTabletOrMobile, isHeaderVisible, isBottomNavBarVisible } = LayoutVisibilityHook( {
        headerVisible: true,
        bottomNavBarVisible: 'isTabletOrMobile'
    } );

    ChatHook( { conversationId: null } );

    const phoneNumberVerificationRef = useRef( null );

    const freelancerProfileQuery = useQuery( FreelancerProfileGQL );

    useEffect( () => {
        if( freelancerProfileQuery.data ) {

            const freelancer: Freelancer = freelancerProfileQuery.data.meFreelancer;

            if( !freelancer.phone ) {
                phoneNumberVerificationRef.current?.open();
            }

            sessionRepository.updateUser( freelancer );
        }
    }, [ freelancerProfileQuery.data ] );

    return (
        <>
            <div className="min-h-screen flex flex-col h-full relative">
                {
                    isHeaderVisible &&
                    <FreelancerHeader />
                }
                <div className={ `flex-grow flex flex-col w-full pt-[96px] lg:pb-[72px] default-padding-x ${ isTabletOrMobile && isBottomNavBarVisible
                                                                                              ? 'pb-[72px]'
                                                                                              : '' }` }>
                    <Outlet />
                </div>
                {
                    isTabletOrMobile &&
                    isBottomNavBarVisible &&
                    <FreelancerNavBarTouch />
                }
            </div>
            <PhoneNumberVerification ref={ phoneNumberVerificationRef } />
        </>
    );
}