import LayoutVisibilityHook from '@/hooks/layoutVisibilityHook';
import { useEffect, useRef } from 'react';
import MenuSection from '@/components/MenuSection';
import { Project, ProjectStatusEnum } from '@/gql/graphql';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import freelancerProjectsHook from '@/hooks/freelancerProjectsHook';
import FreelancerProjectList from '@/pages/freelancer/views/projects/components/FreelancerProjectList';
import FreelancerProjectModal from '@/pages/freelancer/views/project/components/FreelancerProjectModal';
import { PageName, trackPageView } from '@/utils/analytics';

export default function FreelancerProjects() {

    const { status } = useParams();
    const { t } = useTranslation();

    const {
              loadProjects,
              projects,
              projectSelected,
              total,
              loading,
              loadMore,
              setProjectSelected,
              hasMore,
              handleRefresh
          } = freelancerProjectsHook( { limit: 10 } );

    LayoutVisibilityHook( {
        headerVisible: true,
        bottomNavBarVisible: 'isTabletOrMobile'
    } );

    const nav = [
        {
            title: t( 'freelancer:projects.ongoing',
                {
                    count: total?.inProgress
                } ),
            value: ProjectStatusEnum.IN_PROGRESS,
            route: '/freelancer/projects/status/ongoing'
        },
        {
            title: t( 'freelancer:projects.finished', {
                count: total?.done
            } ),
            value: ProjectStatusEnum.DONE,
            route: '/freelancer/projects/status/finished'
        },
        {
            title: t( 'client:projects.cancelled', {
                count: total?.cancelled
            } ),
            value: ProjectStatusEnum.CANCELLED,
            route: '/freelancer/projects/status/cancelled'
        }
    ];

    const projectModalRef = useRef( null );

    const [ searchParams, setSearchParams ] = useSearchParams();
    const projectIdParam = searchParams.get( 'projectId' );

    useEffect( () => {
        if( status != null ) {
            let statusEnum: ProjectStatusEnum;
            switch( status ) {
                case 'ongoing':
                    statusEnum = ProjectStatusEnum.IN_PROGRESS;
                    break;
                case 'finished':
                    statusEnum = ProjectStatusEnum.DONE;
                    break;
                case 'cancelled':
                    statusEnum = ProjectStatusEnum.CANCELLED;
                    break;
                default:
                    statusEnum = ProjectStatusEnum.IN_PROGRESS;
            }

            loadProjects( statusEnum );
        }
    }, [ status ] );

    useEffect( () => {        
        if( projects?.length && projectIdParam ) {            
            const project = projects.find( project => project.id === projectIdParam );            
            setProjectSelected( project );            
            projectModalRef.current.open();
            searchParams.delete('projectId');
            setSearchParams( searchParams );
        }
    }, [ projects ]);

    useEffect( () => {
        trackPageView( PageName.FreelancerProject );
    }, [] );

    const handleDrawerOpening = ( project ) => {
        projectModalRef.current.open();
        setProjectSelected( project );
    };

    return (
        <>
            <div className="w-full">
                <div className="hidden lg:block font-medium text-2xl font-sharp">
                    { t( 'client:projects.title' ) }
                </div>
                <div className="w-full flex flex-col lg:flex-row lg:mt-8">
                    <MenuSection routes={ nav }
                                 className="lg:w-[180px]" />
                    <div className="mt-6 lg:mt-0 w-full lg:ml-[164px]">
                        <FreelancerProjectList projects={ projects }
                                               hasMore={ hasMore }
                                               isLoading={ loading }
                                               loadMore={ () => loadMore() }
                                               onProjectSelected={ ( value ) => handleDrawerOpening( value ) } />
                    </div>
                </div>
            </div>

            <FreelancerProjectModal ref={ projectModalRef }
                                    projectId={ projectSelected?.id }
                                    onReviewSubmitted={ ( project: Project ) => handleRefresh( project ) } />
        </>

    );
}