import { UserOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';
import React from 'react';

interface IconedImageProps {
    src: string;
    icon?: React.ReactNode;
}

const IconedImage: React.FC<IconedImageProps> = ({ src, icon }) => {
    return (
        <div className="icon-component w-fit">
            <div className="relative">
                <Avatar size={ 40 }
                    icon={ <UserOutlined /> }
                    src={ src } />
                { icon && <div className="additional-icon absolute -right-1 -bottom-1">{ icon }</div> }
            </div>
        </div>
    );
}

export default IconedImage;
