import { CometChat } from '@cometchat-pro/chat';
import { createContext, useContext, useEffect, useState } from 'react';
import { SessionRepository } from '@/core/auth/sessionRepository';
import { SessionContext } from '@/core/auth/SessionProvider';
import * as Sentry from '@sentry/react';

export const CometChatIsLoggedInContext = createContext( false );

export default function CometChatProvider( { children } ) {

    const sessionRepository: SessionRepository = new SessionRepository();
    const sessionIsLoaded: boolean = useContext( SessionContext );

    const [ isInitialized, setIsInitialized ] = useState( false );
    const [ cometchatIsLoggedIn, setCometChatIsLoggedIn ] = useState<boolean>( false );

    useEffect( () => {
        const settings: CometChat.AppSettings = setCometChatAppSettings();
        initCometChat( settings );
    }, [] );

    useEffect( () => {
        if( sessionIsLoaded && isInitialized ) {
            CometChat.getLoggedinUser().then(
                ( user: CometChat.User ) => {
                    if( !user ) {
                        CometChat.login( sessionRepository.cometChatAuthToken ).then(
                            ( user: CometChat.User ) => {
                                setCometChatIsLoggedIn( true );
                            }, ( error: CometChat.CometChatException ) => {
                                Sentry.captureException(
                                    'CometChat login failed',
                                    {
                                        extra: {
                                            level: 'error',
                                            error
                                        }
                                    }
                                );
                            }
                        );
                    }
                    else {
                        setCometChatIsLoggedIn( true );
                    }
                }, ( error: CometChat.CometChatException ) => {
                    Sentry.captureException(
                        'Impossible to get CometChat logged in user',
                        {
                            level: 'error',
                            extra: {
                                error
                            }
                        }
                    );
                }
            );
        }
    }, [ sessionIsLoaded, isInitialized ] );

    useEffect( () => {
        if( !sessionIsLoaded && cometchatIsLoggedIn ) {
            logoutCometChat();
        }
    }, [ sessionIsLoaded ] );

    const setCometChatAppSettings = (): CometChat.AppSettings => {
        const region: string = import.meta.env.VITE_COMETCHAT_REGION;
        return new CometChat.AppSettingsBuilder()
            .subscribePresenceForAllUsers()
            .setRegion( region )
            .autoEstablishSocketConnection( true )
            .build();
    };

    const initCometChat = ( appSetting ): void => {
        let appID: string = import.meta.env.VITE_COMETCHAT_APP_ID;
        CometChat.init( appID, appSetting ).then(
            ( initialized: boolean ) => {
                setIsInitialized( initialized );
            }, ( error: CometChat.CometChatException ) => {
                Sentry.captureException( 'CometChat initialization failed', {
                    level: 'error',
                    extra: {
                        error
                    }
                } );
            }
        );
    };

    const logoutCometChat = (): void => {
        CometChat.logout().then(
            ( loggedOut: Object ) => {
                setCometChatIsLoggedIn( false );
            }, ( error: CometChat.CometChatException ) => {
                Sentry.captureException(
                    'CometChat logout failed',
                    {
                        level: 'error',
                        extra: {
                            error
                        }
                    } );
            }
        );
    };

    return (
        <>
            <CometChatIsLoggedInContext.Provider value={ cometchatIsLoggedIn }>
                { children }
            </CometChatIsLoggedInContext.Provider>
        </>
    );
}