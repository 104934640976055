import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from 'antd';
import { SignUpFreelancerRepository } from '@/pages/auth/sign-up/sign-up-freelancer//signUpFrelancerRepository';
import { Controller, useForm } from 'react-hook-form';
import numberFormatter from '@/utils/numberFormatter';
import { twMerge } from 'tailwind-merge';
import InputComponent from '@/components/custom-element-form/InputComponent';
import ChatQuoteAboutFee from '@/pages/freelancer/views/chat/components/freelancer-chat-quote/components/ChatQuoteAboutFee';

const SignUpFreelancerStepAVGPrice = forwardRef( function( props, ref ) {

    const { t } = useTranslation();
    const [ isFocused, setIsFocused ] = useState( false );
    const [ inputWidth, setInputWidth ] = useState( 100 );
    const { trigger, control, setValue, watch, formState: { errors }, handleSubmit } = useForm();
    const repository = new SignUpFreelancerRepository();
    const [ price, setPrice ] = useState( repository.avgPrice );
    const inputRef = useRef( null );
    const chatQuoteAboutFeeRef = useRef( null );

    const subscription = watch( ( value, { name, type } ) => {
        repository.avgPrice = numberFormatter( value.avgPrice );
        setPrice( value.avgPrice );
    } );

    useEffect( () => {
        return () => subscription.unsubscribe();
    }, [] );

    useImperativeHandle( ref, () => ( {
        async validateFields() {
            return trigger();
        }
    } ) );

    useEffect( () => {
        if( price ) {
            if( price < 0 || isNaN( price ) ) {
                setValue( 'avgPrice', null );
                setValue( 'priceAfterFee', null );
            }
            else {
                const fee = +( import.meta.env.VITE_FEE_RATE ) / 100;
                setValue( 'priceAfterFee', numberFormatter( price - ( price * fee ) ) );
            }
        }
    }, [ price ] );


    const calculateWidth = ( value ) => {
        const minSize: number = 100;
        const multiplier: number = 30;
        const newSize: number = Math.max( minSize, value.length * multiplier );
        setInputWidth( newSize );
    };

    const handleOpenAboutFee = (): void => {
        if( chatQuoteAboutFeeRef.current ) {
            chatQuoteAboutFeeRef.current.open();
        }
    };

    const focusBorder = isFocused ? 'border-black' : 'border-grey-light text-[#bfbfbf]';
    const errorBorder = errors[ 'avgPrice' ] ? 'border-red' : '';

    return (
        <>
            <div className={ twMerge( 'w-full transition-colors duration-500 ease-in-out border rounded-[10px] px-3.5 py-2.5 mt-12 flex items-center justify-center h-[140px]', focusBorder, errorBorder ) }
                 onClick={ () => inputRef.current.focus() }>
                <Controller
                    control={ control }
                    name="avgPrice"
                    defaultValue={ repository.avgPrice }
                    rules={ { required: t( 'common:error.form.required' ) } }
                    render={ ( { fieldState: { error }, field: { onChange, onBlur, value, ref } } ) => (
                        <div className="flex items-center justify-center text-[32px] font-medium font-sharp">
                            <span className={ price ? 'text-black' : 'text-[#bfbfbf]' }>$</span>
                            <Input className="text-[32px] font-medium font-sharp py-0 px-1"
                                   style={ { width: `${ inputWidth }px` } }
                                   placeholder={ t( 'auth:sign-up.freelancer.price-page.inputPlaceholder' ) }
                                   value={ value }
                                   ref={ ( e ) => {
                                       ref( e );
                                       inputRef.current = e;
                                   } }
                                   onBlur={ () => {
                                       setIsFocused( false );
                                       onBlur();
                                   } }
                                   onFocus={ () => setIsFocused( true ) }
                                   status={ error ? 'error' : '' }
                                   onChange={ ( e ) => {
                                       calculateWidth( e.target.value );
                                       onChange( e );
                                   } }
                                   bordered={ false }
                                   min={ 10 }
                            />
                        </div>
                    ) }
                />
            </div>

            <InputComponent control={ control }
                            name="priceAfterFee"
                            disabled
                            prefix="$"
                            className="mt-8 avgPrice"
                            label={ t( 'auth:sign-up.freelancer.price-page.inputPriceAfterFeeLabel' ) }
                            placeholder={ t( 'auth:sign-up.freelancer.price-page.inputPriceAfterFeePlaceholder' ) } />

            <p className="mt-2 text-primary-500 underline text-xs font-medium cursor-pointer"
               onClick={ handleOpenAboutFee }>
                { t( 'freelancer:chat.quote.fees' ) }
            </p>
            <ChatQuoteAboutFee ref={ chatQuoteAboutFeeRef } />
        </>
    );
} );

export default SignUpFreelancerStepAVGPrice;
