import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';

export interface ParamsProps {
    allowedParams: string[];
}

export default function captureUrlParamsFiltersHook( props: ParamsProps) {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const [params, setParams] = useState<object>(null);

    useEffect(() => {
        const params = {};
        for (let param of searchParams) {
            params[param[0]] = param[1];
        }
        const paramsAllowed = matchAllowedParams(props.allowedParams, params);
        setParams(paramsAllowed);
    }, [location]);

    const matchAllowedParams = (allowedParams: string[], urlParams: any) => {
        return Object.keys(urlParams).filter((paramKey) => {
            return allowedParams.includes(paramKey);
        }).reduce((obj, key) => {
            if (typeof urlParams[key] === 'string' && urlParams[key].includes(',')) {
                obj[key] = urlParams[key].split(',');
            } else {
                obj[key] = urlParams[key];
            }
            return obj;
        }, {});
    }

    return {
        params
    }
}
