import { IsEmailAvailableGQL } from '@/gql/global-queries/isEmailAvailableGQL';
import { useLazyQuery } from '@apollo/client';
import * as Sentry from '@sentry/react';

export default function emailVerificationHook() {

    const emailAvailableQuery = useLazyQuery( IsEmailAvailableGQL );

    const isAvailable = async( email: string ): Promise<boolean> => {
        try {
            const response = await emailAvailableQuery[ 0 ]( {
                variables: {
                    email
                }
            } );

            return response?.data?.isEmailAvailable;
        } catch( e ) {
            Sentry.captureException( 'An error occur when trying to check email', e );
            return false;
        }
    };

    return {
        isAvailable
    };
}