import { gql } from '@apollo/client';

export const UpdateProfilePictureGQL = gql`
    mutation UpdateProfilePictureGQL($profilePictureUrl: String!) {
        updateProfilePicture(profilePictureUrl: $profilePictureUrl) {
            id
            type
            profilePicture
        }
    }`;


