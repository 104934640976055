import { gql } from '@apollo/client';

export const defaultPaymentMethodGQL = gql`
    query DefaultPaymentMethodGQL {
        defaultPaymentMethod {
            id
            type
            brand
            last4
        }
    }
`;
