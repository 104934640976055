import { Channel } from '@/gql/graphql';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import ChannelItem from '@/components/ChannelItem';
import ClientItem from '@/components/ClientItem';

interface FreelancerProjectConversationLinkProps {
    channel?: Channel;
    client?: {
        userName: string;
        profilePicture: string;
    };
    conversationId: string;
}

export default function FreelancerProjectConversationLink( {
                                                               channel,
                                                               client,
                                                               conversationId
                                                           }: FreelancerProjectConversationLinkProps ) {

    const { t } = useTranslation();

    return (
        <div className="w-full flex items-center rounded-3xl border border-grey-light p-6 gap-x-4">
            <div className="w-full flex">
                {
                    channel ?
                    <ChannelItem channel={ channel }
                                 titleClass="text-base font-medium"
                                 avatarSize={ 64 } /> :
                    <ClientItem avatarSize={ 14 }
                                client={ client } />
                }
            </div>
            <Link to={ `/freelancer/messages/${ conversationId }` }
                  replace={ true }>
                <Button type="default">
                    { t( 'client:project.goToConversation' ) }
                </Button>
            </Link>
        </div>
    );
}