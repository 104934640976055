import { Switch } from 'antd';
import { useContext, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { CertifiedCreativeAcademyIcon } from '@/assets/icons/CustomIcons';
import { FreelancersRepository } from '@/components/freelancers-filter-items/freelancersRepository';
import { FreelancersRepositoryContext } from '@/core/FreelancerRepositoryContext';

export default function FilterItemCertifiedButton() {
    const { t } = useTranslation();

    const freelancersRepository: FreelancersRepository = useContext( FreelancersRepositoryContext );

    const [ isCertified, setIsCertified ] = useState( freelancersRepository.isCertified );

    useMemo( () => freelancersRepository.isCertified$.subscribe( ( value ) => {
        setIsCertified( value ? value : false );
    } ), [ isCertified ] );

    const onChangeCertified = ( checked: boolean ) => {
        freelancersRepository.setIsCertified( checked ? checked : null );
    };

    return (
        <>
            <div className="w-full flex justify-between items-center mt-2">
                <div>
                    <div className="flex-none flex items-center w-full">
                        <CertifiedCreativeAcademyIcon style={ { fontSize: '32px' } } />
                        <div className="flex flex-col ml-3">
                        <span className="font-base font-bold">
                            { t( 'transverse:freelancers.filters.certified.title' ) }
                        </span>
                            <div className="text-xs text-grey">
                                <Trans i18nKey="transverse:freelancers.filters.certified.description">
                                    <span></span>
                                    <a className="text-primary-400 underline"
                                       target="_blank"
                                       href="https://www.the-creative-academy.com/en"></a>
                                </Trans>
                            </div>
                        </div>
                    </div>

                </div>
                <Switch checked={ isCertified }
                        className="primary"
                        onChange={ onChangeCertified } />
            </div>
        </>
    );
}