import { SessionRepository } from '@/core/auth/sessionRepository';
import { useEffect, useState } from 'react';
import { UserTypeEnum } from '@/gql/graphql';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import DynamicHeader from '@/pages/transverse/layout/header/DynamicHeader';

export default function TransverseRoot() {
    const session = new SessionRepository();
    const navigate = useNavigate();
    const location = useLocation();
    const { id } = useParams();

    const [ isLoggedIn, setIsLoggedIn ] = useState<boolean>( false );
    const [ userType, setUserType ] = useState<UserTypeEnum>( null );

    useEffect( () => {
        const observableIsLoggedIn = session.isLoggedIn$.subscribe( ( isLoggedIn ) => {
            setIsLoggedIn( isLoggedIn );
        } );

        const observableUserType = session.userType$.subscribe( ( userType ) => {
            setUserType( userType );
        } );

        return () => {
            observableIsLoggedIn.unsubscribe();
            observableUserType.unsubscribe();
        };
    }, [] );

    useEffect( () => {
        const isProfileRoute = location.pathname.match( /^\/p\/[\w-]+$/ );

        if( isLoggedIn && userType === UserTypeEnum.CLIENT ) {
            if( isProfileRoute ) {
                navigate( `/client/p/${ id }` );
            }
            else {
                navigate( '/client' );
            }
        }
    }, [ isLoggedIn, userType ] );

    return (
        <div className="h-full relative">
            <DynamicHeader />
            <div className="w-full default-padding-x pb-[72px] pt-[96px]">
                <Outlet />
            </div>
        </div>
    );
}