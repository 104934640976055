import ProfilePictureUploader from '@/components/ProfilePictureUploader';
import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import { FreelancerProfilePictureGQL } from '@/pages/freelancer/views/account/freelancer-account-edit-profile-picture/freelancerProfilePictureGQL';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Button, message, Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';
import { ProfilePicturePreSignUrl } from '@/pages/client/views/account/client-account-edit-profile/queries/getProfilePicturePresignUrl';
import { useMediaQuery } from 'react-responsive';
import { SessionRepository } from '@/core/auth/sessionRepository';
import { UpdateProfilePictureGQL } from '@/gql/global-queries/updateProfilePictureGQL';
import SkeletonAvatar from 'antd/es/skeleton/Avatar';
import { ArrowLeftFilledIcon } from '@/assets/icons/CustomIcons';
import { useNavigate } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { trackSegmentEvent, SegmentEvent, trackPageView, PageName } from '@/utils/analytics';

export default function FreelancerAccountEditProfilePicture() {

    const isTabletOrMobile = useMediaQuery( { query: `(max-width: ${ import.meta.env.VITE_MOBILE_BREAK_POINT })` } );
    const fileUploaderRef: MutableRefObject<any> = useRef( null );
    const [ isSaving, setIsSaving ] = useState( false );
    const { t } = useTranslation();
    const sessionRepository = new SessionRepository();
    const navigate = useNavigate();
    const [ messageApi, contextHolder ] = message.useMessage();

    const { data, loading, error } = useQuery( FreelancerProfilePictureGQL );
    const updateProfilePictureMutation = useMutation( UpdateProfilePictureGQL );
    const getPreSignedUrl = useLazyQuery( ProfilePicturePreSignUrl );

    const rules = [
        t( 'freelancer:account.edit-profile-picture.rules.rule-1' ),
        t( 'freelancer:account.edit-profile-picture.rules.rule-2' ),
        t( 'freelancer:account.edit-profile-picture.rules.rule-3' )
    ];

    const uploadProfilePicture = async(): Promise<string> => {
        try {
            const preSignedUrl = await getPreSignedUrl[ 0 ]( {
                variables: {
                    contentType: fileUploaderRef.current.getFile().type
                }
            } );
            return fileUploaderRef.current.upload( preSignedUrl.data.profilePicturePreSignUrl );
        } catch( e ) {
            Sentry.captureException( 'An error occur when trying to get preSignUrl', e );
            messageApi.error( t( 'error.default' ) );
        }
    };


    useEffect( () => {
        trackPageView( PageName.FreelancerEditProfilePicture );
    }, [ ] );

    const onSubmit = async(): Promise<void> => {
        setIsSaving( true );
        let profilePictureUrl: string;

        if( fileUploaderRef.current.getFile() != null ) {
            try {
                profilePictureUrl = await uploadProfilePicture();
            } catch( e ) {
                Sentry.captureException( 'An error occur when trying to upload profile picture', e );
                setIsSaving( false );
            }
        }


        try {
            const response = await updateProfilePictureMutation[ 0 ]( {
                variables: {
                    profilePictureUrl
                }
            } );
            sessionRepository.updateUser( response.data?.updateProfilePicture );
            messageApi.success( t( 'client:account.edit-profile.success' ) );
            trackSegmentEvent( SegmentEvent.ProfileUpdated, { profile_updated_type: 'Profile Picture' } );
        } catch( e ) {
            Sentry.captureException( 'An error occur when trying to update freelancer profile', e );
            messageApi.error( t( 'error.default' ) );
            setIsSaving( false );
        }
        setIsSaving( false );
    };

    const handleOpenEditWindows = () => {
        fileUploaderRef.current.open();
    };

    const goBack = () => {
        navigate( '/freelancer/settings/edit-profile' );
    };

    const loadingJSX = (
        <>
            <div className="w-full flex items-center justify-center">
                <SkeletonAvatar active
                                size={ 100 } />
            </div>

            <div className="mt-4">
                <Skeleton active />
            </div>
        </>
    );

    const contentJSX = (
        <>
            <div className="w-full flex items-center justify-center">
                <ProfilePictureUploader ref={ fileUploaderRef }
                                        displayEditButton={ false }
                                        defaultProfilePictureUrl={ data?.meFreelancer.profilePicture } />
            </div>
            <div className="mt-4">
                <div className="text-lg font-medium">
                    { t( 'freelancer:account.edit-profile-picture.rules.title' ) }
                </div>
                <ul className="list-decimal mt-2 pl-4">
                    {
                        rules.map( ( rule, index ) => (
                            <li key={ index }
                                className="text-grey text-sm font-normal">
                                { rule }
                            </li>
                        ) )
                    }
                </ul>
            </div>

            <div className="flex flex-col lg:flex-row items-center lg:justify-between mt-6 lg:mt-14">
                <Button type="default"
                        className="mt-6 lg:mt-0 w-full lg:w-fit"
                        size="middle"
                        disabled={ isSaving }
                        onClick={ () => handleOpenEditWindows() }>
                    { t( 'freelancer:account.edit-profile-picture.uploadNewPicture' ) }
                </Button>

                <Button type="primary"
                        className="mt-14 lg:mt-0 lg:max-w-[120px] w-full"
                        onClick={ () => onSubmit() }
                        loading={ isSaving }
                        size={ isTabletOrMobile ? 'large' : 'middle' }>
                    { t( 'freelancer:account.edit-profile-picture.submit' ) }
                </Button>
            </div>
        </>
    );

    return (
        <div className="flex flex-col w-full">
            { contextHolder }
            <div className="items-center hidden lg:flex">
                <Button type="link"
                        onClick={ () => goBack() }>
                    <ArrowLeftFilledIcon className="pr-2" />
                    { t( 'common:button.back' ) }
                </Button>
            </div>

            <div className="lg:mt-6">
                {
                    loading ? loadingJSX : contentJSX
                }
            </div>
        </div>
    );
}