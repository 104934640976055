import { forwardRef, useImperativeHandle, useRef } from 'react';
import ResponsiveModal from '@/components/ResponsiveModal';
import { useTranslation } from 'react-i18next';
import ChatConversationPaymentModalForm from '@/components/chat/components/chat-conversation/chat-conversation-payment-modal/ChatConversationPaymentModalForm';

interface ChatConversationPaymentModalProps {
    quoteId: string;
}

const ChatConversationPaymentModal = forwardRef( ( props: ChatConversationPaymentModalProps, ref ) => {

    const { t } = useTranslation();

    const { quoteId } = props;
    const modalRef = useRef( null );

    useImperativeHandle( ref, () => ( {
        open() {
            handleOpenModal();
        },
        close() {
            handleCloseModal();
        }
    } ) );

    const handleOpenModal = () => modalRef.current?.open();

    const handleCloseModal = () => modalRef.current.close();

    return (
        <>
            <ResponsiveModal ref={ modalRef }
                             desktopWidth="1000px"
                             destroyOnClose={ true }
                             title={ t( 'common:chat.conversation.payment.title' ) }>
                <ChatConversationPaymentModalForm quoteId={ quoteId }
                                                  onPaymentDone={ () => handleCloseModal() } />
            </ResponsiveModal>
        </>
    );
} );

export default ChatConversationPaymentModal;