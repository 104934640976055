import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SessionRepository } from '@/core/auth/sessionRepository';
import { ItemTypeEnum } from '@/components/chat/enums/itemTypeEnum';
import ChatConversationItemLayout from '@/components/chat/components/chat-conversation/chat-conversation-messages/components/chat-conversation-items/components/ChatConversationItemLayout';
import ChatConversationItemMessage from '@/components/chat/components/chat-conversation/chat-conversation-messages/components/chat-conversation-items/ChatConversationItemMessage';
import ChatConversationItemOffer from '@/components/chat/components/chat-conversation/chat-conversation-messages/components/chat-conversation-items/ChatConversationItemOffer';
import ChatConversationItemQuote from '@/components/chat/components/chat-conversation/chat-conversation-messages/components/chat-conversation-items/ChatConversationItemQuote';
import ChatConversationItemAction from '@/components/chat/components/chat-conversation/chat-conversation-messages/components/chat-conversation-items/ChatConversationItemAction';
import { ConversationItemMessage } from '@/components/chat/interfaces/conversationItemMessage';
import { ConversationItemQuote } from '@/components/chat/interfaces/conversationItemQuote';
import { ConversationItemOffer } from '@/components/chat/interfaces/conversationItemItemOffer';
import { ConversationItemActionQuote } from '@/components/chat/interfaces/conversationItemActionQuote';
import { ConversationItemActionOffer } from '@/components/chat/interfaces/conversationItemActionOffer';

interface ChatConversationItemProps {
    item: ConversationItemMessage | ConversationItemQuote | ConversationItemOffer | ConversationItemActionQuote | ConversationItemActionOffer;
}

export default function ChatConversationItem( { item }: ChatConversationItemProps ) {
    const { t } = useTranslation();
    const sessionRepository = new SessionRepository();
    const sessionUserId = sessionRepository.userId;

    const isSender: boolean = useMemo( () =>
        item.senderId === sessionUserId, [ item.senderId, sessionUserId ]
    );

    const itemContent: JSX.Element = useMemo( () => {
        switch( item.type ) {
            case ItemTypeEnum.MESSAGE:
                return <ChatConversationItemMessage item={ item as ConversationItemMessage } />;
            case ItemTypeEnum.OFFER:
                return <ChatConversationItemOffer item={ item as ConversationItemOffer }
                                                  isCurrentUser={ isSender } />;
            case ItemTypeEnum.QUOTE:
                return <ChatConversationItemQuote item={ item as ConversationItemQuote }
                                                  isSender={ isSender } />;
            case ItemTypeEnum.ACTION:
                return <ChatConversationItemAction item={ item as ConversationItemActionQuote | ConversationItemActionOffer } />;
            default:
                return null;
        }
    }, [ item, isSender ] );

    const header: string = useMemo( () => {
        if( item.type === ItemTypeEnum.OFFER ) {
            return isSender
                   ? t( 'common:chat.conversation.header.senderOffer' )
                   : t( 'common:chat.conversation.header.receiverOffer' );
        }
    }, [ item.type, isSender ] );

    if( isSender === null ) {
        return null;
    }

    return (
        <ChatConversationItemLayout type={ item.type }
                                    sentAt={ item.sentAt }
                                    header={ header }
                                    isCurrentUser={ isSender }>
            { itemContent }
        </ChatConversationItemLayout>
    );
}
