import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useMutation } from '@apollo/client';
import { AttachPaymentMethodGQL } from '@/pages/client/views/account/client-account-edit-payment-methods/services/attachPaymentMethodGQL';
import { DetachPaymentMethodGQL } from '@/pages/client/views/account/client-account-edit-payment-methods/services/detachPaymentMethodGQL';
import { useState } from 'react';
import { MakeDefaultPaymentMethodGQL } from '@/pages/client/views/account/client-account-edit-payment-methods/services/makeDefaultPaymentMethodGQL';
import { PaymentMethod } from '@/gql/graphql';
import * as Sentry from '@sentry/react';

export default function paymentMethodHook() {

    const [ errorMessage, setErrorMessage ] = useState( null );
    const [ isSubmitting, setIsSubmitting ] = useState( false );
    const [ isDetached, setIsDetached ] = useState<boolean>( false );
    const [ paymentMethod, setPaymentMethod ] = useState<PaymentMethod | null>( null );

    const stripe = useStripe();
    const elements = useElements();
    const attachPaymentMethodGQL = useMutation( AttachPaymentMethodGQL );
    const detachPaymentMethodGQL = useMutation( DetachPaymentMethodGQL );
    const makeDefaultPaymentMethodGQL = useMutation( MakeDefaultPaymentMethodGQL );

    const CARD_ELEMENT_OPTIONS = {
        style: {
            base: {
                color: '#000',
                fontSmoothing: 'antialiased',
                padding: '10px 12px',
                fontSize: '16px',
                '::placeholder': {
                    color: '#EAECF0'
                }
            },
            invalid: {
                color: '#D92D20',
                iconColor: '#D92D20'
            }
        }
    };

    const handleDetachPaymentMethod = async( paymentMethod ) => {
        try {
            const result = await detachPaymentMethodGQL[ 0 ]( {
                variables: {
                    paymentMethodId: paymentMethod.id
                }
            } );

            if( result.data.detachPaymentMethod ) {
                setIsDetached( true );
            }
        } catch( e ) {
            Sentry.captureException( 'An error occurred when trying to detach the payment method', e );
            setErrorMessage( e.message );
        }
    };

    const handleMakeDefaultPaymentMethod = async( paymentMethod ) => {
        try {
            await makeDefaultPaymentMethodGQL[0]( {
                variables: { paymentMethodId: paymentMethod.id }
            } );
        } catch( e ) {
            Sentry.captureException( 'An error occur when trying to make a payment method as default', e );
            setErrorMessage( e.message );
        }
    };

    const handleCreatePaymentMethod = async( e? ): Promise<PaymentMethod> => {
        if( e ) {
            e.preventDefault();
        }

        return new Promise<PaymentMethod>( async( resolve, reject ) => {
            setIsSubmitting( true );

            if( !stripe || !elements ) {
                return;
            }

            const cardElement = elements.getElement( CardElement );
            const { error, paymentMethod } = await stripe.createPaymentMethod( {
                type: 'card',
                card: cardElement
            } );

            if( error ) {
                setErrorMessage( error.message );
                setIsSubmitting( false );
            }
            else {
                try {
                    const result = await attachPaymentMethodGQL[ 0 ]( {
                        variables: {
                            paymentMethodId: paymentMethod.id
                        }
                    } );

                    if( result.data.attachPaymentMethod ) {
                        setPaymentMethod( result.data.attachPaymentMethod );
                        resolve( result.data.attachPaymentMethod );
                    }
                } catch( e ) {
                    Sentry.captureException( 'An error occur when trying to attach a payment method', e );
                    setErrorMessage( e.message );
                    reject( e.message );
                }

                setIsSubmitting( false );
            }
        } );
    };

    return {
        CARD_ELEMENT_OPTIONS,
        errorMessage,
        paymentMethod,
        isSubmitting,
        handleCreatePaymentMethod,
        handleMakeDefaultPaymentMethod,
        isDetached,
        handleDetachPaymentMethod,
        setPaymentMethod
    };
}