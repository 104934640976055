import { gql } from '@apollo/client';

export const projectsInProgressByChannelGQL = gql`
    query ProjectsInProgressByChannelGQL {
        projectsInProgressByChannel {
            totalCount
            countByChannel {
                channelId
                totalCount
            }
        }
    }`;


