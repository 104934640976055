import { Button, App } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { SubmitFreelancerProfileForVerification } from '@/gql/global-queries/submitFreelancerProfileForVerificationGQL'; 
import * as Sentry from '@sentry/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface FreelancerDashboardProfileRejectedProps {
    rejectionReasons: string;
}

const FreelancerDashboardProfileRejected = ({ rejectionReasons }:FreelancerDashboardProfileRejectedProps) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { message } = App.useApp();

    const submitProfileMutationGQL = useMutation( SubmitFreelancerProfileForVerification );
    const [ isSubmited, setIsSubmited ] = useState(false);


    const handleSubmit = async( ) => {
        try {            
            const response = await submitProfileMutationGQL[ 0 ]();            
            
            if( response.data ) {
                setIsSubmited(true);
            }

        } catch( e ) {
            Sentry.captureException( 'An error occur when trying to submit freelancer profile for validation', e );
            message.error( t( 'common:form.submitError' ) );
        }
    };


    return (
        <div className="flex gap-8 rounded-xl border border-blue-100 p-8">
            <div>
                <span className='font-semibold text-base'>{t( 'freelancer:dashboard.profileRejected.title' )}</span>
                {
                    isSubmited ?
                        <p className='text-grey mt-3 mb-5'>{t( 'freelancer:dashboard.profileRejected.submitConfirmation' )}</p>
                        :
                    <>
                        <p className='text-grey mt-3 mb-5'>
                            {t( 'freelancer:dashboard.profileRejected.description' )}
                        </p>
                        <ul className='text-grey font-semibold'>
                            - { rejectionReasons }
                        </ul>
                        <div className="flex gap-3 mt-6">
                            <Button type="primary" className='bg-black' onClick={ () => navigate('/freelancer/settings/edit-profile') }>{t( 'freelancer:dashboard.profileRejected.editProfileButton' )}</Button>
                            <Button type="primary" className='bg-black' onClick={ () => handleSubmit() }>{t( 'freelancer:dashboard.profileRejected.resubmitButton' )}</Button>
                        </div>
                    </>
                }
            </div>
        </div>
    );
};

export default FreelancerDashboardProfileRejected;