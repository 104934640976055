import { CardElement } from '@stripe/react-stripe-js';
import { useEffect } from 'react';
import { Button } from 'antd';
import { PaymentMethod } from '@/gql/graphql';
import { useTranslation } from 'react-i18next';
import paymentMethodHook from '@/hooks/paymentMethodHook';

interface NewPaymentMethodProps {
    onSuccess: ( paymentMethod: PaymentMethod ) => void;
    onCancel?: ( ) => void;
}

export default function NewPaymentMethod( { onSuccess, onCancel }: NewPaymentMethodProps ) {

    const { t } = useTranslation( 'translation' );
    const { handleCreatePaymentMethod, isSubmitting, CARD_ELEMENT_OPTIONS, errorMessage, paymentMethod } = paymentMethodHook();

    useEffect( () => {
        if( paymentMethod ) {
            onSuccess( paymentMethod );
        }
    }, [ paymentMethod ] );

    return (
        <form onSubmit={ handleCreatePaymentMethod }>
            <div className="border border-grey rounded-2xl px-6 py-5">
                <CardElement options={ CARD_ELEMENT_OPTIONS } />
            </div>

            {
                errorMessage &&
                <div className="mt-2 text-red">
                    { errorMessage }
                </div>
            }

            <div className="w-full flex gap-4 lg:justify-end">
                <Button type="default"
                    htmlType="reset"
                    onClick={ onCancel && onCancel }
                    className="w-full lg:w-[100px] mt-6">
                    { t( 'common:cancel' ) }
                </Button>
                <Button htmlType="submit"
                        type="primary"
                        size="middle"
                        loading={ isSubmitting }
                        className="w-full lg:w-[100px] mt-6 text-white"
                        disabled={ isSubmitting }>
                    { t( 'common:savePaymentMethod' ) }
                </Button>
            </div>
        </form>
    );
};
