
import { useEffect, useState } from 'react';
import { Freelancer, FreelancerAccountStatusEnum } from '@/gql/graphql';
import { SessionRepository } from '@/core/auth/sessionRepository';
import FreelancerDashboardProfile from '@/components/freelancer-dashboard/component/FreelancerDashboardProfile';
import FreelancerDashboardProfileRejected from '@/components/freelancer-dashboard/component/FreelancerDashboardProfileRejected';
import FreelancerDashboardProfileIncomplete from '@/components/freelancer-dashboard/component/FreelancerDashboardProfileIncomplete';
import FreelancerDashboardActionsList from '@/components/freelancer-dashboard/component/FreelancerActions/freelancer-action-items/FreelancerActionsList';
import { useLazyQuery } from '@apollo/client';
import { CheckIfProfileIsCompletedGQL } from '../account/freelancer-account-profile-validation/checkIfProfileIsCompletedGQL';
import ListSkeleton from '@/components/ListSkeleton';

export default function FreelancerDashboard() {
    
    const sessionRepository: SessionRepository = new SessionRepository();
    const freelancer = sessionRepository.user as Freelancer;
    
    const [ getCheckProfile, { data: checkProfile } ] = useLazyQuery( CheckIfProfileIsCompletedGQL );

    const [ isLoadingRequiredData, setIsLoadingRequiredData ] = useState<boolean>(true);

    useEffect( () => {
        loadRequiredData();
    }, [] );

    async function loadRequiredData() {
        await setIsLoadingRequiredData(true);
        await getCheckProfile();
        await setIsLoadingRequiredData(false);
    }

    return (
        <div className="relative w-full h-full flex flex-col lg:flex-row justify-center mx-auto gap-4 lg:max-w-[calc(900px+16px)]">
            <div className='lg:w-1/3'>
                {
                    <FreelancerDashboardProfile 
                        freelancer={ freelancer } 
                        isLoading={ isLoadingRequiredData }
                        isProfileComplete={ checkProfile?.checkIfProfileIsCompleted?.isCompleted }
                        completedPercentage={ checkProfile?.checkIfProfileIsCompleted?.completedPercentage } />
                }
            </div>
            <div className='lg:w-2/3'>
                {
                    !isLoadingRequiredData ?
                        freelancer.accountStatus === FreelancerAccountStatusEnum.REJECTED ? (
                            <FreelancerDashboardProfileRejected rejectionReasons={freelancer.rejectedReason} />
                        ) : checkProfile?.checkIfProfileIsCompleted?.isCompleted ? (
                            <FreelancerDashboardActionsList profilePicture={freelancer.profilePicture} />
                        ) : (
                            <FreelancerDashboardProfileIncomplete
                                completedPercentage={checkProfile?.checkIfProfileIsCompleted?.completedPercentage}
                            />
                        )
                    :
                    <div className="flex flex-col gap-y-4">
                        <ListSkeleton isLoading={ true }
                            height={ 200 }
                            count={ 3 } />
                    </div>
                }
            </div>
        </div>
    );
}

