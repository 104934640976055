import { AuthGuard } from '@/core/auth/AuthGuard';
import { RoleGuard } from '@/core/auth/RoleGuard';
import { UserTypeEnum } from '@/gql/graphql';

import ClientAccountEditYTChannels from '@/pages/client/views/account/client-account-edit-yt-channels/ClientAccountEditYTChannels';
import ClientAccountEditCompanyDetails from '@/pages/client/views/account/client-account-edit-company-details/ClientAccountEditCompanyDetails';
import ClientAccountEditPassword from '@/pages/client/views/account/client-account-edit-password/ClientAccountEditPassword';
import ClientAccountEditNotifications from '@/pages/client/views/account/ClientAccountEditNotifications';
import ClientFreelancers from '@/pages/client/views/freelancers/ClientFreelancers';
import ClientProject from '@/pages/client/views/project/ClientProject';
import ClientFreelancerProfile from '@/pages/client/views/freelancer/ClientFreelancerProfile';
import ClientConversation from '@/pages/client/views/chat/ClientConversation';
import ClientAccountEditPaymentMethods from '@/pages/client/views/account/client-account-edit-payment-methods/ClientAccountEditPaymentMethods';
import ErrorBoundary from '@/pages/errors/ErrorBoundary';
import ClientProjects from '@/pages/client/views/projects/ClientProjects';
import RootClient from '@/pages/client/views/RootClient';
import ClientAccount from '@/pages/client/views/account/ClientAccount';
import ClientChat from '@/pages/client/views/chat/ClientChat';
import ClientAccountEditProfile from '@/pages/client/views/account/client-account-edit-profile/ClientAcountEditProfile';
import { Navigate } from 'react-router-dom';
import ClientFavorites from '@/pages/client/views/favorites/ClientFavorites';

export const ClientRouting = {
    path: 'client',
    element:
        <AuthGuard redirectPath="/">
            <RoleGuard allowedUserType={ UserTypeEnum.CLIENT }
                       redirectPath="/freelancer">
                <RootClient />
            </RoleGuard>
        </AuthGuard>,
    errorElement: <ErrorBoundary />,
    children: [
        {
            path: '',
            element: <Navigate to="/client/discover"
                               replace />
        },
        {
            path: 'home',
            element: <ClientFreelancers />,
            errorElement: <ErrorBoundary />
        },
        {
            path: 'discover',
            children: [
                {
                    path: '',
                    element: <ClientFreelancers />,
                    errorElement: <ErrorBoundary />
                },
                {
                    path: 'manual',
                    element: <ClientFreelancers />,
                    errorElement: <ErrorBoundary />
                }
                /*{
                 path: 'recommended',
                 element: <ClientFreelancers />,
                 errorElement: <ErrorBoundary />
                 }*/
            ]
        },
        {
            path: 'freelancers/:id',
            element: <ClientFreelancerProfile />,
            errorElement: <ErrorBoundary />
        },
        {
            path: 'p/:id',
            element: <ClientFreelancerProfile />,
            errorElement: <ErrorBoundary />
        },
        {
            path: 'favorites',
            element: <ClientFavorites />,
            errorElement: <ErrorBoundary />
        },
        {
            path: 'settings',
            element: <ClientAccount />,
            errorElement: <ErrorBoundary />,
            children: [
                {
                    path: '',
                    element: <Navigate to="/client/settings/edit-profile"
                                       replace />,
                    errorElement: <ErrorBoundary />
                },
                {
                    path: 'edit-profile',
                    element: <ClientAccountEditProfile />,
                    errorElement: <ErrorBoundary />
                },
                {
                    path: 'yt-channels',
                    element: <ClientAccountEditYTChannels />,
                    errorElement: <ErrorBoundary />
                },
                {
                    path: 'edit-password',
                    element: <ClientAccountEditPassword />,
                    errorElement: <ErrorBoundary />
                },
                {
                    path: 'notifications',
                    element: <ClientAccountEditNotifications />,
                    errorElement: <ErrorBoundary />
                },
                {
                    path: 'company-details',
                    element: <ClientAccountEditCompanyDetails />,
                    errorElement: <ErrorBoundary />
                },
                {
                    path: 'payment-methods',
                    element: <ClientAccountEditPaymentMethods />,
                    errorElement: <ErrorBoundary />
                }
            ]
        },
        {
            path: 'projects',
            children: [
                {
                    path: '',
                    element: <Navigate to={ '/client/projects/status/ongoing' }
                                       replace />
                },
                {
                    path: 'status',
                    element: <ClientProjects />,
                    children: [
                        {
                            path: ':status',
                            element: <ClientProjects />
                        }
                    ]
                },
                {
                    path: ':projectId',
                    element: <ClientProject className="mx-0 lg:mx-10 w-full" />,
                    errorElement: <ErrorBoundary />
                }
            ]
        },
        {
            path: 'messages',
            element: <ClientChat />,
            errorElement: <ErrorBoundary />,
            children: [
                {
                    path: ':conversationId',
                    element: <ClientConversation />,
                    errorElement: <ErrorBoundary />
                }
            ]
        }

    ]
};