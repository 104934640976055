import { gql } from '@apollo/client';

export const freelancerPreviewProfileGQL = gql`
    query FreelancerPreviewProfileGQL($id: String!) {
        publicFreelancer(id: $id) {
            id
            firstName
            lastName
            fullName
            experience
            profilePicture
            priceCurrency
            avgPrice
            experience
            mainJob
            countProjects
        }
    }`;


