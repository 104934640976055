import { createStore, select, withProps } from '@ngneat/elf';


export interface SignUpClientProps {
    data: {
        email: string | null;
        password: string | null;
        code: string | null;
        userName: string | null;
    };
    step: number;
    code: string;
    isLoading: boolean;
}

const signUpClientStore = createStore(
    { name: 'signUpClientProps' },
    withProps<SignUpClientProps>( {
        data: {
            email: null,
            password: null,
            code: null,
            userName: null
        },
        step: 0,
        code: null,
        isLoading: false
    } )
);

export class SignUpClientRepository {

    step$ = signUpClientStore.pipe( select( ( state ) => state.step ) );
    isLoading$ = signUpClientStore.pipe( select( ( state ) => state.isLoading ) );

    set isLoading( isLoading: boolean ) {
        signUpClientStore.update( ( state ) => {
            return {
                ...state,
                isLoading
            };
        } );
    }

    get data() {
        return signUpClientStore.getValue().data;
    }

    get code() {
        return signUpClientStore.getValue().code;
    }

    set code( code: string ) {
        signUpClientStore.update( ( state ) => {
            return {
                ...state,
                code
            };
        } );
    }

    get email(): string | null {
        return signUpClientStore.getValue().data.email;
    }

    get password(): string | null {
        return signUpClientStore.getValue().data.password;
    }

    get userName(): string | null {
        return signUpClientStore.getValue().data.userName;
    }

    get step(): number {
        return signUpClientStore.getValue().step;
    }

    set email( email: string | null ) {
        signUpClientStore.update( ( state ) => {
            return {
                ...state,
                data: {
                    ...state.data,
                    email
                }
            };
        } );
    }

    set password( password: string | null ) {
        signUpClientStore.update( ( state ) => {
            return {
                ...state,
                data: {
                    ...state.data,
                    password
                }
            };
        } );
    }

    set userName( userName: string | null ) {
        signUpClientStore.update( ( state ) => {
            return {
                ...state,
                data: {
                    ...state.data,
                    userName
                }
            };
        } );
    }

    set step( step: number ) {
        signUpClientStore.update( ( state ) => {
            return {
                ...state,
                step
            };
        } );
    }

    nextStep( count: number = 1 ) {
        signUpClientStore.update( ( state ) => {
            return {
                ...state,
                step: state.step + count
            };
        } );
    }

    previousStep( count: number = 1 ) {
        signUpClientStore.update( ( state ) => {
            return {
                ...state,
                step: state.step - count
            };
        } );
    }

    clearStore(): void {
        signUpClientStore.reset();
    }
}