import { gql } from '@apollo/client';

export const quoteGQL = gql`
    query QuoteGQL($id: String!) {
        quote(id: $id) {
            id
            vatRate
            currency
            amountExVAT
            amountIncVAT
            project {
                id
                name
                client {
                    id
                    profilePicture
                }
                freelancer {
                    id
                    profilePicture
                    firstName
                }
            }
        }
    }
`;
