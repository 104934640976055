import { useMutation } from '@apollo/client';
import { loginWithPasswordGQL } from '@/gql/global-queries/loginWithPasswordGQL';

export default function loginHook() {
    const [ loginMutation, { loading } ] = useMutation( loginWithPasswordGQL );

    const query = async( { email, password }: { email: string, password: string } ): Promise<any> => {
        return loginMutation( {
            variables: {
                args: {
                    email,
                    password
                }
            }
        } );
    };

    return { query, loading };
}