import { gql } from '@apollo/client';

export const FreelancerCharterGQL = gql`
    query FreelancerCharterGQL {
        meFreelancer {
            id
            charterAccepted
        }
    }`;


