import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';

import ProjectCard, { ProjectCardInterface } from '@/components/projects/project-card/ProjectCard';
import LayoutVisibilityHook from '@/hooks/layoutVisibilityHook';
import NoDataIcon from '@/components/NoDataIcon';
import ListSkeleton from '@/components/ListSkeleton';
import { useNavigate } from 'react-router-dom';

interface ClientProjectListDesktopProps {
    projects: ProjectCardInterface[];
    isLoading: boolean;
    loadMore: () => void;
    hasMore: boolean;
    onProjectSelected?: ( project: ProjectCardInterface ) => void;
}

export default function ClientProjectListDesktop( {
                                                      projects,
                                                      isLoading,
                                                      loadMore,
                                                      hasMore,
                                                      onProjectSelected
                                                  }: ClientProjectListDesktopProps ) {

    LayoutVisibilityHook( {
        headerVisible: true,
        bottomNavBarVisible: false
    } );

    const { t } = useTranslation();

    const skeleton = ( limit: number ) => {
        return (
            <div className="flex flex-col gap-y-4">
                <ListSkeleton isLoading={ true }
                              height={ 130 }
                              count={ limit } />
            </div>
        );
    };

    const handleProjectSelected = ( project: ProjectCardInterface ) => onProjectSelected?.( project );

    if( isLoading && !projects?.length ) {
        return skeleton( 4 );
    }

    if( projects?.length > 0 ) {
        return (
            <InfiniteScroll className="w-full pb-10 flex flex-col gap-y-4"
                            dataLength={ projects?.length }
                            next={ () => loadMore() }
                            pullDownToRefresh={ false }
                            hasMore={ hasMore }
                            loader={ skeleton( 4 ) }>
                {
                    projects?.map( ( project, index ) => (
                        <div key={ index }
                             onClick={ () => handleProjectSelected( project ) }>
                            <ProjectCard project={ project } />
                        </div>
                    ) )
                }
            </InfiniteScroll>
        );
    }

    return (
        <div className="flex justify-center items-center">
            <NoDataIcon message={ t( 'client:projects.noDataFound' ) }
                        subMessage={ t( 'client:projects.noDataFoundSubMessage' ) }
                        button={ {
                            text: t( 'client:projects.noDataFoundButton' ),
                            to: '/client/discover'
                        } } />
        </div>
    );
}