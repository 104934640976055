import { gql } from '@apollo/client';

export const addChannelGQL = gql`
    mutation AddChannel($code: String!) {
        addChannelToUser(code: $code) {
            id
            name
            thumbnailUrl
            subscriberCount
            videoCount
            url
            acquisitionStatus
        }
    }
`;