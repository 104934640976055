import Paragraphs from '@/components/Paragraphs';
import { ReviewUI } from '@/components/freelance-profile/components/freelancer-profile-reviews/reviewUI';
import { Avatar } from 'antd';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { UserOutlined } from '@ant-design/icons';
interface FreelancerProfileReviewItemProps {
    review: ReviewUI;
}

export default function FreelancerProfileReviewItem( { review }: FreelancerProfileReviewItemProps ) {

    const { t } = useTranslation();

    return (
        <div className="flex flex-col gap-y-4">
            <div className="flex items-center gap-4">
                <Avatar size={ 64 }
                    className="flex-none"
                    icon={ <UserOutlined /> }
                    src={ review.author?.profilePictureUrl } />
                <div>
                    <div className="font-bold">
                        { review.author?.userName }
                    </div>
                    <span className="text-sm text-grey">{ DateTime.fromISO( review.createdAt ).toFormat( 'MMMM yyyy' ) }</span>
                </div>
            </div>
            <div>
                <Paragraphs text={ review.comment }
                            className="!text-sm text-black"
                            maxLength={ 180 } />
            </div>
        </div>
    );
}