import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SignUpFreelancerRepository } from '@/pages/auth/sign-up/sign-up-freelancer//signUpFrelancerRepository';
import { Controller, useForm } from 'react-hook-form';
import { Divider, Switch } from 'antd';
import FreelancerCard from '@/components/freelance-profile/FreelancerCard';
import { Freelancer } from '@/gql/graphql';

const SignUpFreelancerStepPreview = forwardRef( function( props, ref ) {


    const { t } = useTranslation();
    const { trigger, control, watch, formState: { errors } } = useForm();
    const repository = new SignUpFreelancerRepository();

    const [ profile, setProfile ] = useState<Freelancer>( {
        id: '',
        firstName: repository.firstName,
        lastName: repository.lastName,
        avgPrice: repository.avgPrice,
        experience: repository.experience,
        creatorsIWorkedWith: repository.portfolioChannels,
        portfolioElements: repository.portfolioVideos,
        isAvailable: repository.isAvailable,
        mainJob: repository.mainJobLabel
    } );

    const [ imageUrl, setImageUrl ] = useState( null );

    const subscription = watch( ( value ) => {
        setProfile( ( prevProfile ) => ( {
            ...prevProfile,
            isAvailable: value.isAvailable
        } ) );
        repository.updateData( value );
    } );

    useEffect( () => {
        if( !imageUrl && repository.profilePicture ) {
            const reader = new FileReader();
            reader.onload = ( e ) => {
                const localImageUrl = e.target.result;
                setImageUrl( localImageUrl );
                setProfile( ( prevProfile ) => ( {
                    ...prevProfile,
                    profilePicture: localImageUrl as string
                } ) );
            };
            reader.readAsDataURL( repository.profilePicture );
        }
    }, [ repository ] );


    useEffect( () => {
        return () => subscription.unsubscribe();
    }, [] );

    useImperativeHandle( ref, () => ( {
        async validateFields() {
            return trigger();
        }
    } ) );

    return (

        <div className="flex flex-col w-full">
            <div className="justify-center self-center max-w-[308px]">
                <FreelancerCard profile={ profile }
                                favoriteButton={ false }
                                enableClick={ true } />
            </div>
            <Divider className="mt-8 mb-5" />
            <div className="flex justify-between">
                <div>
                    <div className="font-medium text-base">
                        { t( 'auth:sign-up.freelancer.preview-page.isAvailableLabel' ) }
                    </div>

                    <div className="text-xs text-grey mt-1">
                        { t( 'auth:sign-up.freelancer.preview-page.isAvailableDescription' ) }
                    </div>
                </div>
                <Controller
                    control={ control }
                    name="isAvailable"
                    defaultValue={ repository.isAvailable }
                    rules={ { required: t( 'common:error.form.required' ) } }
                    render={ ( { field } ) => (
                        <Switch
                            className="primary"
                            checked={ field.value }
                            onChange={ ( value ) => field.onChange( value ) }
                        />

                    ) }
                />
            </div>
            <Divider />
        </div>
    );
} );

export default SignUpFreelancerStepPreview;
