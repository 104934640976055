import './styles.scss';
import './i18n';
import './plugins/sentry';
import { StrictMode } from 'react';

import ReactDOM from 'react-dom/client';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { enableElfProdMode } from '@ngneat/elf';

import { RouterProvider } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { client } from './plugins/apollo';
import { router } from './appRouting';
import { Elements } from '@stripe/react-stripe-js';
import ColorSchemeProvider from './core/dark-mode/colorSchemeProvider';
import CometChatProvider from '@/plugins/CometChatProvider';
import SessionProvider from '@/core/auth/SessionProvider';
import reportWebVitals from './reportWebVitals';
import buildProvidersTree from '@/utils/buildProvidersTree';
import IntercomProvider from '@/plugins/intercom/IntercomProvider';
import AppProvider from '@/core/AppProvider';
import FreelancersRepositoryProvider from '@/core/FreelancerRepositoryContext';
const googleClientId: string = import.meta.env.VITE_GOOGLE_CLIENT_ID;
const stripePublishableKey: string = import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY;

if( import.meta.env.VITE_ENV == 'production' ) {
    enableElfProdMode();
}

const stripePromise: Promise<Stripe> = loadStripe( stripePublishableKey );

const ProvidersTree = buildProvidersTree( [
        [ StrictMode ],
        [ ApolloProvider, { client } ],
        [ SessionProvider ],
        [ CometChatProvider ],
        [ GoogleOAuthProvider, { clientId: googleClientId } ],
        [ IntercomProvider ],
        [ ColorSchemeProvider ],
        [FreelancersRepositoryProvider],
        [ Elements, { stripe: stripePromise } ],
        [ AppProvider ],
        [ RouterProvider, { router } ]
    ]
);

ReactDOM.createRoot( document.getElementById( 'root' ) ).render(
    <ProvidersTree />
);

reportWebVitals();