import { gql } from '@apollo/client';

export const FreelancerCompanyDetailsGQL = gql`
    query FreelancerCompanyDetailsGQL {
        meFreelancer {
            id
            vatRate
            addressName
            addressStreet1
            addressStreet2
            addressCity
            addressState
            addressPostalCode
            addressCountry
        }
    }`;


