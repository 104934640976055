import { Slider } from 'antd';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import { SessionRepository } from '@/core/auth/sessionRepository';
import { CurrencyEnum } from '@/gql/graphql';
import { priceFormatter } from '@/utils/priceFormatter';
import { FreelancersRepository } from '@/components/freelancers-filter-items/freelancersRepository';
import { FreelancersRepositoryContext } from '@/core/FreelancerRepositoryContext';
import { useObservable } from '@ngneat/react-rxjs';


interface AVGPriceRange {
    min: number;
    max: number;
}

export default function FilterItemAVGPriceRange() {
    const { t } = useTranslation();
    const freelancersRepository: FreelancersRepository = useContext( FreelancersRepositoryContext );
    const session: SessionRepository = new SessionRepository();

    const min: number = 0,
          max: number = 2000;

    const [avgPriceMin] = useObservable(freelancersRepository.avgPriceMin$);
    const [avgPriceMax] = useObservable(freelancersRepository.avgPriceMax$);

    const avgPriceMarks = {
        [avgPriceMin]: priceFormatter({
            amount: avgPriceMin,
            currency: session.user?.currency || CurrencyEnum.USD
        }),
        [avgPriceMax]: priceFormatter({
            amount: avgPriceMax,
            currency: session.user?.currency || CurrencyEnum.USD
        })
    };

    const onChangeAvgRate = (value: number[]) => {
        freelancersRepository.setAvgPriceMin(+value[0]);
        freelancersRepository.setAvgPriceMax(+value[1]);
    };

    return (
        <Slider range
                min={min}
                max={max}
                step={100}
                marks={avgPriceMarks}
                className="mt-7"
                onChange={onChangeAvgRate}
                defaultValue={[avgPriceMin, avgPriceMax]}
        />
    );
}