import { createStore } from '@ngneat/elf';
import { deleteEntities, selectEntities, setEntities, UIEntitiesRef, unionEntities, withEntities, withUIEntities } from '@ngneat/elf-entities';
import { Action } from '@/gql/graphql';
import { map } from 'rxjs';

export interface ActionUI {
    id: number;
    new: boolean;
}

const actionStore = createStore(
    { name: 'action' },
    withEntities<Action>(),
    withUIEntities<ActionUI>()
);

export class ActionsRepository {

    actions$ = actionStore.combine( {
        entities: actionStore.pipe( selectEntities(), map( ( entities: Record<string, Action> ) => {
            const actions = Object.values( entities );
            if( actions.length == 0 ) {
                return [];
            }
            return actions;
        } ) ),
        UIEntities: actionStore.pipe( selectEntities( { ref: UIEntitiesRef } )
        )
    } ).pipe( unionEntities() );

    get actions(): Action[] {
        return Object.values( actionStore.getValue().entities );
    }

    set actions( actions: Action[] ) {
        actionStore.update( setEntities( actions ) );
    }

    hideAction( actionId: string ) {
        actionStore.update( deleteEntities( actionId ) );
    }

}