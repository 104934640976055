import { ConversationItemMessage } from '@/components/chat/interfaces/conversationItemMessage';
import Linkify from 'linkify-react';

interface ChatConversationItemMessageProps {
    item: ConversationItemMessage;
}

export default function ChatConversationItemMessage( { item }: ChatConversationItemMessageProps ) {

    return (
        <Linkify as="p" options={{
            className: 'underline hover:text-primary-400',
            target: {
                url: '_blank'
            }
        }}>
            { item.message }
        </Linkify>
    );
}