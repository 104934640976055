import { PortfolioElement } from '@/gql/graphql';
import { ReorderOutlinedIcon, TrashOutlinedIcon } from '@/assets/icons/CustomIcons';
import abbreviationNumberFormatter from '@/utils/abbrevationNumberFormatter';
import { useTranslation } from 'react-i18next';
import { cuttingTextFormatter } from '@/utils/cuttingTextFormatter';
import { Button } from 'antd';

interface FreelancerAccountEditPortfolioListItemProps {
    portfolioElement: PortfolioElement;
    isDeleting: boolean;
    onDelete: ( portfolioElement: PortfolioElement ) => void;
}

export default function FreelancerAccountEditPortfolioListItem( {
                                                                    portfolioElement,
                                                                    onDelete,
                                                                    isDeleting
                                                                }: FreelancerAccountEditPortfolioListItemProps ) {

    const { t } = useTranslation();

    const handleDelete = () => {
        onDelete( portfolioElement );
    };

    return (
        <div className="bg-white flex items-center justify-between border border-grey-light px-3 lg:px-6 py-2 rounded-xl cursor-grab card-shadow">
            <div className="flex items-center">
                <ReorderOutlinedIcon className="text-grey text-[16px]" />
                <img src={ portfolioElement?.thumbnailUrl }
                     alt="thumbnail"
                     className="w-[70px] h-[40px] object-cover rounded-lg border-[4px] border-grey-light  ml-4" />
                <div className="flex flex-col justify-between ml-4">
                    <p className="text-sm font-semibold">
                        { cuttingTextFormatter( portfolioElement?.title, 30 ) }
                    </p>
                    <p className="font-light text-xs mr-4">
                        { cuttingTextFormatter( portfolioElement?.portfolioChannel?.name, 15 ) } • { abbreviationNumberFormatter( portfolioElement?.viewCount ) } { t( 'freelancer:account.edit-portfolio-elements.views' ) }
                    </p>
                </div>
            </div>

            <Button type="link"
                    loading={ isDeleting }
                    onClick={ () => handleDelete() }>
                {
                    !isDeleting &&
                    <TrashOutlinedIcon className="text-red text-lg" />
                }
            </Button>
        </div>
    );
}