import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import SignUpClientStepPersonalInfo from '@/pages/auth/sign-up/sign-up-client/sign-in-client-steps/SignUpClientStepPersonalInfo';
import SignUpClientStepAuthentication from '@/pages/auth/sign-up/sign-up-client/sign-in-client-steps/SignUpClientStepAuthentication';
import SignUpClientStepYoutubeChannel from '@/pages/auth/sign-up/sign-up-client/sign-in-client-steps/SignUpClientStepYoutubeChannel';
import { SignUpClientRepository } from '@/pages/auth/sign-up/sign-up-client/signUpClientRepository';
import { useMutation } from '@apollo/client';
import { App } from 'antd';
import { SessionRepository } from '@/core/auth/sessionRepository';
import { createClientWithPasswordGQL } from '@/gql/global-queries/createClientWithPasswordGQL';
import { createClientWithGoogleGQL } from '@/gql/global-queries/createClientWithGoogleGQL';
import { ClientCreatedOutput } from '@/gql/graphql';

enum ProgressBarItemEnum {
    AUTHENTICATION  = 'AUTHENTICATION',
    PERSONAL_INFO   = 'PERSONAL_INFO',
    YOUTUBE_CHANNEL = 'YOUTUBE_CHANNEL',
    WELCOME         = 'WELCOME'
}

export function signUpClientHook() {

    const { t } = useTranslation( 'auth' );
    const { message } = App.useApp();
    const navigate = useNavigate();

    const [ currentStep, setCurrentStep ] = useState( 0 );
    const [ isLoading, setIsLoading ] = useState( false );

    const authenticationRef = useRef( null );
    const personalInfoRef = useRef( null );

    const signUpClientRepository: SignUpClientRepository = new SignUpClientRepository();
    const sessionRepository: SessionRepository = new SessionRepository();

    const createClientWithPasswordMutation = useMutation( createClientWithPasswordGQL );
    const createClientWithGoogleMutation = useMutation( createClientWithGoogleGQL );

    useEffect( () => {
        const subscribe = signUpClientRepository.isLoading$.subscribe( ( loading ) => {
            setIsLoading( loading );
        } );

        return () => subscribe.unsubscribe();
    }, [] );

    const items = [
        {
            progressBarId: ProgressBarItemEnum.AUTHENTICATION,
            titleLeft: t( 'auth:sign-up.client.authentication-page.titleLeft' ),
            titleRight: t( 'auth:sign-up.client.authentication-page.titleRight' ),
            subtitle: t( 'auth:sign-up.client.authentication-page.subtitle' ),
            content: <SignUpClientStepAuthentication ref={ authenticationRef } />
        },
        {
            progressBarId: ProgressBarItemEnum.PERSONAL_INFO,
            titleLeft: t( 'auth:sign-up.client.personal-info-page.titleLeft' ),
            titleRight: t( 'auth:sign-up.client.personal-info-page.titleRight' ),
            subtitle: t( 'auth:sign-up.client.personal-info-page.subtitle' ),
            content: <SignUpClientStepPersonalInfo ref={ personalInfoRef } />
        },
        {
            progressBarId: ProgressBarItemEnum.YOUTUBE_CHANNEL,
            titleLeft: t( 'auth:sign-up.client.youtube-channel-page.titleLeft' ),
            titleRight: t( 'auth:sign-up.client.youtube-channel-page.titleRight' ),
            subtitle: t( 'auth:sign-up.client.youtube-channel-page.subtitle' ),
            content: <SignUpClientStepYoutubeChannel />
        }
    ];

    const progressBar = [
        {
            id: ProgressBarItemEnum.AUTHENTICATION,
            label: t( 'auth:sign-up.client.steps.authentication' )
        },
        {
            id: ProgressBarItemEnum.PERSONAL_INFO,
            label: t( 'auth:sign-up.client.steps.personalInfo' )
        },
        {
            id: ProgressBarItemEnum.YOUTUBE_CHANNEL,
            label: t( 'auth:sign-up.client.steps.youtubeChannel' )
        },
        {
            id: ProgressBarItemEnum.WELCOME,
            label: t( 'auth:sign-up.client.steps.welcome' )
        }
    ];

    useEffect( () => {
        const observable = signUpClientRepository.step$.subscribe( ( step ) => {
            if( step != currentStep ) {
                setCurrentStep( step );
            }
        } );
        return () => {
            observable.unsubscribe();
        };
    }, [ currentStep ] );

    useEffect( () => {
        return () => signUpClientRepository.clearStore();
    }, [] );

    const convertCurrentStepInProgressBarIndex = (): number => {
        const id: ProgressBarItemEnum = items[ currentStep ]?.progressBarId;
        return progressBar.findIndex( item => item.id == id );
    };

    const signUpWithPassword = async() => {
        const response = await createClientWithPasswordMutation[ 0 ]( {
            variables: {
                args: {
                    email: signUpClientRepository.email,
                    password: signUpClientRepository.password,
                    userName: signUpClientRepository.userName
                }
            }
        } );
        return response.data.createClientWithPassword;
    };

    const signUpWithGoogle = async() => {
        const response = await createClientWithGoogleMutation[ 0 ]( {
            variables: {
                args: {
                    userName: signUpClientRepository.userName,
                    code: signUpClientRepository.code
                }
            }
        } );
        return response.data.createClientWithGoogle;
    };

    const submit = async(): Promise<boolean> => {
        signUpClientRepository.isLoading = true;
        let clientCreated: ClientCreatedOutput;
        if( signUpClientRepository.code ) {
            try {
                clientCreated = await signUpWithGoogle();
            } catch( e ) {
                message.error( t( 'auth:sign-up.freelancer.errors.submitError' ) );
                signUpClientRepository.isLoading = false;
                return false;
            }
        }
        else {
            try {
                clientCreated = await signUpWithPassword();
            } catch( e ) {
                message.error( t( 'auth:sign-up.freelancer.errors.submitError' ) );
                signUpClientRepository.isLoading = false;
                return false;
            }
        }

        sessionRepository.updateUser( clientCreated.client );
        sessionRepository.setTokens( clientCreated.token?.accessToken, clientCreated.token?.refreshToken, clientCreated.token?.cometchatAuthToken );
        signUpClientRepository.isLoading = false;
        return true;
    };

    const nextStep = async() => {
        switch( currentStep ) {
            case 0:
                const step0IsValid = await authenticationRef.current.validateFields();
                if( step0IsValid ) {
                    signUpClientRepository.nextStep();
                }
                break;
            case 1:
                const step1IsValid = await personalInfoRef.current.validateFields();
                if( step1IsValid ) {
                    const accountCreated: boolean = await submit();
                    if( accountCreated ) {
                        signUpClientRepository.nextStep();
                    }
                }
                break;
            case 2:
                navigate( '/auth/sign-up/client/welcome', { replace: true } );
                break;
        }
    };

    const previousStep = () => {
        if( currentStep == 0 ) {
            signUpClientRepository.clearStore();
            navigate( '/auth/sign-up', { replace: true } );
        }

        signUpClientRepository.previousStep();
    };

    return {
        items,
        progressBar,
        currentStep,
        isLoading,
        nextStep,
        previousStep,
        currentProgressBarIndex: convertCurrentStepInProgressBarIndex
    };

}