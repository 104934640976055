import { gql } from '@apollo/client';

export const FavoritesGQL = gql`
    query Favorites($page: Int, $limit: Int) {
        favorites(page: $page, limit: $limit) {
            data {
                id
                freelancer {
                    id
                    firstName
                    lastName
                    profilePicture
                    isFavorite
                    averageRate
                    countProjects
                    mainJob
                    avgPrice
                    priceCurrency
                    experience
                    isAvailable
                    isCertified
                    accountStatus
                    creatorsIWorkedWith {
                        id
                        name
                        channelUrl
                        thumbnailUrl
                        subscriberCount
                    }
                    portfolioElements {
                        id
                        url
                    }
                }
                createdAt
            }
            page
            limit
            lastPage
            total
        }
    }`;


