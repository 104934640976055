import { createStore, select, withProps } from '@ngneat/elf';
import { CurrencyEnum, ExperienceTypeEnum, PortfolioChannel, PortfolioElement } from '@/gql/graphql';
import { RcFile } from 'antd/es/upload';

import parsePhoneNumber, { CountryCode, getCountryCallingCode, PhoneNumber } from 'libphonenumber-js';

export interface SignUpFreelancerProps {
    data: {
        email: string | null;
        firstName: string | null;
        lastName: string | null;
        phoneCodeCountry: string | null,
        phoneNumber: string | null,
        password: string | null;
        isAgreeForNewsletter: boolean | null;
        avgPrice: number | null;
        bio: string | null;
        profilePicture: RcFile | null;
        profilePictureVirtualUrl: string | null;
        profilePictureUploaderRef: null;
        currency: CurrencyEnum | null;
        mainJobId: string | null;
        mainJobLabel: string | null;
        jobIds: string[] | null;
        experience: ExperienceTypeEnum | null;
        skillIds: string[] | null;
        portfolioChannels: PortfolioChannel[];
        portfolioVideos: PortfolioElement[];
        isAvailable: boolean;
        languageIds: string[] | null;
        expertiseIds: string[] | null;
        vatRate: number | null,
        companyStreet: string | null;
        companyStreet2: string | null;
        companyCity: string | null;
        companyState: string | null;
        companyPostalCode: string | null;
        companyName: string | null;
        companyCountry: string | null;
        companyCountryCode: string | null;
    };
    step: number;
    isLoadingNextStep: boolean;
    lastCodeSentAt?: Date;
    phoneNumberVerified: boolean | null;
}

const signUpFreelancerStore = createStore(
    { name: 'signUpFreelancer' },
    withProps<SignUpFreelancerProps>( {
        data: {
            email: null,
            firstName: null,
            lastName: null,
            password: null,
            isAgreeForNewsletter: null,
            avgPrice: null,
            bio: null,
            profilePicture: null,
            profilePictureVirtualUrl: null,
            profilePictureUploaderRef: null,
            phoneCodeCountry: null,
            phoneNumber: null,
            currency: CurrencyEnum.USD,
            mainJobId: null,
            mainJobLabel: null,
            experience: null,
            isAvailable: true,
            jobIds: null,
            skillIds: null,
            portfolioChannels: [],
            portfolioVideos: [],
            languageIds: null,
            expertiseIds: null,
            vatRate: null,
            companyStreet: null,
            companyStreet2: null,
            companyCity: null,
            companyState: null,
            companyPostalCode: null,
            companyName: null,
            companyCountry: null,
            companyCountryCode: null
        },
        step: 0,
        isLoadingNextStep: false,
        lastCodeSentAt: null,
        phoneNumberVerified: null
    } )
);

export class SignUpFreelancerRepository {

    step$ = signUpFreelancerStore.pipe( select( ( state ) => state.step ) );

    isLoadingNextStep$ = signUpFreelancerStore.pipe( select( ( state ) => state.isLoadingNextStep ) );

    get data() {
        return signUpFreelancerStore.getValue().data;
    }

    set data( data: SignUpFreelancerProps['data'] ) {
        signUpFreelancerStore.update( ( state ) => {
            return {
                ...state,
                data
            };
        } );
    }

    get email(): string {
        return signUpFreelancerStore.getValue().data.email;
    }

    set email( email: string ) {
        signUpFreelancerStore.update( ( state ) => {
            return {
                ...state,
                data: {
                    ...state.data,
                    email
                }
            };
        } );
    }

    get firstName(): string {
        return signUpFreelancerStore.getValue().data.firstName;
    }

    get lastName(): string {
        return signUpFreelancerStore.getValue().data.lastName;
    }

    get password(): string {
        return signUpFreelancerStore.getValue().data.password;
    }

    get experience(): ExperienceTypeEnum {
        return signUpFreelancerStore.getValue().data.experience;
    }

    set experience( experience: ExperienceTypeEnum ) {
        signUpFreelancerStore.update( ( state ) => {
            return {
                ...state,
                data: {
                    ...state.data,
                    experience
                }
            };
        } );
    }

    get companyStreet(): string {
        return signUpFreelancerStore.getValue().data.companyStreet;
    }

    get companyStreet2(): string {
        return signUpFreelancerStore.getValue().data.companyStreet2;
    }

    get companyCity(): string {
        return signUpFreelancerStore.getValue().data.companyCity;
    }

    get companyState(): string {
        return signUpFreelancerStore.getValue().data.companyState;
    }

    get companyPostalCode(): string {
        return signUpFreelancerStore.getValue().data.companyPostalCode;
    }

    get companyName(): string {
        return signUpFreelancerStore.getValue().data.companyName;
    }

    get companyCountry(): string {
        return signUpFreelancerStore.getValue().data.companyCountry;
    }

    set companyCountry( companyCountry: string ) {
        signUpFreelancerStore.update( ( state ) => {
            return {
                ...state,
                data: {
                    ...state.data,
                    companyCountry
                }
            };
        } );
    }

    get companyCountryCode(): string {
        return signUpFreelancerStore.getValue().data.companyCountryCode;
    }

    set companyCountryCode( companyCountryCode: string ) {
        signUpFreelancerStore.update( ( state ) => {
            return {
                ...state,
                data: {
                    ...state.data,
                    companyCountryCode
                }
            };
        } );
    }

    get isAvailable(): boolean {
        return signUpFreelancerStore.getValue().data.isAvailable;
    }

    get avgPrice(): number {
        return signUpFreelancerStore.getValue().data.avgPrice;
    }

    set avgPrice( avgPrice: number ) {
        signUpFreelancerStore.update( ( state ) => {
            return {
                ...state,
                data: {
                    ...state.data,
                    avgPrice
                }
            };
        } );
    }

    get phoneNumberVerified(): boolean {
        return signUpFreelancerStore.getValue().phoneNumberVerified;
    }

    set phoneNumberVerified( phoneNumberVerified: boolean ) {
        signUpFreelancerStore.update( ( state ) => {
            return {
                ...state,
                phoneNumberVerified
            };
        } );
    }

    get lastCodeSentAt(): Date {
        return signUpFreelancerStore.getValue().lastCodeSentAt;
    }

    set lastCodeSentAt( lastCodeSentAt: Date ) {
        signUpFreelancerStore.update( ( state ) => {
            return {
                ...state,
                lastCodeSentAt
            };
        } );
    }

    get bio(): string {
        return signUpFreelancerStore.getValue().data.bio;
    }

    set bio( bio: string ) {
        signUpFreelancerStore.update( ( state ) => {
            return {
                ...state,
                data: {
                    ...state.data,
                    bio
                }
            };
        } );
    }

    get profilePicture(): RcFile {
        return signUpFreelancerStore.getValue().data.profilePicture;
    }

    set profilePicture( profilePicture: RcFile ) {
        signUpFreelancerStore.update( ( state ) => {
            return {
                ...state,
                data: {
                    ...state.data,
                    profilePicture
                }
            };
        } );
    }

    get phoneCodeCountry(): string {
        return signUpFreelancerStore.getValue().data.phoneCodeCountry;
    }

    get phoneCodeCountryNumber(): string {
        if( !this.phoneCodeCountry ) {
            return null;
        }

        return `+${ getCountryCallingCode( this.phoneCodeCountry as CountryCode ) }`;
    }

    get phoneNumber(): string {
        return signUpFreelancerStore.getValue().data.phoneNumber;
    }

    get fullPhoneNumber(): string {
        const phoneNumber: string = this.phoneNumber;

        if( !phoneNumber ) {
            return null;
        }

        return `${ this.phoneCodeCountryNumber }${ this.phoneNumber }`;
    }

    get fullPhoneNumberFormatted(): string {
        const fullPhoneNumber: string = this.fullPhoneNumber;

        if( !fullPhoneNumber ) {
            return null;
        }

        const phoneNumber: PhoneNumber = parsePhoneNumber( this.fullPhoneNumber );
        return phoneNumber.format( 'E.164' );
    }

    get profilePictureVirtualUrl(): string {
        return signUpFreelancerStore.getValue().data.profilePictureVirtualUrl;
    }

    set profilePictureVirtualUrl( profilePictureVirtualUrl: string ) {
        signUpFreelancerStore.update( ( state ) => {
            return {
                ...state,
                data: {
                    ...state.data,
                    profilePictureVirtualUrl
                }
            };
        } );
    }

    get profilePictureUploaderRef(): any {
        return signUpFreelancerStore.getValue().data.profilePictureUploaderRef;
    }

    set profilePictureUploaderRef( profilePictureUploaderRef: any ) {
        signUpFreelancerStore.update( ( state ) => {
            return {
                ...state,
                data: {
                    ...state.data,
                    profilePictureUploaderRef
                }
            };
        } );
    }


    get currency(): CurrencyEnum {
        return signUpFreelancerStore.getValue().data.currency;
    }

    set currency( currency: CurrencyEnum ) {
        signUpFreelancerStore.update( ( state ) => {
            return {
                ...state,
                data: {
                    ...state.data,
                    currency
                }
            };
        } );
    }

    get skillIds(): string[] {
        return signUpFreelancerStore.getValue().data.skillIds;
    }

    set skillIds( skillIds: string[] ) {
        signUpFreelancerStore.update( ( state ) => {
            return {
                ...state,
                data: {
                    ...state.data,
                    skillIds
                }
            };
        } );
    }


    get portfolioChannels(): PortfolioChannel[] {
        return signUpFreelancerStore.getValue().data.portfolioChannels;
    }

    set portfolioChannels( portfolioChannels: PortfolioChannel[] ) {
        signUpFreelancerStore.update( ( state ) => {
            return {
                ...state,
                data: {
                    ...state.data,
                    portfolioChannels
                }
            };
        } );
    }

    get portfolioVideos(): PortfolioElement[] {
        return signUpFreelancerStore.getValue().data.portfolioVideos;
    }

    set portfolioVideos( portfolioVideos: PortfolioElement[] ) {
        signUpFreelancerStore.update( ( state ) => {
            return {
                ...state,
                data: {
                    ...state.data,
                    portfolioVideos
                }
            };
        } );
    }

    get jobIds(): string[] {
        return signUpFreelancerStore.getValue().data.jobIds;
    }

    set jobIds( jobIds: string[] ) {
        signUpFreelancerStore.update( ( state ) => {
            return {
                ...state,
                data: {
                    ...state.data,
                    jobIds
                }
            };
        } );
    }

    get mainJobId(): string {
        return signUpFreelancerStore.getValue().data.mainJobId;
    }

    set mainJobId( mainJobId: string ) {
        signUpFreelancerStore.update( ( state ) => {
            return {
                ...state,
                data: {
                    ...state.data,
                    mainJobId
                }
            };
        } );
    }


    get mainJobLabel(): string {
        return signUpFreelancerStore.getValue().data.mainJobLabel;
    }

    set mainJobLabel( mainJobLabel: string ) {
        signUpFreelancerStore.update( ( state ) => {
            return {
                ...state,
                data: {
                    ...state.data,
                    mainJobLabel
                }
            };
        } );
    }

    get vatRate(): number {
        return +signUpFreelancerStore.getValue().data.vatRate;
    }

    set vatRate( vatRate: number ) {
        signUpFreelancerStore.update( ( state ) => {
            return {
                ...state,
                data: {
                    ...state.data,
                    vatRate
                }
            };
        } );
    }

    get languageIds(): string[] {
        return signUpFreelancerStore.getValue().data.languageIds;
    }

    set languageIds( languageIds: string[] ) {
        signUpFreelancerStore.update( ( state ) => {
            return {
                ...state,
                data: {
                    ...state.data,
                    languageIds
                }
            };
        } );
    }

    get expertiseIds(): string[] {
        return signUpFreelancerStore.getValue().data.expertiseIds;
    }

    set expertiseIds( expertiseIds: string[] ) {
        signUpFreelancerStore.update( ( state ) => {
            return {
                ...state,
                data: {
                    ...state.data,
                    expertiseIds
                }
            };
        } );
    }

    get isAgreeForNewsletter(): boolean {
        return signUpFreelancerStore.getValue().data.isAgreeForNewsletter;
    }

    set props( props: SignUpFreelancerProps ) {
        signUpFreelancerStore.update( ( state ) => {
            return {
                ...state,
                ...props
            };
        } );
    }

    set isLoadingNextStep( isLoadingNextStep: boolean ) {
        signUpFreelancerStore.update( ( state ) => {
            return {
                ...state,
                isLoadingNextStep
            };
        } );
    }

    updateData( data: Partial<SignUpFreelancerProps['data']> ) {
        signUpFreelancerStore.update( ( state ) => {
            return {
                ...state,
                data: {
                    ...state.data,
                    ...data
                }
            };
        } );
    }

    nextStep( count: number = 1 ) {
        signUpFreelancerStore.update( ( state ) => {
            return {
                ...state,
                step: state.step + count
            };
        } );
    }

    previousStep( count: number = 1 ) {
        signUpFreelancerStore.update( ( state ) => {
            return {
                ...state,
                step: state.step - count
            };
        } );
    }

    clearJobIds() {
        signUpFreelancerStore.update( ( state ) => ( { ...state, args: { ...state.data, jobIds: null } } ) );
    }

    addJobId( jobId: string ) {
        signUpFreelancerStore.update( ( state ) => ( {
            ...state,
            args: { ...state.data, jobIds: [ ...state.data.jobIds, jobId ] }
        } ) );
    }

    removeJobId( jobId: string ) {
        signUpFreelancerStore.update( ( state ) => ( {
            ...state,
            args: { ...state.data, jobIds: state.data.jobIds.filter( id => id !== jobId ) }
        } ) );
    }

    clearStore(): void {
        signUpFreelancerStore.reset();
    }
}