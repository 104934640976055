import { useEffect, useState } from 'react';
import { ConversationItem } from '@/components/chat/interfaces/conversationItem';
import { CometChat } from '@cometchat-pro/chat';
import { ChatRepository, Conversation } from '@/components/chat/ChatRepository';
import { SessionRepository } from '@/core/auth/sessionRepository';
import { useParams, useLocation } from 'react-router-dom';
import { conversationFromCometChatConversation, conversationItemMessageFromCometChatRawMessage } from '@/components/chat/chatUtils';
import ConversationCustomItemFormattingHook from '@/components/chat/hooks/ConversationCustomItemFormattingHook';
import { ConversationItemMessage } from '@/components/chat/interfaces/conversationItemMessage';
import { ConversationItemOffer } from '@/components/chat/interfaces/conversationItemItemOffer';
import { ConversationItemQuote } from '@/components/chat/interfaces/conversationItemQuote';
import { ConversationItemActionQuote } from '@/components/chat/interfaces/conversationItemActionQuote';
import { ConversationItemActionOffer } from '@/components/chat/interfaces/conversationItemActionOffer';
import * as Sentry from '@sentry/react';
import { ConversationReceiverProfileUI } from '@/components/chat/interfaces/conversationReceiverProfileUI';
import CometChatHelper = CometChat.CometChatHelper;

export default function ChatConversationHook() {

    const [ conversationItems, setConversationItems ] = useState<ConversationItem[]>( [] );
    const [ receiverId, setReceiverId ] = useState<string>( '' );
    const [ receiver, setReceiver ] = useState<ConversationReceiverProfileUI>( null );
    const [ messagesRequest, setMessagesRequest ] = useState<CometChat.MessagesRequest>( null );
    const [ hasMore, setHasMore ] = useState<boolean>( true );
    const [ isLoadingMessages, setIsLoadingMessages ] = useState<boolean>( true );
    const [ isLoadingConversation, setIsLoadingConversation ] = useState<boolean>( true );
    const [ isReceiverTyping, setIsReceiverTyping ] = useState<boolean>( false );
    const [ isOnline, setIsOnline ] = useState<boolean>( false );

    const chatRepository: ChatRepository = new ChatRepository();
    const sessionRepository: SessionRepository = new SessionRepository();

    const {
              conversationItemCustomFromCometChatRawCustomMessage,
              conversationItemActionFromCometChatAction
          } = ConversationCustomItemFormattingHook();

    const { conversationId } = useParams();
    const location = useLocation();
    const messageIdParam = new URLSearchParams(location.search).get('messageId');
    const messageLimit = 20;

    useEffect( () => {
        if( conversationId ) {
            const receiverId = conversationId.split( '_user_' ).find( ( id ) => id != sessionRepository.userId );
            setReceiverId( receiverId );
        }

        const isOnlineObservable = watchIsOnline();
        const conversationObservable = watchConversation();
        const isTypingObservable = watchReceiverTyping();

        return () => {
            endTyping();
            setMessagesRequest( null );
            isOnlineObservable.unsubscribe();
            conversationObservable.unsubscribe();
            isTypingObservable.unsubscribe();
        };
    }, [ conversationId ] );

    useEffect( () => {
        if( receiverId ) {
            const builder = previousMessageBuilder( receiverId, messageLimit );
            setMessagesRequest( builder );
            setHasMore( true );
        }
    }, [ receiverId ] );

    useEffect( () => {
        if( messagesRequest ) {
            fetchPreviousMessages( messagesRequest );
        }
    }, [ messagesRequest ] );

    const watchIsOnline = () => {
        return chatRepository.activeIsOnline$.subscribe( ( isOnline ) => {
            setIsOnline( isOnline );
        } );
    };

    const watchReceiverTyping = () => {
        return chatRepository.activeIsTyping$.subscribe( ( isReceiverTyping ) => {
            setIsReceiverTyping( isReceiverTyping );
        } );
    };

    const watchConversation = () => {
        return chatRepository.activeConversation$.subscribe( ( conversation ) => {
            if( conversation != null ) {
                setReceiver( conversation.receiver );
                setIsLoadingConversation( false );
                setConversationItems( conversation.conversationItems );

                if( conversation.conversationItems.length > 0 && conversation.unreadMessageCount > 0 ) {
                    const lastMessage: ConversationItem = conversation.conversationItems[ conversation.conversationItems.length - 1 ];
                    markAsReadConversation( lastMessage.id, conversation.receiver.id, sessionRepository.user.id );
                }
            }
        } );
    };

    const markAsReadConversation = async( lastMessageId: string, receiverId: string, senderId: string ) => {
        if( lastMessageId != null ) {
            CometChat.markAsRead( lastMessageId, receiverId, 'user', senderId ).then( () => {
                    try {
                        chatRepository.markAsReadConversation( conversationId );
                    } catch( e ) {
                        Sentry.captureException( e );
                    }
                },
                ( error: CometChat.CometChatException ) => {
                    Sentry.captureException( error );
                }
            );
        }
    };

    const fetchPreviousMessages = ( messagesRequest: CometChat.MessagesRequest ) => {
        setIsLoadingMessages( true );
        messagesRequest?.fetchPrevious().then(
            async( messages ) => {

                if( messages.length > 0 ) {
                    await previousMessages( messages );
                }

                if( messages.length < messageLimit ) {
                    setHasMore( false );
                }

                setIsLoadingMessages( false );
            }, ( error: CometChat.CometChatException ) => {
                Sentry.captureException( error );
                setIsLoadingMessages( false );
            }
        );
    };

    const previousMessages = async( messages: CometChat.BaseMessage[] ) => {
        let conversationItems = await Promise.all(
            messages.map( async( message: CometChat.BaseMessage | CometChat.Action ) => {
                if( message.getCategory() == CometChat.CATEGORY_MESSAGE || message.getCategory() == CometChat.CATEGORY_CUSTOM ) {
                    return await previousMessage( message as CometChat.BaseMessage );
                }
                else if( message.getCategory() == CometChat.CATEGORY_ACTION ) {
                    return previousAction( message as CometChat.Action );
                }
            } )
        );

        conversationItems = conversationItems.filter( ( conversationItem: ( ConversationItemMessage | ConversationItemOffer | ConversationItemActionQuote | ConversationItemActionOffer ) ) => conversationItem != null );

        try {
            chatRepository.addConversationItems( conversationId, conversationItems as ( ConversationItemMessage | ConversationItemOffer | ConversationItemActionQuote | ConversationItemActionOffer )[] );
            if ( messageIdParam && !chatRepository.getConversationItemsOfConversation( conversationId )?.find( message => message.id === messageIdParam ) ) {
                fetchPreviousMessages( messagesRequest );
            }
        } catch( e ) {
            const conversationCometChat: CometChat.Conversation = await CometChatHelper.getConversationFromMessage( messages[ 0 ] );
            const conversation: Conversation = conversationFromCometChatConversation( conversationCometChat );
            chatRepository.addConversation( conversation );
            chatRepository.addConversationItems( conversationId, conversationItems as ( ConversationItemMessage | ConversationItemOffer | ConversationItemQuote )[] );
        }
        chatRepository.activeConversationId = conversationId;
    };

    const previousMessage = async( message: CometChat.BaseMessage ) => {
        if( message instanceof CometChat.TextMessage ) {
            return conversationItemMessageFromCometChatRawMessage(
                message as CometChat.TextMessage
            );
        }
        else if( message instanceof CometChat.CustomMessage ) {
            return await conversationItemCustomFromCometChatRawCustomMessage( message );
        }
    };

    const previousAction = ( message: CometChat.Action ): ConversationItemActionQuote | ConversationItemActionOffer => {
        return conversationItemActionFromCometChatAction( message, sessionRepository.user.type );
    };

    const previousMessageBuilder = ( UID: string, limit: number ) => {
        let messagesRequest: CometChat.MessagesRequest = new CometChat.MessagesRequestBuilder()
            .setUID( UID )
            .setLimit( limit )
            .hideDeletedMessages( true )
            .build();

        return messagesRequest;
    };

    const handleOnMessageSend = ( message: string ) => {
        let receiverID: string                 = receiverId,
            messageText: string                = message,
            receiverType: string               = CometChat.RECEIVER_TYPE.USER,
            textMessage: CometChat.TextMessage = new CometChat.TextMessage( receiverID, messageText, receiverType );

        CometChat.sendMessage( textMessage ).then(
            ( message: CometChat.TextMessage ) => {
                chatRepository.addConversationItems( conversationId, [ conversationItemMessageFromCometChatRawMessage( message ) ] );
            }, ( error: CometChat.CometChatException ) => {
                Sentry.captureException( {
                    error
                } );
            }
        );
    };

    const handleOnTypingChange = ( isTyping: boolean ) => {
        if( isTyping ) {
            startTyping();
        }
        else {
            endTyping();
        }
    };

    const startTyping = () => {
        let typingNotification: CometChat.TypingIndicator = new CometChat.TypingIndicator( receiverId, CometChat.RECEIVER_TYPE.USER );
        CometChat.startTyping( typingNotification );
    };

    const endTyping = () => {
        let typingNotification: CometChat.TypingIndicator = new CometChat.TypingIndicator( receiverId, CometChat.RECEIVER_TYPE.USER );
        CometChat.endTyping( typingNotification );
    };

    const handleOnLoadMore = () => fetchPreviousMessages( messagesRequest );

    return {
        conversationItems,
        receiver,
        receiverId,
        isOnline,
        isReceiverTyping,
        isLoadingMessages,
        isLoadingConversation,
        hasMore,
        handleOnMessageSend,
        handleOnTypingChange,
        handleOnLoadMore
    };
}