import ChatConversationListSideBar from '@/components/chat/components/chat-conversation-list/chat-conversation-list-side-bar/ChatConversationListSideBar';
import { NavigateFunction, Outlet, useNavigate, useParams } from 'react-router-dom';
import ChatHook from '@/components/chat/hooks/ChatHook';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ChatLayout from '@/components/chat/components/ChatLayout';
import SkeletonInput from 'antd/es/skeleton/Input';
import LayoutVisibilityHook from '@/hooks/layoutVisibilityHook';
import NoDataIcon from '@/components/NoDataIcon';
import { Subscription } from 'rxjs';
import { PageName, trackPageView } from '@/utils/analytics';

export default function ClientChat() {

    const { t } = useTranslation();
    const { conversationId } = useParams();
    const { conversationsPreviewUI, cometChatIsLoggedIn, chatRepository } = ChatHook( { conversationId } );
    const [ isLoading, setIsLoading ] = useState<boolean>( true );
    const navigate: NavigateFunction = useNavigate();

    const { isTabletOrMobile } = LayoutVisibilityHook( {
        headerVisible: true,
        bottomNavBarVisible: 'isTabletOrMobile'
    } );

    useEffect( () => {
        const observable: Subscription = chatRepository.isLoadingConversations$.subscribe( ( isLoading ) => {
            setIsLoading( isLoading );
        } );

        return () => {
            observable.unsubscribe();
        };
    }, [] );

    useEffect( () => {
        trackPageView( PageName.ClientChat );
    }, [ ] );

    useEffect( () => {
        if( !isLoading ) {
            if( !conversationId && conversationsPreviewUI?.length > 0 && !isTabletOrMobile ) {
                navigate( '/client/messages/' + conversationsPreviewUI[ 0 ]?.id );
            }
        }
    }, [ conversationsPreviewUI, isLoading ] );

    const isLoadingJSX = (
        <div className="h-full w-full px-3">
            <SkeletonInput className="w-full h-full rounded-3xl"
                           active={ true } />
        </div>
    );

    const noConversationsJSX = (
        <div className="flex flex-col items-center justify-center h-full w-full">
            <NoDataIcon message={ t( 'client:chat.noConversationTitle' ) }
                        subMessage={ t( 'client:chat.noConversationSubtitle' ) }
                        button={ {
                            text: t( 'client:chat.noConversationButton' ),
                            to: '/client/discover'
                        } }
                        icon={ <img src="/img/no-conversation.png"
                                    alt="no conversation" /> } />
        </div>
    );

    const contentJSX = () => {
        if( isLoading ) {
            return isLoadingJSX;
        }

        if( conversationsPreviewUI?.length > 0 ) {
            return <Outlet />;
        }

        return noConversationsJSX;
    };

    return (
        <div className="flex-grow flex flex-col">
            <h1 className="hidden lg:block text-2xl">
                { t( 'common:chat.conversationList.title' ) }
            </h1>
            <ChatLayout className="lg:mt-6 flex-grow"
                        hideLeftSideBar={ isTabletOrMobile && conversationId != null }
                        leftSideBar={ isLoading ?
                                      isLoadingJSX :
                                      <ChatConversationListSideBar conversations={ conversationsPreviewUI }
                                                                   endpointConversation="/client/messages/" /> }
                        content={ contentJSX() } />

        </div>
    );
}