import { gql } from '@apollo/client';

export const FreelancerProfilePictureGQL = gql`
    query FreelancerProfilePictureGQL {
        meFreelancer {
            id
            profilePicture
        }
    }`;


