import { useTranslation } from 'react-i18next';
import { Button, Divider } from 'antd';
import React from 'react';
import FilterItemAvailabilityButton from '@/components/freelancers-filter-items/FilterItemAvailabilityButton';
import FilterItemCertifiedButton from '@/components/freelancers-filter-items/FilterItemCertifiedButton';
import FilterItemExperiencesCheckbox from '@/components/freelancers-filter-items/FilterItemExperiencesCheckbox';
import FilterItemJobsCheckbox from '@/components/freelancers-filter-items/FilterItemJobsCheckbox';
import FilterItemExpertisesCheckbox from '@/components/freelancers-filter-items/FilterItemExpertisesCheckbox';
import FilterItemSkillsCheckbox from '@/components/freelancers-filter-items/FilterItemSkillsCheckbox';
import FilterItemLanguagesCheckbox from '@/components/freelancers-filter-items/FilterItemLanguagesCheckbox';
import FilterItemAVGPriceRange from '@/components/freelancers-filter-items/FilterItemAVGPriceRange';

interface FreelancerListNotRecommendedFilterProps {
    handleApplyFilters: () => void;
    handleResetFilters: () => void;
}

export default function TransverseFreelancerListFilter( {
                                                            handleApplyFilters,
                                                            handleResetFilters
                                                        }: FreelancerListNotRecommendedFilterProps ) {

    const { t } = useTranslation();

    const handleApply = () => handleApplyFilters();

    const handleReset = () => handleResetFilters();

    return (
        <>
            <div className="w-full h-full pb-[60px]">
                {/* AVAILABLE NOW */ }
                <FilterItemAvailabilityButton />

                <Divider />

                { /* AVG PRICE */ }
                <div className="mt-6">
                    <div className="font-medium font-sharp text-base">
                        { t( 'transverse:freelancers.filters.avgPrice.title' ) }
                    </div>
                    <div className="px-2">
                    <FilterItemAVGPriceRange />
                    </div>
                </div>

                <Divider />

                { /* CERTIFIED */ }
                <div className="mt-6">
                    <FilterItemCertifiedButton />
                </div>

                <Divider />

                {/* EXPERIENCES */ }
                <div className="mt-6">
                    <div className="font-bold font-base">
                        { t( 'transverse:freelancers.filters.experiences-title' ) }
                    </div>
                    <div className="mt-2">
                        <FilterItemExperiencesCheckbox />
                    </div>
                </div>

                {/* JOBS */ }
                <div className="mt-6">
                    <div className="font-bold font-base">
                        { t( 'transverse:freelancers.filters.jobsTitle' ) }
                    </div>
                    <div className="mt-2">
                        <FilterItemJobsCheckbox />
                    </div>
                </div>

                <Divider />

                {/* EXPERTISES */ }
                <div className="mt-6">
                    <div className="font-bold font-base">
                        { t( 'transverse:freelancers.filters.expertisesTitle' ) }
                    </div>
                    <div className="mt-2">
                        <FilterItemExpertisesCheckbox />
                    </div>
                </div>


                <Divider />

                {/* SKILLS */ }
                <div className="mt-6">
                    <div className="font-bold font-base">
                        { t( 'transverse:freelancers.filters.skillsTitle' ) }
                    </div>
                    <div className="mt-2">
                        <FilterItemSkillsCheckbox />
                    </div>
                </div>

                <Divider />

                {/* LANGUAGES */ }
                <div className="mt-6 pb-4 lg:pb-0">
                    <div className="font-bold font-base">
                        { t( 'transverse:freelancers.filters.languagesTitle' ) }
                    </div>
                    <div className="mt-2">
                        <FilterItemLanguagesCheckbox />
                    </div>
                </div>
            </div>
            <div className="absolute bottom-0 left-0 right-0 flex justify-between px-6 py-4 z-0 bg-white rounded-b-xl">
                <Button type="link"
                        onClick={ () => handleReset() }
                        className="text-grey-medium text-sm p-0">
                    { t( 'transverse:freelancers.filters.resetFiltersButton' ) }
                </Button>
                <Button type="primary"
                        onClick={ () => handleApply() }>
                    { t( 'transverse:freelancers.filters.applyFiltersButton' ) }
                </Button>
            </div>
        </>

    );
}