import { Tag } from 'antd';
import { ProjectStatusEnum } from '@/gql/graphql';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';

interface ChatConversationStatusTagProps {
    status: ProjectStatusEnum;
    size?: 'small' | 'middle' | 'large';
}

export default function ChatConversationProjectStatusTag( { status, size = 'small' }: ChatConversationStatusTagProps ) {

    const { t } = useTranslation();
    const [ color, setColor ] = useState( 'grey' );
    const [ sizeTag, setSizeTag ] = useState<'tag-xs' | 'tag-sm' | 'tag-xl'>( 'tag-xs' );

    useEffect( () => {
        setColor( mapStatusToColor( status ) );
    }, [ status ] );

    useEffect( () => {
        setSizeTag( mapSize( size ) );
    }, [ size ] );

    const mapStatusToColor = ( status: ProjectStatusEnum ) => {
        switch( status ) {
            case ProjectStatusEnum.PENDING:
                return 'bg-orange-200 border-orange-100 text-orange-300';
            case ProjectStatusEnum.OFFER_SENT:
                return 'bg-orange-200 border-orange-100 text-orange-300';
            case ProjectStatusEnum.IN_PROGRESS:
                return 'bg-blue-200 border-blue-100 text-black';
            case ProjectStatusEnum.DONE:
                return 'bg-[#F0FDF4] text-green-300 border-[#ABEFC6]';
            case ProjectStatusEnum.DECLINED:
                return 'bg-red text-white border-red';
            case ProjectStatusEnum.CANCELLED:
                return 'bg-red text-white border-red';
            default:
                return 'bg-grey';
        }
    };

    const mapSize = ( size: 'small' | 'middle' | 'large' ): 'tag-xs' | 'tag-sm' | 'tag-xl' => {
        switch( size ) {
            case 'small':
                return 'tag-xs';
            case 'middle':
                return 'tag-sm';
            case 'large':
                return 'tag-xl';
            default:
                return 'tag-sm';
        }
    };

    return (
        <Tag className={ twMerge( sizeTag, color ) }>
            { t( 'translation:enum.projectStatus.' + status ) }
        </Tag>
    );
}