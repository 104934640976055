import { Action, ActionTypeEnum } from '@/gql/graphql';
import FreelancerActionNewJobOfferItem from './components/FreelancerActionNewJobOfferItem';
import FreelancerActionQuotePendingAnswerItem from './components/FreelancerActionQuotePendingAnswerItem';
import FreelancerActionProjectInProgressItem from './components/FreelancerActionProjectInProgressItem';
import FreelancerActionProjectDeadlinePassedItem from './components/FreelancerActionProjectDeadlinePassedItem';
import ListSkeleton from '@/components/ListSkeleton';
import { Avatar, Badge, Button } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import actionsHook from '@/hooks/actionsHook';
import { useEffect } from 'react';
import { UserOutlined } from '@ant-design/icons';
import { LinkFilledIcon, FlashFilledIcon } from '@/assets/icons/CustomIcons';
import { freelancerProfileLinkHook } from '@/hooks/freelancerProfileLinkHook';
import { useNavigate } from 'react-router-dom';

interface FreelancerDashboardActionsListProps {
    profilePicture: string;
}

export default function FreelancerDashboardActionsList({ profilePicture }:FreelancerDashboardActionsListProps) {

    const { t } = useTranslation();

    const navigate = useNavigate();

    const {
        actions,
        loadActions,
        isLoading
    } = actionsHook( { limit: 10 } );

    useEffect( () => {
        loadActions();
    }, [] );

    const { getProfileLink } = freelancerProfileLinkHook();

    const renderActionComponent = ( action: Action ) => {        
        switch ( action.type ) {
        case ActionTypeEnum.NEW_JOB_OFFER:
            return <FreelancerActionNewJobOfferItem action={ action } />;
        case ActionTypeEnum.QUOTE_PENDING_ANSWER:
            return <FreelancerActionQuotePendingAnswerItem action={ action } />;
        case ActionTypeEnum.QUOTE_ACCEPTED:
            return <FreelancerActionProjectInProgressItem action={ action } />;
        case ActionTypeEnum.PROJECT_DEADLINE_PASSED:
            return <FreelancerActionProjectDeadlinePassedItem action={ action } />;
        default:
            return null;
        }
    };

    const skeleton = ( limit: number ) => {
        return (
            <div className="flex flex-col gap-y-4">
                <ListSkeleton isLoading={ true }
                    height={ 200 }
                    count={ limit } />
            </div>
        );
    };

    if( isLoading ) {
        return skeleton( 3 );
    } else if( !actions?.length ) {
        return (
            <div className="flex flex-col gap-4">
                <div className='flex flex-col gap-4 items-center text-center rounded-xl border border-blue-100 pt-5 pb-10 px-20'>
                    <div className='flex justify-center bg-purple-50 rounded-full p-6'>
                        <FlashFilledIcon className='text-white text-xl bg-gradient-to-br rounded-full p-3 from-indigo-400 via-purple-600 to-blue-900' />
                    </div>
                    <div className="flex flex-col gap-3">
                        <span className='font-sharp text-lg'>{t( 'freelancer:dashboard.actionCenter.noActiontitle' )}</span>
                        <span className='text-grey block'>{t( 'freelancer:dashboard.actionCenter.noActionDesc' )}</span>
                    </div>
                </div>
                <div className="flex flex-col lg:flex-row gap-8 items-center rounded-xl p-8 border border-blue-100">
                    <div className="w-28 flex justify-center">
                        <Avatar
                            className='border-4 border-white w-full h-fit'
                            icon={ <UserOutlined /> }
                            src={ profilePicture } />
                    </div>
                    
                    <div className='flex-1'>
                        <span className='font-semibold text-base'>{t( 'freelancer:dashboard.noProjectsYet.shareProfile.title' )}</span>
                        <p className='text-black/70 mt-3 mb-5'>
                            <Trans 
                                i18nKey="freelancer:dashboard.noProjectsYet.shareProfile.description" 
                                components={[<strong />]}
                            />
                        </p>
                        <Button type="primary"
                            onClick={ () => getProfileLink() }
                            className="flex items-center gax-x-2 bg-black">
                            <LinkFilledIcon className="text-[18px]" />
                            { t( 'freelancer:account.shareProfile' ) }
                        </Button>
                    </div>
                </div>
                <div className="flex gap-8 rounded-xl bg-blue-200">
                    <div className="relative w-32 m-2 rounded-lg bg-cover bg-[url('/img/creator-cards.png')] opacity-80">
                        <div className='absolute w-full h-full right-0 bg-gradient-to-r from-white/0 to-blue-200'></div>
                    </div>
                    <div className='py-8 pr-8 flex-1'>
                        <span className='font-semibold text-base'>{t( 'freelancer:dashboard.noProjectsYet.reviewProfile.title' )}</span>
                        <p className='text-black/70 mt-3 mb-5'>
                            <Trans 
                                i18nKey="freelancer:dashboard.noProjectsYet.reviewProfile.description" 
                                components={[<strong />]}
                            />
                        </p>
                        <Button 
                            type="primary"
                            className='bg-black'
                            onClick={ () => navigate('/freelancer/settings/edit-profile') }>
                            {t( 'freelancer:dashboard.noProjectsYet.reviewProfile.reviewProfileButton' )}
                        </Button>
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div>
                <div className='mb-2 flex items-center gap-2'>
                    <span className='font-sharp text-xl'>{t( 'freelancer:dashboard.actionCenter.title' )}</span>
                    <Badge count={ actions.length } className="text-inherit" />
                </div>
                <div className='flex flex-col gap-4'>
                    {
                        actions.map(action => (
                            <div key={action.id}>
                                { renderActionComponent(action) }
                            </div>
                        ))
                    }
                </div>
            </div>
        );
    }
}