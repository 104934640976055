import { Button, Divider } from 'antd';
import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Project, ProjectStatusEnum } from '@/gql/graphql';
import projectHook from '@/hooks/projectHook';
import SkeletonInput from 'antd/es/skeleton/Input';
import ResponsiveModal from '@/components/ResponsiveModal';
import FreelancerProject from '@/pages/freelancer/views/project/FreelancerProject';
import { cuttingTextFormatter } from '@/utils/cuttingTextFormatter';
interface FreelancerProjectModalProps {
    projectId: string;
    onReviewSubmitted?: ( project: Project ) => void;
}

const FreelancerProjectModal = forwardRef( ( props: FreelancerProjectModalProps, ref ) => {

    const { projectId, onReviewSubmitted } = props;

    const { t } = useTranslation();
    const { project, isLoadingProject } = projectHook( { projectId } );

    const projectRef = useRef( null );
    const projectReviewRef = useRef( null );

    useImperativeHandle( ref, () => ( {
            open() {
                projectRef.current.open();
            },
            close() {
                projectRef.current.close();
            }
        } )
    );

    const handleReviewSubmitted = ( project: Project ) => {
        onReviewSubmitted?.( project );
        projectRef.current.close();
    };

    const openReviewModal = () => {
        projectReviewRef?.current?.openReviewModal() 
    };

    return (
        <ResponsiveModal ref={ projectRef }
                         title={ isLoadingProject ?
                                 <SkeletonInput active
                                                className="w-[200px] lg:w-[400px] h-[27px]" /> :
                                 project?.name }
                         destroyOnClose={ true }
                         desktopWidth="853px">
            <Divider className="mt-0" />
            <FreelancerProject id={ projectId }
                               ref={ projectReviewRef }
                               onReviewSubmitted={ ( project: Project ) => handleReviewSubmitted( project ) } />
            <Divider className="w-full" />

            <div className="w-full flex items-end justify-between">
                <Button type="text"
                        onClick={ () => projectRef.current.close() }>
                    { t( 'common:modal.close' ) }
                </Button>

                {
                    project?.status == ProjectStatusEnum.IN_PROGRESS && !project.freelancerHasReviewed &&
                    <Button type="primary"
                            onClick={ openReviewModal }>
                        { !project.clientHasReviewed ?
                            t( 'common:project-card.dropAReviewFirst' ) 
                            : t( 'common:project-card.dropAReviewSecond', { name: project.channel ? cuttingTextFormatter( project.channel?.name, 15 ) : project.client.userName } ) }
                    </Button>
                }
            </div>

        </ResponsiveModal>
    );
} );

export default FreelancerProjectModal;