import React, { forwardRef, useContext, useImperativeHandle, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Button, Drawer, Modal, Space } from 'antd';
import { ColorSchemeContext } from '@/core/dark-mode/colorSchemeProvider';
import { Trans, useTranslation } from 'react-i18next';
import { CloseOutlinedIcon } from '@/assets/icons/CustomIcons';

interface ChatQuoteConfirmationProps {
    onConfirm: () => void;
    onClose?: () => void;
}

const ChatQuoteConfirmation = forwardRef( ( props: ChatQuoteConfirmationProps, ref ) => {

    const { onConfirm, onClose } = props;

    const isTabletOrMobile = useMediaQuery( { query: `(max-width: ${ import.meta.env.VITE_MOBILE_BREAK_POINT })` } );
    const [ isOpen, setIsOpen ] = useState( false );
    const colorSchemeContext = useContext( ColorSchemeContext );
    const { t } = useTranslation();

    const information = [
        {
            text: t( 'freelancer:chat.quote.confirmation.arguments.one' ),
            icon: '1'
        },
        {
            text: t( 'freelancer:chat.quote.confirmation.arguments.two' ),
            icon: '2️'
        }
    ];

    useImperativeHandle( ref, () => ( {
        open() {
            handleOpen();
        },
        close() {
            handleClose();
        }
    } ) );

    const handleOpen = () => {
        setIsOpen( true );
    };

    const handleClose = () => {
        setIsOpen( false );
        if( onClose ) {
            onClose();
        }

    };

    const handleConfirm = () => {
        onConfirm();
        setIsOpen( false );
    };

    const informationJSX = (
        <div className="flex flex-col gap-y-4">
            <p className="font-semibold text-base">
                { t( 'freelancer:chat.quote.confirmation.subtitle' ) }
            </p>
            {
                information.map( ( argument, index ) => {
                    return (
                        <div className="flex items-center"
                             key={ index }>
                            <div className="flex-none w-[48px] h-[48px] rounded-full bg-primary-gradient p-1">
                                <div className="bg-white  rounded-full w-full h-full flex items-center justify-center text-2xl">
                                    { argument.icon }
                                </div>
                            </div>
                            <p className="text-sm ml-4 font-normal">
                                { argument.text }
                            </p>
                        </div>
                    );
                } )
            }

            <Button type="primary"
                    size="large"
                    className="mt-4"
                    onClick={ () => handleConfirm() }>
                { t( 'freelancer:chat.quote.confirmation.submit' ) }
            </Button>

            <p>
                <Trans i18nKey="freelancer:chat.quote.confirmation.warning">
                    <span className="text-grey-medium"></span>
                    <span className="text-pink cursor-pointer"></span>
                </Trans>
            </p>
        </div>
    );

    const modalJSX = (
        <Modal open={ isOpen }
               rootClassName={ colorSchemeContext.toLowerCase() }
               width={ 668 }
               destroyOnClose={ true }
               onCancel={ () => handleClose() }
               footer={ null }>
            <h1 className="font-extrabold text-[23px] mb-4">
                { t( 'freelancer:chat.quote.confirmation.title' ) }
            </h1>
            { informationJSX }
        </Modal>
    );

    const drawerJSX = (
        <Drawer placement="bottom"
                className="overflow-x-hidden bg-white  rounded-t-4xl"
                bodyStyle={ { padding: 24 } }
                width="100%"
                height="fit-content"
                headerStyle={ { textAlign: 'center' } }
                closable={ false }
                rootClassName={ colorSchemeContext.toLowerCase() }
                open={ isOpen }
                title={ t( 'freelancer:chat.quote.confirmation.title' ) }
                extra={
                    <Space>
                        <Button onClick={ () => handleClose() }
                                size="small"
                                icon={ <CloseOutlinedIcon style={ { fontSize: '12px' } } /> }
                                shape="circle">
                        </Button>
                    </Space>
                }
                destroyOnClose={ true }>
            { informationJSX }
        </Drawer>
    );

    return (
        <>
            {
                isTabletOrMobile ? drawerJSX : modalJSX
            }
        </>
    );
} );

export default ChatQuoteConfirmation;
