import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { SignUpFreelancerRepository } from '@/pages/auth/sign-up/sign-up-freelancer//signUpFrelancerRepository';
import { ExperienceTypeEnum } from '@/gql/graphql';
import SelectMultiTags from '@/components/SelectMultiTags';


export default function SignUpFreelancerStepExperience() {

    const { t } = useTranslation();
    const repository: SignUpFreelancerRepository = new SignUpFreelancerRepository();
    const [ tags, setTags ] = useState<any[]>( [] );

    useEffect( () => {
        const experiences: ExperienceTypeEnum[] = Object.values( ExperienceTypeEnum );
        const options: any[] = [];
        experiences.forEach( ( experience: ExperienceTypeEnum ) => {
            options.push( {
                label: t( `enum.experiences.${ experience }` ) + ' years',
                id: experience
            } );
        } );
        setTags( options );
    }, [] );

    const handleExperienceChanged = ( experiences: string[] ) => {
        repository.experience = ExperienceTypeEnum?.[ experiences[ 0 ] ];
    };

    return (
        <SelectMultiTags onTagsSelected={ ( value ) => handleExperienceChanged( value ) }
                         tags={ tags }
                         limit={ 1 }
                         defaultSelectedIds={ [ repository.experience ] } />
    );
}