import { gql } from '@apollo/client';

export const PaymentMethodsGQL = gql`
    query PaymentMethodsGQL{
        retrievePaymentMethods {
            id
            type
            brand
            last4
        }
    }`;


