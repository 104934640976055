import { CometChat } from '@cometchat-pro/chat';
import { Conversation, ConversationUI } from '@/components/chat/ChatRepository';
import { ConversationItemMessage } from '@/components/chat/interfaces/conversationItemMessage';
import { ItemTypeEnum } from '@/components/chat/enums/itemTypeEnum';
import { DateTime } from 'luxon';

const conversationFromCometChatConversation = ( conversationCometChat ): Conversation => {
    const conversationWith = conversationCometChat.getConversationWith();

    const messageMasking = checkIfDataIsMasking( conversationCometChat.lastMessage );

    return {
        id: conversationCometChat.conversationId,
        receiver: {
            id: conversationWith.uid,
            userName: conversationWith.name,
            avatar: conversationWith.avatar || null
        },
        lastMessage: messageMasking != null ? messageMasking : conversationCometChat.lastMessage?.text || null,
        lastMessageAt: conversationCometChat.lastMessage?.sentAt != null ? DateTime.fromSeconds( conversationCometChat.lastMessage?.sentAt ).toRelative( { locale: 'eng' } ) : null,
        unreadMessageCount: conversationCometChat.unreadMessageCount,
        conversationItems: []
    };
};

const checkIfDataIsMasking = ( message: CometChat.BaseMessage ) => {
    if( message != null && message.hasOwnProperty( 'metadata' ) ) {
        const metadata = message[ 'metadata' ];
        if( metadata != null ) {
            const injectedObject = metadata[ '@injected' ];
            if( injectedObject != null && injectedObject.hasOwnProperty( 'extensions' ) ) {
                const extensionsObject = injectedObject[ 'extensions' ];
                if( extensionsObject != null && extensionsObject.hasOwnProperty( 'data-masking' ) ) {
                    const dataMaskingFilterObject = extensionsObject[ 'data-masking' ][ 'data' ];
                    return dataMaskingFilterObject[ 'message_masked' ];
                }
            }
        }
    }
    return null;
};

const conversationUIFromCometChatConversation = ( conversationCometChat: CometChat.Conversation ): ConversationUI => {
    const conversationWith: CometChat.User = ( conversationCometChat.getConversationWith() as CometChat.User );
    return {
        id: conversationCometChat.getConversationId(),
        status: undefined,
        isTyping: undefined,
        isOnline: conversationWith.getStatus() === CometChat.USER_STATUS.ONLINE
    };
};

const conversationItemMessageFromCometChatMessage = ( message: CometChat.TextMessage ): ConversationItemMessage => {

    const maskingMessage = checkIfDataIsMasking( message );
    return {
        id: message.getId().toString(),
        receiverId: message.getReceiverId(),
        type: ItemTypeEnum.MESSAGE,
        message: maskingMessage ? maskingMessage : message.getText(),
        senderId: message.getSender().getUid(),
        sentAt: DateTime.fromSeconds( message.getSentAt() ).toISO()
    };
};

const conversationItemMessageFromCometChatRawMessage = ( message ): ConversationItemMessage => {

    const maskingMessage = checkIfDataIsMasking( message );
    return {
        id: message.id,
        receiverId: message.receiverId,
        type: ItemTypeEnum.MESSAGE,
        message: maskingMessage ? maskingMessage : message.text,
        senderId: message.sender.uid,
        sentAt: DateTime.fromSeconds( message.sentAt ).toISO()
    };
};


export {
    checkIfDataIsMasking,
    conversationFromCometChatConversation,
    conversationItemMessageFromCometChatMessage,
    conversationItemMessageFromCometChatRawMessage,
    conversationUIFromCometChatConversation
};