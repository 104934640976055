import { FavoritesRepository, FavoriteUI } from '@/pages/client/views/favorites/favoritesRepository';
import { Favorite, Freelancer } from '@/gql/graphql';
import { useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { FavoritesGQL } from '@/gql/global-queries/favoritesGQL';
import { RemoveToMyFavoriteGQL } from '@/gql/global-queries/removeFavoriteGQL';
import { AddToFavoriteGQL } from '@/gql/global-queries/addFavoriteGQL';

export default function FavoritesHook() {
    const favoritesStore: FavoritesRepository = new FavoritesRepository();
    const limit: number = 30;
    const [ favorites, setFavorites ] = useState<( Favorite & FavoriteUI )[]>( [] );
    const [ countNewFavorites, setCountNewFavorites ] = useState<number>( 0 );

    const favoritesQuery = useLazyQuery( FavoritesGQL );
    const removeFavoriteMutation = useMutation( RemoveToMyFavoriteGQL );
    const addFavoriteMutation = useMutation( AddToFavoriteGQL );

    useEffect( () => {
        const observableFavorites = favoritesStore.favorites$.subscribe( ( favorites:(Favorite & FavoriteUI )[]) => {
            setFavorites( favorites );
            setCountNewFavorites( favorites.filter( favorite => favorite.new ).length );
        } );

        const observableNewFavorites = favoritesStore.newFavorites$.subscribe( ( newFavorites: ( Favorite & FavoriteUI )[] ) => {
            setCountNewFavorites( newFavorites.length );
        } );

        return () => {
            observableFavorites.unsubscribe();
            observableNewFavorites.unsubscribe();
        };
    }, [] );

    useEffect( () => {
        if( removeFavoriteMutation[ 1 ].data ) {
            favoritesStore.removeFavorite( removeFavoriteMutation[ 1 ].data.removeToMyFavorite.id );
        }
    }, [ removeFavoriteMutation[ 1 ].data ] );

    useEffect( () => {
        if( addFavoriteMutation[ 1 ].data != null && addFavoriteMutation[ 1 ].data.addToFavorite != null ) {
            favoritesStore.addFavorite( addFavoriteMutation[ 1 ].data.addToFavorite );
        }
    }, [ addFavoriteMutation[ 1 ].data ] );

    useEffect( () => {
        if( favoritesQuery[ 1 ]?.data ) {
            const result = favoritesQuery[ 1 ].data.favorites;
            const favorites: Favorite[] = result.data as Favorite[];
            if( result.page == 0 ) {
                favoritesStore.favorites = favorites;
            }
            else {
                favoritesStore.addFavorites( favorites );
            }
        }
    }, [ favoritesQuery[ 1 ].data ] );

    const load = () => {
        favoritesQuery[ 0 ]( {
            variables: {
                limit,
                page: 0
            }
        } );
    };

    const favoritesCardFromDataGQL = ( data: (Favorite & FavoriteUI)[] ): ( Freelancer & {  new: boolean } )[] => {
        return data.map( ( favorite: ( Favorite & FavoriteUI ) ) => {
            const freelancer: Freelancer = favorite.freelancer;
            return {
                ...freelancer,
                new: favorite.new || false
            };
        } );
    };

    const resetNewFavorites = () => {
        favoritesStore.resetNewFavorites();
    };

    const remove = ( id: string ) => {
        removeFavoriteMutation[ 0 ]( {
            variables: {
                id
            }
        } );
    };

    const add = ( freelancerId: String ) => {
        addFavoriteMutation[ 0 ]( {
            variables: {
                id: freelancerId
            }
        } );
    };

    const next = () => {
        favoritesQuery[ 1 ].refetch( {
            limit,
            page: favoritesQuery[ 1 ].data.favorites.page + 1
        } );
    };

    return {
        load,
        raw: favoritesQuery[ 1 ]?.data?.favorites?.data,
        favorites: favoritesCardFromDataGQL( favorites ),
        countNewFavorites,
        next,
        error: favoritesQuery[ 1 ]?.error,
        loading: favoritesQuery[ 1 ]?.loading,
        add,
        remove,
        resetNewFavorites,
        hasMore: favoritesQuery[ 1 ]?.data?.favorites?.page < favoritesQuery[ 1 ]?.data?.favorites?.page.lastPage,
        total: favoritesQuery[ 1 ]?.data?.favorites?.total
    };
}