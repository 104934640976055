import React, { forwardRef, useEffect, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { SignUpFreelancerRepository } from '@/pages/auth/sign-up/sign-up-freelancer//signUpFrelancerRepository';
import InputComponent from '@/components/custom-element-form/InputComponent';

const SignUpFreelancerStepInvoicing = forwardRef( function( props, ref ) {
    const { t } = useTranslation();
    const { trigger, control, watch, formState: { errors } } = useForm();
    const repository = new SignUpFreelancerRepository();

    const subscription = watch( ( value ) => {
        repository.updateData( {
            ...value,
            vatRate: +parseFloat(value.vatRate.replace(',', '.'))
        } );
    } );

    useEffect( () => {
        return () => subscription.unsubscribe();
    }, [] );

    useImperativeHandle( ref, () => ( {
        async validateFields() {
            return trigger();
        }
    } ) );

    return (
        <form className="flex w-full flex-col gap-y-4">
            <InputComponent control={ control }
                            className="max-w-[230px]"
                            name="vatRate"
                            defaultValue={ repository.vatRate }
                            tooltipLabel={ t( 'freelancer:account.edit-company-details.tooltipVatRate' ) }
                            label={ t( 'freelancer:account.edit-company-details.vatRate' ) }
                            placeholder={ t( 'freelancer:account.edit-company-details.placeholderVatRate' ) }
                            rules={ {
                                required: t( 'common:error.form.required' ),
                                min: {
                                    value: 0,
                                    message: t( 'freelancer:account.edit-company-details.error.min' )
                                },
                                max: {
                                    value: 100,
                                    message: t( 'freelancer:account.edit-company-details.error.max' )
                                }
                            } }
                            suffix="%"
                            errors={ errors } />

            <InputComponent control={ control }
                            name="companyName"
                            defaultValue={ repository.companyName }
                            className="w-full"
                            label={ t( 'auth:sign-up.freelancer.invoicing-page.companyName' ) }
                            placeholder={ t( 'auth:sign-up.freelancer.invoicing-page.companyNamelaceholder' ) }
                            errors={ errors }
                            rules={ {
                                required: t( 'common:error.form.required' )
                            } } />


            <InputComponent control={ control }
                            name="companyCountry"
                            defaultValue={ repository.companyCountry }
                            className="w-full lg:w-1/3"
                            label={ t( 'auth:sign-up.freelancer.invoicing-page.companyCountry' ) }
                            placeholder={ t( 'auth:sign-up.freelancer.invoicing-page.companyCountryPlaceholder' ) }
                            errors={ errors }
                            disabled={ true }
                            rules={ {
                                required: t( 'common:error.form.required' )
                            } } />

            <div className="flex flex-wrap lg:flex-nowrap gap-x-2 gap-y-4">
                <InputComponent control={ control }
                                name="companyCity"
                                defaultValue={ repository.companyCity }
                                className="w-full"
                                label={ t( 'auth:sign-up.freelancer.invoicing-page.companyCity' ) }
                                placeholder={ t( 'auth:sign-up.freelancer.invoicing-page.companyCityPlaceholder' ) }
                                errors={ errors }
                                rules={ {
                                    required: t( 'common:error.form.required' )
                                } } />

                <InputComponent control={ control }
                                name="companyState"
                                defaultValue={ repository.companyState }
                                className="w-full lg:w-1/3"
                                label={ t( 'auth:sign-up.freelancer.invoicing-page.companyState' ) }
                                placeholder={ t( 'auth:sign-up.freelancer.invoicing-page.companyStatePlaceholder' ) }
                                errors={ errors }
                />

                <InputComponent control={ control }
                                name="companyPostalCode"
                                defaultValue={ repository.companyPostalCode }
                                className="w-full lg:w-1/3"
                                label={ t( 'auth:sign-up.freelancer.invoicing-page.companyPostalCode' ) }
                                placeholder={ t( 'auth:sign-up.freelancer.invoicing-page.companyPostalCodePlaceholder' ) }
                                errors={ errors }
                                rules={ {
                                    required: t( 'common:error.form.required' )
                                } }
                />
            </div>
            <InputComponent control={ control }
                            name="companyStreet"
                            defaultValue={ repository.companyStreet }
                            className="w-full"
                            label={ t( 'auth:sign-up.freelancer.invoicing-page.companyStreet' ) }
                            placeholder={ t( 'auth:sign-up.freelancer.invoicing-page.companyStreetPlaceholder' ) }
                            errors={ errors }
                            rules={ {
                                required: t( 'common:error.form.required' )
                            } }
            />
            <InputComponent control={ control }
                            name="companyStreet2"
                            defaultValue={ repository.companyStreet2 }
                            className="w-full"
                            label={ t( 'auth:sign-up.freelancer.invoicing-page.companyStreet2' ) }
                            placeholder={ t( 'auth:sign-up.freelancer.invoicing-page.companyStreet2Placeholder' ) }
                            errors={ errors }
            />

        </form>
    );
} );

export default SignUpFreelancerStepInvoicing;