import { gql } from '@apollo/client';

export const freelancerFirstNameGQL = gql`
    query Freelancer($id: String!) {
        publicFreelancer(id: $id) {
            id
            firstName
        }
    }
`;
