import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FreelancerCreatorsNameList from '@/components/freelance-profile/components/FreelancerCreatorsNameList';
import { PortfolioChannel } from '@/gql/graphql';

interface FreelancerCreatorsIWorkWithListProps {
    creators: PortfolioChannel[];
    maxThumbnail?: number;
}

export default function FreelancerCreatorsIWorkedWithList( {
                                                               creators,
                                                               maxThumbnail = 3
                                                           }: FreelancerCreatorsIWorkWithListProps ) {

    const { t } = useTranslation();

    const paddingAndMarginValues: number = 24;

    const containerRef = useRef( null );
    const avatarGroupRef = useRef( null );

    const [ containerWidth, setContainerWidth ] = useState( 0 );
    const [ avatarGroupWidth, setAvatarGroupWidth ] = useState( 0 );

    useEffect( () => {
        if( containerRef.current && avatarGroupRef.current ) {
            setContainerWidth( containerRef.current.offsetWidth );
            setAvatarGroupWidth( avatarGroupRef.current.offsetWidth );
        }
    }, [ creators ] );

    const contentJSX = (
        <>
            <div ref={ avatarGroupRef }
                 className="flex-none flex items-center justify-center mr-2">
                <Avatar.Group>
                    {
                        creators?.map( ( creator, index ) => {
                            return (
                                <Avatar shape="circle"
                                        key={ index }
                                        size={ 34 }
                                        src={ creator?.thumbnailUrl }
                                        icon={ <UserOutlined /> } />
                            );

                        } )
                    }
                </Avatar.Group>
            </div>
            <div className="w-full">
                <FreelancerCreatorsNameList names={ creators?.map( ( creator ) => creator.name ) }
                                            availableWidth={ containerWidth - avatarGroupWidth - paddingAndMarginValues } />
            </div>
        </>
    );

    const noCreatorsJSX = (
        <span className="text-grey font-normal text-base">{ t( 'common:creator-list.noCreator' ) }</span>
    );


    return (
        <div ref={ containerRef }
             className="flex flex-row items-center justify-start">
            {
                creators?.length > 0 ? contentJSX : noCreatorsJSX
            }
        </div>
    );
}