import { Outlet } from 'react-router-dom';
import ClientNavBarTouch from '@/pages/client/layout/nav-bar/ClientNavBarTouch';
import LayoutVisibilityHook from '@/hooks/layoutVisibilityHook';
import ClientHeader from '@/pages/client/layout/header/ClientHeader';
import ChatHook from '@/components/chat/hooks/ChatHook';

export default function RootClient() {


    const { isTabletOrMobile, isHeaderVisible, isBottomNavBarVisible } = LayoutVisibilityHook( {
        headerVisible: true,
        bottomNavBarVisible: 'isTabletOrMobile'
    } );

    ChatHook( { conversationId: null } );

    return (
        <div className="min-h-screen flex flex-col h-full relative">
            {
                isHeaderVisible &&
                <ClientHeader />
            }

            <div className={ `flex-grow flex flex-col w-full pt-[96px] default-padding-x ${ isTabletOrMobile && isBottomNavBarVisible
                                                                                          ? 'pb-[72px]'
                                                                                          : '' }` }>
                <Outlet />
            </div>
            {
                isTabletOrMobile &&
                isBottomNavBarVisible &&
                <ClientNavBarTouch />
            }
        </div>
    );
}