import { ActionsRepository, ActionUI } from '@/pages/freelancer/views/dashboard/actionsRepository';
import { Action } from '@/gql/graphql';
import { useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { ActionsGQL } from '@/gql/global-queries/actionsGQL';
import { HideActionGQL } from '@/gql/global-queries/hideActionGQL';

interface ActionsHookProps {
    limit?: number;
}

export default function ActionsHook( { limit }: ActionsHookProps = {}) {
    const actionsStore: ActionsRepository = new ActionsRepository();
    const [ actions, setActions ] = useState<( Action & ActionUI )[]>( [] );
    const [ countNewFavorites, setCountNewFavorites ] = useState<number>( 0 );

    const actionsQuery = useLazyQuery( ActionsGQL );
    const hideActionMutation = useMutation( HideActionGQL );

    useEffect( () => {
        const observableFavorites = actionsStore.actions$.subscribe( ( actions: ( Action & ActionUI )[] ) => {
            setActions( actions );
            setCountNewFavorites( actions.filter( action => action ).length );
        } );

        return () => {
            observableFavorites.unsubscribe();
        };
    }, [] );

    useEffect( () => {
        if( hideActionMutation[ 1 ].data ) {
            actionsStore.hideAction( hideActionMutation[ 1 ].data.hideAction.id );
        }
    }, [ hideActionMutation[ 1 ].data ] );

    useEffect( () => {
        if( actionsQuery[ 1 ]?.data ) {
            const result = actionsQuery[ 1 ].data.actions;
            const actions: Action[] = result.data as Action[];
            if( result.page == 0 ) {
                actionsStore.actions = actions;
                setCountNewFavorites( actionsQuery[ 1 ]?.data?.actions?.page.total );
            }
        }
    }, [ actionsQuery[ 1 ].data ] );

    const loadActions = () => {
        actionsQuery[ 0 ]( {
            variables: {
                limit,
                page: 0
            }
        } );
    };

    const hideAction = ( id: string ) => {
        hideActionMutation[ 0 ]( {
            variables: {
                id
            }
        } );
    };

    const next = () => {
        actionsQuery[ 1 ].refetch( {
            limit,
            page: actionsQuery[ 1 ].data.actions.page + 1
        } );
    };

    return {
        loadActions,
        raw: actionsQuery[ 1 ]?.data?.actions?.data,
        actions,
        countNewFavorites,
        next,
        error: actionsQuery[ 1 ]?.error,
        isLoading: actionsQuery[ 1 ]?.loading,
        hideAction,
        hasMore: actionsQuery[ 1 ]?.data?.actions?.page < actionsQuery[ 1 ]?.data?.actions?.page.lastPage,
        total: actionsQuery[ 1 ]?.data?.actions?.total
    };
}