import { useMediaQuery } from 'react-responsive';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import ChatConversationDeclineProjectModalTouch from '@/components/chat/components/chat-conversation/chat-conversation-decline-project-modal/ChatConversationDeclineProjectModalTouch';
import ChatConversationDeclineProjectModalDesktop from '@/components/chat/components/chat-conversation/chat-conversation-decline-project-modal/ChatConversationDeclineProjectModalDesktop';
import { ConversationItemOffer } from '@/components/chat/interfaces/conversationItemItemOffer';

interface ChatConversationDeclineModalProps {
    offerItem: ConversationItemOffer;
}

const ChatConversationDeclineProjectModal = forwardRef( function( props: ChatConversationDeclineModalProps, ref ) {

    const { offerItem } = props;

    const isTabletOrMobile = useMediaQuery( { query: `(max-width: ${ import.meta.env.VITE_MOBILE_BREAK_POINT })` } );
    const [ isOpen, setIsOpen ] = useState( false );

    useImperativeHandle( ref, () => ( {
        open() {
            handleOpenModal();
        },
        close() {
            handleCloseModal();
        }
    } ) );

    const handleOpenModal = () => {
        setIsOpen( true );
    };

    const handleCloseModal = () => {
        setIsOpen( false );
    };

    return (
        <>
            {
                isTabletOrMobile ?
                <ChatConversationDeclineProjectModalTouch offerItem={ offerItem }
                                                          onCloseModal={ () => handleCloseModal() }
                                                          isOpen={ isOpen } /> :
                <ChatConversationDeclineProjectModalDesktop offerItem={ offerItem }
                                                            onCloseModal={ () => handleCloseModal() }
                                                            isOpen={ isOpen } />
            }
        </>
    );
} );

export default ChatConversationDeclineProjectModal;