import { gql } from '@apollo/client';


export const expertisesGQL = gql`
    query Expertises {
        expertises {
            id
            i18n
            name
        }
    }`;


