import { ApolloClient, ApolloLink, concat, fromPromise, HttpLink, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import * as Sentry from '@sentry/react';
import { SessionRepository } from '@/core/auth/sessionRepository';
import { refreshTokenGQL } from '@/gql/global-queries/refreshToken';
import { resetStores } from '@/core/resetStores';
import { SentryLink } from 'apollo-link-sentry';

let client;
const refreshTokenQuery = () => {
    return client.query( { query: refreshTokenGQL } ).then( ( response ) => {
        const { accessToken, refreshToken, cometchatAuthToken } = response.data.refreshToken;
        return {
            accessToken,
            refreshToken,
            cometchatAuthToken
        };
    } );
};

const createApollo = () => {
    const sessionRepository = new SessionRepository();
    const httpLink: HttpLink = new HttpLink( { uri: `${ import.meta.env.VITE_API_ENDPOINT }/graphql` } );

    const link = onError( ( { graphQLErrors, networkError, operation, forward } ) => {
        if( graphQLErrors ) {
            for( let err of graphQLErrors ) {
                switch( err.extensions.code ) {
                    case 'UNAUTHENTICATED':
                        sessionRepository.accessToken = null;
                        return fromPromise(
                            refreshTokenQuery().catch( ( error ) => {
                                resetStores();
                                return;
                            } )
                        ).filter( ( value ) => Boolean( value ) )
                         .flatMap( ( { accessToken, refreshToken, cometchatAuthToken } ) => {
                             const oldHeaders = operation.getContext().headers;
                             // modify the operation context with a new token
                             sessionRepository.setTokens( accessToken, refreshToken, cometchatAuthToken );
                             operation.setContext( {
                                 headers: {
                                     ...oldHeaders,
                                     authorization: `Bearer ${ accessToken }`
                                 }
                             } );
                             // retry the request, returning the new observable
                             return forward( operation );
                         } );
                }
            }


            if( graphQLErrors ) {
                graphQLErrors.map( ( { message, locations, path } ) => {
                        Sentry.captureException( message );
                    }
                );
            }
            if( networkError ) {
                Sentry.captureException( networkError );
            }
        }
        if( networkError ) {
            Sentry.captureException( networkError );
            //router.navigate( [ '/error', '504' ] );
        }
    } );

    const authMiddleware = new ApolloLink( ( operation, forward ) => {
        operation.setContext( ( { headers = {} } ) => ( {
            headers: {
                authorization: 'Bearer ' + ( sessionRepository.accessToken
                                             ? sessionRepository.accessToken
                                             : sessionRepository.refreshToken ) || null,
                ...headers
            }
        } ) );


        return forward( operation );
    } );

    return {
        link: ApolloLink.from( [
            new SentryLink( {
                uri: `${ import.meta.env.VITE_API_ENDPOINT }/graphql`,
                attachBreadcrumbs: {
                    includeQuery: true,
                    includeVariables: true
                }
            } ),
            concat( authMiddleware, link.concat( httpLink ) )
        ] ),
        cache: new InMemoryCache()
    };

};

client = new ApolloClient( {
    ...createApollo(),
    defaultOptions: {
        watchQuery: {
            fetchPolicy: 'no-cache',
            errorPolicy: 'all'
        },
        mutate: {
            fetchPolicy: 'no-cache',
            errorPolicy: 'all'
        }
    },
    cache: new InMemoryCache()
} );

export { client };