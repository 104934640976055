import { Controller } from 'react-hook-form';
import firstLetterUppercaseFormatter from '@/utils/firstLetterUppercaseFormatter';
import { DatePicker } from 'antd';
import React, { useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { DateTime } from 'luxon';

interface DatePickerProps {
    className?: string;
    defaultValue?: string | number;
    control: any;
    name: string;
    label?: string;
    placeholder: string;
    rules?: any;
    errors: any;
    suffix?: JSX.Element | string;
    disablePreviousDate: boolean;
}


export default function DatePickerComponent( {
                                                 className,
                                                 defaultValue,
                                                 control,
                                                 name,
                                                 label,
                                                 placeholder,
                                                 rules,
                                                 errors,
                                                 disablePreviousDate
                                             }: DatePickerProps ) {
    const [ isFocused, setIsFocused ] = useState( false );
    const selectRef = useRef( null );

    const focusBorder = isFocused ? 'border-black' : 'border-grey-light';
    const errorBorder = errors[ name ] ? 'border-red' : '';

    function disabledDate( current ) {
        const today = DateTime.now().startOf('day');
        return current && current < today;
    }

    return (
        <div className={ twMerge( 'w-full', className ) }
             onClick={ () => selectRef.current.focus() }>
            <Controller control={ control }
                        name={ name }
                        rules={ rules }
                        defaultValue={ defaultValue }
                        render={ ( { fieldState: { error }, field: { onChange, onBlur, value, ref } } ) => (
                            <div className="w-full custom-select">
                                <div className={ twMerge( 'w-full transition-colors duration-500 ease-in-out border rounded-[10px] px-3.5 py-2.5', focusBorder, errorBorder ) }>
                                    <label className="text-xs text-grey font-normal">
                                        { label }
                                    </label>
                                    <DatePicker onChange={ onChange }
                                                value={ value }
                                                onBlur={ onBlur }
                                                disabledDate={ ( current ) => disablePreviousDate && disabledDate( current ) }
                                                ref={ ( e ) => {
                                                    ref( e );
                                                    selectRef.current = e;
                                                } }
                                                bordered={ false }
                                                placeholder={ placeholder }
                                                className="w-full p-0"
                                                status={ error ? 'error' : '' } />
                                </div>
                                {
                                    errors[ name ] &&
                                    (
                                        <div className="w-fit text-sm font-normal text-red mt-1.5">
                                            { firstLetterUppercaseFormatter( errors[ name ].message.toString() ) }
                                        </div>
                                    )
                                }
                            </div>
                        ) }
            />
        </div>
    );
}