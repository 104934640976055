import { useEffect, useState } from 'react';
import { App } from 'antd';
import { useGoogleLogin } from '@react-oauth/google';
import * as Sentry from '@sentry/react';
import { Trans, useTranslation } from 'react-i18next';
import { loginWithGoogleGQL } from '@/gql/global-queries/loginWithGoogleGQL';
import { useMutation } from '@apollo/client';
import { SessionRepository } from '@/core/auth/sessionRepository';
import { useNavigate } from 'react-router-dom';

interface GoogleConnectHookProps {
    scopes: string[];
}

export default function googleConnectHook( { scopes }: GoogleConnectHookProps ) {

    const { t } = useTranslation();
    const { message } = App.useApp();
    const session = new SessionRepository();
    const navigate = useNavigate();

    const [ isLoading, setIsLoading ] = useState( false );
    const [ code, setCode ] = useState( null );

    const loginWithGoogleMutation = useMutation( loginWithGoogleGQL );

    useEffect( () => {
        if( loginWithGoogleMutation ) {
            setIsLoading( loginWithGoogleMutation[ 1 ].loading );
        }
    }, [ loginWithGoogleMutation[ 1 ]?.loading ] );

    const getCode = (): void => {
        setIsLoading( true );
        googleLoginFunc();
    };

    const login = async( code: string ): Promise<any> => {
        try {
            const response = await loginWithGoogleMutation[ 0 ]( {
                variables: {
                    code
                }
            } );

            const data = response.data.loginWithGoogle;
            session.login( data );

            if( data.user.type == 'CLIENT' ) {
                navigate( '/client' );
            }
            else {
                navigate( '/freelancer' );
            }
        } catch( e ) {
            message.open({
                content: (
                    <Trans i18nKey="auth:sign-in.errors.loginGoogleFailed">
                        <span></span>
                        <a className='text-primary-400 underline' href="/auth/sign-up/client"></a>
                    </Trans>
                ),
                duration: 5,
                type: 'error'
              });
        }
    };

    const googleLoginFunc = useGoogleLogin( {
        flow: 'auth-code',
        scope: scopes.join( ' ' ),
        onSuccess: async( codeResponse ) => {
            setIsLoading( false );
            if( !areAllScopesValid( codeResponse.scope, scopes ) ) {
                message.error( t( 'auth:sign-up.client.ytScopeNotAccepted' ), 4000 );
                return;
            }

            setCode( codeResponse.code );
        },
        onError: errorResponse => {
            setIsLoading( false );
            Sentry.captureException( errorResponse );
            message.error( t( 'translation:error.default' ) );
            return;
        }
    } );

    function areAllScopesValid( scopesString: string, scopeArray: string[] ): boolean {
        const scopes: string[] = scopesString.split( ' ' );
        return scopeArray.every( scope => scopes.includes( scope ) );
    }

    return {
        login,
        getCode,
        code,
        isLoading
    };
}
