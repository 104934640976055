import { gql } from '@apollo/client';


export const skillsGQL = gql`
    query Skills {
        skills {
            id
            i18n
        }
    }`;


