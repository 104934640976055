import { FreelancersRepository } from '@/components/freelancers-filter-items/freelancersRepository';
import { useObservable } from '@ngneat/react-rxjs';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import firstLetterUppercaseFormatter from '@/utils/firstLetterUppercaseFormatter';

interface FiltersFreelancerHookProps {
    freelancersRepository: FreelancersRepository;
}

export function filtersFreelancerHook( props: FiltersFreelancerHookProps ) {

    const { freelancersRepository } = props;

    const [ filters ] = useObservable( freelancersRepository.filters$ );

    const navigate = useNavigate();

    useEffect( () => {
        const params = builderParams( filters );
        const searchParams = new URLSearchParams( params ).toString();
        navigate( `?${ searchParams }` );
    }, [ filters, history ] );

    const builderParams = ( filters: any ) => {
        let params = {};
        if( filters ) {
            for( let filter in filters ) {
                if( filters[ filter ] !== null ) {
                    if( typeof filters[ filter ] === 'object' && !Array.isArray( filters[ filter ] ) && filters[ filter ] !== null ) {
                        for( let subFilter in filters[ filter ] ) {
                            params[ `${ filter }${ firstLetterUppercaseFormatter( subFilter ) }` ] = filters[ filter ][ subFilter ];
                        }
                    }
                    else if( Array.isArray( filters[ filter ] ) && filters[ filter ].length > 0 ) {
                        params[ filter ] = filters[ filter ].join( ',' );
                    }
                    else if( !Array.isArray( filters[ filter ] ) ) {
                        params[ filter ] = filters[ filter ];
                    }
                }
            }
        }
        return params;
    };
}
