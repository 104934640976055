import { gql } from '@apollo/client';

export const createProjectGQL = gql`
    mutation createProject($args: CreateProjectInput!) {
        createProject(args: $args) {
            id
            name
            description
            conversationId
            client {
                id
            }
        }
    }
`;
