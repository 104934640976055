import React from 'react';
import IconedImage from '@/components/IconedImage';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';


interface FreelancerActionItemLayoutProps {
    title: string | React.ReactNode;
    datetime: string;
    clientProfilePicture: string;
    icon?: React.ReactNode;
    badge?: React.ReactNode;
    children: React.ReactNode;
}

const FreelancerActionItemLayout: React.FC<FreelancerActionItemLayoutProps> = ({ title, datetime, clientProfilePicture, icon, badge, children }) => {
    const { t } = useTranslation();
    return (
        <div className="relative action-component flex gap-4 rounded-xl border border-blue-100 p-5">
            <IconedImage src={ clientProfilePicture } icon={ icon } />
            <div className='flex-1'>
                <div className="flex justify-between">
                    <div className="action-header flex flex-col mb-5">
                        <span className='font-sharp'>{ title }</span>
                        <span className='text-grey'>
                            { t( 'freelancer:dashboard.actionCenter.action.date', { date: DateTime.fromISO( datetime ).toFormat('dd LLLL yyyy') }) }
                        </span>
                    </div>
                    { badge }
                </div>
                <div className="action-content">{ children }</div>
            </div>
        </div>
    );
}

export default FreelancerActionItemLayout;
