import { gql } from '@apollo/client';

export const freelancerStatsGQL = gql`
    query freelancerStatsGQL($from: DateTime!, $to: DateTime!) {
        getFreelancerStats(from: $from, to: $to) {
            avgRating
            countProjectsDone
            countRatings
            moneyEarned
        }
    }
`;
