import { createStore, select, withProps } from '@ngneat/elf';

interface drawerFullProfileStore {
    freelancerSelected: string | null;
}

const drawerFullProfileStore = createStore(
    { name: 'clientDrawerFullProfile' },
    withProps<drawerFullProfileStore>( {
        freelancerSelected: null
    } )
);

const freelancerSelectedStore = drawerFullProfileStore.query( ( { freelancerSelected } ) => freelancerSelected );
const isFreelancerSelectedStore$ = drawerFullProfileStore.pipe( select( ( state ) => state.freelancerSelected ) );
const setFreelancerSelectedStore = ( value ) => {
    drawerFullProfileStore.update( ( state ) => {
        return {
            ...state,
            freelancerSelected: value
        };
    } );
};

export { freelancerSelectedStore, isFreelancerSelectedStore$, setFreelancerSelectedStore };