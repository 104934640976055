import { gql } from '@apollo/client';

export const CheckIfProfileIsCompletedGQL = gql`
    query CheckIfProfileIsCompletedGQL {
        checkIfProfileIsCompleted {
            status
            isCompleted
            completedPercentage
            profilePicture
            portfolioElements
            companyInformation
            connectedAccount
            freelancerCharterAccepted
        }
    }`;