import { gql } from '@apollo/client';

export const ActionsGQL = gql`
    query Actions($page: Int, $limit: Int) {
        actions(page: $page, limit: $limit) {
            data {
                id
                type
                quoteId
                createdAt
                isActive
                isHidden
                project {
                    id
                    name
                    conversationId
                    endedAt
                    createdAt
                    channel {
                        id
                        name
                    }
                    client {
                        id
                        profilePicture
                        userName
                    }
                }
                quote {
                    messageId
                }
            }
            page
            limit
            lastPage
            total
        }
    }`;