import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { CheckIfProfileIsCompletedGQL } from '@/pages/freelancer/views/account/freelancer-account-profile-validation/checkIfProfileIsCompletedGQL';
import { CheckOutlinedIcon, ChevronRightFilledIcon, ExclamationFilledIcon, HourGlassFilledIcon } from '@/assets/icons/CustomIcons';
import React, { useEffect, useState } from 'react';
import { ChecklistVerifiedProfile, FreelancerAccountStatusEnum } from '@/gql/graphql';
import SkeletonInput from 'antd/es/skeleton/Input';

interface StateUI {
    status: FreelancerAccountStatusEnum;
    icon: JSX.Element;
    css: string;
    title: string;
}

export default function FreelancerAccountProfileValidationSection() {

    const { t } = useTranslation();
    const [ state, setState ] = useState<StateUI>( null );

    const checkIfProfileIsCompletedQuery = useQuery( CheckIfProfileIsCompletedGQL );

    useEffect( () => {
        if( checkIfProfileIsCompletedQuery.data ) {
            const elements: ChecklistVerifiedProfile = checkIfProfileIsCompletedQuery.data.checkIfProfileIsCompleted;
            setState( states.find( item => item.status == elements.status ) );
        }
    }, [ checkIfProfileIsCompletedQuery.data ] );

    const validatedIconJSX = (
        <div className="flex items-center justify-center w-6 h-6 rounded-full border-[1.5px] border-green-200">
            <CheckOutlinedIcon className="text-green-200 text-[12px]" />
        </div>
    );

    const alertIconJSX = (
        <div className="flex items-center justify-center w-6 h-6 rounded-full border-[1.5px] border-red">
            <ExclamationFilledIcon className="text-red text-[12px]" />
        </div>
    );

    const hourGlassIconJSX = (
        <div className="flex items-center justify-center w-6 h-6 rounded-full border-[1.5px] border-orange-100">
            <HourGlassFilledIcon className="text-orange-100 text-[12px]" />
        </div>
    );

    const states: StateUI[] = [
        {
            status: FreelancerAccountStatusEnum.PENDING_APPROBATION,
            icon: hourGlassIconJSX,
            css: 'border-orange-100',
            title: t( 'freelancer:account.verifyProfile.pendingApprobation' )
        },
        {
            status: FreelancerAccountStatusEnum.APPROVED,
            icon: validatedIconJSX,
            css: 'border-green-200',
            title: t( 'freelancer:account.verifyProfile.verifiedAccount' )
        },
        {
            status: FreelancerAccountStatusEnum.REJECTED,
            icon: alertIconJSX,
            css: 'border-red',
            title: t( 'freelancer:account.verifyProfile.unVerifiedAccount' )
        }
    ];

    const loadingJSX = (
        <SkeletonInput active={ true }
                       className="h-[44px] w-[230px] rounded-full" />
    );

    const coreJSX = (
        <>
            {
                state &&
                <div className={ `h-[44px] flex items-center rounded-full px-4 text-black border ${ state?.css }` }>
                  <div className="flex justify-between items-center">
                    <div className="w-full flex items-center">
                      <div className="flex-none">
                          {
                              state?.icon
                          }
                      </div>
                      <div className="ml-2 text-base font-medium">
                          { state?.title }
                      </div>
                    </div>
                      {
                          state?.status == FreelancerAccountStatusEnum.REJECTED &&
                          <div className="ml-2 flex items-center">
                            <ChevronRightFilledIcon className="text-[18px]" />
                          </div>
                      }
                  </div>
                </div>
            }
        </>
    );

    return (
        <>
            {
                checkIfProfileIsCompletedQuery.loading || state == null ? loadingJSX : coreJSX
            }
        </>
    );
}