import Upload, { RcFile } from 'antd/es/upload';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import firstLetterUppercaseFormatter from '@/utils/firstLetterUppercaseFormatter';
import { message, Progress } from 'antd';
import getObjectUrlFromPreSignedUrl from '@/utils/getObjectUrlFromPreSignedUrl';
import Resizer from 'react-image-file-resizer';
// @ts-expect-error https://github.com/onurzorluer/react-image-file-resizer/issues/68
const resizer: typeof Resizer = ( Resizer.default || Resizer );

interface UploadFileProps {
    defaultProfilePictureUrl?: string;
    onUploadCompleted?: ( file: File ) => void;
    onFileCreated?: ( file: File ) => void;
    isUploading?: ( value: boolean ) => void;
    displayEditButton?: boolean;
    className?: string;
    name?: string;
    errors?: any;
}

const ProfilePictureUploader = forwardRef( ( props: UploadFileProps, ref ) => {

    const {
              defaultProfilePictureUrl,
              isUploading,
              onUploadCompleted,
              onFileCreated,
              displayEditButton = true,
              className,
              errors,
              name
          } = props;
    const { t } = useTranslation();
    const [ messageApi, contextHolder ] = message.useMessage();
    const [ file, setFile ] = useState( null );
    const [ imageUrl, setImageUrl ] = useState( defaultProfilePictureUrl );
    const [ uploadProgress, setUploadProgress ] = useState( 0 );
    const uploaderRef = useRef( null );

    useImperativeHandle( ref, () => ( {
        async upload( preSignedUrl: string ) {
            return handleUpload( preSignedUrl );
        },
        open() {
            handleOpenEditWindows();
        },
        getFile() {
            return file;
        }
    } ) );

    useEffect( () => {
        if( isUploading ) {
            if( uploadProgress > 0 && uploadProgress < 100 ) {
                isUploading( true );
            }
            else {
                isUploading( false );
            }
        }
    }, [ uploadProgress ] );

    const handleOpenEditWindows = () => {
        uploaderRef.current.upload.uploader.fileInput.click();
    };

    const resizeFile = ( file ): Promise<File> =>
        new Promise( ( resolve ) => {
            resizer.imageFileResizer(
                file,
                300,
                300,
                'WEBP',
                100,
                0,
                ( uri ) => {
                    resolve( uri as File );
                },
                'file'
            );
        } );

    const handleUpload = async( preSignedUrl: string ): Promise<string> => {
        return new Promise( ( resolve, reject ) => {
            if( !file ) {
                return;
            }

            const xhr = new XMLHttpRequest();
            xhr.open( 'PUT', preSignedUrl, true );
            xhr.setRequestHeader( 'Content-Type', file.type );

            xhr.upload.addEventListener( 'progress', ( event ) => {
                if( event.lengthComputable ) {
                    const percentComplete: number = Math.round( ( event.loaded / event.total ) * 100 );
                    setUploadProgress( percentComplete );
                }
            } );

            xhr.addEventListener( 'load', () => {
                if( xhr.status === 200 ) {
                    setUploadProgress( 100 );
                    if( onUploadCompleted ) {
                        onUploadCompleted( file );
                    }
                    resolve( getObjectUrlFromPreSignedUrl( preSignedUrl ) );
                }
                else {
                    messageApi.error( 'Failed to upload image' );
                    setUploadProgress( 0 );
                    reject( null );
                }
            } );

            xhr.addEventListener( 'error', () => {
                messageApi.error( 'An error occurred while uploading the file' );
                setUploadProgress( 0 );
                reject( null );
            } );

            xhr.send( file );
        } );
    };

    const uploadJSX = (
        <div className="flex flex-col items-center justify-center">
            <PlusOutlined />
            <p className="mt-2">Upload</p>
        </div>
    );

    const beforeUpload = async( file: RcFile ) => {
        try {
            const fileResized: File = await resizeFile( file );
            setFile( fileResized );
            onFileCreated?.( file );
            const reader: FileReader = new FileReader();
            reader.onload = e => setImageUrl( e.target.result as string );
            reader.readAsDataURL( fileResized );
            return false;
        } catch( e ) {
            messageApi.error( e.message );
            setImageUrl( defaultProfilePictureUrl );
            uploaderRef.current.fileList = [];
            setFile( [] );
            return false;
        }
    };

    return (
        <>
            <div className={ `w-fit flex items-center ${ className }` }>
                { contextHolder }
                <div className="flex-none">
                    <Upload name="avatar"
                            listType="picture-circle"
                            maxCount={ 1 }
                            ref={ uploaderRef }
                            showUploadList={ false }
                            accept="image/*"
                            beforeUpload={ ( file ) => beforeUpload( file ) }>
                        {
                            uploadProgress > 0 && uploadProgress < 100 ?
                            <Progress type="circle"
                                      percent={ uploadProgress } /> :
                            imageUrl ?
                            <img className="rounded-full"
                                 src={ imageUrl }
                                 style={ { width: '100%', height: '100%' } }
                                 alt="Profile Preview" /> :
                            defaultProfilePictureUrl ?
                            <img className="rounded-full"
                                 src={ defaultProfilePictureUrl }
                                 alt="Profile Preview" /> : uploadJSX

                        }
                    </Upload>
                </div>
                <div className="w-full ml-8 flex flex-col">
                    <div>
                        <div className="text-base font-semibold">
                            { t( 'common:profile-picture-uploader.title' ) }
                        </div>
                        <div className="text-base font-normal text-grey">
                            { t( 'common:profile-picture-uploader.description' ) }
                        </div>
                    </div>

                    {
                        displayEditButton &&
                        <div onClick={ () => handleOpenEditWindows() }
                             className="w-fit text-sm font-semibold mt-2 lg:mt-8 text-primary-400 hover:text-primary-500 cursor-pointer">
                            { firstLetterUppercaseFormatter( t( 'common:update' ) ) }
                        </div>
                    }
                </div>
            </div>
            {
                errors &&
                errors[ name ] &&
                (
                    <div className="w-fit text-sm font-normal text-red">
                        { firstLetterUppercaseFormatter( errors[ name ].message.toString() ) }
                    </div>
                )
            }
        </>
    );
} );

export default ProfilePictureUploader;