import { gql } from '@apollo/client';

export const projectGQL = gql`
    query Project($id: String!) {
        project(id: $id) {
            id
            name
            description
            status
            startedAt
            clientHasReviewed
            validatedByClientAt
            validatedByFreelancerAt
            freelancerHasReviewed
            conversationId
            client {
                id
                userName
                profilePicture
            }
            freelancer {
                id
                userName
            }
            users {
                id
                type
            }
            payment {
                id
                currency
                paymentExVat
                freelancerAmountExVat
                clientAmountIncVat
                clientInvoiceUrl
                freelancerInvoiceUrl
                vatRate
                feeRate
            }
            channel {
                id
                name
                thumbnailUrl
                videoCount
                subscriberCount
            }
            createdAt
            updatedAt
        }
    }`;


