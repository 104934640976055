import { useTranslation } from 'react-i18next';
import { Link, Outlet } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { GetOnboardingStripeLinkGQL } from '@/gql/global-queries/getOnboardingStripeLinkGQL';
import LayoutVisibilityHook from '@/hooks/layoutVisibilityHook';
import MenuSection, { AccountNavigationItem } from '@/components/MenuSection';
import { Button } from 'antd';
import { LinkFilledIcon } from '@/assets/icons/CustomIcons';
import FreelancerAccountProfileValidationSection from '@/pages/freelancer/views/account/freelancer-account-profile-validation/components/FreelancerAccountProfileValidationSection';
import { freelancerProfileLinkHook } from '@/hooks/freelancerProfileLinkHook';

export default function FreelancerSettings() {

    const { t } = useTranslation();
    const [ onboardingStripeLink, setOnboardingStripeLink ] = useState( null );
    const { getProfileLink } = freelancerProfileLinkHook();

    LayoutVisibilityHook( {
        headerVisible: true,
        bottomNavBarVisible: false
    } );

    const onboardingLinkQuery = useQuery( GetOnboardingStripeLinkGQL );

    useEffect( () => {
        if( onboardingLinkQuery.data ) {
            setOnboardingStripeLink( onboardingLinkQuery.data.getOnboardingStripeLink );
        }
    }, [ onboardingLinkQuery.data ] );

    const navigationSections: AccountNavigationItem[] = [
        {
            title: t( 'freelancer:account.navigation.editProfile' ),
            route: '/freelancer/settings/edit-profile'
        },
        {
            title: t( 'freelancer:account.navigation.editPortfolio' ),
            route: '/freelancer/settings/edit-portfolio'
        },
        {
            title: t( 'freelancer:account.navigation.creatorsIveWorkWith' ),
            route: '/freelancer/settings/edit-creators'
        },
        {
            title: t( 'freelancer:account.navigation.editPassword' ),
            route: '/freelancer/settings/edit-password'
        },
        {
            title: t( 'freelancer:account.navigation.myBankAccount' ),
            route: onboardingStripeLink,
            isExternal: true
        },
        {
            title: t( 'freelancer:account.navigation.companyDetails' ),
            route: '/freelancer/settings/edit-company-details'
        }
    ];

    return (
        <div className="w-full h-full">
            <div className="flex justify-between items-center mb-8">
                <div className="hidden lg:block text-2xl font-medium font-sharp">
                    { t( 'freelancer:account.navigation.settings' ) }
                </div>

                <div className="flex flex-wrap items-center gap-4">
                    <Link to="/freelancer/settings/verify-profile">
                        <FreelancerAccountProfileValidationSection />
                    </Link>
                    <Button type="primary"
                            onClick={ () => getProfileLink() }
                            className="flex items-center gax-x-2">
                        <LinkFilledIcon className="text-[18px]" />
                        { t( 'freelancer:account.shareProfile' ) }
                    </Button>
                </div>
            </div>
            <div className="flex flex-col lg:flex-row">
                <div className="flex-none lg:w-[250px]">
                    <MenuSection routes={ navigationSections } />
                </div>

                <div className="mt-6 lg:mt-0 w-full lg:max-w-[977px] h-full lg:ml-[96px] lg:border-[1.5px] lg:border-grey-light lg:rounded-3xl lg:p-6">
                    <Outlet />
                </div>
            </div>
        </div>
    );
}