import { DateTime } from 'luxon';
import { ItemTypeEnum } from '@/components/chat/enums/itemTypeEnum';

interface ChatConversationMessageLayoutProps {
    type: ItemTypeEnum;
    isCurrentUser?: boolean;
    header?: string;
    footer?: string;
    children?: React.ReactNode;
    sentAt: Date;
}

export default function ChatConversationItemLayout( {
                                                        type,
                                                        isCurrentUser,
                                                        header,
                                                        sentAt,
                                                        footer = DateTime.fromISO( sentAt ).toLocaleString( DateTime.TIME_SIMPLE ),
                                                        children
                                                    }: ChatConversationMessageLayoutProps ) {

    const message = () => {
        return (
            <div className={ `w-full flex ${ isCurrentUser ? 'justify-end' : 'justify-start' }` }>
                <div className="max-w-[90%] lg:max-w-[80%]">
                    {
                        header &&
                        <div className={ `w-full flex ${ isCurrentUser
                                                         ? 'items-end'
                                                         : 'items-start' } lg:font-extrabold mb-2 lg:mb-4 text-grey-medium lg:text-2xl` }>
                          <h4>{ header }</h4>
                        </div>
                    }
                    <div className={ `break-words font-base font-normal w-full p-2 lg:p-4 border border-solid border-grey-light rounded-b-2xl whitespace-pre-wrap ${ isCurrentUser
                                                                                                                                                                     ? 'rounded-tl-2xl bg-blue-200'
                                                                                                                                                                     : 'rounded-tr-2xl bg-white' }` }>
                        { children }
                    </div>
                    <div className="text-xs font-normal text-grey-medium mt-1">
                        { footer }
                    </div>
                </div>
            </div>
        );
    };

    const action = () => {
        return (
            <div className="w-full flex justify-center my-8 text-center">
                { children }
            </div>
        );
    };

    return (
        <>
            {
                type && type == ItemTypeEnum.ACTION ? action() : message()
            }
        </>
    );
}