import { useEffect, useState } from 'react';
import { ChannelManagerRepository } from '@/pages/client/common/channelManagerRepository';
import { Channel } from '@/gql/graphql';

export default function ChannelManagerHook() {

    const [ channelsSelected, setChannelsSelected ] = useState<Channel[] | null>( [] );
    const channelManagerRepository = new ChannelManagerRepository();

    useEffect( () => {
        const observableChannels = channelManagerRepository.channels$.subscribe( ( channel: Channel[] ) => {
            setChannelsSelected( channel );
        } );

        return () => {
            observableChannels.unsubscribe();
        };
    }, [] );

    return {
        channelsSelected
    };
}