import { useTranslation } from 'react-i18next';
import { CurrencyEnum, ExperienceTypeEnum } from '@/gql/graphql';
import React, { useEffect, useState } from 'react';
import FreelancerPrice from '@/components/freelance-profile/components/FreelancerPrice';
import FreelancerExperienceTag from '@/components/freelance-profile/components/FreelancerExperienceTag';
import FreelancerRatingTag from '@/components/freelance-profile/components/FreelancerRatingTag';
import FreelancerLanguages from '@/components/freelance-profile/components/FreelancerLanguages';
import { twMerge } from 'tailwind-merge';

interface FreelancerProfileMainIndicatorProps {
    data: {
        avgPrice: number;
        priceCurrency: CurrencyEnum;
        experience: ExperienceTypeEnum;
        languages: string[];
        avgRate: number | null;
        countProjects: number | null;
    };
    className?: string;
}

export default function MainIndicatorCardProfile( { data, className }: FreelancerProfileMainIndicatorProps ) {

    const { t } = useTranslation();
    const [ indicators, setIndicators ] = useState( [] );

    useEffect( () => {
        setIndicators( [
            {
                description: t( 'common:freelancerProfile.card.AvgPriceSubTitle' ),
                value: <FreelancerPrice price={ data?.avgPrice }
                                        className="text-black font-medium text-base"
                                        iconClassName="text-xl"
                                        legend={ false }
                                        bordered={ false } />
            },
            {
                description: t( 'common:freelancerProfile.card.experienceSubTitle' ),
                value: <FreelancerExperienceTag experience={ data?.experience }
                                                className="text-black font-medium text-base"
                                                iconClassName="text-xl"
                                                bordered={ false } />
            },
            {
                description: t( 'common:freelancerProfile.card.languagesSubTitle' ),
                value: <FreelancerLanguages languages={ data?.languages }
                                            className="text-black font-medium text-base mt-1"
                                            iconClassName="text-lg" />
            }
        ] );
    }, [] );

    useEffect( () => {
        if( data.avgRate ) {
            setIndicators( ( values ) => [
                ...values,
                {
                    description: t( 'common:freelancerProfile.card.reviewsSubtitle' ),
                    value: <FreelancerRatingTag rating={ data.avgRate } />
                }
            ] );
        }

    }, [ data.avgRate ] );

    return (
        <div className={ twMerge( 'flex flex-wrap lg:flex-nowrap gap-6 lg:gap-x-14', className ) }>
            {
                indicators?.map( ( indicator, index ) => (
                    <div key={ index }
                         className="flex flex-col">
                        <div className="text-grey text-sm font-medium">
                            { indicator?.description }
                        </div>
                        <div className="text-base font-medium text-black">
                            { indicator?.value }
                        </div>
                    </div>
                ) )
            }
        </div>
    );
}