import React from 'react';
import FreelancerActionItemLayout from './FreelancerActionItemLayout';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import { Action } from '@/gql/graphql';
import { LoadingOutlinedIcon, FlashFilledIcon } from '@/assets/icons/CustomIcons';

interface NewJobOfferItemProps {
    action: Action;
}

const NewJobOfferItem: React.FC<NewJobOfferItemProps> = ({ action }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <div className='relative'>
            <div className="bg-primary-500 h-10 w-[3px] absolute left-0 top-4" />
            <FreelancerActionItemLayout 
                title={
                    <Trans 
                        i18nKey="freelancer:dashboard.actionCenter.action.newJobOfferTitle" 
                        components={[<span className='text-primary-500' />]} 
                        values={{ creatorName: action.project.client?.userName }}
                    />
                }
                datetime={ action.createdAt }
                icon={
                    <div className='size-6 bg-primary-500 rounded-full border-2 border-white flex justify-center items-center'>
                        <LoadingOutlinedIcon className='text-white' />
                    </div> 
                }
                badge={ 
                    <div className='bg-gradient-to-br h-fit text-white text-xs whitespace-nowrap font-semibold from-indigo-400 via-purple-600 to-blue-900 flex gap-0.5 px-2 py-1 rounded-2xl justify-center items-center'>
                        <FlashFilledIcon className='text-xs text-white' />  { t( 'freelancer:dashboard.actionCenter.action.badge.jobOffer' ) }
                    </div> 
                }
                clientProfilePicture={ action.project.client?.profilePicture }
            >
                <div className="flex flex-col gap-4">
                    <div className='text-slate-600'>
                        <Trans 
                            i18nKey="freelancer:dashboard.actionCenter.action.newJobOfferDesc" 
                            components={[<strong className='text-gray-600' />, <strong className='text-gray-600' />]} 
                            values={{
                                creatorName: action.project.client?.userName,
                                projectTitle: action.project.name 
                            }}
                        />
                    </div>
                    <Button type='default' className='w-fit text-primary-500' onClick={ () => navigate(`/freelancer/messages/${action.project.conversationId}`) }>
                        { t( 'freelancer:dashboard.actionCenter.action.button.goToConversation' ) }
                    </Button>
                </div>
            </FreelancerActionItemLayout>
        </div>
    );
}

export default NewJobOfferItem;
