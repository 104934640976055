import { useMediaQuery } from 'react-responsive';
import { ClientHeaderDesktop } from '@/pages/client/layout/header/ClientHeaderDesktop';
import { ClientHeaderTouch } from '@/pages/client/layout/header/ClientHeaderTouch';
import FavoritesHook from '@/hooks/favoritesHook';
import { SessionRepository } from '@/core/auth/sessionRepository';
import { useEffect, useState } from 'react';
import { Channel, Client } from '@/gql/graphql';
import SkeletonInput from 'antd/es/skeleton/Input';
import ChannelItem from '@/components/ChannelItem';
import channelManagerSettingsHook from '@/hooks/channelManagerSettingsHook';
import { IncognitoFilledIcon, TVFilledIcon } from '@/assets/icons/CustomIcons';
import { Avatar } from 'antd';
import { useTranslation } from 'react-i18next';

export default function ClientHeader() {

    const { t } = useTranslation();
    const isTabletOrMobile: boolean = useMediaQuery( { query: `(max-width: ${ import.meta.env.VITE_MOBILE_BREAK_POINT })` } );

    const { countNewFavorites } = FavoritesHook();
    const sessionRepository = new SessionRepository();
    const [ user, setUser ] = useState<Partial<Client>>( null );

    const {
              loading,
              channels,
              channelSelected,
              updateSelectedChannel,
              channelSelectedKey
          } = channelManagerSettingsHook();

    let customSection: JSX.Element[] | null = [];

    if( loading == null && channels == null ) {
        customSection = null;
    }

    if( loading ) {
        customSection = [
            (
                <div className="py-1.5">
                    <SkeletonInput active={ true } />
                </div>
            )
        ];
    }
    else if( !loading ) {

        customSection.push(
            <div onClick={ () => updateSelectedChannel( null ) }
                 key="no-channel"
                 className="py-1.5 flex items-center">
                <Avatar size={ 32 }
                        icon={ <IncognitoFilledIcon /> }
                        className="flex-none" />
                <span className="ml-2">
                    { t( 'client:header.desktop.incognito' ) }
                </span>
            </div>
        );

        if( channels.length > 0 ) {
            customSection.splice(
                0,
                0,
                <div onClick={ () => updateSelectedChannel( [] ) }
                     key="all-channel"
                     className="py-1.5 flex items-center">
                    <Avatar size={ 32 }
                            icon={ <TVFilledIcon /> }
                            className="flex-none" />
                    <span className="ml-2">
                    { t( 'client:header.desktop.allChannels' ) }
                </span>
                </div>
            );

            const channelsJSX = channels.map( ( channel ) => (
                <div className="py-1.5"
                     onClick={ () => updateSelectedChannel( channel ) }
                     key={ channel.id }>
                    <ChannelItem channel={ channel }
                                 avatarSize={ 32 } />
                </div>
            ) );

            customSection.splice(2, 0, ...channelsJSX )
        }
    }

    const channelSelectIntoIcon = ( channelSelected: Channel[] | null ) => {

        if( channelSelected == null ) {
            return (
                <Avatar size={ 19 }
                        className="absolute bottom-0 right-0 border-[1.5px] border-white bg-[#bcb9bf]"
                        icon={ <IncognitoFilledIcon /> }>
                </Avatar>
            );
        }

        if( channelSelected.length == 0 ) {
            return (
                <Avatar size={ 19 }
                        className="absolute bottom-0 right-0 border-[1.5px] border-white bg-[#bcb9bf]"
                        icon={ <TVFilledIcon /> }>
                </Avatar>
            );
        }

        if( Array.isArray( channelSelected ) && channelSelected.length > 0 ) {
            return (
                <Avatar size={ 19 }
                        className="absolute bottom-0 right-0 border-[1.5px] border-white"
                        src={ channelSelected[ 0 ].thumbnailUrl }>
                </Avatar>
            );
        }

    };

    useEffect( () => {
        const observable = sessionRepository.session$.subscribe( ( session ) => {
            setUser( session.user as Client );
        } );
        return () => {
            observable.unsubscribe();
        };
    }, [] );

    return (
        <>
            {
                isTabletOrMobile ?
                <ClientHeaderTouch countNewFavorites={ countNewFavorites }
                                   user={ user }
                                   channelSelectedKey={ channelSelectedKey }
                                   channelSelected={ channelSelectIntoIcon( channelSelected ) }
                                   customMyAccountSection={ customSection } /> :
                <ClientHeaderDesktop countNewFavorites={ countNewFavorites }
                                     user={ user }
                                     channelSelectedKey={ channelSelectedKey }
                                     channelSelected={ channelSelectIntoIcon( channelSelected ) }
                                     customMyAccountSection={ customSection } />
            }
        </>
    );
}