import { conversationPreviewUI } from '@/components/chat/interfaces/conversationPreviewUI';
import { Avatar, Badge } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { cuttingTextFormatter } from '@/utils/cuttingTextFormatter';
import Lottie from 'lottie-react';
import loaderAnimation from '@/assets/animations/loader.json';

interface ChatConversationListItemProps {
    item: conversationPreviewUI;
}

export default function ChatConversationListItem( { item }: ChatConversationListItemProps ) {
    return (
        <div className="h-full w-full group">
            <div className="flex justify-between w-full">
                <div className="flex items-center gap-x-3 flex-none">
                    <div className={ `w-2.5 h-2.5 rounded-full bg-primary-500 ${ item.isOnline
                                                                                 ? 'visible'
                                                                                 : 'collapse' }` }>
                    </div>

                    <Badge count={ item.unreadMessageCount }
                           size="small"
                           offset={ [ -5, 5 ] }>
                        <Avatar size={ 40 }
                                src={ item.userPicture }
                                icon={ <UserOutlined /> } />
                    </Badge>
                    <div className="text-inherit group-hover:text-primary-500 font-semibold">
                        { item.userName }
                    </div>
                </div>
                <div className="text-grey text-sm">
                    { item.lastMessageAt }
                </div>
            </div>

            <div className="w-full ml-6 mt-4">
                {
                    item.isTyping ?
                    <div className="w-fit rounded-full flex items-center justify-center">
                        <Lottie animationData={ loaderAnimation }
                                style={ { width: 60, height: 20, transform: 'scale(2.5)' } }
                                loop={ true } />
                    </div> :
                    <p className="text-grey text-sm">
                        { cuttingTextFormatter( item.lastMessage, 23 ) }
                    </p>
                }
            </div>
        </div>
    );
}