import { useIntercomHook } from '@/plugins/intercom/useIntercomHook';
import { Button } from 'antd';
import { LifeBuoyFilledIcon } from '@/assets/icons/CustomIcons';
import { useTranslation } from 'react-i18next';

interface SupportButtonProps {
    onlyIcon?: boolean;
}

export default function SupportButton( { onlyIcon = false }: SupportButtonProps ) {

    const { t } = useTranslation();
    const { toggleIntercomVisibility } = useIntercomHook();

    return (
        <>
            {
                onlyIcon ? (
                    <Button type="text"
                            shape="circle"
                            icon={ <LifeBuoyFilledIcon className="text-[24px]" /> }
                            onClick={ () => toggleIntercomVisibility() }>
                    </Button>
                ) : (
                    <Button type="text"
                            className="flex items-center justify-center"
                            onClick={ () => toggleIntercomVisibility() }>
                        <LifeBuoyFilledIcon className="text-[24px]" />
                        { t( 'common:supportButton' ) }
                    </Button>
                )
            }
        </>

    );
}