import { useEffect, useRef, useState } from 'react';
import LayoutVisibilityHook from '@/hooks/layoutVisibilityHook';
import ClientFreelancerListNotRecommended from '@/pages/client/views/freelancers/components/ClientFreelancerListNotRecommended';
import { isFreelancerSelectedStore$, setFreelancerSelectedStore } from '@/pages/client/views/freelancers/drawerFullProfileStore';
import ClientFreelancerProfileDrawer from '@/pages/client/views/freelancer/ClientFreelancerProfileDrawer';
import NewProjectModal from '@/pages/client/views/new-project/NewProjectModal';

export default function ClientFreelancers() {
    const [ freelancerIdSelected, setFreelancerIdSelected ] = useState<string>( null );
    const modalRef = useRef( null );

    LayoutVisibilityHook( {
        headerVisible: true,
        bottomNavBarVisible: 'isTabletOrMobile'
    } );

    useEffect( () => {
        const subscription = isFreelancerSelectedStore$.subscribe( ( value ) => {
            setFreelancerIdSelected( value );
        } );

        return () => subscription.unsubscribe();
    }, [] );

    const handleHire = () => modalRef.current?.open();

    const handleOnCloseNewProjectDrawer = () => modalRef.current?.close();

    const handleCloseProfileDrawer = () => setFreelancerSelectedStore( null );

    return (
        <>
            <ClientFreelancerListNotRecommended />
            <ClientFreelancerProfileDrawer freelancerId={ freelancerIdSelected }
                                           onHire={ () => handleHire() }
                                           onCloseDrawer={ () => handleCloseProfileDrawer() } />

            <NewProjectModal onClose={ () => handleOnCloseNewProjectDrawer() }
                             freelancer={ {
                                 id: freelancerIdSelected
                             } } />
        </>
    );
}

