import ChannelItem from '@/components/ChannelItem';
import Paragraphs from '@/components/Paragraphs';
import ChatConversationProjectStatusTag from '@/components/chat/components/chat-conversation/chat-conversation-messages/components/chat-conversation-items/components/ChatConversationProjectStatusTag';
import { ConversationItemOffer } from '@/components/chat/interfaces/conversationItemItemOffer';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useRef } from 'react';
import ChatConversationDeclineProjectModal from '@/components/chat/components/chat-conversation/chat-conversation-decline-project-modal/ChatConversationDeclineProjectModal';
import { ProjectStatusEnum } from '@/gql/graphql';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import FreelancerChatQuoteModal from '@/pages/freelancer/views/chat/components/freelancer-chat-quote/FreelancerChatQuoteModal';

interface ChatConversationOfferProps {
    item: ConversationItemOffer;
    isCurrentUser: boolean;
}

export default function ChatConversationItemOffer( { item, isCurrentUser }: ChatConversationOfferProps ) {

    const { t } = useTranslation();
    const navigate: NavigateFunction = useNavigate();
    const isTabletOrMobile: boolean = useMediaQuery( { query: `(max-width: ${ import.meta.env.VITE_MOBILE_BREAK_POINT })` } );
    const quoteModalRef = useRef( null );
    const declineModalRef = useRef( null );

    const handleOfferPrice = () => {
        if( isTabletOrMobile ) {
            navigate( `quote/${ item.projectId }` );
        }
        else {
            quoteModalRef?.current?.open();
        }

    };

    const handleDecline = () => {
        declineModalRef?.current.open();
    };

    return (
        <>
            <div className="flex flex-col min-w-[300px]">

                <div className="w-fit">
                    <ChatConversationProjectStatusTag status={ item.status } />
                </div>

                <h4 className="font-extrabold text-lg lg:text-[22px] mt-3 lg:mt-5">
                    { item.name }
                </h4>

                {
                    item?.channel != null ?
                    <div className="mt-4">
                        <ChannelItem channel={ item.channel }
                                     infoClass="lg:text-xs"
                                     avatarSize={ 32 }
                                     titleClass="lg:text-base" />
                    </div> :
                    null
                }

                <div className="mt-3">
                    <Paragraphs text={ item.description }
                                className="text-sm lg:text-base"
                                classNameShowMoreButton="text-black underline text-xs lg:text-sm"
                                showLessButtonText={ t( 'common:chat.conversation.offer.buttons.readLess' ) }
                                showMoreButtonText={ t( 'common:chat.conversation.offer.buttons.readMore' ) }
                                maxLength={ 300 } />
                </div>

                {
                    !isCurrentUser &&
                    item.status === ProjectStatusEnum.PENDING &&
                    <div className="flex gap-4 mt-4">
                      <Button type="primary"
                              size="small"
                              className="w-1/2"
                              onClick={ () => handleOfferPrice() }>
                          { t( 'common:chat.conversation.buttonOfferPrice' ) }
                      </Button>

                      <Button type="default"
                              size="small"
                              className="w-1/2"
                              onClick={ () => handleDecline() }>
                          { t( 'common:chat.conversation.buttonDeclineOffer' ) }
                      </Button>
                    </div>
                }
            </div>
            <ChatConversationDeclineProjectModal ref={ declineModalRef } offerItem={ item } />
            <FreelancerChatQuoteModal ref={ quoteModalRef }
                                      projectId={ item.projectId } />
        </>
    );
}