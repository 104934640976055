import React from 'react';
import FreelancerActionItemLayout from './FreelancerActionItemLayout';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import { Action } from '@/gql/graphql';

interface QuotePendingAnswerItemProps {
    action: Action;
}

const QuotePendingAnswerItem: React.FC<QuotePendingAnswerItemProps> = ({ action }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <div className='relative'>
            <div className="bg-zinc-800 h-10 w-[3px] absolute left-0 top-4" />
            <FreelancerActionItemLayout 
                title={ t( 'freelancer:dashboard.actionCenter.action.quotePendingAnswerTitle' ) }
                datetime={ action.createdAt }
                clientProfilePicture={ action.project.client?.profilePicture }>
                <div className="flex flex-col gap-4">
                    <div className='text-slate-600'>
                        <Trans 
                            i18nKey="freelancer:dashboard.actionCenter.action.quotePendingAnswerDesc" 
                            components={[<strong className='text-gray-600' />, <strong className='text-gray-600' />]} 
                            values={{
                                creatorName: action.project?.client.userName,
                                projectTitle: action.project?.name 
                            }} 
                        />
                    </div>
                    <Button type='default' className='w-fit text-primary-500' onClick={ () => navigate(`/freelancer/messages/${action.project.conversationId}?messageId=${action.quote.messageId}`) }>
                        { t( 'freelancer:dashboard.actionCenter.action.button.goToQuote' ) }
                    </Button>
                </div>
            </FreelancerActionItemLayout>
        </div>
    );
}

export default QuotePendingAnswerItem;
