import { gql } from '@apollo/client';

export const CreatePortfolioChannelGQL = gql`
    mutation CreatePortfolioChannelGQL($url: String!) {
        createPortfolioChannel(url: $url) {
            id
            name
            videoCount
            viewCount
            subscriberCount
            category
            thumbnailUrl
            freelancerToPortfolioChannels {
                id
                order
            }
        }
    }
`;