import { useLazyQuery, useMutation } from '@apollo/client';
import { CheckIfCustomUrlExistsGQL } from '@/gql/global-queries/checkIfCustomUrlExistsGQL';
import { CreateCustomUrlGQL } from '@/gql/global-queries/createCustomUrlGQL';
import * as Sentry from '@sentry/react';
import { useTranslation } from 'react-i18next';
import { App } from 'antd';

export function customUrlHook() {
    const { t } = useTranslation();
    const { message } = App.useApp();

    const [ checkIfCustomUrlExistsQuery, { data: checkResult } ] = useLazyQuery( CheckIfCustomUrlExistsGQL );
    const [ createCustomUrlMutation ] = useMutation( CreateCustomUrlGQL );

    const checkIfCustomUrlExists = async( url ) => {
        try {
            const { data } = await checkIfCustomUrlExistsQuery( {
                variables: {
                    customUrl: url
                }
            } );

            return data.checkIfCustomUrlExists;
        } catch( err ) {
            Sentry.captureException( 'An error occur when trying to check url', err );
            message.error( t( 'common:form.submitError' ) );
        }
    };

    const createCustomUrl = async( url ) => {
        try {
            const { data } = await createCustomUrlMutation( {
                variables: { url }
            } );
            return data;
        } catch( err ) {
            Sentry.captureException( 'An error occur when trying to create url', err );
            message.error( t( 'common:form.submitError' ) );
        }
    };

    return {
        checkIfCustomUrlExists,
        createCustomUrl
    };
}
