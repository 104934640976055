import { Modal } from 'antd';
import React, { useContext } from 'react';
import { ColorSchemeContext } from '@/core/dark-mode/colorSchemeProvider';
import { useTranslation } from 'react-i18next';
import ChatConversationDeclineProjectModalForm from '@/components/chat/components/chat-conversation/chat-conversation-decline-project-modal/ChatConversationDeclineProjectModalForm';
import { ConversationItemOffer } from '@/components/chat/interfaces/conversationItemItemOffer';

interface ChatConversationDeclineModalDesktopProps {
    offerItem: ConversationItemOffer;
    isOpen: boolean;

    onCloseModal(): void;
}

export default function ChatConversationDeclineProjectModalDesktop( {
                                                                 offerItem,
                                                                 isOpen,
                                                                 onCloseModal
                                                             }: ChatConversationDeclineModalDesktopProps ) {
    const colorSchemeContext = useContext( ColorSchemeContext );
    const { t } = useTranslation();

    const handleOnCloseModal = () => onCloseModal?.();

    return (
        <Modal open={ isOpen }
               rootClassName={ colorSchemeContext.toLowerCase() }
               width={ 668 }
               destroyOnClose={ true }
               onCancel={ () => handleOnCloseModal() }
               footer={ null }>
            <div className="h-full w-full flex flex-col">
                <div className="w-full">
                    <h1 className="font-extrabold text-[23px]">
                        { t( 'common:chat.conversation.declineOffer.title' ) }
                    </h1>
                    <p className="text-base font-semibold mt-4">
                        { t( 'common:chat.conversation.declineOffer.subtitle' ) }
                    </p>
                    <ChatConversationDeclineProjectModalForm offerItem={ offerItem }
                                                             onDecline={ () => onCloseModal() } />
                </div>
            </div>
        </Modal>
    );
}