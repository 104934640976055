import { SessionRepository } from '@/core/auth/sessionRepository';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Button, Divider, message, Skeleton } from 'antd';
import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import ProfilePictureUploader from '@/components/ProfilePictureUploader';
import { UpdateClientProfileGQL } from '@/pages/client/views/account/client-account-edit-profile/queries/updateClientProfileGQL';
import { ClientProfileGQL } from '@/pages/client/views/account/client-account-edit-profile/queries/clientProfileGQL';
import { ProfilePicturePreSignUrl } from '@/pages/client/views/account/client-account-edit-profile/queries/getProfilePicturePresignUrl';
import { useMediaQuery } from 'react-responsive';
import * as Sentry from '@sentry/react';
import InputComponent from '@/components/custom-element-form/InputComponent';
import { PencilOutlinedIcon } from '@/assets/icons/CustomIcons';
import { PageName, identifySegmentUser, trackPageView, userTypeName } from '@/utils/analytics';

export default function ClientAccountEditProfile() {

    const isTabletOrMobile = useMediaQuery( { query: `(max-width: ${ import.meta.env.VITE_MOBILE_BREAK_POINT })` } );
    const { t } = useTranslation();
    const sessionRepository: SessionRepository = new SessionRepository();
    const [ messageApi, contextHolder ] = message.useMessage();
    const [ isSaving, setIsSaving ] = useState( false );
    const fileUploaderRef: MutableRefObject<any> = useRef( null );

    const { data, loading, error } = useQuery( ClientProfileGQL, {
        variables: {
            id: sessionRepository.userId
        }
    } );

    const { setValue, trigger, control, watch, formState: { errors }, handleSubmit } = useForm();

    const updateClientProfileGQL = useMutation( UpdateClientProfileGQL );
    const getPreSignedUrl = useLazyQuery( ProfilePicturePreSignUrl );

    useEffect( () => {
        if( data ) {
            setValue( 'userName', data?.client.userName );
            setValue( 'email', data?.client.email );
        }
    }, [ data ] );

    useEffect( () => {
        trackPageView( PageName.ClientEditProfile );
    }, [ ] );

    const uploadProfilePicture = async(): Promise<string> => {
        try {
            const preSignedUrl = await getPreSignedUrl[ 0 ]( {
                variables: {
                    contentType: fileUploaderRef.current.getFile().type
                }
            } );
            return fileUploaderRef.current.upload( preSignedUrl.data.profilePicturePreSignUrl );
        } catch( e ) {
            Sentry.captureException( 'An error occur when trying to upload client profile picture', e );
            messageApi.error( t( 'error.default' ) );
        }
    };

    const onSubmit = async( data ): Promise<void> => {
        setIsSaving( true );
        if( !( await trigger() ) ) {
            return;
        }

        let profilePictureUrl: string;

        if( fileUploaderRef.current.getFile() != null ) {
            try {
                profilePictureUrl = await uploadProfilePicture();
            } catch( e ) {
                setIsSaving( false );
            }

        }

        try {
            const response = await updateClientProfileGQL[ 0 ]( {
                variables: {
                    args: {
                        userName: data.userName,
                        email: data.email,
                        profilePicture: profilePictureUrl
                    }
                }
            } );
            identifySegmentUser(data, userTypeName.Creator);
            sessionRepository.updateUser( response.data?.updateClient );
            messageApi.success( t( 'client:account.edit-profile.success' ) );
        } catch( e ) {
            Sentry.captureException( 'An error occur when trying to update client profile', e );
            messageApi.error( t( 'error.default' ) );
            setIsSaving( false );
        }
        setIsSaving( false );
    };

    const loadingJSX = (
        <Skeleton active />
    );

    const coreJSX = (
        <div className="w-full">
            <ProfilePictureUploader ref={ fileUploaderRef }
                                    defaultProfilePictureUrl={ data?.client.profilePicture }
                                    className={ isTabletOrMobile ? 'touch-uploader' : '' } />
            <Divider type="horizontal" />
            <form className="flex flex-col gap-4 w-full"
                  onSubmit={ handleSubmit( onSubmit ) }>

                <InputComponent control={ control }
                                name="userName"
                                label={ t( 'client:account.edit-profile.inputs.userName.label' ) }
                                suffix={ <PencilOutlinedIcon className="text-grey text-2xl" /> }
                                placeholder={ t( 'client:account.edit-profile.inputs.userName.placeholder' ) }
                                rules={ {
                                    required: t( 'common:error.form.required' )
                                } }
                                errors={ errors } />

                <InputComponent control={ control }
                                name="email"
                                label={ t( 'client:account.edit-profile.inputs.email.label' ) }
                                suffix={ <PencilOutlinedIcon className="text-grey text-2xl" /> }
                                placeholder={ t( 'client:account.edit-profile.inputs.email.placeholder' ) }
                                rules={ {
                                    required: t( 'common:error.form.required' ),
                                    pattern: {
                                        value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
                                        message: t( 'common:error.form.invalidEmail' )
                                    }
                                } }
                                errors={ errors } />

                <div className="flex lg:justify-end">
                    <Button type="primary"
                            htmlType="submit"
                            loading={ isSaving }
                            className="mt-4 w-full lg:w-[86px]">
                        { t( 'client:account.edit-profile.buttonSave' ) }
                    </Button>
                </div>
            </form>
        </div>
    );

    return (
        <>
            { contextHolder }
            { loading ? loadingJSX : coreJSX }
        </>
    );
}