import { useEffect, useRef, useState } from 'react';

import { Trans, useTranslation } from 'react-i18next';
import { FieldValues, useForm } from 'react-hook-form';
import { Form } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { Button, message } from 'antd';
import * as Sentry from '@sentry/react';

import { VATRateFreelancerGQL } from '@/pages/freelancer/views/chat/components/freelancer-chat-quote/services/VATRateFreelancerGQL';
import ChatQuoteAboutFee from '@/pages/freelancer/views/chat/components/freelancer-chat-quote/components/ChatQuoteAboutFee';
import ChatQuoteConfirmation from '@/pages/freelancer/views/chat/components/freelancer-chat-quote/components/ChatQuoteConfirmation';
import { CreateQuoteGQL } from '@/pages/freelancer/views/chat/components/freelancer-chat-quote/services/createQuoteGQL';
import FreelancerCharterModalDrawer from '@/components/freelancer-charter/components/FreelancerCharterModalDrawer';
import InputComponent from '@/components/custom-element-form/InputComponent';
import DatePickerComponent from '@/components/custom-element-form/DatePickerComponent';
import { SegmentEvent, trackSegmentEvent } from '@/utils/analytics';

interface FreelancerChatQuoteFormProps {
    projectId: string;
    onSubmit?: () => void;
}

export default function FreelancerChatQuoteForm( { projectId, onSubmit }: FreelancerChatQuoteFormProps ) {

    const { t } = useTranslation();
    const { getValues, setValue, trigger, control, watch, formState: { errors } } = useForm();
    const feeRate: number = +( import.meta.env.VITE_FEE_RATE );

    const [ remuneration, setRemuneration ] = useState( 0 );
    const [ messageApi, contextHolder ] = message.useMessage();

    const charterModalDrawerRef = useRef( null );
    const chatQuoteConfirmationRef = useRef( null );
    const chatQuoteAboutFeeRef = useRef( null );

    const vatRateQuery = useQuery( VATRateFreelancerGQL );
    const createQuoteMutation = useMutation( CreateQuoteGQL );


    useEffect( () => {
        if( vatRateQuery?.data ) {
            setValue( 'vatRate', vatRateQuery?.data?.meFreelancer.vatRate );
        }
    }, [ vatRateQuery?.data ] );

    const handleSubmitDemand = async(): Promise<void> => {
        if( !( await trigger() ) ) {
            return;
        }

        if( chatQuoteConfirmationRef.current ) {
            chatQuoteConfirmationRef.current.open();
        }
    };

    const handleSubmitQuote = async(): Promise<void> => {
        const values: FieldValues = getValues();
        try {
            const response = await createQuoteMutation[ 0 ]( {
                variables: {
                    args: {
                        projectId,
                        amount: +values.priceOffer,
                        vatRate: +values.vatRate,
                        dueAt: values.dueAt
                    }
                }
            } );

            if( response.data ) {                
                trackSegmentEvent(
                    SegmentEvent.QuoteSent,
                    {
                        quote_sent_amount: +values.priceOffer * ( 1 + ( values.vatRate / 100 ) ),
                        project_id : projectId,
                        project_creator: response.data?.createQuote?.project?.client.id,
                        project_receiver: response.data?.createQuote?.project?.freelancer.id
                    }
                );
            }
            onSubmit?.();
        } catch( e ) {
            Sentry.captureException( 'An error occur when trying to create a new quote', e );
            messageApi.error( t( 'translation:error.default' ) );
        }
    };

    useEffect( () => {
        setValue( 'remuneration', remuneration );
    }, [ remuneration ] );

    useEffect( () => {
        const subscription = watch( ( value, { name, type } ) => {
            const priceOffer = value.priceOffer;
            const vatRate = value.vatRate;

            if( priceOffer <= 0 ) {
                setValue( 'priceOffer', 0 );
            }
            else {
                if( priceOffer ) {
                    if( vatRate <= 0 ) {
                        setRemuneration( priceOffer - priceOffer * ( feeRate / 100 ) );
                    }
                    else {
                        const vatPrice = priceOffer * ( 1 + vatRate / 100 );
                        setRemuneration( vatPrice - vatPrice * ( feeRate / 100 ) );
                    }
                }
                else {
                    setRemuneration( 0 );
                }
            }
        } );
        return () => subscription.unsubscribe();
    }, [ watch ] );

    const handleOpenAboutFee = (): void => {
        if( chatQuoteAboutFeeRef.current ) {
            chatQuoteAboutFeeRef.current.open();
        }
    };

    return (
        <>
            { contextHolder }
            <Form className="flex flex-col gap-4 w-full">

                <InputComponent control={ control }
                                name="priceOffer"
                                label={ t( 'freelancer:chat.quote.inputs.price.label' ) }
                                placeholder={ t( 'freelancer:chat.quote.inputs.price.placeholder' ) }
                                suffix={ '$' }
                                rules={ {
                                    required: t( 'common:error.form.required' )
                                } }
                                errors={ errors }
                                min={ 0 } />

                <InputComponent control={ control }
                                name="vatRate"
                                label={ t( 'freelancer:chat.quote.inputs.taxes.label' ) }
                                tooltipLabel={ t( 'freelancer:chat.quote.inputs.taxes.tooltip' ) }
                                placeholder={ t( 'freelancer:chat.quote.inputs.taxes.placeholder' ) }
                                suffix={ '%' }
                                rules={ {
                                    max: {
                                        value: 27,
                                        message: t( 'freelancer:chat.quote.inputs.taxes.error.max' )
                                    },
                                    min: {
                                        value: 0,
                                        message: t( 'freelancer:chat.quote.inputs.taxes.error.min' )
                                    },
                                    required: t( 'common:error.form.required' )
                                } }
                                errors={ errors }
                                min={ 0 } />

                <div>
                    <InputComponent control={ control }
                                    name="remuneration"
                                    label={ t( 'freelancer:chat.quote.inputs.remuneration.label' ) }
                                    placeholder={ t( 'freelancer:chat.quote.inputs.remuneration.placeholder' ) }
                                    suffix={ '$' }
                                    disabled={ true } />
                    <p className="mt-2 text-primary-500 underline text-xs font-medium cursor-pointer"
                       onClick={ handleOpenAboutFee }>
                        { t( 'freelancer:chat.quote.fees' ) }
                    </p>
                </div>

                <div>
                    <DatePickerComponent control={ control }
                                         name="dueAt"
                                         placeholder={ t( 'freelancer:chat.quote.inputs.dueDate.placeholder' ) }
                                         label={ t( 'freelancer:chat.quote.inputs.dueDate.label' ) }
                                         errors={ errors }
                                         disablePreviousDate
                                         rules={ {
                                             required: t( 'common:error.form.required' )
                                         } } />
                    <p className="mt-2 text-xs">
                        <Trans i18nKey="freelancer:chat.quote.inputs.dueDate.description">
                            <span className="text-grey"></span>
                            <span></span>
                        </Trans>
                    </p>
                </div>

                <Button type="primary"
                        size="middle"
                        loading={ createQuoteMutation[ 1 ].loading }
                        onClick={ () => handleSubmitDemand() }
                        className="w-full mt-6">
                    { t( 'freelancer:chat.quote.submit' ) }
                </Button>

                <p className="text-sm"
                   onClick={ () => charterModalDrawerRef.current.open() }>
                    <Trans i18nKey="freelancer:chat.quote.charter">
                        <span className="text-grey"></span>
                        <span className="text-primary-500 cursor-pointer"></span>
                    </Trans>
                </p>
            </Form>
            <ChatQuoteAboutFee ref={ chatQuoteAboutFeeRef } />
            <ChatQuoteConfirmation ref={ chatQuoteConfirmationRef }
                                   onConfirm={ () => handleSubmitQuote() } />
            <FreelancerCharterModalDrawer ref={ charterModalDrawerRef } />
        </>
    );

}