import { createStore, select, withProps } from '@ngneat/elf';
import { addEntities, getActiveEntity, getAllEntities, getEntity, hasEntity, selectActiveEntity, selectAllEntities, selectEntities, setActiveId, setEntities, UIEntitiesRef, unionEntities, updateEntities, withActiveId, withEntities, withUIEntities } from '@ngneat/elf-entities';
import { map, Observable } from 'rxjs';
import { ItemTypeEnum } from '@/components/chat/enums/itemTypeEnum';
import { ConversationItemMessage } from '@/components/chat/interfaces/conversationItemMessage';
import { DateTime } from 'luxon';
import { ConversationItemOffer } from '@/components/chat/interfaces/conversationItemItemOffer';
import { ConversationItemQuote } from '@/components/chat/interfaces/conversationItemQuote';
import { ConversationItemAction } from '@/components/chat/interfaces/conversationItemAction';

export interface Conversation {
    id: string;
    receiver: {
        id: string;
        userName: string;
        avatar: string;
    };
    unreadMessageCount: number;
    lastMessage: string;
    lastMessageAt: string;
    conversationItems: ( ConversationItemMessage | ConversationItemOffer | ConversationItemQuote | ConversationItemAction )[];
}

export interface ConversationUI {
    id: string;
    isOnline: boolean;
    isTyping: boolean;
    status: any;
}

interface ConversationFiltersProps {
    search: string;
    totalUnreadMessageCount: number;
    isLoadingConversations: boolean;
}

const defaultFilters: ConversationFiltersProps = {
    search: null,
    totalUnreadMessageCount: 0,
    isLoadingConversations: true
};

const chatStore = createStore(
    { name: 'chat' },
    withEntities<Conversation>(),
    withUIEntities<ConversationUI>(),
    withActiveId(),
    withProps<ConversationFiltersProps>( defaultFilters )
);

export class ChatRepository {

    conversations$ = chatStore.combine( {
                                  entities: chatStore.pipe( selectAllEntities() ),
                                  UIEntities: chatStore.pipe( selectEntities( { ref: UIEntitiesRef } ) )
                              } )
                              .pipe( unionEntities() );

    totalUnreadMessageCount$: Observable<number> = chatStore.pipe( select( ( state ) => state.totalUnreadMessageCount ) );

    activeConversation$ = chatStore.pipe(
        selectActiveEntity(),
        map( ( value ) => {
            if( value == null ) {
                return null;
            }
            value?.conversationItems.filter( ( item ) => item != null && item.type == ItemTypeEnum.MESSAGE && ( ( item as ConversationItemMessage ).message !== null || ( item as ConversationItemMessage ).message !== '' ) );
            return value;
        } ),
        map( ( value ) => {
                if( value == null ) {
                    return null;
                }
                value.conversationItems = value?.conversationItems.sort( ( a, b ) => {
                    return DateTime.fromISO( a.sentAt ) < DateTime.fromISO( b.sentAt ) ? 1 : -1;
                } );
                return value;
            }
        )
    );

    activeConversationUI$ = chatStore.pipe( selectActiveEntity( { ref: UIEntitiesRef } ) );
    activeIsOnline$ = chatStore.pipe( selectActiveEntity( { ref: UIEntitiesRef } ), map( ( value ) => value?.isOnline || false ) );
    activeIsTyping$ = chatStore.pipe( selectActiveEntity( { ref: UIEntitiesRef } ), map( ( value ) => value?.isTyping || false ) );
    filters$ = chatStore.pipe( select( ( state ) => {
            return {
                search: state.search
            };
        } )
    );
    isLoadingConversations$ = chatStore.pipe( select( ( state ) => state.isLoadingConversations ) );

    get activeConversation() {
        return chatStore.query( getActiveEntity() );
    }

    get activeConversationUI() {
        return chatStore.query( getActiveEntity( { ref: UIEntitiesRef } ) );
    }

    get conversations() {
        return chatStore.query( getAllEntities() );
    }

    set activeConversationId( id: string ) {
        chatStore.update( setActiveId( id ) );
    }

    set isLoadingConversations( isLoadingConversations: boolean ) {
        chatStore.update( ( state ) => ( {
            ...state,
            isLoadingConversations
        } ) );
    }

    get totalUnreadMessageCount() {
        return chatStore.query( ( state ) => state.totalUnreadMessageCount );
    }

    set totalUnreadMessageCount( totalUnreadMessageCount: number ) {
        chatStore.update( ( state ) => ( {
            ...state,
            totalUnreadMessageCount
        } ) );
    }

    getConversationItemsOfConversation( conversationId: string ) {
        return chatStore.query( getEntity( conversationId ) ).conversationItems;
    }

    getPreviousLastConversationItemId( limit: number ) {
        const conversationItems = this.activeConversation.conversationItems;
        if( limit >= conversationItems.length ) {
            return null;
        }
        return conversationItems[ conversationItems.length - limit ].id;
    }

    setUserOnlineStatus( userId: string, isOnline: boolean ) {
        const conversations = chatStore.query( getAllEntities() );
        const conversation = conversations.find( ( conversation ) => conversation.receiver.id === userId );
        if( conversation ) {
            chatStore.update( updateEntities( conversation.id, ( entity ) => ( {
                ...entity,
                isOnline
            } ), {
                ref: UIEntitiesRef
            } ) );
        }
    }

    setUserTypingStatus( userId: string, isTyping: boolean ) {
        const conversations = chatStore.query( getAllEntities() );
        const conversation = conversations.find( ( conversation ) => conversation.receiver.id === userId );
        if( conversation ) {
            chatStore.update( updateEntities( conversation.id, ( entity ) => ( {
                ...entity,
                isTyping
            } ), {
                ref: UIEntitiesRef
            } ) );
        }
    }

    markAsReadConversation( conversationId: string ) {
        const unreadConversationMessage: number = this.activeConversation.unreadMessageCount;
        this.markAsReadConversationMessages( conversationId );
        this.removeTotalUnreadMessageCount( unreadConversationMessage );
    }

    markAsReadConversationMessages( conversationId: string ) {
        chatStore.update( updateEntities( conversationId, ( entity ) => ( {
            ...entity,
            unreadMessageCount: 0
        } ) ) );
    }

    removeTotalUnreadMessageCount( count: number ) {
        chatStore.update( ( state ) => {
            let newCount: number = state.totalUnreadMessageCount - count;
            if( newCount < 0 ) {
                newCount = 0;
            }

            return {
                ...state,
                totalUnreadMessageCount: newCount
            };
        } );
    }

    addUnreadMessageCountInConversation( conversationId: string, count: number ) {
        chatStore.update( updateEntities( conversationId, ( entity ) => ( {
            ...entity,
            unreadMessageCount: entity.unreadMessageCount + count
        } ) ) );
    }

    addTotalUnreadMessageCount( count: number ) {
        chatStore.update( ( state ) => ( {
            ...state,
            totalUnreadMessageCount: state.totalUnreadMessageCount + count
        } ) );
    }

    addUnreadMessageCount( conversationId: string, count: number ) {
        this.addUnreadMessageCountInConversation( conversationId, count );
        this.addTotalUnreadMessageCount( count );
    }

    setConversations( conversations: Conversation[], conversationsUI: ConversationUI[] ) {
        chatStore.update( setEntities( conversations ) );
        chatStore.update( setEntities( conversationsUI, { ref: UIEntitiesRef } ) );
    }

    addOrUpdateConversations( conversations: Conversation[], conversationsUI: ConversationUI[] ) {
        conversations.map( ( conversation ) => {
            this.addOrUpdateConversation( conversation, conversationsUI.find( ( conversationUI ) => conversationUI.id === conversation.id ) );
        } );
    }

    addOrUpdateConversation( conversation: Conversation, conversationUI?: ConversationUI ) {
        if( chatStore.query( hasEntity( conversation.id ) ) ) {
            chatStore.update( updateEntities( conversation.id, ( entity ) => ( {
                ...entity,
                conversation: {
                    ...conversation,
                    conversationItems: [ ...entity.conversationItems, ...conversation.conversationItems ]
                }
            } ) ) );
        }
        else {
            chatStore.update( addEntities( conversation ) );

            if( conversationUI ) {
                chatStore.update( addEntities( conversationUI, { ref: UIEntitiesRef } ) );
            }
        }
    }

    addConversation( conversation: Conversation ) {
        chatStore.update( addEntities( conversation ) );
    }

    addConversationItems( conversationId: string,
                          conversationItems: ( ConversationItemMessage | ConversationItemOffer | ConversationItemQuote | ConversationItemAction )[] ) {
        if( !chatStore.query( hasEntity( conversationId ) ) ) {
            throw new Error( `Conversation ${ conversationId } not found` );
        }

        const items = this.getConversationItemsOfConversation( conversationId );
        const filteredItems = conversationItems.filter( ( item ) => !items.find( ( i ) => i.id === item.id ) );

        chatStore.update( updateEntities( conversationId, ( entity ) => ( {
            ...entity,
            conversationItems: [ ...entity.conversationItems, ...filteredItems ]
        } ) ) );
    }

    setLastMessage( conversationId: string, message: string ) {
        if( !chatStore.query( hasEntity( conversationId ) ) ) {
            throw new Error( `Conversation ${ conversationId } not found` );
        }
        chatStore.update( updateEntities( conversationId, ( entity ) => ( {
            ...entity,
            lastMessage: message
        } ) ) );
    }

    updateConversationItem( conversationId: string, conversationItemMessage: ConversationItemMessage ): void {
        if( !chatStore.query( hasEntity( conversationId ) ) ) {
            throw new Error( `Conversation ${ conversationId } not found` );
        }
        const conversationItems = this.getConversationItemsOfConversation( conversationId );
        const index = conversationItems.findIndex( ( item ) => item.id === conversationItemMessage.id );

        if( index === -1 ) {
            throw new Error( `Conversation item ${ conversationItemMessage.id } not found` );
        }

        chatStore.update( updateEntities( conversationId, ( entity ) => ( {
            ...entity,
            conversationItems: [
                ...conversationItems.slice( 0, index ),
                conversationItemMessage,
                ...conversationItems.slice( index + 1 )
            ]
        } ) ) );
    }

    addOrUpdateProfilePictures( conversationId: string, profilePicture: string ): void {
        if( !chatStore.query( hasEntity( conversationId ) ) ) {
            throw new Error( `Conversation ${ conversationId } not found` );
        }

        chatStore.update( updateEntities( conversationId, ( entity ) => ( {
            ...entity,
            receiver: {
                ...entity.receiver,
                avatar: profilePicture
            }
        } ) ) );
    }
}