import { useTranslation } from 'react-i18next';
import channelsHook from '@/hooks/channelsHook';
import { useContext } from 'react';
import ListSkeleton from '@/components/ListSkeleton';
import ChannelItem from '@/components/ChannelItem';
import { Button, Divider, Modal } from 'antd';
import { PlusOutlinedIcon } from '@/assets/icons/CustomIcons';
import { Channel } from '@/gql/graphql';
import { ColorSchemeContext } from '@/core/dark-mode/colorSchemeProvider';

interface ChannelSwitcherDesktopProps {
    isOpen: boolean;
    onClose?: () => void;

    onSelect( channel: Channel ): void;
}

export default function ChannelSwitcherDesktop( {
                                                    isOpen,
                                                    onSelect,
                                                    onClose
                                                }: ChannelSwitcherDesktopProps ) {

    const { t } = useTranslation();

    const { loading, channels, addYTChannelHook, handleAddChannel } = channelsHook();
    const colorSchemeContext = useContext( ColorSchemeContext );

    const handleClose = () => {
        if( onClose ) {
            onClose();
        }
    };

    const loadingJSX = (
        <ListSkeleton isLoading={ true }
                      height={ 70 }
                      count={ 3 } />
    );

    const loadingNewYTChannelJSX = (
        <ListSkeleton isLoading={ true }
                      height={ 50 }
                      count={ 1 } />
    );

    const coreJSX = (
        <>
            {
                channels?.map( ( channel, index ) => {
                    return (
                        <div className="group border-2 border-primary-100 cursor-pointer hover:bg-primary-100 rounded-3xl px-4 py-2"
                             key={ index }>
                            <ChannelItem channel={ channel }
                                         avatarSize={ 50 }
                                         onClick={ ( channel ) => onSelect( channel ) }
                                         key={ index } />
                        </div>
                    );
                } )
            }
            {
                addYTChannelHook.loading ? loadingNewYTChannelJSX : null
            }
        </>
    );

    return (
        <Modal open={ isOpen }
               rootClassName={ colorSchemeContext.toLowerCase() }
               width={ 668 }
               title={ t( 'transverse:new-project.updateChannel.title' ) }
               destroyOnClose={ true }
               onCancel={ () => handleClose() }
               footer={ null }>
            <Divider className="w-full" />
            <div className="h-full w-full flex flex-col gap-y-4">
                {
                    loading ? loadingJSX : coreJSX
                }
                <div className="flex justify-end mt-4">
                    <Button type="primary"
                            onClick={ () => handleAddChannel() }
                            loading={ addYTChannelHook.loading }
                            className="mt-4 w-full lg:w-[300px]">
                        <PlusOutlinedIcon /> { t( 'client:account.youtube-channels.button' ) }
                    </Button>
                </div>
            </div>
        </Modal>
    );
}
