import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AnimatedNumber from '@/components/AnimatedNumber';
import { BankNoteOutlinedIcon, StarFilledIcon, FilmFilledIcon } from '@/assets/icons/CustomIcons';
import SkeletonInput from 'antd/es/skeleton/Input';

interface FreelancerDashboardStatsProps {
    stats: {
        countProjectsDone: number;
        moneyEarned: number;
        avgRating: number;
    };
    title: string,
    isLoading: boolean
}

export default function FreelancerDashboardStats( { stats, title, isLoading }: FreelancerDashboardStatsProps ) {

    const { t } = useTranslation();

    const [ _stats, setStats ] = useState( [] );

    useEffect( () => {
        if( stats ) {
            const data = [
                {
                    label: t( 'freelancer:dashboard.stats.projectsTitle' ),
                    value: stats.countProjectsDone,
                    icon: <FilmFilledIcon className='text-[20px] text-slate-300' />
                },
                {
                    label: t( 'freelancer:dashboard.stats.ratingsTitle' ),
                    value: stats.avgRating,
                    icon: <StarFilledIcon className='text-[20px] text-slate-300' />
                },
                {
                    label: t( 'freelancer:dashboard.stats.earningsTitle' ),
                    value: stats.moneyEarned,
                    prefix: '$',
                    icon: <BankNoteOutlinedIcon className='text-[20px] text-slate-300' />
                }
            ];
            setStats( data );
        }
    }, [ stats ] );

    if( isLoading ) {
        return (
            <div className="flex flex-col items-center gap-y-12">
                <SkeletonInput active={ true }
                    className="w-full h-[70px] rounded-2xl" />
            </div>
        )
    }


    return (
        <div>
            <div className='mb-3'>{ title }</div>
            <div className="flex justify-between gap-4">
                {
                    _stats.map( ( stat, index ) => (
                        <div key={ index }
                            className="flex flex-col gap-2">
                            <div className='flex items-center gap-4'>
                                <span className="text-grey">
                                    { stat.label }
                                </span>
                            </div>
                            <div className='flex gap-2 max-w-12'>
                                { stat.icon }
                                {
                                    stat.value ?
                                        <div className='flex text-base'>
                                            { stat.prefix }
                                            <AnimatedNumber targetValue={ stat.value } />
                                        </div>
                                        : <span className="text-base">{t( 'freelancer:dashboard.stats.noProject' )}</span>
                                }
                            </div>
                        </div>
                    ) )
                }
            </div>
        </div>
    );
}