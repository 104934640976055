import { Divider } from 'antd';
import ChatConversationSendMessage from '@/components/chat/components/chat-conversation/chat-conversation-messages/components/ChatConversationSendMessage';
import ChatConversationMessagesList from '@/components/chat/components/chat-conversation/chat-conversation-messages/ChatConversationMessagesList';
import ChatConversationHeaderReceiver from '@/components/chat/components/chat-conversation/chat-conversation-messages/components/ChatConversationHeaderReceiver';
import { ConversationItem } from '@/components/chat/interfaces/conversationItem';
import { ConversationReceiverProfileUI } from '@/components/chat/interfaces/conversationReceiverProfileUI';
import { ReactNode, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { twMerge } from 'tailwind-merge';

interface FreelancerConversationDesktopProps {
    conversationItems: ConversationItem[];
    customHeader?: ReactNode;
    receiver: ConversationReceiverProfileUI;
    enableClickOnProfile?: boolean;
    hasMore: boolean;
    isReceiverTyping: boolean;
    isOnline: boolean;
    isLoading: boolean;
    handleOnLoadMore: () => void;
    handleOnMessageSend: ( e: any ) => void;
    handleOnTypingChange: ( e: any ) => void;
}

export default function ChatConversation( {
                                                     conversationItems,
                                                     customHeader,
                                                     receiver,
                                                     enableClickOnProfile = false,
                                                     hasMore,
                                                     isReceiverTyping,
                                                     isOnline,
                                                     isLoading,
                                                     handleOnLoadMore,
                                                     handleOnMessageSend,
                                                     handleOnTypingChange
                                                 }: FreelancerConversationDesktopProps ) {

    const isTabletOrMobile = useMediaQuery( { query: `(max-width: ${ import.meta.env.VITE_MOBILE_BREAK_POINT })` } );

    const [ keyboardOpen, setKeyboardOpen ] = useState( false );

    const refreshKeyboardState = () => {
        setKeyboardOpen( true );
        setKeyboardOpen( false );
    };

    return (
        <div className="flex-grow flex flex-col h-full">
            <div className="flex-none px-6 fixed lg:relative z-10 flex items-center justify-center top-0 left-0 right-0 h-[70px] bg-white">
                <ChatConversationHeaderReceiver profile={ receiver }
                                                isOnline={ isOnline }
                                                enableClickOnProfile={ enableClickOnProfile }
                                                customHeader={ customHeader }
                                                size={ isTabletOrMobile ? 'small' : 'large' } />
            </div>
            <div className="flex-grow w-full h-full max-h-screen pt-[80px] pb-[80px] lg:pt-4 lg:pb-0 lg:max-h-[calc(100vh_-_365px)]">
                <Divider className="m-0"></Divider>
                <ChatConversationMessagesList key={ receiver.id }
                                              conversationItems={ conversationItems }
                                              hasMore={ hasMore }
                                              isTyping={ isReceiverTyping }
                                              isLoading={ isLoading }
                                              onLoadMore={ handleOnLoadMore } />
            </div>
            <div className={ twMerge( keyboardOpen
                                      ? 'sticky'
                                      : 'fixed', 'bottom-0 left-0 right-0 pb-4 lg:pb-0 bg-white px-4 lg:flex-none lg:px-5 lg:relative' ) }>
                <ChatConversationSendMessage onMessageSend={ ( e ) => handleOnMessageSend( e ) }
                                             onTypingChange={ ( e ) => handleOnTypingChange( e ) }
                                             onLooseFocus={ () => refreshKeyboardState() }
                                             onFocus={ () => refreshKeyboardState() } />
            </div>
        </div>
    );
}