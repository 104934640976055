import { gql } from '@apollo/client';

export const createClientWithGoogleGQL = gql`
    mutation createClientWithGoogleGQL($args: CreateClientWithGoogleInput!) {
        createClientWithGoogle(args: $args) {
            client {
                id
                email
                type
                userName
            }
            token {
                accessToken
                refreshToken
                cometchatAuthToken
            }
        }
    }
`;
