import { useLazyQuery } from '@apollo/client';
import { freelancerStatsGQL } from '@/gql/global-queries/freelancerStatsGQL';

export const freelancerStatsHook = () => {
    const [load, { loading, error, data }] = useLazyQuery( freelancerStatsGQL );

    return {
        load,
        loading,
        error,
        data : data?.getFreelancerStats
    };
};
