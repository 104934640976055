import { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';

import { FreelancersRepository } from '@/components/freelancers-filter-items/freelancersRepository';
import { publicFreelancersGQL } from '@/gql/global-queries/publicFreelancersGQL';
import { useObservable } from '@ngneat/react-rxjs';

interface PublicFreelancersHookProps {
    freelancersRepository: FreelancersRepository;
}

export default function publicFreelancersHook( props: PublicFreelancersHookProps ) {

    const { freelancersRepository } = props;

    const [ freelancers ] = useObservable( freelancersRepository.freelancers$ );
    const [ countFilterActive] = useObservable( freelancersRepository.updatedFiltersCount$ );
    const [ totalFreelancers ] = useObservable( freelancersRepository.totalFreelancers$ );
    const [ hasMore ] = useObservable( freelancersRepository.hasMore$ );

    const freelancersQuery = useLazyQuery( publicFreelancersGQL, {
        notifyOnNetworkStatusChange: true,
    } );

    useEffect( () => {
        if( freelancersQuery[ 1 ].data ) {
            const result = freelancersQuery[ 1 ].data.publicFreelancers;
            freelancersRepository.addFreelancers( result.data, result.page, result.total, result.lastPage );
        }
    }, [ freelancersQuery[ 1 ].data ] );

    const load = async (): Promise<void> => {
        if( freelancers.length > 0 ) {
            return;
        }

        await freelancersQuery[ 0 ]( {
            variables: {
                args: {
                    ...freelancersRepository.getFiltersForQuery()
                },
                limit: freelancersRepository.perPage,
                page: 1
            }
        } );
    };

    const loadMore = async ( { resetFilters = false, resetPage = false, resetData = false } = {} ): Promise<void> => {
        if( resetFilters ) {
            freelancersRepository.resetFilters();
        }

        if( resetPage ) {
            freelancersRepository.setPage( 0 );
        }

        if( resetData ) {
            freelancersRepository.resetFreelancers();
        }

        if( freelancersQuery[ 1 ]?.data?.publicFreelancers?.page != null ) {
            await freelancersQuery[ 1 ].refetch( {
                args: {
                    ...freelancersRepository.getFiltersForQuery()
                },
                limit: freelancersRepository.paginationData.perPage,
                page: resetPage ? 1 : freelancersRepository.paginationData.currentPage + 1
            } );
        }
        else {
            await load();
        }
    };

    const handleApplyFilters = () => {
        freelancersRepository.isOpen = false;
        loadMore( { resetFilters: false, resetPage: true, resetData: true } );
    };

    const handleResetFilters = () => {
        freelancersRepository.isOpen = false;
        loadMore( { resetFilters: true, resetPage: true, resetData: true } );
    };

    return {
        loading: freelancersQuery[ 1 ].loading,
        error: freelancersQuery[ 1 ].error,
        freelancers,
        totalFreelancers,
        load,
        loadMore,
        hasMore,
        handleApplyFilters,
        handleResetFilters,
        countFilterActive
    };
}