import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Project, ProjectStatusEnum } from '@/gql/graphql';
import { Button, Divider, Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';
import { Interweave } from 'interweave';
import { DownloadOutlinedIcon } from '@/assets/icons/CustomIcons';
import { useParams } from 'react-router-dom';
import { shortDateFormatter } from '@/utils/shortDateFormatter';
import ProjectPriceDetails from '@/components/ProjectPriceDetails';
import projectHook from '@/hooks/projectHook';
import FreelancerProjectConversationLink from '@/pages/freelancer/views/project/components/FreelancerProjectConversationLink';
import LayoutVisibilityHook from '@/hooks/layoutVisibilityHook';
import FreelancerProjectReview from '@/pages/freelancer/views/project/components/FreelancerProjectReview';
import ResponsiveModal from '@/components/ResponsiveModal';

interface FreelancerProjectProps {
    id?: string;
    onReviewSubmitted?: ( value: Project ) => void;
    className?: string;
}

const FreelancerProject = forwardRef( ( props: FreelancerProjectProps, ref ) => {

    const { id, onReviewSubmitted, className } = props;

    let content;
    const { t } = useTranslation();
    const { projectId } = useParams();
    const [ _projectId, setProjectId ] = useState( projectId );

    const reviewModalRef = useRef( null );

    useImperativeHandle( ref, () => ( {
        openReviewModal() {
            reviewModalRef.current?.open();
        }
    } ) );

    LayoutVisibilityHook( {
        headerVisible: '!isTabletOrMobile',
        bottomNavBarVisible: false
    } );

    useEffect( () => {
        if( projectId ) {
            setProjectId( projectId );
        }
        else {
            setProjectId( id );
        }
    }, [] );

    const { project, setProject, isLoading } = projectHook( { projectId: _projectId } );

    const handleReviewSubmitted = ( value: Project ) => {
        onReviewSubmitted?.( value );

        setProject( {
            ...project,
            status: value?.status,
            validatedByFreelancerAt: value?.validatedByFreelancerAt,
            freelancerHasReviewed: value?.freelancerHasReviewed
        } );
    };

    const downloadInvoice = ( url ) => {
        window.open( url, '_blank', 'noreferrer' );
    };

    if( isLoading ) {
        content = (
            <Skeleton active={ true }
                      className="w-full" />
        );
    }
    else if( project != null ) {
        content = (
            <>
                <FreelancerProjectConversationLink conversationId={ project?.conversationId }
                                                   channel={ project?.channel }
                                                   client={ {
                                                       profilePicture: project?.client?.profilePicture,
                                                       userName: project?.client?.userName
                                                   } } />
                <h3 className="mt-5">
                    { t( 'client:project.projectDetails' ) }
                </h3>

                <div className="mt-3 break-words">
                    <Interweave content={ project?.description }
                                className="text-grey font-normal text-sm" />
                </div>

                <div className="mt-[19px] font-medium text-sm">
                    <span>{ t( 'client:project.projectStarted' ) }</span>
                    <span className="ml-1">{ shortDateFormatter( project?.startedAt ) }</span>
                </div>

                <div className="mt-[19px]">
                    <ProjectPriceDetails data={ {
                        amountExVAT: project?.payment?.paymentExVat,
                        amountIncVAT: project?.payment?.clientAmountIncVat,
                        currency: project?.payment?.currency
                    } } />
                </div>

                {
                    project?.status === ProjectStatusEnum.DONE &&
                    project?.payment?.clientInvoiceUrl &&
                    project?.payment?.freelancerInvoiceUrl ?
                    (
                        <div className="flex justify-center items-center w-full mt-6 lg:mt-8 gap-x-4">
                            <Button type="text"
                                    onClick={ () => downloadInvoice( project?.payment.clientInvoiceUrl ) }
                                    size="large"
                                    className="text-pink text-base flex items-center justify-center">
                                                <span className="underline">
                                                    { t( 'freelancer:project.downloadInvoiceClient' ) }
                                                </span>
                                <DownloadOutlinedIcon style={ { fontSize: '20px' } } />
                            </Button>

                            <Button type="text"
                                    onClick={ () => downloadInvoice( project?.payment.freelancerInvoiceUrl ) }
                                    size="large"
                                    className="text-pink text-base flex items-center justify-center">
                                                <span className="underline">
                                                    { t( 'freelancer:project.downloadInvoiceFreelancer' ) }
                                                </span>
                                <DownloadOutlinedIcon style={ { fontSize: '20px' } } />
                            </Button>
                        </div>
                    ) :
                    null
                }
            </>
        );
    }

    return (
        <>
            <div className="min-h-screen lg:min-h-fit default-padding-layout-x default-padding-layout-y lg:rounded-4xl">
                { content }
            </div>
            <ResponsiveModal ref={ reviewModalRef }
                             title={ t( 'client:projectReview.title' ) }
                             mobileHeight="h-fit"
                             width={ 493 }
                             closable={ true }>
                <Divider className="mt-0" />
                <p className="text-center text-sm font-medium mb-8">
                    { t( 'client:projectReview.description', { name: project?.channel?.name } ) }
                </p>
                <FreelancerProjectReview reviewSubmitted={ ( project: Project ) => handleReviewSubmitted( project ) }
                                         data={ {
                                             projectId: project?.id,
                                             users: project?.users
                                         } } />
            </ResponsiveModal>
        </>
    );
} );

export default FreelancerProject;