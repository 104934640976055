import React, { useContext } from 'react';
import { ColorSchemeContext } from '@/core/dark-mode/colorSchemeProvider';
import { useTranslation } from 'react-i18next';
import { Button, Drawer, Space } from 'antd';
import { CloseOutlinedIcon } from '@/assets/icons/CustomIcons';
import ChatConversationDeclineProjectModalForm from '@/components/chat/components/chat-conversation/chat-conversation-decline-project-modal/ChatConversationDeclineProjectModalForm';
import { ConversationItemOffer } from '@/components/chat/interfaces/conversationItemItemOffer';

interface ChatConversationDeclineModalTouchProps {
    offerItem: ConversationItemOffer;
    isOpen: boolean;

    onCloseModal(): void;
}

export default function ChatConversationDeclineProjectModalTouch( {
                                                               offerItem,
                                                               isOpen,
                                                               onCloseModal
                                                           }: ChatConversationDeclineModalTouchProps ) {

    const colorSchemeContext = useContext( ColorSchemeContext );
    const { t } = useTranslation();

    const handleOnCloseModal = () => {
        onCloseModal();
    };

    return (
        <Drawer placement="bottom"
                className="overflow-x-hidden bg-white mt-[15%] rounded-t-4xl"
                bodyStyle={ { padding: 0 } }
                width="100%"
                height="100%"
                headerStyle={ { textAlign: 'center' } }
                closable={ false }
                rootClassName={ colorSchemeContext.toLowerCase() }
                open={ isOpen }
                title={ t( 'common:chat.conversation.declineOffer.title' ) }
                extra={
                    <Space>
                        <Button onClick={ () => handleOnCloseModal() }
                                size="small"
                                icon={ <CloseOutlinedIcon style={ { fontSize: '12px' } } /> }
                                shape="circle">
                        </Button>
                    </Space>
                }
                destroyOnClose={ true }>
            <div className="relative bg-white default-padding-layout-x default-padding-layout-y">
                <p className="text-base font-semibold mt-4">
                    { t( 'common:chat.conversation.declineOffer.subtitle' ) }
                </p>
                <ChatConversationDeclineProjectModalForm offerItem={ offerItem }
                                                         onDecline={ () => onCloseModal() } />
            </div>
        </Drawer>
    );
}