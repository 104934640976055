import { useEffect, useState } from 'react';
import CarouselYoutubeVideos from '@/components/freelance-profile/components/CarouselYoutubeVideos';
import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import FreelancerPrice from '@/components/freelance-profile/components/FreelancerPrice';
import FreelancerExperienceTag from '@/components/freelance-profile/components/FreelancerExperienceTag';
import FreelancerCreatorsIWorkedWithList from '@/components/freelance-profile/components/FreelancerCreatorsIWorkedWithList';
import FreelancerAvailabilityTag from '@/components/freelance-profile/components/FreelancerAvailabilityTag';
import FreelancerFavoriteButton from '@/components/freelance-profile/components/FreelancerFavoriteButton';
import MouseInactivityDetector from '@/hooks/mouseInactivityDetectorHook';
import FreelancerMainJob from '@/components/freelance-profile/components/FreelancerMainJob';
import { useMediaQuery } from 'react-responsive';
import { Freelancer } from '@/gql/graphql';

interface ProfileListItemProps {
    profile: Freelancer;
    newTag?: boolean;
    viewProfile?: ( profile: Freelancer ) => void;
    enableClick?: boolean;
    favoriteButton?: boolean;
    onLike?: ( freelancer: Freelancer ) => void;
    onDislike?: ( freelancer: Freelancer ) => void;
}

export default function FreelancerCard( {
                                            profile,
                                            newTag = false,
                                            favoriteButton = false,
                                            viewProfile,
                                            enableClick = true,
                                            onLike,
                                            onDislike
                                        }: ProfileListItemProps ) {

    const isTabletOrMobile = useMediaQuery( { query: `(max-width: ${ import.meta.env.VITE_MOBILE_BREAK_POINT })` } );
    const [ isHover, setIsHover ] = useState( false );
    const [ playOnClick, setPlayOnClick ] = useState( false );
    const [ isHoverWithTimer, setIsHoverWithTimer ] = useState( false );

    const { isMouseActive, destroyWatcher, startWatcher } = MouseInactivityDetector( { timer: 1000 } );

    useEffect( () => {

        return () => {
            destroyWatcher();
        };
    }, [] );

    useEffect( () => {
        if( isHover ) {
            startWatcher();
        }
        else {
            destroyWatcher();
        }
    }, [ isHover ] );

    useEffect( () => {
        if( isMouseActive ) {
            setIsHoverWithTimer( true );
        }

        if( !isMouseActive ) {
            setIsHoverWithTimer( false );
        }
    }, [ isMouseActive ] );

    const handleLike = () => onLike?.( profile );

    const handleDislike = () => onDislike?.( profile );

    const profileSelected = ( e ) => {
        viewProfile?.( profile );
    };

    const handleIsHover = ( value: boolean ) => {
        setIsHoverWithTimer( value );
        setIsHover( value );
    };

    const handlePlayOnClick = ( e ) => {
        e.preventDefault();
        if( isTabletOrMobile ) {
            setPlayOnClick( ( value ) => !value );
        }
    };

    return (
        <div className={ `relative w-full h-full rounded-2xl overflow-hidden lg:cursor-pointer card-shadow ${ enableClick
                                                                                                              ? ''
                                                                                                              : 'pointer-events-none' }` }
             onMouseEnter={ () => handleIsHover( true ) }
             onMouseLeave={ () => handleIsHover( false ) }>
            <div className="relative">
                <div onClick={ ( e ) => handlePlayOnClick( e ) }>
                    <CarouselYoutubeVideos videos={ profile?.portfolioElements?.map( ( element ) => element.url ) }
                                           height="170px"
                                           arrows={ isTabletOrMobile ? null : isHoverWithTimer }
                                           play={ isTabletOrMobile ? playOnClick : isHover } />
                </div>
                {
                    !isHover &&
                    <div className="absolute left-3 top-3">
                      <FreelancerAvailabilityTag isAvailable={ profile?.isAvailable }
                                                 firstName={ profile?.firstName } />
                    </div>
                }
                {
                    favoriteButton &&
                    <div className="absolute right-3 top-3">
                      <FreelancerFavoriteButton isFavorite={ profile?.isFavorite }
                                                onDislike={ () => handleDislike() }
                                                onLike={ () => handleLike() } />
                    </div>
                }
            </div>
            <div className="flex flex-col p-4"
                 onClick={ ( e ) => profileSelected( e ) }>
                <div className="flex items-center">
                    <Avatar size={ 48 }
                            className="flex-none"
                            icon={ <UserOutlined /> }
                            src={ profile?.profilePicture } />
                    <div className="flex flex-col font-medium justify-between ml-2 text-base">
                        { profile?.firstName } { profile?.lastName }.
                        <FreelancerMainJob job={ profile.mainJob as 'VIDEO_EDITOR' | 'GRAPHIC_DESIGNER' } />
                    </div>
                </div>

                <div className="flex mt-4 gap-x-2">
                    <FreelancerPrice price={ profile?.avgPrice }
                                     color="purple" />
                    <FreelancerExperienceTag experience={ profile?.experience } />
                </div>

                <div className="mt-4">
                    <FreelancerCreatorsIWorkedWithList creators={ profile?.creatorsIWorkedWith } />
                </div>
            </div>
        </div>
    );
}