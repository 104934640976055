import { CreateRateInput, Project, User, UserTypeEnum } from '@/gql/graphql';
import { Button, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { ReviewAndValidateProjectGQL } from '@/gql/global-queries/sendReviewGQL';
import { useMutation } from '@apollo/client';
import * as Sentry from '@sentry/react';
import RateComponent from '@/components/RateComponent';
import TextAreaComponent from '@/components/custom-element-form/TextAreaComponent';
import { SegmentEvent, trackSegmentEvent } from '@/utils/analytics';
import clientProjectsHook from '@/hooks/clientProjectsHook';

interface ClientProjectReviewProps {
    data: {
        projectId: string;
        users: User[];
        hasReviewedByFreelancer: boolean;
    };
    reviewSubmitted?: ( project: Project ) => void;
}

export default function ClientProjectFreelancerReviewForm( { data, reviewSubmitted }: ClientProjectReviewProps ) {

    const { t } = useTranslation();
    const { trigger, control, watch, formState: { errors }, handleSubmit } = useForm();
    const [ messageApi, contextHolder ] = message.useMessage();

    const sendReviewMutationGQL = useMutation( ReviewAndValidateProjectGQL );

    const onSubmit = async( formData ) => {
        if( !( await trigger() ) ) {
            return;
        }

        try {
            const to: User = data.users.find( user => user.type === UserTypeEnum.FREELANCER );
            const from: User = data.users.find( user => user.type === UserTypeEnum.CLIENT );

            const args: CreateRateInput = {
                projectId: data.projectId,
                reactivity: null,
                reliabilityAndCommunication: null,
                toId: to.id,
                fromId: from.id,
                review: formData.review,
                creativity: formData.creativityRate,
                technicalSkills: formData.technicalSkillsRate,
                friendlyCommunication: formData.communicationRate
            };

            const response = await sendReviewMutationGQL[ 0 ]( {
                variables: {
                    args
                }
            } );

            if( reviewSubmitted && response.data ) {
                trackSegmentEvent(
                    SegmentEvent.ProjetAccepted,
                    {
                        project_id : data.projectId,
                        project_creator: from.id,
                        project_receiver: to.id,
                        rating_freelancer_creativity : formData.creativityRate,
                        rating_freelancer_skills : formData.technicalSkillsRate,
                        rating_freelancer_reliability : formData.communicationRate
                    }
                );

                reviewSubmitted( response.data.reviewAndValidateProject );
            }
        } catch( e ) {
            Sentry.captureException( 'An error occur when client tried to send a review', e );
            messageApi.error( t( 'common:form.submitError' ) );
        }
    };

    return (
        <>
            { contextHolder }
            <form className="w-full flex flex-col items-center justify-center gap-y-4 lg:gap-y-6 mt-2"
                  onSubmit={ handleSubmit( onSubmit ) }>

                <RateComponent control={ control }
                               name="creativityRate"
                               label={ t( 'client:projectReview.creativity' ) }
                               rules={ {
                                   required: t( 'common:error.form.required' )
                               } }
                               errors={ errors } />

                <RateComponent control={ control }
                               name="technicalSkillsRate"
                               label={ t( 'client:projectReview.technicalSkills' ) }
                               rules={ {
                                   required: t( 'common:error.form.required' )
                               } }
                               errors={ errors } />

                <RateComponent control={ control }
                               name="communicationRate"
                               label={ t( 'client:projectReview.communication' ) }
                               rules={ {
                                   required: t( 'common:error.form.required' )
                               } }
                               errors={ errors } />

                <TextAreaComponent control={ control }
                                   rows={ 6 }
                                   className="w-full"
                                   name="review"
                                   label={ t( 'client:projectReview.review' ) }
                                   placeholder={ t( 'client:projectReview.reviewPlaceholder' ) }
                                   errors={ errors } />

                <Button type="primary"
                        htmlType="submit"
                        loading={ sendReviewMutationGQL[ 1 ].loading }
                        className="mt-4 w-full">
                    { t( 'client:projectReview.submit' ) }
                </Button>
            </form>
        </>
    );

}