import { Outlet } from 'react-router-dom';

import DynamicHeader from '@/pages/transverse/layout/header/DynamicHeader';

export default function AuthRoot() {

    return (
        <div className="flex flex-col w-full h-full min-h-screen">
            <DynamicHeader />
            <Outlet />
        </div>
    );
}