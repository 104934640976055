import { Divider } from 'antd';
import SkeletonAvatar from 'antd/es/skeleton/Avatar';
import { useMediaQuery } from 'react-responsive';
import SkeletonButton from 'antd/es/skeleton/Button';
import SkeletonInput from 'antd/es/skeleton/Input';
import React from 'react';

export default function ChatConversationSkeleton() {

    const isTabletOrMobile: boolean = useMediaQuery( { query: `(max-width: ${ import.meta.env.VITE_MOBILE_BREAK_POINT })` } );
    const count: number = 4;

    return (
        <div className="relative flex flex-col">
            <div className="flex-none">
                <div className="bg-red-100 w-full flex justify-between px-6 pt-6">
                    <div className="flex-none flex">
                        <SkeletonAvatar size={ isTabletOrMobile ? 42 : 64 }
                                        active={ true } />
                        <div className="flex flex-col justify-between ml-4">
                            <SkeletonInput className={ `w-36 ` }
                                           style={ { minHeight: `${ isTabletOrMobile ? 42 : 64 }px` } }
                                           active={ true } />
                        </div>
                    </div>
                    <div className="w-fit">
                        <SkeletonButton shape="circle"
                                        style={ {
                                            height: `${ isTabletOrMobile ? 42 : 64 }px`,
                                            width: `${ isTabletOrMobile ? 42 : 64 }px`
                                        } }
                                        active={ true } />
                    </div>
                </div>
                <Divider className="mb-0"></Divider>
            </div>


            <div className="flex-grow w-full h-full">
                {
                    [ ...Array( count ).keys() ].map( ( index ) => {
                        return (
                            <div key={ index }
                                 className={ `w-full flex ${ index % 2 === 0 ? 'justify-end' : 'justify-start' }` }>
                                <div className={ `w-full max-w-[70%] lg:max-w-[70%]` }>
                                    <div className="flex flex-col px-6 py-4 w-full">
                                        <div className={ `h-full rounded-b-2xl whitespace-pre-wrap ${ index % 2 === 0
                                                                                                      ? 'rounded-tl-2xl'
                                                                                                      : 'rounded-tr-2xl' }` }>
                                            <SkeletonInput active={ true }
                                                           className={ `w-full h-full rounded-b-2xl whitespace-pre-wrap ${ index % 2 === 0
                                                                                                                           ? 'rounded-tl-2xl '
                                                                                                                           : 'rounded-tr-2xl' }` }
                                                           style={ { minHeight: '120px' } } />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    } )
                }
            </div>
        </div>
    );
}