import React from 'react';
import { CheckOutlinedIcon, ChevronRightFilledIcon, ExclamationFilledIcon } from '@/assets/icons/CustomIcons';
import { Link } from 'react-router-dom';

interface FreelancerAccountProfileValidationElementProps {
    text: string;
    isCompleted: boolean;
    link?: string;
    isExternal?: boolean;
}

export default function FreelancerAccountProfileValidationElement( {
                                                                       text,
                                                                       isCompleted,
                                                                       link,
                                                                       isExternal
                                                                   }: FreelancerAccountProfileValidationElementProps ) {

    const validatedIconJSX = (
        <div className="flex items-center justify-center w-6 h-6 rounded-full border-[1.5px] border-green-200">
            <CheckOutlinedIcon className="text-green-200 text-[12px]" />
        </div>
    );

    const alertIconJSX = (
        <div className="flex items-center justify-center  w-6 h-6 rounded-full border-[1.5px] border-red-pastel-dark">
            <ExclamationFilledIcon className="text-red text-[12px]" />
        </div>
    );

    const elementJSX = (
        <div className="flex items-center justify-between cursor-pointer">
            <div className="flex items-center justify-center">
                {
                    isCompleted ? validatedIconJSX : alertIconJSX
                }
                <span className="ml-3 text-base font-medium">
                    { text }
                </span>
            </div>
            <ChevronRightFilledIcon className="text-black text-[20px]" />
        </div>
    );

    return (
        <>
            {
                isExternal ?
                <a href={ link }
                   target="_blank"
                   className="w-full"
                   rel="noopener noreferrer">
                    { elementJSX }
                </a> :
                <Link to={ link }
                      className="w-full">
                    { elementJSX }
                </Link>

            }
        </>
    );
}