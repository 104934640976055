import { PaymentMethod } from '@/gql/graphql';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import paymentMethodHook from '@/hooks/paymentMethodHook';
import { Button } from 'antd';
import PaymentMethodModal from './PaymentMethodModal';

interface PaymentMethodProps {
    defaultPaymentMethod: PaymentMethod;
    isLoading?: ( isSaving: boolean ) => void;
}

const PaymentMethodForm = forwardRef( ( props: PaymentMethodProps, ref ) => {

    const { defaultPaymentMethod, isLoading } = props;
    const [ currentPaymentMethod, setCurrentPaymentMethod ] = useState<PaymentMethod>( null );
    const { isSubmitting, paymentMethod } = paymentMethodHook();

    const { t } = useTranslation();

    useEffect( () => {
        if( isLoading ) {
            isLoading( isSubmitting );
        }
    }, [ isSubmitting ] );

    useEffect( () => {
        if( defaultPaymentMethod ) {
            setCurrentPaymentMethod( defaultPaymentMethod );
        }
    }, [ defaultPaymentMethod ] );

    useEffect( () => {
        if( paymentMethod ) {
            setCurrentPaymentMethod( paymentMethod );
        }
    }, [ paymentMethod ] );


    useImperativeHandle( ref, () => ( { getPaymentMethod: async() => handleGetPaymentMethod() } ) )

    const handleGetPaymentMethod = async(): Promise<PaymentMethod> => {
        if( currentPaymentMethod ) {
            return currentPaymentMethod;
        }
    };

    const paymentMethodMissingJSX = (
        <>
            <div className="text-sm mt-2 text-red">
                { t( 'common:chat.conversation.payment.paymentMethodForm.missingInfo' )  }
            </div>
        </>
    );

    const defaultPaymentMethodJSX = (
        <p className="text-xs text-grey">
            <Trans i18nKey="common:chat.conversation.payment.disclaimerPayment"
                values={ { last4: currentPaymentMethod?.last4 } }>
                <span className="text-grey"></span>
                <span className="font-medium"></span>
            </Trans>
        </p>
    );

    const paymentMethodModalRef = useRef( null );

    const openModal = (): void => paymentMethodModalRef?.current.open();

    return (
        <div>
            <div className="w-full text-sm font-medium font-sharp mt-6">
                { t( 'common:chat.conversation.payment.paymentMethodForm.title' )  }
            </div>
            <div className="text-sm font-normal mt-4">
                {
                    currentPaymentMethod ?
                        defaultPaymentMethodJSX :
                        paymentMethodMissingJSX
                }
            </div>
            <Button className='mt-3' onClick={openModal} type="default">
                { currentPaymentMethod ? 
                    t( 'common:chat.conversation.payment.paymentMethodForm.editButton' ) :
                    t( 'common:chat.conversation.payment.paymentMethodForm.addButton' ) 
                }
                
            </Button>
            <PaymentMethodModal
                setCurrentPaymentMethod={ setCurrentPaymentMethod }
                currentPaymentMethod={ currentPaymentMethod }
                defaultPaymentMethod={ defaultPaymentMethod } 
                ref={ paymentMethodModalRef } 
            />
        </div>
    )
} )

export default PaymentMethodForm