import { useNavigate, useParams } from 'react-router-dom';
import FreelancerProfileHook from '@/hooks/freelancerProfileHook';
import FreelancerProfileReviewHook from '@/components/freelance-profile/components/freelancer-profile-reviews/freelancerProfileReviewHook';
import FreelancerProfile from '@/components/freelance-profile/FreelancerProfile';
import { PageName, trackPageView } from '@/utils/analytics';
import { useContext, useEffect, useState } from 'react';
import isUuid from '@/utils/isUuid';
import FreelancerIdFromCustomUrlHook from '@/hooks/freelancerIdFromCustomUrlHook';
import { FreelancersRepository } from '@/components/freelancers-filter-items/freelancersRepository';
import { FreelancersRepositoryContext } from '@/core/FreelancerRepositoryContext';

interface FreelancerProfileProps {
    freelancerId?: string;
}

export default function TransverseFreelancerProfile( { freelancerId }: FreelancerProfileProps ) {

    const { id: idParams } = useParams();
    const navigate = useNavigate();

    const { getId, id } = FreelancerIdFromCustomUrlHook();

    const [ userId, setUserId ] = useState<string>( null );

    const { load: loadProfile, freelancerProfile, isLoading: isLoadingProfile } = FreelancerProfileHook( {
        visibility: 'PUBLIC'
    } );

    const { load: loadReviews, reviews, averageRate, isLoading: isLoadingReviews } = FreelancerProfileReviewHook( {
        limit: 5
    } );

    const isLoadingCombined: boolean = isLoadingProfile || isLoadingReviews;

    useEffect( () => {
        trackPageView( PageName.FreelancerProfile );

        if( freelancerProfile && isUuid(idParams) && freelancerProfile.customUrl ) {
            replaceUrlIdByCustomUrl( freelancerProfile.customUrl );
        }
    }, [] );

    useEffect( () => {
        const userId: string | null = idParams || freelancerId;
        const isUuidId: boolean | null = isUuid( idParams || freelancerId );

        if( !isUuidId ) {
            getId( userId );
        }
        else {
            setUserId( userId );
        }

    }, [ idParams, freelancerId ] );

    useEffect( () => {
        if( id ) {
            setUserId( id );
        }
    }, [ id ] );

    useEffect( () => {
        if( userId ) {
            loadProfile( userId );
            loadReviews( userId );
        }
    }, [ userId ] );

    const replaceUrlIdByCustomUrl = (newId: string) => {
        navigate(`/p/${newId}`, { replace: true });
    }

    const handleHire = () => {
        navigate( '/auth/sign-up/client' );
    };

    return (
        <FreelancerProfile freelancer={ freelancerProfile }
                           isLoading={ isLoadingCombined }
                           reviews={ reviews }
                           averageRate={ averageRate }
                           onHire={ () => handleHire() } />
    );
}