import { gql } from '@apollo/client';

export const PortfolioStatsGQL = gql`
    query PortfolioStatsGQL($freelancerId: String!) {
        portfolioElementStats(freelancerId: $freelancerId) {
            countElements
            countLikes
            countViews
        }
    }`;


