import { PortfolioElement } from '@/gql/graphql';
import React from 'react';
import ScrollableContainer from '@/components/scrollable-container/ScrollableContainer';
import FreelancerProfilePortfolioListItem from '@/components/freelance-profile/FreelancerProfilePortfolioListItem';

interface FreelancerProfilePortfolioListProps {
    portfolio: PortfolioElement[];
}

export default function FreelancerProfilePortfolioList( { portfolio }: FreelancerProfilePortfolioListProps ) {

    return (
        <ScrollableContainer>
            <div className="flex gap-x-6">
                {
                    portfolio?.map( ( portfolioElement: PortfolioElement, index ) => {
                        return (
                            <FreelancerProfilePortfolioListItem portfolioElement={ portfolioElement }
                                                                key={ index } />
                        );
                    } )
                }
            </div>
        </ScrollableContainer>
    );
}