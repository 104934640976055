import { Trans, useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Button, message } from 'antd';
import { useMutation, useQuery } from '@apollo/client';
import { AcceptFreelancerCharterGQL } from '@/components/freelancer-charter/services/acceptFreelancerCharterGQL';
import { FreelancerCharterGQL } from '@/components/freelancer-charter/services/freelancerCharterGQL';
import ListSkeleton from '@/components/ListSkeleton';
import * as Sentry from '@sentry/react';

export default function FreelancerCharter() {

    const { t } = useTranslation();
    const [ messageApi, contextHolder ] = message.useMessage();
    const [ charterAccepted, setCharterAccepted ] = useState( false );

    const { data, loading } = useQuery( FreelancerCharterGQL );
    const acceptCharterMutation = useMutation( AcceptFreelancerCharterGQL );

    useEffect( () => {
        if( data?.meFreelancer?.charterAccepted ) {
            setCharterAccepted( data?.meFreelancer?.charterAccepted );
        }
    }, [ data ] );

    const handleAcceptCharter = async() => {
        try {
            await acceptCharterMutation[ 0 ]();
            setCharterAccepted( true );
            messageApi.success( t( 'freelancer:charter.successSubmit' ) );
        } catch( e ) {
            Sentry.captureException( 'An error occur when trying to accept charter', e );
            messageApi.error( t( 'translation:error.default' ) );
        }
    };

    const rules = [
        {
            title: 'freelancer:charter.rules1.title',
            description: 'freelancer:charter.rules1.description'
        },
        {
            title: 'freelancer:charter.rules2.title',
            description: 'freelancer:charter.rules2.description'
        },
        {
            title: 'freelancer:charter.rules3.title',
            description: 'freelancer:charter.rules3.description'
        },
        {
            title: 'freelancer:charter.rules4.title',
            description: 'freelancer:charter.rules4.description'
        },
        {
            title: 'freelancer:charter.rules5.title',
            description: 'freelancer:charter.rules5.description'
        }
    ];

    const questions = [
        {
            title: 'freelancer:charter.question1.title',
            description: 'freelancer:charter.question1.description'
        },
        {
            title: 'freelancer:charter.question2.title',
            description: 'freelancer:charter.question2.description'
        },
        {
            title: 'freelancer:charter.question3.title',
            description: 'freelancer:charter.question3.description'
        }
    ];

    return (
        <div className="flex flex-col">

            { contextHolder }
            <h1 className="text-[40px] font-bold tracking-[-0.56px] leading-[120%] ">
                <Trans i18nKey="freelancer:charter.title">
                    <span></span>
                    <span className="text-orange-pastel"></span>
                </Trans>
            </h1>

            <p className="text-sm font-normal mt-6 ">
                <Trans i18nKey="freelancer:charter.description" />
            </p>

            <div className="flex flex-col space-y-4 mt-6">
                {
                    rules.map( ( rule, index ) => (
                        <div key={ index }
                             className="flex items-start">
                            <div className="flex-none bg-primary-gradient rounded-full w-[32px] h-[32px] flex justify-center items-center">
                                <div className="bg-white rounded-full  text-[22px] font-bold w-[28px] h-[28px] flex justify-center items-center">
                                    { index + 1 }
                                </div>
                            </div>
                            <div className="ml-4 lex flex-col">
                                <h4 className="text-base font-normal ">
                                    { t( rule.title ) }
                                </h4>
                                <p className="text-sm text-grey-medium">
                                    { t( rule.description ) }
                                </p>
                            </div>
                        </div>
                    ) )
                }
            </div>


            {
                loading ?
                <div className="mt-12">
                    <ListSkeleton isLoading={ true }
                                  count={ 1 }
                                  height={ 60 } />
                </div> :
                <>
                    {
                        !charterAccepted &&
                        <>
                          <h2 className="mt-12 font-semibold text-center text-lg">
                              { t( 'freelancer:charter.agree' ) }
                          </h2>
                          <Button type="primary"
                                  size="large"
                                  loading={ acceptCharterMutation[ 1 ].loading }
                                  onClick={ () => handleAcceptCharter() }
                                  className="w-full mt-4">
                              { t( 'freelancer:charter.button' ) }
                          </Button>

                        </>
                    }
                </>
            }

            <div className="mt-12 flex-none bg-primary-gradient rounded-4xl w-full p-1">
                <div className="bg-white  rounded-[1.3em] text-[22px] font-bold w-full p-4 lg:p-6 flex flex-col space-y-12">
                    {
                        questions.map( ( question, index ) => (
                            <div key={ index }
                                 className="flex flex-col space-y-2">
                                <h4 className="text-[28px] font-bold tracking-[-0.56px] leading-[120%] ">
                                    { t( question.title ) }
                                </h4>
                                <p className="text-sm font-normal">
                                    <Trans i18nKey={ question.description } />
                                </p>
                            </div>
                        ) )
                    }
                </div>
            </div>
        </div>
    );
}