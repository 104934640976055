import { useContext, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Button, Drawer } from 'antd';

import { ArrowLeftFilledIcon, BoldFilledIcon } from '@/assets/icons/CustomIcons';
import ClientFreelancerProfile from '@/pages/client/views/freelancer/ClientFreelancerProfile';
import { ColorSchemeEnum } from '@/core/dark-mode/colorSchemeRepository';
import { ColorSchemeContext } from '@/core/dark-mode/colorSchemeProvider';

interface ClientFreelancerProfileDrawerProps {
    freelancerId: string;
    onCloseDrawer?: () => void;
    onHire?: () => void;
}

export default function ClientFreelancerProfileDrawer( {
                                                           freelancerId,
                                                           onCloseDrawer,
                                                           onHire
                                                       }: ClientFreelancerProfileDrawerProps ) {
    const { t } = useTranslation();

    const [ isOpen, setIsOpen ] = useState( false );
    const colorSchemeContext: ColorSchemeEnum = useContext( ColorSchemeContext );

    const handleCloseDrawer = () => onCloseDrawer?.();
    const handleHire = () => onHire?.();

    useEffect( () => {
        setIsOpen( freelancerId !== null );
    }, [ freelancerId ] );

    useEffect( () => {
        if( isOpen ) {
            document.body.style.overflow = 'hidden';
        }
        else {
            document.body.style.overflow = 'auto';
        }

        return () => {
            document.body.style.overflow = 'auto'; // Reset when component unmounts
        };
    }, [ isOpen ] );

    return (
        <Drawer placement="bottom"
                className="overflow-x-hidden bg-white"
                bodyStyle={ { padding: '24px 24px 0 24px' } }
                width="100%"
                height="100%"
                closable={ false }
                rootClassName={ colorSchemeContext.toLowerCase() }
                open={ isOpen }
                destroyOnClose={ true }>
            <div className="pb-[30px]">
                {
                    freelancerId && <ClientFreelancerProfile freelancerId={ freelancerId } />
                }
            </div>
            <div className="bg-white lg:hidden sticky bottom-0 left-0 right-0 h-[70px] flex justify-between items-center gap-x-4">
                <Button shape="circle"
                        size="middle"
                        onClick={ () => handleCloseDrawer() }
                        icon={ <ArrowLeftFilledIcon style={ { fontSize: '14px' } } /> }
                />
                <Button type="primary"
                        onClick={ () => handleHire() }
                        className="w-full pulse">
                                    <span className="flex gap-x-2 justify-center">
                                    { t( 'common:freelancerProfile.card.hireButton' ) }
                                        <BoldFilledIcon style={ { fontSize: '16px' } } />
                                    </span>
                </Button>
            </div>
        </Drawer>
    );
}