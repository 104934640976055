import { gql } from '@apollo/client';

export const AddToFavoriteGQL = gql`
    mutation AddToFavorite($id: String!) {
        addToFavorite(id: $id) {
            id
            freelancer {
                id
                firstName
                lastName
                profilePicture
                isFavorite
                averageRate
                countProjects
                mainJob
                avgPrice
                priceCurrency
                experience
                isAvailable
                isCertified
                accountStatus
                creatorsIWorkedWith {
                    id
                    name
                    channelUrl
                    thumbnailUrl
                    subscriberCount
                }
                portfolioElements {
                    id
                    url
                }
            }
            createdAt
        }
    }
`;
