import { Button, Divider } from 'antd';
import ClientProject from '@/pages/client/views/project/ClientProject';
import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Project, ProjectStatusEnum } from '@/gql/graphql';
import projectHook from '@/hooks/projectHook';
import SkeletonInput from 'antd/es/skeleton/Input';
import ResponsiveModal from '@/components/ResponsiveModal';

interface ClientProjectModalProps {
    projectId: string;
    onReviewSubmitted?: ( project: Project ) => void;
}

const ClientProjectModal = forwardRef( ( props: ClientProjectModalProps, ref ) => {

    const { projectId, onReviewSubmitted } = props;

    const { t } = useTranslation();
    const { project, isLoadingProject } = projectHook( { projectId } );

    const projectRef = useRef( null );
    const projectReviewRef = useRef( null );

    useImperativeHandle( ref, () => ( {
            open() {
                projectRef.current.open();
            },
            close() {
                projectRef.current.close();
            }
        } )
    );

    const handleReviewSubmitted = ( projectUpdated ) => {
        onReviewSubmitted?.( projectUpdated );
        projectRef.current.close();
    };

    const openReviewModal = () => {
        projectReviewRef?.current?.openReviewModal();
    };

    return (
        <ResponsiveModal ref={ projectRef }
                         title={ isLoadingProject ?
                                 <SkeletonInput active
                                                className="w-[200px] lg:w-[400px] h-[27px]" /> :
                                 project?.name }
                         destroyOnClose={ true }
                         desktopWidth="853px">
            <Divider className="mt-0" />
            <ClientProject id={ projectId }
                           ref={ projectReviewRef }
                           onReviewSubmitted={ ( projectUpdated: Project ) => handleReviewSubmitted( projectUpdated ) } />
            <Divider className="w-full" />

            <div className="w-full flex items-end justify-between">
                <Button type="text"
                        onClick={ () => projectRef.current.close() }>
                    { t( 'common:modal.close' ) }
                </Button>

                {
                    project?.status == ProjectStatusEnum.IN_PROGRESS &&
                    !project?.clientHasReviewed &&
                    project?.validatedByClientAt == null &&
                    <Button type="primary"
                            onClick={ openReviewModal }>
                        { t( 'client:project.projectFinished' ) }
                    </Button>
                }
            </div>

        </ResponsiveModal>
    );
} );

export default ClientProjectModal;