import { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { Freelancer, PortfolioElementStatsOutput } from '@/gql/graphql';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { privateFreelancerGQL } from '@/gql/global-queries/privateFreelancerGQL';
import { publicFreelancerGQL } from '@/gql/global-queries/publicFreelancerGQL';
import { PortfolioStatsGQL } from '@/gql/global-queries/portfolioStatsGQL';

interface FreelancerProfileProps {
    visibility: 'PRIVATE' | 'PUBLIC';
}

export default function freelancerProfileHook( { visibility }: FreelancerProfileProps ) {
    const [ isLoading, setIsLoading ] = useState( true );
    const { t } = useTranslation();
    const nav = useNavigate();

    const [ freelancerProfile, setFreelancerProfile ] = useState<Freelancer & {
        portfolioElementsStats: PortfolioElementStatsOutput
    }>( null );
    const [ freelancerQuery, { data, error } ] = useLazyQuery( visibility == 'PRIVATE'
                                                               ? privateFreelancerGQL
                                                               : publicFreelancerGQL );
    const [
              portfolioStatsFreelancerQuery, {
            data: portfolioStatsData,
            error: portfolioStatsError
        }
          ] = useLazyQuery( PortfolioStatsGQL );

    useEffect( () => {
        if( data ) {
            const freelancer = visibility == 'PRIVATE' ? data.privateFreelancer : data.publicFreelancer;
            portfolioStatsFreelancerQuery( {
                variables: {
                    freelancerId: freelancer.id
                }
            } );
        }
    }, [ data ] );

    useEffect( () => {
        if( error ) {
            // @ts-ignore
            if( error.graphQLErrors.length > 0 && error.graphQLErrors[ 0 ].extensions.exception.status == 404 ) {
                nav( '/404' );
            }
            else {
                nav( '/500' );
            }
        }
    }, [ error ] );

    useEffect( () => {
        if( data ) {
            const freelancer = visibility == 'PRIVATE' ? data.privateFreelancer : data.publicFreelancer;
            setFreelancerProfile( { ...freelancerProfile, ...freelancer } );
        }
    }, [ data ] );

    useEffect( () => {
        if( portfolioStatsData ) {
            const stats = portfolioStatsData.portfolioElementStats;
            setFreelancerProfile( { ...freelancerProfile, portfolioElementsStats: stats } );
        }
    }, [ portfolioStatsData ] );

    useEffect( () => {
        if( data && portfolioStatsData ) {
            setIsLoading( false );
        }
    }, [ data, portfolioStatsData ] );

    const load = ( freelancerId: string ): void => {
        setIsLoading( true );

        freelancerQuery( {
            variables: {
                id: freelancerId
            }
        } );
    };

    return {
        load,
        freelancerProfile,
        isLoading
    };
}