import { gql } from '@apollo/client';

export const userProfilePictureGQL = gql`
    query User($id: String!) {
        user(id: $id) {
            id
            profilePicture
        }
    }`;


