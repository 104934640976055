import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import ResponsiveModal from '@/components/ResponsiveModal';
import { Button, Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import RadioCheckmarkComponent from '@/components/custom-element-form/RadioCheckmarkComponent';
import PaymentMethodItem from './PaymentMethodItem';
import { useQuery } from '@apollo/client';
import { PaymentMethodsGQL } from '@/pages/client/views/account/client-account-edit-payment-methods/services/paymentMethodsGQL';
import { PaymentMethod } from '@/gql/graphql';
import NewPaymentMethod from './NewPaymentMethod';
import { PlusOutlinedIcon } from '@/assets/icons/CustomIcons';
import paymentMethodHook from '@/hooks/paymentMethodHook';
import NoDataIcon from './NoDataIcon';

interface PaymentMethodModalProps {
    defaultPaymentMethod: any,
    setCurrentPaymentMethod: ( paymentMethod: PaymentMethod ) => void,
    currentPaymentMethod: PaymentMethod,
}

const PaymentMethodModal = forwardRef( ( props: PaymentMethodModalProps, ref ) => {

    const {
              data: paymentMethods,
              refetch: refetchPaymentMethods,
              loading: isCreatingPaymentMethod
          } = useQuery( PaymentMethodsGQL );
    const { defaultPaymentMethod, currentPaymentMethod, setCurrentPaymentMethod } = props;

    const handleNewPaymentMethod = ( newPaymentMethod ) => {
        setIsAddingNewPaymentMethod( false );
        if( !paymentMethods?.retrievePaymentMethods?.length ) {
            handleMakeDefaultPaymentMethod( newPaymentMethod );
            setCurrentPaymentMethod( newPaymentMethod );
            setValue( 'paymentMethod', newPaymentMethod.id );
        }
        refetchPaymentMethods();
    };

    const { handleMakeDefaultPaymentMethod } = paymentMethodHook();

    const handlePaymentMethodDeletion = ( paymentMethodDeleted: PaymentMethod ) => {
        if( paymentMethods?.retrievePaymentMethods?.length > 1 && currentPaymentMethod.id === paymentMethodDeleted?.id ) {
            const newPaymentMethods = paymentMethods?.retrievePaymentMethods?.filter( ( paymentMethod: PaymentMethod ) => paymentMethod.id !== paymentMethodDeleted?.id );
            handleMakeDefaultPaymentMethod( newPaymentMethods[ 0 ] );
            setCurrentPaymentMethod( newPaymentMethods[ 0 ] );
            setValue( 'paymentMethod', newPaymentMethods[ 0 ].id );
        }
        else if( paymentMethods?.retrievePaymentMethods?.length === 1 ) {
            setCurrentPaymentMethod( null );
            reset( { paymentMethod: null } );
        }
        refetchPaymentMethods();
    };

    const [ isAddingNewPaymentMethod, setIsAddingNewPaymentMethod ] = useState<boolean>( false );

    const { t } = useTranslation( [ 'common' ] );

    const {
              control,
              trigger,
              setValue,
              reset,
              formState: { errors },
              handleSubmit
          } = useForm();

    const modalRef = useRef( null );

    const [ chosenPaymentMethodId, setChosenPaymentMethodId ] = useState<string | null>( null );

    useImperativeHandle( ref, () => ( {
        open: openModal,
        close: closeModal
    } ) );

    const openModal = () => modalRef?.current?.open();

    const closeModal = () => modalRef?.current?.close();

    const onSubmit = async( data ): Promise<void> => {

        if( !( await trigger() ) ) {
            return;
        }

        const updatedPaymentMethods = paymentMethods.retrievePaymentMethods.find(
            ( method ) => method.id === data.paymentMethod
        );

        setCurrentPaymentMethod( updatedPaymentMethods );
        closeModal();
    };

    useEffect( () => {
        if( defaultPaymentMethod ) {
            setChosenPaymentMethodId( defaultPaymentMethod.id );
            setValue( 'paymentMethod', defaultPaymentMethod.id );
        }
    }, [ defaultPaymentMethod ] );


    const noDataJSX = (
        <div className="flex items-center justify-center w-full mb-5">
            <NoDataIcon message={ t( 'client:account.edit-payments.noData' ) } />
        </div>
    );

    return (
        <ResponsiveModal ref={ modalRef }
                         desktopWidth="600px"
                         mobileHeight="h-fit"
                         destroyOnClose={ true }
                         title={ t( 'common:chat.conversation.payment.paymentMethodModal.title' ) }>
            <Divider className="mt-0" />
            <RadioCheckmarkComponent control={ control }
                                     renderOptionContent={ ( method ) => (
                                         <PaymentMethodItem onDelete={ handlePaymentMethodDeletion }
                                                            currentPaymentMethod={ method }
                                                            key={ method.id } />
                                     ) }
                                     optionsRadio={ paymentMethods?.retrievePaymentMethods }
                                     defaultValue={ chosenPaymentMethodId }
                                     name="paymentMethod"
                                     className="mb-4"
                                     errors={ errors }
                                     rules={ { required: t( 'common:error.form.required' ) } } />
            {
                !paymentMethods?.retrievePaymentMethods.length && noDataJSX
            }

            {
                isAddingNewPaymentMethod ?
                <NewPaymentMethod onCancel={ () => setIsAddingNewPaymentMethod( false ) }
                                  onSuccess={ handleNewPaymentMethod } /> :
                <div className="flex lg:justify-end">
                    <Button type="primary"
                            onClick={ () => setIsAddingNewPaymentMethod( true ) }
                            className="mt-4 w-full lg:w-[230px]">
                        <PlusOutlinedIcon /> { t( 'client:account.edit-payments.addButton' ) }
                    </Button>
                </div>
            }
            <Divider />
            <Button type="primary"
                    className="w-full mt-10"
                    onClick={ handleSubmit( onSubmit ) }
                    disabled={ isCreatingPaymentMethod }
                    loading={ isCreatingPaymentMethod }>
                { t( 'common:chat.conversation.payment.paymentMethodModal.submitButton' ) }
            </Button>
        </ResponsiveModal>
    );
} );

export default PaymentMethodModal;