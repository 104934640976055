import InputComponent from '@/components/custom-element-form/InputComponent';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { App, SelectProps } from 'antd';
import getFlagEmoji from '@/utils/flagEmoji';
import SelectComponent from '@/components/custom-element-form/SelectComponent';
import countries from '@/constants/countries';
import JobForm from '@/components/JobForm';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { useMutation } from '@apollo/client';
import { CreateWaitingContactGQL } from '@/gql/global-queries/createWaitingContactGQL';
import { useNavigate } from 'react-router-dom';
import { SignUpFreelancerRepository } from '@/pages/auth/sign-up/sign-up-freelancer/signUpFrelancerRepository';

const SignUpFreelancerStepUnsupportedCountry = forwardRef( ( props, ref ) => {
    const { t } = useTranslation();
    const { message } = App.useApp();
    const navigate = useNavigate();

    const [ jobErrorActive, setJobErrorActive ] = useState<boolean>( false );

    const repository = new SignUpFreelancerRepository();

    const {
              trigger,
              control,
              getValues,
              formState: { errors }
          } = useForm();

    const jobFormRef = useRef( null );

    const [ createWaitingContactMutation, { data, loading, error } ] = useMutation( CreateWaitingContactGQL );

    useImperativeHandle( ref, () => ( {
            async submitForm() {
                return onTriggerSubmit();
            }
        } )
    );

    const getSelectOptions = (): SelectProps['options'] => {
        return countries?.map( ( { value, label } ) => {
            return {
                value,
                label: `${ getFlagEmoji( value ) } ${ t( label ) }`
            };
        } ).sort( ( a, b ) => a.label.localeCompare( b.label ) );
    };

    const selectOptions = getSelectOptions();

    const filterOption = ( inputValue, option ) => {
        return option?.label.toLowerCase().includes( inputValue.toLowerCase() );
    };

    const onTriggerSubmit = async(): Promise<void> => {

        repository.isLoadingNextStep = true;

        if( !( await trigger() ) || !checkIfJobIsValid ) {
            setJobErrorActive( true );
            return;
        }

        const email = getValues( 'email' );
        const country = getValues( 'country' );
        const jobLabels = jobFormRef?.current.getJobsSelected()?.join( ',' );

        try {
            const response = await createWaitingContactMutation( {
                variables: {
                    args: {
                        email,
                        country,
                        jobs: jobLabels
                    }
                }
            } );
            repository.isLoadingNextStep = false;
            if( response.data.createWaitingContact ) {
                message.success( t( 'auth:sign-up.freelancer.unsupported-country-page.formSend' ), 8);
                navigate( '/auth/sign-up/freelancer/discord' );
            }
            else {
                message.error( t( 'common:error.form.submit' ) );
            }
        } catch( e ) {
            repository.isLoadingNextStep = false;
            message.error( t( 'common:error.form.submit' ) );
        }
    };

    const checkIfJobIsValid = async(): Promise<boolean> => {
        const jobIds = jobFormRef?.current.getSelectedIds();
        return !( jobIds == null || jobIds.length === 0 );
    };

    const disableJobError = ( jobIds: string[] | null ) => {
        if( jobErrorActive && jobIds?.length > 0 ) {
            setJobErrorActive( false );
        }
    }

    return (
        <form className="flex flex-col gap-y-4 max-w-[800px]">
            <InputComponent control={ control }
                            name="email"
                            label={ t( 'auth:sign-up.freelancer.unsupported-country-page.email' ) }
                            placeholder={ t( 'auth:sign-up.freelancer.unsupported-country-page.emailPlaceholder' ) }
                            errors={ errors }
                            rules={ {
                                required: t( 'common:error.form.required' ),
                                pattern: {
                                    value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                                    message: t( 'common:error.form.invalidEmail' )
                                }
                            } } />


            <SelectComponent options={ selectOptions }
                             control={ control }
                             filterOption={ filterOption }
                             name="country"
                             className=""
                             label={ t( 'auth:sign-up.freelancer.unsupported-country-page.country' ) }
                             rules={ {
                                 required: t( 'common:error.form.required' )
                             } }
                             placeholder={ t( 'auth:sign-up.freelancer.unsupported-country-page.countryPlaceholder' ) }
                             errors={ errors } />

            <div className="flex flex-col gap-y-2">
                <label className="font-semibold text-base">
                    { t( 'auth:sign-up.freelancer.unsupported-country-page.jobLabel' ) }
                </label>
                <JobForm ref={ jobFormRef }
                         onJobsSelected={ ( jobIds ) => disableJobError( jobIds ) }
                         error={ jobErrorActive ? t( 'auth:sign-up.freelancer.unsupported-country-page.jobRequired' ) : null } />
            </div>
        </form>
    );
} );

export default SignUpFreelancerStepUnsupportedCountry;