import React, { useContext } from 'react';
import { FreelancersRepository } from '@/components/freelancers-filter-items/freelancersRepository';
import { Button } from 'antd';
import { FiltersOutlinedIcon } from '@/assets/icons/CustomIcons';
import { useTranslation } from 'react-i18next';
import { FreelancersRepositoryContext } from '@/core/FreelancerRepositoryContext';

interface TransverseFreelancerListHeaderOptionsProps {
    totalFreelancers?: number;
    countFilterActive?: number;
}

export default function TransverseFreelancerListHeaderOptions( {
                                                                   totalFreelancers,
                                                                   countFilterActive
                                                               }: TransverseFreelancerListHeaderOptionsProps ) {

    const { t } = useTranslation();
    const freelancersRepository: FreelancersRepository = useContext( FreelancersRepositoryContext );

    const handleFiltersOpening = () => freelancersRepository.setInvertOpen();
    return (
        <div className="fixed top-[72px] h-[80px] px-6 lg:px-0 flex items-center justify-center bg-white z-50 left-0 right-0 ">
            <div className="w-full max-w-[1312px]">
                <div className="flex items-center justify-between">
                    <div className="text-base font-medium">
                        { t( 'transverse:freelancers.freelancerAvailableWithCount', { count: totalFreelancers || 0 } ) }
                    </div>
                    <Button type="default"
                            onClick={ () => handleFiltersOpening() }
                            size="middle"
                            className="flex items-center gap-x-1">
                        <FiltersOutlinedIcon style={ { fontSize: '20px' } } />
                        { t( 'transverse:freelancers.header-options.filters' ) }
                        {
                            countFilterActive > 0 ?
                            <div className="ml-2 text-xs text-white bg-black rounded-full h-[24px] w-[24px] flex justify-center items-center">
                                { countFilterActive }
                            </div> :
                            null
                        }
                    </Button></div>
            </div>
        </div>
    );
}