import { BankNoteOutlinedIcon } from '@/assets/icons/CustomIcons';
import { priceFormatter } from '@/utils/priceFormatter';
import { CurrencyEnum } from '@/gql/graphql';
import { useTranslation } from 'react-i18next';
import { Tag } from 'antd';

interface FreelancerPriceProps {
    price: number;
    currency?: CurrencyEnum;
    className?: string;
    bordered?: boolean;
    color?: string;
    legend?: boolean;
    iconClassName?: string;
}

export default function FreelancerPrice( {
                                             price,
                                             currency = CurrencyEnum.USD,
                                             bordered = true,
                                             color,
                                             className,
                                             legend = true,
                                             iconClassName
                                         }: FreelancerPriceProps ) {

    const { t } = useTranslation();

    return (
        <Tag bordered={ bordered }
             className={ className }
             color={ color }>
            <BankNoteOutlinedIcon className={ iconClassName } />

            <span className="ml-2">
                {
                    priceFormatter( {
                        amount: price,
                        currency
                    } )
                }
                {
                    legend &&
                    <span>{ t( 'common:freelancerProfile.card.pricePerVideo' ) }</span>
                }
            </span>
        </Tag>
    );


}