import { useNavigate } from 'react-router-dom';

export interface ChatConversationItemStatusTemplateUI {
    icon: string;
    sentAt: string;
    text: string;
    button?: string;
    goTo?: string;
}

interface ChatConversationItemStatusTemplateProps {
    data: ChatConversationItemStatusTemplateUI;
}

export default function ChatConversationItemStatusTemplate( { data }: ChatConversationItemStatusTemplateProps ) {
    const navigation = useNavigate();

    const handleGoTo = () => {
        navigation( data.goTo! );
    };

    return (
        <div className="flex flex-col items-center">
            <h3 className="text-2xl font-bold">
                { data?.icon }
            </h3>
            <p className="font-bold text-sm mt-2">
                { data?.text }
            </p>
            {
                data?.button &&
                data?.goTo &&
                <button className="text-[13px] font-extrabold text-pink underline cursor-pointer mt-2"
                        onClick={ () => handleGoTo() }>
                    { data?.button }
                </button>
            }
        </div>
    );
}