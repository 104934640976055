import { useMediaQuery } from 'react-responsive';
import { Drawer, Modal, Space } from 'antd';
import { CloseOutlinedIcon } from '@/assets/icons/CustomIcons';
import { forwardRef, ReactNode, useContext, useEffect, useImperativeHandle, useState } from 'react';
import { ColorSchemeContext } from '@/core/dark-mode/colorSchemeProvider';
import { twMerge } from 'tailwind-merge';

interface ResponsiveModalProps {
    isOpen?: boolean;
    onClose?: ( value?: any | null ) => void;
    desktopWidth?: string;
    mobileHeight?: string;
    title: JSX.Element | string;
    rootClassName?: string;
    closable?: boolean;
    destroyOnClose?: boolean;
    width?: number;
    children: ReactNode;
}

const ResponsiveModal = forwardRef( ( props: ResponsiveModalProps, ref ) => {
    const {
              isOpen         = false,
              onClose,
              title,
              desktopWidth   = '500px',
              mobileHeight   = '100%',
              rootClassName,
              closable       = true,
              destroyOnClose = true,
              children
          } = props;

    const isTabletOrMobile = useMediaQuery( { query: `(max-width: ${ import.meta.env.VITE_MOBILE_BREAK_POINT })` } );

    const [ _isOpen, setIsOpen ] = useState( isOpen );
    const colorSchemeContext = useContext( ColorSchemeContext );

    useImperativeHandle( ref, () => ( {
            open() {
                setIsOpen( true );
            },
            close() {
                setIsOpen( false );
            },
            isOpen: _isOpen
        } )
    );

    useEffect( () => {
        setIsOpen( isOpen );
    }, [ isOpen ] );

    const handleClose = () => {
        setIsOpen( false );
        onClose?.();
    };

    return (
        <>
            {
                isTabletOrMobile ?
                <Drawer open={ _isOpen }
                        placement="bottom"
                        className="overflow-x-hidden mt-[15%] rounded-t-xl"
                        styles={ {
                            body: {
                                padding: '0 24px 24px 24px'
                            },
                            header: {
                                fontFamily: 'SharpGrotesk',
                                fontSize: '18px',
                                fontWeight: 500,
                                border: 0
                            }
                        } }
                        width="100%"
                        height={ mobileHeight }
                        closable={ false }
                        rootClassName={ twMerge( colorSchemeContext.toLowerCase(), rootClassName ) }
                        title={ title }
                        extra={
                            closable ?
                            <Space>
                                <div onClick={ () => handleClose() }>
                                    <CloseOutlinedIcon className="text-[14px] text-grey" />
                                </div>
                            </Space> :
                            null
                        }
                        destroyOnClose={ true }>
                    { children }
                </Drawer> :
                <Modal open={ _isOpen }
                       rootClassName={ twMerge( colorSchemeContext.toLowerCase(), rootClassName ) }
                       width={ desktopWidth }
                       maskClosable={ closable }
                       closeIcon={ closable }
                       destroyOnClose={ destroyOnClose }
                       title={ title }
                       onCancel={ () => handleClose() }
                       footer={ null }>
                    { children }
                </Modal>
            }
        </>
    );
} );

export default ResponsiveModal;
