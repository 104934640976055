import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { expertisesWithCountGQL } from '@/gql/global-queries/expertisesWithCountGQL';
import { Expertise } from '@/gql/graphql';
import Checkbox from 'antd/es/checkbox';
import { FreelancersRepository } from '@/components/freelancers-filter-items/freelancersRepository';
import ListSkeleton from '@/components/ListSkeleton';
import { FreelancersRepositoryContext } from '@/core/FreelancerRepositoryContext';

export default function FilterItemExpertisesCheckbox() {

    const { t } = useTranslation();
    const freelancersRepository: FreelancersRepository = useContext( FreelancersRepositoryContext );

    const defaultLimit: number = 5;

    const [ checkboxOptions, setCheckboxOptions ] = useState( null );
    const [ checkedValues, setCheckedValues ] = useState<string[]>();
    const [ limit, setLimit ] = useState( defaultLimit );

    const { data, loading, error } = useQuery( expertisesWithCountGQL );

    const convertExpertisesToCheckbox = ( expertises: Expertise[] ) => {
        if( expertises == null || expertises.length == 0 ) {
            return [];
        }

        return expertises.map( ( expertise: Expertise ) => {
            return {
                label: `${ t( 'enum.expertises.' + expertise.i18n.toUpperCase() ) } (${ expertise.totalFreelancer })`,
                value: expertise.id
            };
        } );
    };

    useEffect( () => {
        if( data ) {
            setCheckboxOptions( convertExpertisesToCheckbox( data.expertises ) );
            setCheckedValues( freelancersRepository.filters.expertiseIds );
        }
    }, [ data ] );

    useEffect( () => {
        const observable = freelancersRepository.expertiseIds$.subscribe( ( value ) => {
            setCheckedValues( value );
        } );
        return () => {
            observable.unsubscribe();
        };
    }, [] );

    const onChange = ( checkedValues: any ) => {
        freelancersRepository.setExpertiseIds( checkedValues );
    };

    const onShowMore = () => {
        setLimit( limit ? undefined : defaultLimit );
    };

    const coreJSX = (
        <div className="checkbox-vertical">
            {
                checkboxOptions?.length > 0 &&
                <Checkbox.Group style={ { display: 'flex', flexDirection: 'row' } }
                                className="gap-y-1 text-base font-medium"
                                options={ checkboxOptions }
                                value={ checkedValues }
                                onChange={ onChange }>
                </Checkbox.Group>
            }

            <div className={ `${ data?.expertises.length <= defaultLimit
                                 ? 'hidden'
                                 : '' } underline text-primary-500 cursor-pointer mt-4 text-sm` }
                 onClick={ onShowMore }>
                { limit ? t( 'common:button.showMoreButton' ) : t( 'common:button.showLessButton' ) }
            </div>
        </div>
    );

    const loadingJSX = (
        <div className="flex flex-col gap-y-1 max-w-[150px]">
            <ListSkeleton isLoading={ true }
                          count={ 5 }
                          height={ 15 } />
        </div>
    );

    return (
        <>
            {
                loading ? loadingJSX : coreJSX
            }
        </>
    );
}