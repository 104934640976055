import { Rate, Tag } from 'antd';
import { StarFilledIcon } from '@/assets/icons/CustomIcons';
import { twMerge } from 'tailwind-merge';

interface FreelancerRatingProps {
    rating: number;
    displayStars?: boolean;
    bordered?: boolean;
    ratingClass?: string;
}

export default function FreelancerRatingTag( {
                                                 rating = 0,
                                                 displayStars = true,
                                                 bordered = false,
                                                 ratingClass
                                             }: FreelancerRatingProps ) {

    return (
        <Tag bordered={ bordered }>
            <div className="flex items-center text-grey">
                {
                    displayStars ?
                    <Rate count={ 5 }
                          disabled
                          defaultValue={ rating } /> :
                    <StarFilledIcon className="text-[12px]" />
                }

                <span className={ twMerge( 'ml-2 font-medium', ratingClass ) }>
                         {
                             rating ? rating : '-'
                         }
                </span>
            </div>
        </Tag>
    );
}