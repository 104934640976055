import { useEffect, useState } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import { channelsGQL } from '@/gql/global-queries/channelsGQL';
import { freelancerFirstNameGQL } from '@/gql/global-queries/freelancerFirstNameGQL';

interface NewProjectHookProps {
    freelancer: {
        id: string;
        firstName?: string;
    };
}

export default function NewProjectHook( { freelancer }: NewProjectHookProps ) {

    const [ channel, setChannel ] = useState( null );
    const [ freelancerFirstName, setFreelancerFirstName ] = useState( null );
    const { data, loading, error } = useQuery( channelsGQL );

    const freelancerFirstNameQuery = useLazyQuery( freelancerFirstNameGQL );

    useEffect( () => {
        if( data && channel == null ) {
            setChannel( data.channels[ 0 ] );
        }
    }, [ data ] );

    useEffect( () => {
        if( freelancer != null && freelancer.id != null ) {
            if( freelancer.firstName == null ) {
                freelancerFirstNameQuery[ 0 ]( {
                    variables: {
                        freelancerId: freelancer.id
                    }
                } ).then( ( result ) => {
                    setFreelancerFirstName( result.data?.publicFreelancer.firstName );
                } );
            }
            else {
                setFreelancerFirstName( freelancer.firstName );
            }
        }
    }, [ freelancer ] );

    return {
        channel,
        setChannel,
        freelancerFirstName,
        loading,
        error
    };
}