import { useLazyQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { ProjectCardInterface } from '@/components/projects/project-card/ProjectCard';
import { Project, ProjectStatusEnum } from '@/gql/graphql';
import firstLetterUppercaseFormatter from '@/utils/firstLetterUppercaseFormatter';
import { DateTime } from 'luxon';
import { totalProjectsByStatusGQL } from '@/gql/global-queries/totalProjectsByStatusGQL';
import { useTranslation } from 'react-i18next';
import { freelancerProjectsGQL } from '@/gql/global-queries/freelancerProjectsGQL';
import { cuttingTextFormatter } from '@/utils/cuttingTextFormatter';
import abbreviationNumberFormatter from '@/utils/abbrevationNumberFormatter';
import { TotalProjectsUI } from '@/core/interfaces/totalProjectsUI';
import { ProjectsRepository } from '@/components/projects/projectsRepository';
import { App } from 'antd';

interface FreelancerProjectListDesktopHookProps {
    limit?: number;
}

export default function freelancerProjectsHook( { limit }: FreelancerProjectListDesktopHookProps = {} ) {

    const { t } = useTranslation();
    const { message } = App.useApp();
    const projectsStore: ProjectsRepository = new ProjectsRepository();

    const [ projects, setProjects ] = useState<ProjectCardInterface[]>( [] );
    const [ projectSelected, setProjectSelected ] = useState<ProjectCardInterface>( null );
    const [ statusSelected, setStatusSelected ] = useState<ProjectStatusEnum>( ProjectStatusEnum.IN_PROGRESS );
    const [ hasMore, setHasMore ] = useState<boolean>( true );
    const [ currentPage, setCurrentPage ] = useState<number>( 0 );
    const [ total, setTotal ] = useState<TotalProjectsUI>( {
        inProgress: 0,
        done: 0,
        cancelled: 0
    } );

    const [ projectsQuery, { data, loading, error, refetch } ] = useLazyQuery( freelancerProjectsGQL );

    const loadProjects = async ( status: ProjectStatusEnum ) => {
        loadTotal();

        setProjects([]);
        setStatusSelected( status );
        projectsQuery( {
            variables: {
                args: {
                    status
                },
                limit,
                page: 1
            },
            notifyOnNetworkStatusChange: true
        } );
    };


    const loadTotal = async ( ) => {
        await totalProjectsInProgressQuery[ 0 ]();
        await totalProjectsDoneQuery[ 0 ]();
        await totalProjectsCancelledQuery[ 0 ]();
    }

    const totalProjectsInProgressQuery = useLazyQuery( totalProjectsByStatusGQL, {
        variables: {
            args: {
                status: ProjectStatusEnum.IN_PROGRESS
            },
            limit: 1,
            page: 0
        }
    } );

    const totalProjectsDoneQuery = useLazyQuery( totalProjectsByStatusGQL, {
        variables: {
            args: {
                status: ProjectStatusEnum.DONE
            },
            limit: 1,
            page: 0
        }
    } );

    const totalProjectsCancelledQuery = useLazyQuery( totalProjectsByStatusGQL, {
        variables: {
            args: {
                status: ProjectStatusEnum.CANCELLED
            },
            limit: 1,
            page: 0
        }
    } );

    useEffect( () => {
        if( totalProjectsInProgressQuery[ 1 ].data ) {
            projectsStore.freelancerTotalProjects = {
                ...total,
                inProgress: totalProjectsInProgressQuery[ 1 ].data?.projects?.total,
            };
        }
    }, [ totalProjectsInProgressQuery[ 1 ].data ] );

    useEffect( () => {
        if( totalProjectsDoneQuery[ 1 ].data ) {
            projectsStore.freelancerTotalProjects = {
                ...total,
                done: totalProjectsDoneQuery[ 1 ].data?.projects?.total,
            };
        }
    }, [ totalProjectsDoneQuery[ 1 ].data ] );

    useEffect( () => {
        if( totalProjectsCancelledQuery[ 1 ].data ) {
            projectsStore.freelancerTotalProjects = {
                ...total,
                cancelled: totalProjectsCancelledQuery[ 1 ].data?.projects?.total,
            };
        }
    }, [ totalProjectsCancelledQuery[ 1 ].data ] );

    useEffect( () => {
        if( data ) {
            managedData( data.projects );
        }
    }, [ data ] );

    useEffect( () => {   
        totalProjectsInProgressQuery[0]();
        const observableTotalProjects = projectsStore.freelancerTotalProjects$.subscribe( ( state ) => {
            setTotal({
                ...total,
                inProgress: state.inProgress,
                cancelled: state.cancelled,
                done: state.done,
            });
        } );

        return () => {
            observableTotalProjects.unsubscribe();
        };
    }, [] );

    const projectFromDataGQL = ( data ): ProjectCardInterface[] => {
        return data?.map( ( project: Project ) => {
            return {
                id: project.id,
                status: project.status,
                user: {
                    name: project.channel ? cuttingTextFormatter( project.channel?.name, 15 ) : project.client.userName,
                    imageUrl: project.channel ? project.channel.thumbnailUrl : project.client.profilePicture,
                    description: project.channel
                                 ? abbreviationNumberFormatter( project.channel?.subscriberCount ) + ' ' + t( 'freelancer:projects.subscribers' )
                                 : null
                },
                name: firstLetterUppercaseFormatter( project.name ),
                clientInvoiceUrl: project.payment?.clientInvoiceUrl,
                freelancerInvoiceUrl: project.payment?.freelancerInvoiceUrl,
                amount: project.payment?.clientAmountIncVat,
                hasReviewed: project?.freelancerHasReviewed,
                hasReviewedByClient: project.clientHasReviewed,
                currency: project.payment?.currency,
                startedAt: DateTime.fromISO( project.startedAt ).toLocaleString( DateTime.DATE_SHORT )
            };
        } );
    };

    const loadMore = () => {
        refetch( {
                args: {
                    status: statusSelected
                },
                limit,
                page: currentPage + 1
            }
        );
    };

    const handleRefresh = ( project: Project ) => {
        const updatedProjects = [...projects]
        let index = projects.findIndex(existingroject => existingroject.id === project.id);        
        
        if ( index !== -1 ) {
            updatedProjects[ index ].hasReviewedByClient = project.clientHasReviewed
            updatedProjects[ index ].hasReviewed = project.freelancerHasReviewed
            if( project.clientHasReviewed && project.freelancerHasReviewed ) {
                updatedProjects.splice( index, 1 );
                projectsStore.freelancerTotalProjects = {
                    ...total,
                    inProgress: total.inProgress - 1,
                    done: total.done + 1,
                };
                message.success( t( 'freelancer:project.projectDone' ) );
            }
            setProjects( updatedProjects )
        }
    };

    const managedData = ( result ) => {
        const mergedArray = [
            ...projects,
            ...projectFromDataGQL( result.data ).filter(obj2 => !projects.some(obj1 => obj1.id === obj2.id))
        ];
        setProjects( mergedArray );
        setHasMore( result.page < result.lastPage );
        setCurrentPage( result.page );
    };

    return {
        loadProjects,
        loadTotal,
        projects,
        projectSelected,
        total,
        loading,
        loadMore,
        hasMore,
        setProjectSelected,
        handleRefresh,
    };
}