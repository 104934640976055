import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';

import { SessionRepository } from '@/core/auth/sessionRepository';
import { RoundedCheckFilledIcon } from '@/assets/icons/CustomIcons';
import { useLazyQuery } from '@apollo/client';
import { useEffect } from 'react';
import { identifySegmentUser, userTypeName } from '@/utils/analytics';
import { ClientProfileGQL } from '@/pages/client/views/account/client-account-edit-profile/queries/clientProfileGQL';
import { Client } from '@/gql/graphql';

export default function SignUpClientStepWelcome() {

    const { t } = useTranslation();
    const session = new SessionRepository();

    const nav = useNavigate();

    const nextButton = () => nav( '/client/discover' );

    const clientsQuery = useLazyQuery( ClientProfileGQL, {
            variables: {
                id: session?.userId
            }
        }
    );
    useEffect( () => {
        if( clientsQuery[ 1 ].data ) {
            const result = clientsQuery[ 1 ].data.client;
            identifySegmentUser(result as Client, userTypeName.Creator)
        }
    }, [ clientsQuery[ 1 ].data ] );

    useEffect( () => {
        clientsQuery[ 0 ]() 
    }, [ ] );

    return (
        <div className="h-screen flex justify-center items-center bg-[url('/img/bg-welcome-signup.png')] bg-no-repeat bg-cover p-6">
            <div className="bg-white h-fit w-full max-w-[600px] shadow-2xl rounded-xl">
                <div className="flex flex-col items-center justify-center p-6">
                    <RoundedCheckFilledIcon className="text-[46px] text-green-200" />
                    <div className="text-xl lg:text-2xl font-sharp font-medium mt-8 text-center">
                        { t( 'auth:sign-up.client.welcome-page.title', { name: session.user?.userName } ) }
                    </div>
                    <div className="text-base font-normal mt-4 text-center">
                        { t( 'auth:sign-up.client.welcome-page.description' ) }
                    </div>
                    <Button type="primary"
                            className="w-full mt-8"
                            onClick={ () => nextButton() }>
                        { t( 'auth:sign-up.freelancer.welcome-page.button' ) }
                    </Button>
                </div>
            </div>
        </div>
    );
}