import { Avatar } from 'antd';
import { ConversationReceiverProfileUI } from '@/components/chat/interfaces/conversationReceiverProfileUI';
import { ReactNode } from 'react';
import { UserOutlined } from '@ant-design/icons';
import { ChevronLeftFilledIcon } from '@/assets/icons/CustomIcons';
import { useNavigate } from 'react-router-dom';
import IsOnlineTag from '@/components/IsOnlineTag';
import { twMerge } from 'tailwind-merge';


interface ChatConversationHeaderProfileProps {
    profile: ConversationReceiverProfileUI;
    enableClickOnProfile?: boolean;
    customHeader?: ReactNode;
    isOnline: boolean;
    size: 'small' | 'large';
}

export default function ChatConversationHeaderReceiver( {
                                                            profile,
                                                            enableClickOnProfile = false,
                                                            customHeader,
                                                            isOnline,
                                                            size
                                                        }: ChatConversationHeaderProfileProps ) {
    const navigate = useNavigate();

    const handleBack = () => navigate( -1 );

    const goOnProfile = () => {
        if( enableClickOnProfile ) {
            navigate( `/p/${ profile.customUrl ? profile?.customUrl : profile?.id }` );
        }
    };

    return (
        <div className="w-full flex justify-between">
            <div className="flex-none flex">

                <div className="lg:hidden flex items-center"
                     onClick={ () => handleBack() }>
                    <ChevronLeftFilledIcon className=" text-black text-[24px] mr-6" />
                </div>

                <Avatar size={ size == 'large' ? 56 : 48 }
                        onClick={ () => goOnProfile() }
                        className={ twMerge( enableClickOnProfile ? 'cursor-pointer' : '' ) }
                        icon={ <UserOutlined /> }
                        src={ profile?.avatar } />
                <div className="flex flex-col ml-4 gap-y-1">
                    <div className={ twMerge( enableClickOnProfile ? 'cursor-pointer' : '', 'font-semibold' ) }
                         onClick={ () => goOnProfile() }>
                        { profile?.userName }
                    </div>
                    <IsOnlineTag isOnline={ isOnline }
                                 size={ size } />
                </div>
            </div>
            <div className="w-fit">
                { customHeader }
            </div>
        </div>
    );
}
