import { useEffect, useState } from 'react';
import { ChannelManagerRepository } from '@/pages/client/common/channelManagerRepository';
import { Channel } from '@/gql/graphql';
import { useQuery } from '@apollo/client';
import { channelsGQL } from '@/gql/global-queries/channelsGQL';
import AddYTChannelHook from '@/hooks/addYTChannelHook';

export default function channelManagerSettingsHook() {

    const [ channels, setChannels ] = useState<Channel[]>( [] );
    const [ channelSelected, setChannelSelected ] = useState<Channel[]  | null>( [] );
    const [ channelSelectedKey, setChannelSelectedKey ] = useState<string>( null );
    const channelManagerRepository = new ChannelManagerRepository();

    const { data, loading, error } = useQuery( channelsGQL );

    useEffect( () => {
        if( data ) {
            setChannels( data?.channels );
        }
    }, [ data ] );

    useEffect( () => {
        if( !channels || channelSelected == null ) {
            setChannelSelectedKey( `custom-section-2` );
            return;
        }

        if( Array.isArray( channelSelected )  && channelSelected.length == 0 ) {
            setChannelSelectedKey( `custom-section-1` );
            return;
        }

        if( Array.isArray( channelSelected ) && channelSelected.length > 0 ) {
            const index: number = channels?.findIndex( ( channel ) => channel.id === channelSelected[ 0 ]?.id );
            setChannelSelectedKey( `custom-section-${ index + 3 }` );
        }
    }, [ channelSelected ] );

    useEffect( () => {
        const observableChannels = channelManagerRepository.channels$.subscribe( ( channel: Channel[] ) => {
            setChannelSelected( channel );
        } );

        return () => {
            observableChannels.unsubscribe();
        };
    }, [] );

    const addYTChannelHook = AddYTChannelHook();

    useEffect( () => {
        if( addYTChannelHook.channel ) {
            setChannels( [ ...channels, addYTChannelHook.channel ] );
        }
    }, [ addYTChannelHook.channel ] );

    const handleAddChannel = (): void => {
        addYTChannelHook.login();
    };

    const updateSelectedChannel = ( channel: Channel | [] | null ) => {
        if( channel == null ) {
            channelManagerRepository.channel = null;
            return;
        }

        if( Array.isArray(channel) && channel.length == 0 ) {
            channelManagerRepository.channel = [];
            return;
        }

        channelManagerRepository.channel = [ channel as Channel ];
    };

    return {
        channels,
        loading,
        channelSelected,
        updateSelectedChannel,
        channelSelectedKey,
        handleAddChannel
    };
}