import SignUpLeftSide from '@/pages/auth/sign-up/components/SignUpLeftSide';
import SignUpLayout from '@/pages/auth/sign-up/components/SignUpLayout';
import SignUpFreelancerStepMain from '@/pages/auth/sign-up/sign-up-freelancer/sign-up-freelancer-steps/SignUpFreelancerStepMain';
import signUpFreelancerHook from '@/hooks/signUpFreelancerHook';
import Steps from '@/components/Step';

export default function SignUpFreelancer() {

    const {
              items,
              progressBar,
              isLoading,
              previousStep,
              currentStep,
              nextStep,
              currentProgressBarIndex,
              isLastStep
          } = signUpFreelancerHook();

    const handleNext = async() => {
        await nextStep();
    };

    const leftSide = (
        <SignUpLeftSide data={ {
            title: items[ currentStep ]?.titleLeft,
            stepsBar: <Steps steps={ progressBar.map( item => item.label ) }
                             currentStep={ currentProgressBarIndex() } />
        } } />
    );

    return (
        <SignUpLayout leftSide={ leftSide }
                      rightSide={
                          <SignUpFreelancerStepMain steps={ items as any }
                                                    currentStep={ currentStep } />
                      }
                      isLastStep={ isLastStep }
                      loadingNext={ isLoading }
                      onNext={ () => handleNext() }
                      onBack={ () => previousStep() } />
    );
}