import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { PortfolioElementsGQL } from '@/gql/global-queries/portfolioElementsGQL';
import FreelancerAccountEditPortfolioElementOrderedList from '@/pages/freelancer/views/account/freelancer-account-edit-portfolio-elements/components/FreelancerAccountEditPortfolioElementOrderedList';
import { DeletePortfolioElementGQL } from '@/gql/global-queries/deletePortfolioElementGQL';
import { UpdatePortfolioElementsOrderGQL } from '@/gql/global-queries/updatePortfolioElementsOrderGQL';
import { CreatePortfolioElementGQL } from '@/gql/global-queries/createPortfolioElementGQL';
import { Button, Divider, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import * as Sentry from '@sentry/react';
import InputComponent from '@/components/custom-element-form/InputComponent';
import { PortfolioElement } from '@/gql/graphql';
import { identifySegmentUser, PageName, SegmentEvent, trackPageView, trackSegmentEvent, userTypeName } from '@/utils/analytics';

export default function FreelancerAccountEditPortfolioElements() {

    const videoLimit: number = 6;

    const { t } = useTranslation();
    const { trigger, control, formState: { errors }, handleSubmit, reset } = useForm();

    const [ messageApi, contextHolder ] = message.useMessage();
    const [ portfolioElements, setPortfolioItems ] = useState<PortfolioElement[]>( null );
    const [ isDeleting, setIsDeleting ] = useState<string[]>( [] );

    const portfolioElementsQuery = useQuery( PortfolioElementsGQL );
    const deletePortfolioElementMutation = useMutation( DeletePortfolioElementGQL );
    const updateOrderPortfolioElementMutation = useMutation( UpdatePortfolioElementsOrderGQL );
    const addPortfolioElementMutation = useMutation( CreatePortfolioElementGQL );

    useEffect( () => {
        if( portfolioElementsQuery.data ) {
            setPortfolioItems( portfolioElementsQuery.data.portfolioElements );
        }
    }, [ portfolioElementsQuery.data ] );

    useEffect( () => {
        if( deletePortfolioElementMutation[ 1 ].data ) {
            setPortfolioItems( portfolioElements.filter( ( portfolioElement ) => portfolioElement.id !== deletePortfolioElementMutation[ 1 ].data.deletePortfolioElement.id ) );
        }

    }, [ deletePortfolioElementMutation[ 1 ].data ] );

    useEffect( () => {
        trackPageView( PageName.FreelancerEditPortfolioElements );
    }, [] );

    const handleDelete = async( portfolioElement ) => {
        setIsDeleting( [ ...isDeleting, portfolioElement.id ] );
        try {
            await deletePortfolioElementMutation[ 0 ]( {
                variables: {
                    id: portfolioElement.id
                }
            } );
            trackSegmentEvent( SegmentEvent.ProfileUpdated, { profile_updated_type: 'Portfolio' } );
        } catch( e ) {
            Sentry.captureException( 'An error occur when trying to delete portfolio elements', e );
            messageApi.error( t( 'translation:error.default' ) );
        }
        setIsDeleting( isDeleting.filter( ( id ) => id !== portfolioElement.id ) );
    };

    const handleOrderChange = ( orderedList ) => {
        updateOrderPortfolioElementMutation[ 0 ]( {
            variables: {
                args: orderedList.map( ( portfolioElement ) => {
                    return {
                        portfolioElementId: portfolioElement.id,
                        order: portfolioElement.order
                    };
                } )
            }
        } );
        trackSegmentEvent( SegmentEvent.ProfileUpdated, { profile_updated_type: 'Portfolio' } );
    };

    const onSubmit = async( data ) => {
        if( !( await trigger() ) ) {
            return;
        }

        try {
            const portfolioElement = await addPortfolioElementMutation[ 0 ]( {
                variables: {
                    url: data.url
                }
            } );

            const newPortfolioElements = [
                ...portfolioElements,
                ...portfolioElement.data.createPortfolioElement.portfolioElements
            ];
            setPortfolioItems( newPortfolioElements );

            const portfolioElementsTotalViews = newPortfolioElements.reduce( ( acc, video ) => {
                const viewCount = video.viewCount || 0;
                return acc + viewCount;
            }, 0 );

            await identifySegmentUser( { totalPortfolioViews: portfolioElementsTotalViews }, userTypeName.Freelancer );

            reset( {
                url: ''
            } );

            messageApi.success( t( 'freelancer:account.edit-portfolio-elements.videoImportedWithCount', { count: portfolioElement.data.createPortfolioElement.portfolioElements?.length } ) );

            trackSegmentEvent( SegmentEvent.ProfileUpdated, { profile_updated_type: 'Portfolio' } );
        } catch( e ) {
            let errorMessage: string = t( 'translation:error.default' );
            if( e.message === 'You can\'t add more than 6 portfolio elements' ) {
                errorMessage = t( 'freelancer:account.edit-portfolio-elements.errorRichLimit', { limit: videoLimit } );
            }
            Sentry.captureException( 'An error occur when trying to add new portfolio element', e );
            messageApi.error( errorMessage );
        }
    };

    return (
        <div className="w-full flex flex-col">
            { contextHolder }
            <h1>{ t( 'freelancer:account.edit-portfolio-elements.title' ) }</h1>

            <p className="text-grey text-sm mt-1">
                { t( 'freelancer:account.edit-portfolio-elements.subTitle' ) }
            </p>

            <div className="mt-6">
                <FreelancerAccountEditPortfolioElementOrderedList portfolioElements={ portfolioElements }
                                                                  isLoading={ portfolioElementsQuery.loading }
                                                                  isDeleting={ isDeleting }
                                                                  onOrderChange={ ( orderedList ) => handleOrderChange( orderedList ) }
                                                                  onDeleteItem={ ( portfolioElement ) => handleDelete( portfolioElement ) } />
            </div>
            {
                !portfolioElementsQuery.loading &&
                <>
                  <Divider type="horizontal" />
                  <div className="text-base font-medium">
                      { t( 'freelancer:account.edit-portfolio-elements.addProject' ) }
                  </div>

                  <Form className="flex flex-col lg:flex-row mt-2"
                        onSubmit={ handleSubmit( onSubmit ) }>

                    <InputComponent control={ control }
                                    name="url"
                                    rules={ {
                                        required: t( 'common:error.form.required' ),
                                        pattern: {
                                            value: /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*\??[\w=&\+\%]*)*\/?$/,
                                            message: t( 'freelancer:account.edit-portfolio-elements.wrongUrl' )
                                        }
                                    } }
                                    placeholder={
                                        t( 'freelancer:account.edit-portfolio-elements.inputPlaceholder' )
                                    }
                                    errors={ errors } />

                    <Button type="primary"
                            htmlType="submit"
                            loading={ addPortfolioElementMutation[ 1 ]?.loading }
                            className="w-full lg:w-[160px] lg:ml-4 mt-4 lg:mt-0">
                        { t( 'freelancer:account.edit-portfolio-elements.submit' ) }
                    </Button>
                  </Form>
                </>
            }
        </div>
    );
}