import { gql } from '@apollo/client';

export const privateFreelancersGQL = gql`
    query PrivateFreelancers($args: FreelancersFiltersInput, $page: Int, $limit: Int) {
        privateFreelancers(args: $args, page: $page, limit: $limit) {
            data {
                id
                firstName
                lastName
                profilePicture
                isFavorite
                mainJob
                avgPrice
                priceCurrency
                experience
                isAvailable
                isCertified
                accountStatus
                creatorsIWorkedWith {
                    id
                    name
                    channelUrl
                    thumbnailUrl
                    subscriberCount
                }
                portfolioElements {
                    id
                    url
                }
            },
            page
            limit
            lastPage
            total
        }
    }
`;