import { gql } from '@apollo/client';


export const languagesGQL = gql`
    query Languages {
        languages {
            id
            i18n
        }
    }`;


