import { useEffect, useRef, useState } from 'react';

interface StepsProps {
    steps: string[];
    currentStep: number;
}

export default function Steps( { steps, currentStep }: StepsProps ) {
    const [ progressWidth, setProgressWidth ] = useState( 0 );
    const barRef = useRef( null );
    const labelRefs = useRef( [] );

    useEffect( () => {
        if( barRef.current ) {
            let progressWidthPercentage: number;

            if( currentStep === 0 ) {
                progressWidthPercentage = 0;
            }
            else if( currentStep === steps.length - 1 ) {
                progressWidthPercentage = 100;
            }
            else if( labelRefs.current[ currentStep ] ) {
                const barStart = barRef.current.getBoundingClientRect().left;
                const labelMidpoint = labelRefs.current[ currentStep ].getBoundingClientRect().left
                                      + ( labelRefs.current[ currentStep ].getBoundingClientRect().width / 2 );

                const progressWidthInPixels = labelMidpoint - barStart;
                const barWidth = barRef.current.offsetWidth;
                progressWidthPercentage = ( progressWidthInPixels / barWidth ) * 100;
            }

            setProgressWidth( progressWidthPercentage );
        }
    }, [ currentStep, steps.length ] );

    return (
        <div className="relative pb-4">
            <div ref={ barRef }
                 className="h-2 bg-gray-200 relative rounded-full overflow-hidden">
                <div className="absolute h-2 left-0"
                     style={ {
                         width: `${ progressWidth }%`,
                         background: `linear-gradient(90deg, #D490EE 0%, #3C94F7 33.85%, #5ECDB1 54.17%, #6AE465 100%)`
                     } }>
                </div>
            </div>
            <div className="w-full mt-2 flex justify-between">
                {
                    steps.map( ( label, index ) => (
                        <span
                            key={ index }
                            ref={ el => labelRefs.current[ index ] = el }
                            className="text-xs font-light whitespace-nowrap text-white">
                            { label }
                        </span>
                    ) )
                }
            </div>
        </div>
    );
}