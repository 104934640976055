import { App, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useRef } from 'react';
import { QuoteStatusEnum } from '@/gql/graphql';
import { ConversationItemQuote } from '@/components/chat/interfaces/conversationItemQuote';
import ChatConversationQuoteStatusTag from '@/components/chat/components/chat-conversation/chat-conversation-messages/components/chat-conversation-items/components/ChatConversationQuoteStatusTag';
import { DateTime } from 'luxon';
import { useMutation } from '@apollo/client';
import { CancelQuoteGQL } from '@/gql/global-queries/cancelProjectGQL';
import { DeclineQuoteGQL } from '@/gql/global-queries/declineQuoteGQL';
import ChatConversationPaymentModal from '@/components/chat/components/chat-conversation/chat-conversation-payment-modal/ChatConversationPaymentModal';
import * as Sentry from '@sentry/react';
import { PriceComponent } from '@/components/PriceComponent';
import { SegmentEvent, trackSegmentEvent } from '@/utils/analytics';
import { CalendarOutlinedIcon, FileOutlinedIcon } from '@/assets/icons/CustomIcons';

interface ChatConversationOfferProps {
    item: ConversationItemQuote;
    isSender: boolean;
}

export default function ChatConversationItemQuote( { item, isSender }: ChatConversationOfferProps ) {

    const { t } = useTranslation();
    const paymentModalRef = useRef( null );
    const { message } = App.useApp();

    const cancelQuoteQuery = useMutation( CancelQuoteGQL );
    const declineQuoteQuery = useMutation( DeclineQuoteGQL );

    const handleAcceptOffer = () => {
        trackSegmentEvent(
            SegmentEvent.QuoteAccepted,
            {
                quote_amount: item?.amountIncVAT,
                project_id: item?.projectId,
                project_creator: item?.receiverId
            }
        );

        paymentModalRef.current?.open();
    };

    const handleDecline = async() => {
        try {
            await declineQuoteQuery[ 0 ]( {
                variables: {
                    id: item.quoteId
                }
            } );
        } catch( e ) {
            Sentry.captureException( 'An error occur when trying to decline a quote', e );
            message.error( {
                content: t( 'common:error.default' ),
                duration: 4
            } );
        }
    };

    const handleCancel = async() => {
        try {
            await cancelQuoteQuery[ 0 ]( {
                variables: {
                    id: item.quoteId
                }
            } );
        } catch( e ) {
            Sentry.captureException( 'An error occur when trying to cancel a quote', e );
            message.error( {
                content: t( 'common:error.default' ),
                duration: 4
            } );
        }
    };

    return (
        <>
            <div className="flex flex-col gap-y-1 items-start w-full min-w-[260px]">
                <div className="w-fit">
                    <ChatConversationQuoteStatusTag status={ item.status } />
                </div>

                <h4 className="font-sharp font-medium text-[40px]">
                    <PriceComponent amount={ item.amountIncVAT }
                                    vat={ false }
                                    decimalClass="text-[20px]"
                                    currency={ item.currency }
                                    decimals={ true } />
                </h4>

                <div className="flex items-center gap-x-2">
                    <CalendarOutlinedIcon className="text-sm" />
                    { t( 'freelancer:chat.quote.inputs.card.dueDate' ) }{ DateTime.fromISO( item.dueAt ).toLocaleString() }
                </div>

                {
                    item?.projectName ?
                    <div className="flex items-center gap-x-2">
                        <FileOutlinedIcon className="text-sm" />
                        { item.projectName }
                    </div> :
                    null
                }
                
                <div className="w-full flex gap-x-2 mt-4">
                    {
                        isSender &&
                        item.status === QuoteStatusEnum.PENDING ?
                        <Button type="default"
                                size="small"
                                className="w-full"
                                loading={ cancelQuoteQuery[ 1 ].loading }
                                onClick={ () => handleCancel() }>
                            { t( 'common:chat.conversation.buttonCancelOffer' ) }
                        </Button> :
                        null
                    }

                    {
                        !isSender &&
                        item.status === QuoteStatusEnum.PENDING &&
                        <>
                          <Button type="primary"
                                  size="small"
                                  className="w-1/2"
                                  onClick={ () => handleAcceptOffer() }>
                              { t( 'common:chat.conversation.buttonAcceptOffer' ) }
                          </Button>

                          <Button type="default"
                                  size="small"
                                  className="w-1/2"
                                  loading={ declineQuoteQuery[ 1 ].loading }
                                  onClick={ () => handleDecline() }>
                              { t( 'common:chat.conversation.buttonDecline' ) }
                          </Button>
                        </>
                    }
                </div>
            </div>
            <ChatConversationPaymentModal ref={ paymentModalRef }
                                          quoteId={ item.quoteId } />
        </>
    );
}