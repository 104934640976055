import { createStore, select, withProps } from '@ngneat/elf';
import { ExperienceTypeEnum, Freelancer } from '@/gql/graphql';
import { combineLatest, map, Observable } from 'rxjs';
import { addEntities, deleteAllEntities, getAllEntities, selectAllEntities, updateEntities, withEntities } from '@ngneat/elf-entities';
import { getPaginationData, setCurrentPage, setPage, updatePaginationData, withPagination } from '@ngneat/elf-pagination';

const PER_PAGE: number = 16;

export interface FiltersProps {
    args?: {
        isAvailable?: boolean | null;
        isCertified?: boolean | null;
        avgPriceMin?: number | null;
        avgPriceMax?: number | null;
        experiences?: ExperienceTypeEnum[] | null;
        jobIds?: string[] | null;
        expertiseIds?: string[] | null;
        skillIds?: string[] | null;
        languageIds?: string[] | null;
    };
    isOpen: boolean;
}

const defaultFilters = {
    isAvailable: null,
    isCertified: null,
    avgPriceMin: 0,
    avgPriceMax: 2000,
    expertiseIds: [],
    skillIds: [],
    languageIds: [],
    jobIds: [],
    experiences: []
};

export interface paginationData {
    currentPage: number;
    perPage: number;
    total: number;
    lastPage: number;
}

const freelancersStore = createStore(
    { name: 'freelancers' },
    withEntities<Freelancer>(),
    withProps<FiltersProps>( {
        args: {
            ...defaultFilters
        },
        isOpen: false
    } ),
    withPagination()
);

export class FreelancersRepository {

    freelancers$: Observable<Freelancer[]> = freelancersStore.pipe( selectAllEntities() );
    hasMore$: Observable<boolean> = freelancersStore.pipe(
        select( getPaginationData() ),
        map( ( { currentPage, lastPage } ) => currentPage < lastPage )
    );

    totalFreelancers$: Observable<number> = freelancersStore.pipe( select( getPaginationData() ), map( ( { total } ) => total ) );

    isOpen$ = freelancersStore.pipe( select( ( state ) => state.isOpen ) );

    filters$ = freelancersStore.pipe( select( ( state ) => {
        return state.args;
    } ) );

    isAvailable$ = freelancersStore.pipe( select( ( state ) => state.args.isAvailable ) );
    isCertified$ = freelancersStore.pipe( select( ( state ) => state.args.isCertified ) );

    avgPriceMin$ = freelancersStore.pipe(select(state => state.args.avgPriceMin));
    avgPriceMax$ = freelancersStore.pipe(select(state => state.args.avgPriceMax));

    experience$ = freelancersStore.pipe( select( ( state ) => state.args.experiences ) );
    jobIds$ = freelancersStore.pipe( select( ( state ) => state.args.jobIds ) );
    skillIds$ = freelancersStore.pipe( select( ( state ) => state.args.skillIds ) );
    expertiseIds$ = freelancersStore.pipe( select( ( state ) => state.args.expertiseIds ) );
    languageIds$ = freelancersStore.pipe( select( ( state ) => state.args.languageIds ) );

    updatedFiltersCount$ = combineLatest( [
        this.filters$
    ] ).pipe(
        map( ( [ filters ] ) => this.countUpdatedFilters( filters ) )
    );

    get filters() {
        return freelancersStore.query( ( state ) => state.args );
    }

    getFiltersForQuery() {
        return freelancersStore.query( ( state ) => {
            return {
                ...state.args,
                avgPrice: {
                    min: +state.args.avgPriceMin,
                    max: +state.args.avgPriceMax
                },
                avgPriceMin: undefined,
                avgPriceMax: undefined
            }
        });
    }

    get isOpen(): boolean {
        return freelancersStore.query( ( state ) => state.isOpen );
    }

    set isOpen( isOpen: boolean ) {
        freelancersStore.update( ( state ) => ( { ...state, isOpen } ) );
    }

    get isAvailable(): boolean {
        return freelancersStore.query( ( state ) => state.args.isAvailable );
    }

    get isCertified(): boolean {
        return freelancersStore.query( ( state ) => state.args.isCertified ? state.args.isCertified : false );
    }

    get avgPriceMin(): number | null {
        return freelancersStore.query( ( state ) => state.args.avgPriceMin );
    }

    get avgPriceMax(): number | null {
        return freelancersStore.query( ( state ) => state.args.avgPriceMax );
    }

    get experiences(): ExperienceTypeEnum[] | null {
        return freelancersStore.query( ( state ) => state.args.experiences );
    }

    get jobIds(): string[] | null {
        return freelancersStore.query( ( state ) => state.args.jobIds );
    }

    get skillIds(): string[] | null {
        return freelancersStore.query( ( state ) => state.args.skillIds );
    }

    get expertiseIds(): string[] | null {
        return freelancersStore.query( ( state ) => state.args.expertiseIds );
    }

    get languageIds(): string[] | null {
        return freelancersStore.query( ( state ) => state.args.languageIds );
    }

    get paginationData(): paginationData {
        return freelancersStore.query( ( getPaginationData() ) );
    }

    get hasMore(): boolean {
        const result = freelancersStore.query( getPaginationData() );
        return result.currentPage < result.lastPage;
    }

    get perPage(): number {
        const pagination = this.paginationData;
        return pagination.perPage || PER_PAGE;
    }

    get freelancers(): Freelancer[] {
        return freelancersStore.query( getAllEntities() );
    }

   addFreelancers(freelancers: Freelancer[], currentPage: number, total: number, lastPage: number) {
    // Get the current freelancers in the store
    const currentFreelancers = this.freelancers;

    // Filter out the freelancers that already exist in the store
    const newFreelancers = freelancers.filter(freelancer =>
        !currentFreelancers.some(currentFreelancer => currentFreelancer.id === freelancer.id)
    );

    // Only add the new freelancers to the store
    freelancersStore.update(
        addEntities(newFreelancers),
        updatePaginationData({
            total,
            lastPage,
            currentPage,
            perPage: PER_PAGE
        }),
        setPage(
            currentPage,
            newFreelancers.map((c) => c.id)
        )
    );
}

    setInvertOpen() {
        freelancersStore.update( ( state ) => ( { ...state, isOpen: !state.isOpen } ) );
    }

    setAvgPriceMin( avgPriceMin: number ) {
        return freelancersStore.update( ( state ) => ( { ...state, args: { ...state.args, avgPriceMin } } ) );
    }

    setAvgPriceMax( avgPriceMax: number ) {
        return freelancersStore.update( ( state ) => ( { ...state, args: { ...state.args, avgPriceMax } } ) );
    }

    setAvailable( isAvailable: boolean ) {
        freelancersStore.update( ( state ) => ( { ...state, args: { ...state.args, isAvailable } } ) );
    }

    setIsCertified( isCertified: boolean ) {
        freelancersStore.update( ( state ) => ( { ...state, args: { ...state.args, isCertified } } ) );
    }

    setExperiences( experiences: ExperienceTypeEnum[] ) {
        freelancersStore.update( ( state ) => ( { ...state, args: { ...state.args, experiences } } ) );
    }

    setSkillIds( skillIds: string[] ) {
        freelancersStore.update( ( state ) => ( { ...state, args: { ...state.args, skillIds } } ) );
    }

    setJobIds( jobIds: string[] ) {
        freelancersStore.update( ( state ) => ( { ...state, args: { ...state.args, jobIds } } ) );
    }

    setExpertiseIds( expertiseIds: string[] ) {
        freelancersStore.update( ( state ) => ( { ...state, args: { ...state.args, expertiseIds } } ) );
    }

    setLanguageIds( languageIds: string[] ) {
        freelancersStore.update( ( state ) => ( { ...state, args: { ...state.args, languageIds } } ) );
    }

    setPage( page: number ) {
        freelancersStore.update( setCurrentPage( page ) );
    }

    resetFilters() {
        freelancersStore.update( ( state ) => ( { ...state, args: { ...defaultFilters } } ) );
    }

    resetFreelancers() {
        freelancersStore.update( deleteAllEntities() );
    }

    updateIsFavorite( freelancerId: string, isFavorite: boolean ) {
        freelancersStore.update( updateEntities( freelancerId, { isFavorite } ) );
    }

    countUpdatedFilters( filters ) {
        let count: number = 0;

        if( filters.isAvailable !== defaultFilters.isAvailable ) {
            count++;
        }
        if( filters.isCertified !== defaultFilters.isCertified ) {
            count++;
        }
        if( filters.avgPriceMin !== defaultFilters.avgPriceMin || filters.avgPriceMax !== defaultFilters.avgPriceMax ) {
            count++;
        }

        const arrayKeys = [ 'expertiseIds', 'skillIds', 'languageIds', 'jobIds', 'experiences' ];
        for( const key of arrayKeys ) {
            if( !Array.isArray( filters[ key ] ) && filters[key] != null) {
                count++;
            }

            if( Array.isArray( filters[ key ] ) && filters[ key ].length > 0 ) {
                count += filters[ key ].length;
            }
        }

        return count;
    }

    setFilters( params: object ): void {
        freelancersStore.update( ( state ) => ( { ...state, args: { ...state.args, ...params } } ) );
    }
}