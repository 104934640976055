import { gql } from '@apollo/client';

export const ReviewsGQL = gql`
    query ReviewsGQL($userId: String!) {
        rates(userId: $userId) {
            data {
                id
                from {
                    id
                    profilePicture
                    userName
                }
                avgRate
                review
                createdAt
            },
        }
    }
`;