import { Trans, useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { CheckIfProfileIsCompletedGQL } from '@/pages/freelancer/views/account/freelancer-account-profile-validation/checkIfProfileIsCompletedGQL';
import { useQuery } from '@apollo/client';
import ListSkeleton from '@/components/ListSkeleton';
import FreelancerAccountProfileValidationElement from '@/pages/freelancer/views/account/freelancer-account-profile-validation/components/FreelancerAccountProfileValidationElement';
import { GetOnboardingStripeLinkGQL } from '@/gql/global-queries/getOnboardingStripeLinkGQL';

export default function FreelancerAccountProfileValidation() {

    const { t } = useTranslation();

    const [ checkListElements, setCheckListElements ] = useState( [] );

    const checkIfProfileIsCompletedQuery = useQuery( CheckIfProfileIsCompletedGQL );
    const onboardingLinkQuery = useQuery( GetOnboardingStripeLinkGQL );

    useEffect( () => {
        if( checkIfProfileIsCompletedQuery.data ) {
            const checkListElements = [
                {
                    text: t( 'freelancer:account.navigation.companyDetails' ),
                    isCompleted: checkIfProfileIsCompletedQuery.data.checkIfProfileIsCompleted.companyInformation,
                    link: '/freelancer/settings/edit-company-details',
                    isExternal: false
                },
                {
                    text: t( 'freelancer:account.verifyProfile.bankAccount' ),
                    isCompleted: checkIfProfileIsCompletedQuery.data.checkIfProfileIsCompleted.connectedAccount,
                    link: onboardingLinkQuery?.data?.getOnboardingStripeLink,
                    isExternal: true
                },
                {
                    text: t( 'freelancer:account.verifyProfile.portfolio' ),
                    isCompleted: checkIfProfileIsCompletedQuery.data.checkIfProfileIsCompleted.portfolioElements,
                    link: '/freelancer/settings/edit-portfolio',
                    isExternal: false
                },
                {
                    text: t( 'freelancer:account.verifyProfile.profilePicture' ),
                    isCompleted: checkIfProfileIsCompletedQuery.data.checkIfProfileIsCompleted.profilePicture,
                    link: '/freelancer/settings/edit-profile-picture',
                    isExternal: false
                },
                {
                    text: t( 'freelancer:account.verifyProfile.charter' ),
                    isCompleted: checkIfProfileIsCompletedQuery.data.checkIfProfileIsCompleted.freelancerCharterAccepted,
                    link: '/freelancer/settings/charter',
                    isExternal: false
                }
            ];

            setCheckListElements( checkListElements );
        }
    }, [ checkIfProfileIsCompletedQuery.data ] );

    const coreJSX = (
        <div className="flex flex-col">
            {
                checkListElements.map( ( element, index ) => {
                    return (
                        <div key={ index }
                             className="flex flex-row items-center h-[60px] border-b border-separator">
                            <FreelancerAccountProfileValidationElement text={ element?.text }
                                                                       isCompleted={ element?.isCompleted }
                                                                       link={ element?.link }
                                                                       isExternal={ element?.isExternal } />
                        </div>
                    );
                } )
            }
        </div>
    );

    const loadingJSX = (
        <div className="flex flex-col space-y-4">
            <ListSkeleton isLoading={ true }
                          count={ 5 }
                          height={ 44 } />
        </div>
    );

    return (
        <div className="w-full mt-4">
            <h1>{ t( 'freelancer:account.verifyProfile.title' ) }</h1>
            <div className="mt-2 text-sm text-grey font-normal">
                <Trans i18nKey="freelancer:account.verifyProfile.description">
                    <span></span>
                    <span className="text-black font-medium"></span>
                </Trans>
            </div>
            <div className="mt-6">
                {
                    checkIfProfileIsCompletedQuery.loading || onboardingLinkQuery.loading ?
                    loadingJSX :
                    coreJSX
                }</div>
        </div>
    );
}