import { useTranslation } from 'react-i18next';
import { Divider } from 'antd';

import { priceFormatter } from '@/utils/priceFormatter';
import { CurrencyEnum } from '@/gql/graphql';

interface ProjectPriceDetailsProps {
    data: {
        currency: CurrencyEnum;
        amountExVAT: number;
        amountIncVAT: number;
    };
}

export default function ProjectPriceDetails( { data }: ProjectPriceDetailsProps ) {

    const { currency, amountExVAT, amountIncVAT } = data;

    const { t } = useTranslation();

    return (
        <div className="flex flex-col gap-y-4 text-sm font-normal text-grey rounded-lg p-4 border border-grey-light">

            <div className="flex justify-between">
                <span>{ t( 'common:totalExTaxes' ) }</span>
                <span className="text-black font-bold">
                 {
                     priceFormatter( {
                         currency,
                         amount: amountExVAT,
                         decimals: true
                     } )
                 }
                 </span>
            </div>

            <div className="flex justify-between">
                 <span className="font-normal">
                 { t( 'common:Taxes' ) }
                 </span>
                <span className="text-black font-bold">
                 {
                     priceFormatter( {
                         currency,
                         amount: amountIncVAT - amountExVAT,
                         decimals: true
                     } )
                 }
                 </span>
            </div>

            <Divider className="p-0 m-0 border-grey-light" />

            <div className="flex justify-between font-bold">
                <span>{ t( 'common:totalInTaxes' ) }</span>
                <span className="text-lg text-black font-sharp">
                 {
                     priceFormatter( {
                         currency,
                         amount: amountIncVAT,
                         decimals: true
                     } )
                 }
                 </span>
            </div>
        </div>
    );
}