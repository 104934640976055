import { Rate } from 'antd';
import firstLetterUppercaseFormatter from '@/utils/firstLetterUppercaseFormatter';
import { Controller } from 'react-hook-form';
import React from 'react';

interface RateComponentProps {
    className?: string;
    control: any;
    name: string;
    label: string;
    rules: any;
    errors: any;
}

export default function RateComponent( { className, control, name, label, rules, errors }: RateComponentProps ) {

    return (
        <Controller control={ control }
                    name={ name }
                    rules={ rules }
                    render={ ( {
                                   field: { onChange, onBlur, value, ref }
                               } ) => (
                        <div className="flex flex-col items-center justify-center gap-y-3">
                            <label className="text-center text-base font-medium font-sharp">
                                { label }
                            </label>
                            <Rate onChange={ onChange }
                                  value={ value }
                                  style={ { fontSize: '2em' } }
                                  ref={ ref }
                                  onBlur={ onBlur } />
                            {
                                errors[ name ] &&
                                (
                                    <div className="w-fit text-sm font-normal text-red mt-1.5">
                                        { firstLetterUppercaseFormatter( errors[ name ].message.toString() ) }
                                    </div>
                                )
                            }
                        </div>
                    ) } />
    );
}