import { forwardRef, MutableRefObject, useEffect, useImperativeHandle, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Checkbox } from 'antd';
import { Controller, useForm } from 'react-hook-form';
import { useMediaQuery } from 'react-responsive';

import emailVerificationHook from '@/hooks/emailVerificationHook';
import { inputEmailValidationUnique } from '@/utils/customEmailVerification';
import { SignUpFreelancerRepository } from '@/pages/auth/sign-up/sign-up-freelancer//signUpFrelancerRepository';

import InputComponent from '@/components/custom-element-form/InputComponent';
import CheckPasswordValidity from '@/components/CheckPasswordValidity';
import ProfilePictureUploader from '@/components/ProfilePictureUploader';
import PhoneNumberInputs from '@/components/PhoneNumberInputs';

const SignUpFreelanceStepProfile = forwardRef( function( props, ref ) {
    const isTabletOrMobile = useMediaQuery( { query: `(max-width: ${ import.meta.env.VITE_MOBILE_BREAK_POINT })` } );
    const fileUploaderRef: MutableRefObject<any> = useRef( null );
    const { t } = useTranslation();
    const { trigger, control, watch, setValue, formState: { errors } } = useForm();
    const repository = new SignUpFreelancerRepository();
    repository.profilePictureUploaderRef = fileUploaderRef;

    const { isAvailable } = emailVerificationHook();

    const subscription = watch( ( value ) => {
        if( value.phoneCodeCountry != repository.phoneCodeCountry || value.phoneNumber != repository.phoneNumber ) {
            repository.phoneNumberVerified = false;
        }

        repository.updateData( value );
    } );

    const password = watch( 'password' );

    useImperativeHandle( ref, () => ( {
        async validateFields(): Promise<boolean> {
            return trigger();
        }
    } ) );

    const handleNewFile = async( file ) => {
        setValue( 'profilePicture', file );
        repository.profilePicture = file;
        repository.profilePictureVirtualUrl = await createVirtualUrl( file );
    };

    useEffect( () => {
        return () => subscription.unsubscribe();
    }, [] );

    const createVirtualUrl = async( file ) => {
        return new Promise<string>( ( resolve, reject ) => {
            const reader = new FileReader();

            reader.onload = ( e ) => {
                // e.target.result contains the data URL
                resolve( e.target.result as string );
            };

            reader.onerror = ( e ) => {
                console.error( 'Error reading file', e );
            };

            reader.readAsDataURL( file );
        } );
    };

    return (
        <form className="flex w-full flex-col gap-y-4">
            <Controller name="profilePicture"
                        control={ control }
                        defaultValue={ repository.profilePicture }
                        rules={ { required: t( 'common:error.form.required' ) } }
                        render={ ( { field, fieldState: { error } } ) => (
                            <ProfilePictureUploader ref={ fileUploaderRef }
                                                    name="profilePicture"
                                                    defaultProfilePictureUrl={ repository.profilePictureVirtualUrl }
                                                    className={ isTabletOrMobile ? 'touch-uploader' : '' }
                                                    onFileCreated={ handleNewFile }
                                                    errors={ errors } />
                        ) } />

            <InputComponent control={ control }
                            name="email"
                            defaultValue={ repository.email }
                            label={ t( 'auth:sign-up.freelancer.profile-page.email' ) }
                            placeholder={ t( 'auth:sign-up.freelancer.profile-page.emailPlaceholder' ) }
                            errors={ errors }
                            rules={ {
                                required: t( 'common:error.form.required' ),
                                pattern: {
                                    value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                                    message: t( 'common:error.form.invalidEmail' )
                                },
                                validate: {
                                    unique: ( value ) => inputEmailValidationUnique( value, isAvailable, t( 'auth:sign-up.emailAlreadyUsed' ) )
                                }
                            } } />

            <div className="flex flex-wrap lg:flex-nowrap gap-x-2 gap-y-4">
                <InputComponent control={ control }
                                name="firstName"
                                defaultValue={ repository.firstName }
                                className="w-full lg:w-1/2"
                                label={ t( 'auth:sign-up.freelancer.profile-page.firstname' ) }
                                placeholder={ t( 'auth:sign-up.freelancer.profile-page.firstNamePlaceholder' ) }
                                errors={ errors }
                                rules={ {
                                    required: t( 'common:error.form.required' )
                                } } />

                <InputComponent control={ control }
                                name="lastName"
                                defaultValue={ repository.lastName }
                                className="w-full lg:w-1/2"
                                label={ t( 'auth:sign-up.freelancer.profile-page.lastname' ) }
                                placeholder={ t( 'auth:sign-up.freelancer.profile-page.lastNamePlaceholder' ) }
                                errors={ errors }
                                rules={ {
                                    required: t( 'common:error.form.required' )
                                } } />
            </div>

            <PhoneNumberInputs control={ control }
                               defaultValueSelect={ repository.phoneCodeCountry }
                               defaultValueInput={ repository.phoneNumber }
                               inputName="phoneNumber"
                               selectName="phoneCodeCountry"
                               errors={ errors }
                               watch={ watch } />

            <div className="flex flex-col gap-2">
                <InputComponent control={ control }
                                type="password"
                                name="password"
                                className="w-full"
                                label={ t( 'auth:sign-up.freelancer.profile-page.password' ) }
                                placeholder={ t( 'auth:sign-up.freelancer.profile-page.passwordPlaceholder' ) }
                                errors={ errors }
                                rules={ {
                                    required: t( 'common:error.form.required' )
                                } } />
                <CheckPasswordValidity password={ password } />
            </div>

            <div className="mt-4 font-medium">
                <Controller name="newsletter"
                            control={ control }
                            defaultValue={ repository.isAgreeForNewsletter }
                            render={ ( { field: { onChange, value, ref } } ) => (
                                <Checkbox onChange={ onChange }
                                          ref={ ref }>
                                    { t( 'freelancer:auth.sign-up.profile-page.news' ) }
                                </Checkbox>
                            ) } />
                <div className="mt-1">
                    <Trans i18nKey="auth:privacyPolicy">
                        <span className="text-center text-xs font-normal text-grey"></span>
                        <a href="https://jellysmack.com/privacy-policy/"
                        target="_blank"
                        className="text-grey text-xs underline font-medium"
                        rel="noopener noreferrer"/>
                    </Trans>
                </div>

            </div>
        </form>
    );
} );

export default SignUpFreelanceStepProfile;