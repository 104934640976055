import { SignUpFreelancerRepository } from '@/pages/auth/sign-up/sign-up-freelancer/signUpFrelancerRepository';
import { useLazyQuery, useMutation } from '@apollo/client';
import { createFreelancerGQL } from '@/pages/auth/sign-up/sign-up-freelancer/createFreelancerGQL';
import { useEffect, useRef, useState } from 'react';
import loginHook from '@/hooks/loginHook';
import { SessionRepository } from '@/core/auth/sessionRepository';
import numberFormatter from '@/utils/numberFormatter';
import * as Sentry from '@sentry/react';
import { useNavigate } from 'react-router-dom';
import { App } from 'antd';
import { useTranslation } from 'react-i18next';
import { ProfilePicturePreSignUrl } from '@/pages/client/views/account/client-account-edit-profile/queries/getProfilePicturePresignUrl';
import SignUpFreelanceStepProfile from '@/pages/auth/sign-up/sign-up-freelancer/sign-up-freelancer-steps/SignUpFreelanceStepProfile';
import SignUpFreelancerStepJob from '@/pages/auth/sign-up/sign-up-freelancer/sign-up-freelancer-steps/SignUpFreelancerStepJob';
import SignUpFreelancerStepExperience from '@/pages/auth/sign-up/sign-up-freelancer/sign-up-freelancer-steps/SignUpFreelancerStepExperience';
import SignUpFreelancerStepAVGPrice from '@/pages/auth/sign-up/sign-up-freelancer/sign-up-freelancer-steps/SignUpFreelancerStepAVGPrice';
import SignUpFreelancerStepSkill from '@/pages/auth/sign-up/sign-up-freelancer/sign-up-freelancer-steps/SignUpFreelancerStepSkill';
import SignUpFreelanceStepExpertise from '@/pages/auth/sign-up/sign-up-freelancer/sign-up-freelancer-steps/SignUpFreelanceStepExpertise';
import SignUpFreelancerStepLanguage from '@/pages/auth/sign-up/sign-up-freelancer/sign-up-freelancer-steps/SignUpFreelancerStepLanguage';
import SignUpFreelancerStepBio from '@/pages/auth/sign-up/sign-up-freelancer/sign-up-freelancer-steps/SignUpFreelancerStepBio';
import SignUpFreelancerStepPortfolioVideo from '@/pages/auth/sign-up/sign-up-freelancer/sign-up-freelancer-steps/SignUpFreelancerStepPortfolioVideo';
import SignUpFreelancerStepPortfolioChannel from '@/pages/auth/sign-up/sign-up-freelancer/sign-up-freelancer-steps/SignUpFreelancerStepPortfolioChannel';
import SignUpFreelancerStepInvoicing from '@/pages/auth/sign-up/sign-up-freelancer/sign-up-freelancer-steps/SignUpFreelancerStepInvoicing';
import SignUpFreelancerStepPreview from '@/pages/auth/sign-up/sign-up-freelancer/sign-up-freelancer-steps/SignUpFreelancerStepPreview';
import SignUpFreelancerStepVerificationPhoneNumberCode from '@/pages/auth/sign-up/sign-up-freelancer/sign-up-freelancer-steps/SignUpFreelancerStepVerificationPhoneNumberCode';
import { phoneNumberVerificationHook } from '@/hooks/phoneNumberVerificationHook';
import { SegmentEvent, trackSegmentEvent } from '@/utils/analytics';
import SignUpFreelancerStepCountry from '@/pages/auth/sign-up/sign-up-freelancer/sign-up-freelancer-steps/SignUpFreelancerStepCountry';
import SignUpFreelancerStepUnsupportedCountry from '@/pages/auth/sign-up/sign-up-freelancer/sign-up-freelancer-steps/SignUpFreelancerStepUnsupportedCountry';

enum ProgressBarItemEnum {
    PERSONAL_INFO   = 'PERSONAL_INFO',
    PRICE           = 'PRICE',
    PORTFOLIO       = 'PORTFOLIO',
    COMPANY_DETAILS = 'COMPANY_DETAILS',
    PREVIEW         = 'PREVIEW'
}

export default function signUpFreelancerHook() {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const { message } = App.useApp();

    const repository = new SignUpFreelancerRepository();
    const [ isLoadingNextStep, setIsLoadingNextStep ] = useState( false );
    const [ createFreelancer, { data, loading: isLoadingMutation, error } ] = useMutation( createFreelancerGQL );
    const [ currentStep, setCurrentStep ] = useState( 0 );

    const isLoading: boolean = isLoadingNextStep || isLoadingMutation;

    const { sendCode } = phoneNumberVerificationHook( {
        phoneNumber: repository.fullPhoneNumberFormatted,
        lastCodeSentAt: repository.lastCodeSentAt
    } );

    const login = loginHook();
    const session = new SessionRepository();

    const refStep0 = useRef( null );
    const refStep1 = useRef( null );
    const refStep2 = useRef( null );
    const refStep3 = useRef( null );
    const refStep4 = useRef( null );
    const refStep12 = useRef( null );
    const refStep13 = useRef( null );
    const [ isLastStep, setIsLastStep ] = useState( false );

    useEffect( () => {
        const subscription = repository.isLoadingNextStep$.subscribe( ( isLoading ) => {
            setIsLoadingNextStep( isLoading );
        } );

        return () => {
            repository.clearStore();
            subscription.unsubscribe()
        };
    }, [] );

    useEffect( () => {
        setIsLastStep( currentStep === items.length - 1 );
        const observable = repository.step$.subscribe( ( step ) => {
            if( step != currentStep ) {
                setCurrentStep( step );
            }
        } );
        return () => {
            observable.unsubscribe();
        };
    }, [ currentStep ] );


    const nextStep = async() => {
        switch( currentStep ) {
            case 0:
                const step0IsValid = await refStep0?.current?.validateFields();
                if( step0IsValid ) {
                    repository.nextStep(2);
                } else {
                    message.warning( t( 'auth:sign-up.freelancer.supported-countries-page.countryRequired' ) );
                }
                break;
            case 1:
                await refStep1?.current?.submitForm();
                break;
            case 2:
                const step2IsValid = await refStep2?.current?.validateFields();
                if( step2IsValid ) {
                    repository.isLoadingNextStep = true ;
                    await sendCode( repository.fullPhoneNumberFormatted );
                    repository.isLoadingNextStep = false ;
                    repository.nextStep( repository.phoneNumberVerified ? 2 : 1 );
                }
                break;
            case 3:
                repository.isLoadingNextStep = true ;
                const step3IsValid = await refStep3?.current?.validateFields();
                repository.isLoadingNextStep = false ;
                if( step3IsValid ) {
                    repository.nextStep();
                }
                break;
            case 4:
                const step4IsValid = await refStep4?.current?.validateFields();
                if( step4IsValid && repository.avgPrice && repository.currency ) {
                    repository.nextStep();
                }
                break;
            case 5:
                if( repository.jobIds?.length > 0 ) {
                    repository.nextStep();
                }
                else {
                    message.warning( t( 'auth:sign-up.freelancer.job-page.jobRequired' ) );
                }
                break;
            case 6:
                if( repository.experience != null ) {
                    repository.nextStep();
                }
                else {
                    message.warning( t( 'auth:sign-up.freelancer.experience-page.experienceRequired' ) );
                }
                break;
            case 7:
                if( repository.skillIds?.length > 0 ) {
                    repository.nextStep();
                }
                else {
                    message.warning( t( 'auth:sign-up.freelancer.skill-page.skillRequired' ) );
                }
                break;
            case 8:
                if( repository.expertiseIds?.length > 0 ) {
                    repository.nextStep();
                }
                else {
                    message.warning( t( 'auth:sign-up.freelancer.expertise-page.expertiseRequired' ) );
                }
                break;
            case 9:
                if( repository.languageIds?.length > 0 ) {
                    repository.nextStep();
                }
                else {
                    message.warning( t( 'auth:sign-up.freelancer.language-page.languageRequired' ) );
                }
                break;
            case 10:
                if( repository.portfolioVideos?.length > 0 ) {
                    repository.nextStep();
                    trackSegmentEvent( SegmentEvent.PortfolioAdded );
                }
                else {
                    message.warning( t( 'auth:sign-up.freelancer.portfolio-page.videoRequired' ) );
                }
                break;
            case 11:
                if( repository.portfolioChannels?.length > 0 ) {
                    repository.nextStep();
                }
                else {
                    message.warning( t( 'auth:sign-up.freelancer.channel-page.channelRequired' ) );
                }
                break;
            case 12:
                const step12IsValid = await refStep12?.current?.validateFields();
                if( step12IsValid ) {
                    repository.nextStep();
                }
                break;
            case 13:
                const step13IsValid = await refStep13?.current?.validateFields();
                if( step13IsValid ) {
                    repository.nextStep();
                }
                break;
            case 14:
                if( repository.isAvailable !== null ) {
                    submit();
                }
                break;
            default:
                return;
        }
    };

    const previousStep = () => {
        if( currentStep == 4 && repository.phoneNumberVerified ) {
            repository.previousStep( 2 );
            return;
        }

        if( currentStep == 2 ) {
            repository.previousStep( 2 );
            return;
        }

        if( currentStep == 0 ) {
            repository.clearStore();
            navigate( '/auth/sign-up', { replace: true } );
        }

        repository.previousStep();
    };

    const convertCurrentStepInProgressBarIndex = (): number => {
        const id = items[ currentStep ]?.progressBarId;
        return progressBar.findIndex( item => item.id == id );
    };

    const getPreSignedUrl = useLazyQuery( ProfilePicturePreSignUrl );

    const uploadProfilePicture = async(): Promise<string> => {
        try {
            const preSignedUrl = await getPreSignedUrl[ 0 ]( {
                variables: {
                    contentType: repository.profilePicture.type
                }
            } );
            return preSignedUrl.data.profilePicturePreSignUrl;
        } catch( e ) {
            Sentry.captureException( 'An error occur when trying to get preSignUrl', e );
            message.error( t( 'error.default' ) );
        }
    };

    const submit = async() => {
        try {
            const profilePicture = await uploadProfilePicture();
            const args = {
                firstName: repository.firstName,
                lastName: repository.lastName,
                email: repository.email,
                profilePicture,
                isAgreeForNewsletter: repository.isAgreeForNewsletter || true,
                password: repository.password,
                avgPrice: numberFormatter( repository.avgPrice ),
                phone: repository.fullPhoneNumberFormatted,
                experience: repository.experience,
                jobIds: repository.jobIds,
                mainJobId: repository.mainJobId,
                skillIds: repository.skillIds,
                expertiseIds: repository.expertiseIds,
                languageIds: repository.languageIds,
                bio: repository.bio,
                channelIds: repository.portfolioChannels.map( channel => channel.id ),
                portfolioIds: repository.portfolioVideos.map( video => video.id ),
                isAvailable: repository.isAvailable,
                companyDetails: {
                    vatRate: +repository.vatRate,
                    addressStreet1: repository.companyStreet,
                    addressStreet2: repository.companyStreet2,
                    addressCity: repository.companyCity,
                    addressState: repository.companyState,
                    addressPostalCode: repository.companyPostalCode,
                    addressName: repository.companyName,
                    addressCountry: repository.companyCountry,
                    addressCountryCode: repository.companyCountryCode
                }
            };

            await createFreelancer( {
                variables: {
                    args
                }
            } );

            const user = await login.query( { email: repository.email, password: repository.password } );
            session.login( user.data.loginWithPassword );
            repository.clearStore();
            navigate( '/auth/sign-up/freelancer/welcome' );
        } catch( e ) {
            Sentry.captureException( 'An error occur when trying to create a new freelancer account', e );
            message.error( {
                content: t( 'freelancer:auth.sign-up.errors.submitError' ),
                duration: 4
            } );
        }
    };

    const items = [
        {
            progressBarId: ProgressBarItemEnum.PERSONAL_INFO,
            titleLeft: t( 'auth:sign-up.freelancer.supported-countries-page.titleLeft' ),
            titleRight: t( 'auth:sign-up.freelancer.supported-countries-page.titleRight' ),
            subtitle: t( 'auth:sign-up.freelancer.supported-countries-page.subtitle' ),
            content: <SignUpFreelancerStepCountry ref={ refStep0 }/>
        },
        {
            progressBarId: ProgressBarItemEnum.PERSONAL_INFO,
            titleLeft: t( 'auth:sign-up.freelancer.unsupported-country-page.titleLeft' ),
            titleRight: t( 'auth:sign-up.freelancer.unsupported-country-page.titleRight' ),
            subtitle: t( 'auth:sign-up.freelancer.unsupported-country-page.subtitle' ),
            content: <SignUpFreelancerStepUnsupportedCountry ref={ refStep1 }/>
        },
        {
            progressBarId: ProgressBarItemEnum.PERSONAL_INFO,
            titleLeft: t( 'auth:sign-up.freelancer.profile-page.titleLeft' ),
            titleRight: t( 'auth:sign-up.freelancer.profile-page.titleRight' ),
            subtitle: t( 'auth:sign-up.freelancer.profile-page.subtitle' ),
            content: <SignUpFreelanceStepProfile ref={ refStep2 } />
        },
        {
            progressBarId: ProgressBarItemEnum.PERSONAL_INFO,
            titleLeft: t( 'auth:sign-up.freelancer.phone-number-verification-page.titleLeft' ),
            titleRight: t( 'auth:sign-up.freelancer.phone-number-verification-page.titleRight' ),
            subtitle: t( 'auth:sign-up.freelancer.phone-number-verification-page.subtitle', {
                phoneNumber: repository?.fullPhoneNumberFormatted
            } ),
            content: <SignUpFreelancerStepVerificationPhoneNumberCode ref={ refStep3 } />
        },
        {
            progressBarId: ProgressBarItemEnum.PRICE,
            titleLeft: t( 'auth:sign-up.freelancer.price-page.titleLeft' ),
            titleRight: t( 'auth:sign-up.freelancer.price-page.titleRight' ),
            subtitle: t( 'auth:sign-up.freelancer.price-page.subtitle' ),
            content: <SignUpFreelancerStepAVGPrice ref={ refStep4 } />
        },
        {
            progressBarId: ProgressBarItemEnum.PORTFOLIO,
            titleLeft: t( 'auth:sign-up.freelancer.job-page.titleLeft' ),
            titleRight: t( 'auth:sign-up.freelancer.job-page.titleRight' ),
            subtitle: t( 'auth:sign-up.freelancer.job-page.subtitle' ),
            content: <SignUpFreelancerStepJob />
        },
        {
            progressBarId: ProgressBarItemEnum.PORTFOLIO,
            titleLeft: t( 'auth:sign-up.freelancer.experience-page.titleLeft' ),
            titleRight: t( 'auth:sign-up.freelancer.experience-page.titleRight' ),
            subtitle: t( 'auth:sign-up.freelancer.experience-page.subtitle' ),
            content: <SignUpFreelancerStepExperience />
        },
        {
            progressBarId: ProgressBarItemEnum.PORTFOLIO,
            titleLeft: t( 'auth:sign-up.freelancer.skill-page.titleLeft' ),
            titleRight: t( 'auth:sign-up.freelancer.skill-page.titleRight' ),
            subtitle: t( 'auth:sign-up.freelancer.skill-page.subtitle' ),
            content: <SignUpFreelancerStepSkill />
        },
        {
            progressBarId: ProgressBarItemEnum.PORTFOLIO,
            titleLeft: t( 'auth:sign-up.freelancer.expertise-page.titleLeft' ),
            titleRight: t( 'auth:sign-up.freelancer.expertise-page.titleRight' ),
            subtitle: t( 'auth:sign-up.freelancer.expertise-page.subtitle' ),
            content: <SignUpFreelanceStepExpertise />
        },
        {
            progressBarId: ProgressBarItemEnum.PORTFOLIO,
            titleLeft: t( 'auth:sign-up.freelancer.language-page.titleLeft' ),
            titleRight: t( 'auth:sign-up.freelancer.language-page.titleRight' ),
            subtitle: t( 'auth:sign-up.freelancer.language-page.subtitle' ),
            content: <SignUpFreelancerStepLanguage />
        },
        {
            progressBarId: ProgressBarItemEnum.PORTFOLIO,
            titleLeft: t( 'auth:sign-up.freelancer.portfolio-page.titleLeft' ),
            titleRight: t( 'auth:sign-up.freelancer.portfolio-page.titleRight' ),
            subtitle: t( 'auth:sign-up.freelancer.portfolio-page.subtitle' ),
            content: <SignUpFreelancerStepPortfolioVideo />
        },
        {
            progressBarId: ProgressBarItemEnum.PORTFOLIO,
            titleLeft: t( 'auth:sign-up.freelancer.creators-worked-with-page.titleLeft' ),
            titleRight: t( 'auth:sign-up.freelancer.creators-worked-with-page.titleRight' ),
            subtitle: t( 'auth:sign-up.freelancer.creators-worked-with-page.subtitle' ),
            content: <SignUpFreelancerStepPortfolioChannel />
        },
        {
            progressBarId: ProgressBarItemEnum.PORTFOLIO,
            titleLeft: t( 'auth:sign-up.freelancer.bio-page.titleLeft' ),
            titleRight: t( 'auth:sign-up.freelancer.bio-page.titleRight' ),
            subtitle: t( 'auth:sign-up.freelancer.bio-page.subtitle' ),
            content: <SignUpFreelancerStepBio ref={ refStep12 } />
        },
        {
            progressBarId: ProgressBarItemEnum.COMPANY_DETAILS,
            titleLeft: t( 'auth:sign-up.freelancer.invoicing-page.titleLeft' ),
            titleRight: t( 'auth:sign-up.freelancer.invoicing-page.titleRight' ),
            subtitle: t( 'auth:sign-up.freelancer.invoicing-page.subtitle' ),
            content: <SignUpFreelancerStepInvoicing ref={ refStep13 } />
        },
        {
            progressBarId: ProgressBarItemEnum.PREVIEW,
            titleLeft: t( 'auth:sign-up.freelancer.preview-page.titleLeft' ),
            titleRight: t( 'auth:sign-up.freelancer.preview-page.titleRight' ),
            subtitle: t( 'auth:sign-up.freelancer.preview-page.subtitle' ),
            content: <SignUpFreelancerStepPreview />
        }
    ];

    const progressBar = [
        {
            id: ProgressBarItemEnum.PERSONAL_INFO,
            label: t( 'auth:sign-up.freelancer.steps.personalInformation' )
        },
        {
            id: ProgressBarItemEnum.PRICE,
            label: t( 'auth:sign-up.freelancer.steps.price' )
        },
        {
            id: ProgressBarItemEnum.PORTFOLIO,
            label: t( 'auth:sign-up.freelancer.steps.portfolio' )
        },
        {
            id: ProgressBarItemEnum.COMPANY_DETAILS,
            label: t( 'auth:sign-up.freelancer.steps.companyDetails' )
        },
        {
            id: ProgressBarItemEnum.PREVIEW,
            label: t( 'auth:sign-up.freelancer.steps.preview' )
        }
    ];

    return {
        items,
        progressBar,
        isLastStep,
        currentStep,
        nextStep,
        previousStep,
        isLoading,
        currentProgressBarIndex: convertCurrentStepInProgressBarIndex
    };
}