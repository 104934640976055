import { PortfolioChannel } from '@/gql/graphql';
import { ReorderOutlinedIcon, TrashOutlinedIcon } from '@/assets/icons/CustomIcons';
import abbreviationNumberFormatter from '@/utils/abbrevationNumberFormatter';
import { useTranslation } from 'react-i18next';
import { Avatar, Button } from 'antd';
import { UserOutlined } from '@ant-design/icons';

interface FreelancerAccountEditPortfolioListItemProps {
    portfolioChannel: PortfolioChannel;
    isDeleting: boolean;
    onDelete: ( portfolioChannel: PortfolioChannel ) => void;
}

export default function FreelancerAccountEditPortfolioChannelListItem( {
                                                                           portfolioChannel,
                                                                           onDelete,
                                                                           isDeleting = false
                                                                       }: FreelancerAccountEditPortfolioListItemProps ) {

    const { t } = useTranslation();

    const handleDelete = () => {
        onDelete( portfolioChannel );
    };

    return (
        <div className="bg-white flex items-center justify-between border border-grey-light px-6 py-2 rounded-xl cursor-grab card-shadow">
            <div className="flex items-center">
                <ReorderOutlinedIcon className="text-grey-medium text-[12px]" />
                <Avatar size={ 40 }
                        icon={ <UserOutlined /> }
                        className="flex-none ml-4"
                        src={ portfolioChannel?.thumbnailUrl } />

                <div className="flex flex-col ml-4">
                    <h4 className="font-extrabold text-sm lg:text-xl">
                        { portfolioChannel?.name }
                    </h4>
                    <div className="font-light text-grey-medium text-[13px] lg:text-sm">
                        <span>{ abbreviationNumberFormatter( portfolioChannel?.subscriberCount ) }</span>
                        <span className="ml-1">
                        { t( 'common:channel-summary.follower', { count: portfolioChannel?.subscriberCount } ) }
                        </span>
                    </div>
                </div>
            </div>

            <Button type="link"
                    loading={ isDeleting }
                    onClick={ () => handleDelete() }>
                {
                    !isDeleting &&
                    <TrashOutlinedIcon className="text-red text-lg cursor-pointer" />
                }
            </Button>
        </div>
    );
}