import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import ResponsiveModal from '@/components/ResponsiveModal';
import { App, Button, Divider, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import InputComponent from '@/components/custom-element-form/InputComponent';
import * as Sentry from '@sentry/react';
import { ClientUpdateCompanyDetailsGQL } from '@/pages/client/views/account/client-account-edit-company-details/services/clientUpdateCompanyDetailsGQL';

interface PhoneNumberMissingModalProps {
    onSubmit: any;
    defaultCompanyDetails: any;
};

const InvoicingInfoModal = forwardRef( ( props: PhoneNumberMissingModalProps, ref ) => {

    const updateCompanyDetailsMutation = useMutation(ClientUpdateCompanyDetailsGQL);
    const { t } = useTranslation( [ 'common' ] );
    const { message } = App.useApp();

    const {
        control,
        trigger,
        reset,
        formState: { errors,  },
        handleSubmit
    } = useForm();
    
    const modalRef = useRef( null );
                
    useImperativeHandle( ref, () => ( {
        open: openModal,
        close: closeModal
    } ) );

    const openModal = () => modalRef?.current?.open();

    const closeModal = () => modalRef?.current?.close();

    const updateDefaultValues = ( defaultValues ) => {
        reset({
            addressName: defaultValues?.addressName,
            addressStreet1: defaultValues?.addressStreet1,
            addressStreet2: defaultValues?.addressStreet2,
            addressCity: defaultValues?.addressCity,
            addressState: defaultValues?.addressState,
            addressPostalCode: defaultValues?.addressPostalCode,
            addressCountry: defaultValues?.addressCountry,
        })
    };

    useEffect( () => {
        updateDefaultValues( props.defaultCompanyDetails )
    }, [ props.defaultCompanyDetails ] );

    const onSubmit = async(data): Promise<void> => {

        if( !( await trigger() ) ) {
            return;
        }

        try {
            await updateCompanyDetailsMutation[0]({ variables: { args: data } })
            props.onSubmit()
        } catch (e) {
            Sentry.captureException('An error occurred when trying to update company details', e)
            message.error(t('common:form.submitError'))
            return;
        }

        closeModal();
    };

    return (
        <ResponsiveModal ref={ modalRef }
            desktopWidth="600px"
            mobileHeight="h-fit"
            destroyOnClose={ true }
            title={ t( 'common:chat.conversation.payment.invoicingInfoModal.title' ) }
        >
            <Divider className="mt-0" />
            <Form className="text-grey relative rounded-lg p-4 border border-grey-light w-full flex flex-col gap-y-4 mt-5">
                <InputComponent control={control}
                    name="addressName"
                    label={t('client:account.edit-company-details.name')}
                    placeholder={t('client:account.edit-company-details.placeholderName')}
                    rules={{ required: t('common:error.form.required') }}
                    errors={errors} />
                <InputComponent control={control}
                    name="addressStreet1"
                    label={t('client:account.edit-company-details.street1')}
                    placeholder={t('client:account.edit-company-details.placeholderStreet1')}
                    rules={{ required: t('common:error.form.required') }}
                    errors={errors} />
                <InputComponent control={control}
                    name="addressStreet2"
                    label={t('client:account.edit-company-details.street2')}
                    placeholder={t('client:account.edit-company-details.placeholderStreet2')}
                    errors={errors} />

                <div className="flex items-start flex-wrap lg:flex-nowrap gap-4">
                    <InputComponent control={control}
                        name="addressCity"
                        label={t('client:account.edit-company-details.city')}
                        placeholder={t('client:account.edit-company-details.placeholderCity')}
                        rules={{ required: t('common:error.form.required') }}
                        errors={errors} />

                    <InputComponent control={control}
                        name="addressState"
                        label={t('client:account.edit-company-details.state')}
                        placeholder={t('client:account.edit-company-details.placeholderState')} />

                    <InputComponent control={control}
                        name="addressPostalCode"
                        label={t('client:account.edit-company-details.postalCode')}
                        placeholder={t('client:account.edit-company-details.placeholderPostalCode')}
                        rules={{ required: t('common:error.form.required') }}
                        errors={errors} />
                </div>

                <InputComponent control={control}
                    name="addressCountry"
                    label={t('client:account.edit-company-details.country')}
                    placeholder={t('client:account.edit-company-details.placeholderCountry')}
                    rules={{ required: t('common:error.form.required') }}
                    errors={errors} />
                    
                <Button type="primary"
                    className="w-full mt-10"
                    onClick={ handleSubmit( onSubmit ) }>
                    { t( 'common:chat.conversation.payment.invoicingInfoModal.submitButton' ) }
                </Button>
            </Form>
        </ResponsiveModal>
    );
} );

export default InvoicingInfoModal;