import { Navigate } from 'react-router-dom';

import GoogleAuthRedirect from '@/pages/auth/GoogleAuthRedirect';
import SignIn from '@/pages/auth/sign-in/SignIn';
import SignUpFreelancer from '@/pages/auth/sign-up/sign-up-freelancer//SignUpFreelancer';
import SignUpClient from '@/pages/auth/sign-up/sign-up-client/SignUpClient';
import ErrorBoundary from '@/pages/errors/ErrorBoundary';
import RecoveryPassword from '@/pages/auth/recovery-password/RecoveryPassword';
import AuthRoot from '@/pages/auth/AuthRoot';
import ResetPassword from '@/pages/auth/recovery-password/ResetPassword';
import SignUp from '@/pages/auth/sign-up/SignUp';
import SignUpFreelancerStepWelcome from '@/pages/auth/sign-up/sign-up-freelancer/sign-up-freelancer-steps/SignUpFreelancerStepWelcome';
import SignUpFreelancerStepDiscord from '@/pages/auth/sign-up/sign-up-freelancer/sign-up-freelancer-steps/SignUpFreelancerStepDiscord';
import SignUpClientStepWelcome from '@/pages/auth/sign-up/sign-up-client/sign-in-client-steps/SignUpClientStepWelcome';
import SignUpFreelancerStepUnsupportedCountry from '@/pages/auth/sign-up/sign-up-freelancer/sign-up-freelancer-steps/SignUpFreelancerStepUnsupportedCountry';

export const AuthRouting = {
    path: 'auth',
    children: [
        {
            path: '',
            element: <SignIn />,
            errorElement: <ErrorBoundary />,
            index: true
        },
        {
            path: 'google/redirect',
            element: <GoogleAuthRedirect />,
            errorElement: <ErrorBoundary />
        },
        {
            path: 'sign-in',
            element: <SignIn />,
            errorElement: <ErrorBoundary />
        },
        {
            path: 'sign-up',
            children: [
                {
                    path: '',
                    element: <SignUp />,
                    errorElement: <ErrorBoundary />
                },
                {
                    path: 'freelancer',
                    element: <SignUpFreelancer />,
                    errorElement: <ErrorBoundary />
                },
                {
                    path: 'freelancer/welcome',
                    element: <SignUpFreelancerStepWelcome />,
                    errorElement: <ErrorBoundary />
                },
                {
                    path: 'freelancer/discord',
                    element: <SignUpFreelancerStepDiscord />,
                    errorElement: <ErrorBoundary />
                },
                {
                    path: 'client',
                    children: [
                        {
                            path: '',
                            element: <SignUpClient />,
                            errorElement: <ErrorBoundary />
                        },
                        {
                            path: 'welcome',
                            element: <SignUpClientStepWelcome />,
                            errorElement: <ErrorBoundary />
                        }
                    ]
                }
            ]
        },
        {
            path: 'password',
            element: <AuthRoot />,
            children: [
                {
                    path: '*',
                    element: <RecoveryPassword />
                },
                {
                    path: 'recovery',
                    element: <RecoveryPassword />,
                    errorElement: <ErrorBoundary />
                },
                {
                    path: 'reset/',
                    element: <Navigate to="/"
                                       replace />
                },
                {
                    path: 'reset/:token',
                    element: <ResetPassword />,
                    errorElement: <ErrorBoundary />
                }
            ]
        }
    ]
};