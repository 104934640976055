import { useLazyQuery, useQuery } from '@apollo/client';
import { ReviewsGQL } from '@/components/freelance-profile/components/freelancer-profile-reviews/services/reviewsGQL';
import { AverageRateByUserGQL } from '@/components/freelance-profile/components/freelancer-profile-reviews/services/averageRateGQL';
import { ReviewUI } from '@/components/freelance-profile/components/freelancer-profile-reviews/reviewUI';
import { useEffect, useState } from 'react';
import { Rate } from '@/gql/graphql';

interface FreelancerProfileReviewHookProps {
    limit: number
}

export default function freelancerProfileReviewHook( { limit = 10 }: FreelancerProfileReviewHookProps ) {

    const [ reviews, setReviews ] = useState<ReviewUI[]>( [] );
    const [ averageRate, setAverageRate ] = useState<number>( 0 );
    const [ isLoading, setIsLoading ] = useState<boolean>( false );

    const reviewsQuery = useLazyQuery( ReviewsGQL );

    const averageRateQuery = useLazyQuery( AverageRateByUserGQL );

    const load = (userId: string): void => {
        setIsLoading( true );
        reviewsQuery[ 0 ]( {
            variables: {
                userId
            }
        } );

        averageRateQuery[ 0 ]( {
            variables: {
                userId
            }
        } );
    };

    useEffect( () => {
        if( reviewsQuery[1].data ) {
            const newReviews = fromRatesToReviewUI( reviewsQuery[1].data.rates.data )
            setReviews( [ ...reviews, ...newReviews ] );
        }
    }, [ reviewsQuery[1].data ] );

    useEffect( () => {
        if( averageRateQuery[1].data ) {
            setAverageRate( averageRateQuery[1].data.averageRateByUser );
        }
    }, [ averageRateQuery[1].data ] );

    useEffect( () => {
        if( reviewsQuery[1].data && averageRateQuery[1].data ) {
            setIsLoading( false );
        }
    }, [ reviewsQuery[1].data, averageRateQuery[1].data ] );

    const fromRatesToReviewUI = ( rates: Rate[] ): ReviewUI[] => {
        return rates.map( ( rate: Rate ) => {
            return {
                id: rate.id,
                comment: rate.review,
                avgRate: rate.avgRate,
                createdAt: rate.createdAt,
                author: {
                    id: rate.from.id,
                    userName: rate.from.userName,
                    profilePictureUrl: rate.from.profilePicture
                }
            };
        } );
    };

    return {
        load,
        reviews,
        isLoading,
        averageRate
    };
}