import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ArrowLeftFilledIcon } from '@/assets/icons/CustomIcons';

interface BackButtonProps {
    to?: string;
    onClick?: () => void;
}

export default function BackButton( { to, onClick }: BackButtonProps ) {

    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleBack = () => {
        if( to ) {
            navigate( to );
        }
        else {
            onClick();
        }
    };

    return (
        <>
            <div onClick={ () => handleBack() }
                 className="cursor-pointer">
                <ArrowLeftFilledIcon className="lg:hidden text-[24px]" />
            </div>
            <Button type="text"
                    className="hidden lg:block"
                    onClick={ () => handleBack() }>
                { t( 'common:button.back' ) }
            </Button>
        </>
    );

}