import { gql } from '@apollo/client';

export const FreelancerIdFromCustomUrlGQL = gql`
    query FreelancerIdFromCustomUrlGQL($id: String!) {
        publicFreelancer(id: $id) {
            id
        }
    }`;


