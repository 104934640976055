import { gql } from '@apollo/client';

export const AttachPaymentMethodGQL = gql`
    mutation AttachPaymentMethodGQL($paymentMethodId: String!){
        attachPaymentMethod(paymentMethodId: $paymentMethodId) {
            id
            type
            brand
            last4
        }
    }`;


