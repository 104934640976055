import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { PortfolioChannelsGQL } from '@/gql/global-queries/portfolioChannelsGQL';
import { DeletePortfolioChannelGQL } from '@/gql/global-queries/deletePortfolioChannelGQL';
import { UpdatePortfolioChannelsOrderGQL } from '@/gql/global-queries/updatePortfolioChannelsOrderGQL';
import { CreatePortfolioChannelGQL } from '@/gql/global-queries/createPortfolioChannelGQL';
import { Button, Divider, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { PortfolioChannel } from '@/gql/graphql';
import FreelancerAccountEditPortfolioChannelOrderedList from '@/pages/freelancer/views/account/freelancer-account-edit-portfolio-channels/components/FreelancerAccountEditPortfolioChannelOrderedList';
import * as Sentry from '@sentry/react';
import InputComponent from '@/components/custom-element-form/InputComponent';
import { trackSegmentEvent, SegmentEvent, trackPageView, PageName } from '@/utils/analytics';

export default function FreelancerAccountEditPortfolioChannels() {

    const channelLimit: number = 10;

    const { t } = useTranslation();
    const { trigger, control, reset, formState: { errors }, handleSubmit } = useForm();

    const [ messageApi, contextHolder ] = message.useMessage();
    const [ portfolioItems, setPortfolioItems ] = useState<PortfolioChannel[]>( [] );
    const [ isDeleting, setIsDeleting ] = useState<string[]>( [] );
    const portfolioChannelsQuery = useQuery( PortfolioChannelsGQL );
    const deletePortfolioChannelMutation = useMutation( DeletePortfolioChannelGQL );
    const updateOrderPortfolioChannelMutation = useMutation( UpdatePortfolioChannelsOrderGQL );
    const addPortfolioChannelMutation = useMutation( CreatePortfolioChannelGQL );

    useEffect( () => {
        trackPageView( PageName.FreelancerEditPortfolioChannels );
    }, [ ] );

    useEffect( () => {
        if( portfolioChannelsQuery.data ) {
            setPortfolioItems( portfolioChannelsQuery.data.portfolioChannels );
        }
    }, [ portfolioChannelsQuery.data ] );

    useEffect( () => {
        if( deletePortfolioChannelMutation[ 1 ].data ) {
            setPortfolioItems( portfolioItems.filter( ( portfolioChannel ) => portfolioChannel.id !== deletePortfolioChannelMutation[ 1 ].data.deletePortfolioChannel.id ) );
        }

    }, [ deletePortfolioChannelMutation[ 1 ].data ] );

    const handleDelete = async( portfolioChannel ) => {
        setIsDeleting( [ ...isDeleting, portfolioChannel.id ] );
        try {
            await deletePortfolioChannelMutation[ 0 ]( {
                variables: {
                    id: portfolioChannel.id
                }
            } );
            trackSegmentEvent( SegmentEvent.ProfileUpdated, { profile_updated_type: 'Clients' } );
        } catch( e ) {
            Sentry.captureException( 'An error occur when trying to delete portfolio channel', e );
            messageApi.error( t( 'translation:error.default' ) );
        }
        setIsDeleting( isDeleting.filter( ( id ) => id !== portfolioChannel.id ) );
    };

    const handleOrderChange = ( orderedList ) => {
        updateOrderPortfolioChannelMutation[ 0 ]( {
            variables: {
                args: orderedList.map( ( portfolioChannel: PortfolioChannel ) => {
                    return {
                        portfolioChannelId: portfolioChannel.id,
                        order: portfolioChannel.freelancerToPortfolioChannels.order
                    };
                } )
            }
        } );
        trackSegmentEvent( SegmentEvent.ProfileUpdated, { profile_updated_type: 'Clients' } );
    };

    const onSubmit = async( data ) => {
        if( !( await trigger() ) ) {
            return;
        }

        try {
            const portfolioChannel = await addPortfolioChannelMutation[ 0 ]( {
                variables: {
                    url: data.url
                }
            } );
            setPortfolioItems( [ ...portfolioItems, portfolioChannel.data.createPortfolioChannel ] );
            reset( {
                url: ''
            } );
            trackSegmentEvent( SegmentEvent.ProfileUpdated, { profile_updated_type: 'Clients' } );
        } catch( e ) {
            Sentry.captureException( 'An error occur when trying to add channel to portfolio', e );
            let errorMessage: string = t( 'translation:error.default' );
            if( e.message === 'You can\'t add more than 6 portfolio elements' ) {
                errorMessage = t( 'freelancer:account.edit-portfolio-channels.errorRichLimit', { limit: channelLimit } );
            }
            messageApi.error( errorMessage );
        }
    };

    return (
        <div className="w-full flex flex-col">
            { contextHolder }
            <h1>{ t( 'freelancer:account.edit-portfolio-channels.title' ) }</h1>

            <p className="text-sm text-grey mt-1">
                {
                    t( 'freelancer:account.edit-portfolio-channels.subTitle' )
                }
            </p>

            <div className="mt-4">
                <FreelancerAccountEditPortfolioChannelOrderedList portfolioChannels={ portfolioItems }
                                                                  isLoading={ portfolioChannelsQuery.loading }
                                                                  isDeleting={ isDeleting }
                                                                  onOrderChange={ ( orderedList ) => handleOrderChange( orderedList ) }
                                                                  onDeleteItem={ ( portfolioChannel ) => handleDelete( portfolioChannel ) } />
            </div>

            {
                portfolioItems.length < channelLimit &&
                portfolioChannelsQuery.loading === false &&
                <>
                  <Divider type="horizontal" />
                  <div className="text-base font-medium">
                      { t( 'freelancer:account.edit-portfolio-channels.addCreator' ) }
                  </div>

                  <Form className="flex flex-col lg:flex-row mt-2"
                        onSubmit={ handleSubmit( onSubmit ) }>

                    <InputComponent control={ control }
                                    rules={ {
                                        required: t( 'common:error.form.required' )
                                    } }
                                    name="url"
                                    placeholder={ t( 'freelancer:account.edit-portfolio-channels.inputPlaceholder' ) }
                                    errors={ errors } />

                    <Button type="primary"
                            htmlType="submit"
                            loading={ addPortfolioChannelMutation[ 1 ]?.loading }
                            className="w-full lg:w-[120px] lg:ml-4 mt-4 lg:mt-0">
                        { t( 'freelancer:account.edit-portfolio-channels.submit' ) }
                    </Button>
                  </Form>
                </>
            }
        </div>
    );
}