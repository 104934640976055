import LayoutVisibilityHook from '@/hooks/layoutVisibilityHook';
import ClientProjectListDesktop from '@/pages/client/views/projects/components/ClientProjectList';
import clientProjectsHook from '@/hooks/clientProjectsHook';
import { useEffect, useRef, useState } from 'react';
import ClientProjectModal from '@/pages/client/views/project/ClientProjectModal';
import MenuSection from '@/components/MenuSection';
import { Project, ProjectStatusEnum } from '@/gql/graphql';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { PageName, trackPageView } from '@/utils/analytics';
import ChannelManagerHook from '@/hooks/channelManagerHook';
import YoutubeChannelsSelect from '@/components/YoutubeChannelsSelect';
import { useQuery } from '@apollo/client';
import { projectsInProgressByChannelGQL } from '@/gql/global-queries/projectsInProgressByChannelGQL';

export default function ClientProjects() {

    const { status } = useParams();

    const { t } = useTranslation();
    const [ channelIds, setChannelIds ] = useState<string[] | null>( [] );
    const [ channelFilter, setChannelFilter ] = useState<string>( 'all' ); // ['all', 'incognito', 'channelId'
    const [ countProjectsInProgressByChannel, setCountProjectsInProgressByChannel ] = useState( null );
    const {
              loadProjects,
              loadMore,
              loading,
              hasMore,
              projects,
              projectSelected,
              totalByStatus,
              setProjectSelected,
              handleRefresh
          } = clientProjectsHook( { limit: 10 } );

    const { channelsSelected } = ChannelManagerHook();

    let statusEnum: ProjectStatusEnum = ProjectStatusEnum.IN_PROGRESS;
    if( status === 'finished' ) {
        statusEnum = ProjectStatusEnum.DONE;
    }
    else if( status === 'cancelled' ) {
        statusEnum = ProjectStatusEnum.CANCELLED;
    }

    const totalProjectsByChannelQuery = useQuery( projectsInProgressByChannelGQL );

    useEffect( () => {
        if( totalProjectsByChannelQuery?.data ) {
            setCountProjectsInProgressByChannel( totalProjectsByChannelQuery?.data?.projectsInProgressByChannel );
        }
    }, [ totalProjectsByChannelQuery ] );

    useEffect( () => {
        if( channelsSelected != null && channelsSelected.length > 0 ) {
            setChannelIds( channelsSelected?.map( ( channel ) => channel.id ) );
            setChannelFilter( channelsSelected[0]?.id );
        }
        else if( channelsSelected != null && channelsSelected.length == 0 ) {
            setChannelIds( [] );
            setChannelFilter( 'all' );
        }
        else {
            setChannelIds( null );
            setChannelFilter( 'incognito' );
        }
    }, [ channelsSelected ] );

    useEffect( () => {
        if( status != null ) {
            loadProjects( statusEnum, channelIds );
        }
    }, [ status, channelIds ] );

    useEffect( () => {
        trackPageView( PageName.ClientProject );
    }, [] );

    LayoutVisibilityHook( {
        headerVisible: true,
        bottomNavBarVisible: 'isTabletOrMobile'
    } );

    const nav = [
        {
            title: t( 'client:projects.ongoing',
                {
                    count: totalByStatus?.inProgress
                } ),
            value: ProjectStatusEnum.IN_PROGRESS,
            route: '/client/projects/status/ongoing'
        },
        {
            title: t( 'client:projects.finished', {
                count: totalByStatus?.done
            } ),
            value: ProjectStatusEnum.DONE,
            route: '/client/projects/status/finished'
        },
        {
            title: t( 'client:projects.cancelled', {
                count: totalByStatus?.cancelled
            } ),
            value: ProjectStatusEnum.CANCELLED,
            route: '/client/projects/status/cancelled'
        }
    ];

    const clientProjectModalRef = useRef( null );

    const handleDrawerOpening = ( project ) => {
        clientProjectModalRef.current.open();
        setProjectSelected( project );
    };

    const handleOnChannelSelected = ( value: string | 'all' | 'incognito' ) => {
        switch( value ) {
            case 'all':
                setChannelIds( [] );
                break;
            case 'incognito':
                setChannelIds( null );
                break;
            default:
                setChannelIds( [ value ] );
                break;
        }
    };

    const onReviewSubmitted = ( project: Project ) => {
        if( project.clientHasReviewed && project.freelancerHasReviewed ) {
            const countProjectsInProgressByChannelUpdated = { ...countProjectsInProgressByChannel };
            countProjectsInProgressByChannelUpdated.totalCount = countProjectsInProgressByChannel.totalCount - 1;            
            const index = countProjectsInProgressByChannel?.countByChannel?.findIndex( ( channel ) => channel.channelId == project.channelId );
            
            if( index != -1 ) {
                countProjectsInProgressByChannelUpdated.countByChannel[ index ].totalCount = countProjectsInProgressByChannelUpdated.countByChannel[ index ].totalCount - 1;                
            }

            setCountProjectsInProgressByChannel( countProjectsInProgressByChannelUpdated )
        }
        handleRefresh( project )
    }

    return (
        <>
            <div className="w-full">
                <div className="flex justify-between">
                    <div className="hidden lg:block font-medium text-2xl font-sharp">
                        { t( 'client:projects.title' ) }
                    </div>
                    <YoutubeChannelsSelect countProjectsInProgressByChannel={  countProjectsInProgressByChannel }
                                           defaultValue={ channelFilter }
                                           onChannelSelected={ ( value ) => handleOnChannelSelected( value ) } />
                </div>
                <div className="w-full flex flex-col mt-4 lg:flex-row lg:mt-8">
                    <MenuSection routes={ nav }
                                 className="lg:w-[180px]" />
                    <div className="mt-6 lg:mt-0 w-full lg:ml-[164px]">
                        <ClientProjectListDesktop projects={ projects }
                                                  hasMore={ hasMore }
                                                  isLoading={ loading }
                                                  loadMore={ () => loadMore( channelIds ) }
                                                  onProjectSelected={ ( value ) => handleDrawerOpening( value ) } />
                    </div>
                </div>
            </div>

            <ClientProjectModal ref={ clientProjectModalRef }
                                projectId={ projectSelected?.id }
                                onReviewSubmitted={( project: Project ) => onReviewSubmitted( project )} />
        </>

    );
}