import { NavLink } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

export interface AccountNavigationItem {
    title: string;
    route: string;
    isExternal?: boolean;
}


interface MenuSectionProps {
    routes: AccountNavigationItem[];
    className?: string;
}

export default function MenuSection( { routes, className }: MenuSectionProps ) {

    const navCSS: string = 'whitespace-nowrap w-fit px-3.5 py-2.5 text-grey hover:text-primary-500 hover:bg-primary-100 rounded-xl font-semibold border border-grey-light lg:border-none ';

    return (
        <nav className={ `pb-4 lg:pb-0 w-full overflow-x-scroll lg:overflow-auto flex flex-row lg:flex-col gap-2 ${ className }` }>
            {
                routes?.map( ( item, index ) => {
                        return (
                            item.isExternal ?
                            <a href={ item.route }
                               key={ index }
                               target={ '_blank' }
                               className={ navCSS }
                               rel={ 'noreferrer' }>
                                { item.title }
                            </a> :
                            <NavLink to={ item.route }
                                     key={ index }
                                     className={ ( { isActive } ) => {
                                         const state = isActive ? 'text-primary-500 bg-primary-100 border-primary-400' : '';
                                         return twMerge( navCSS, state );
                                     } }>
                                { item.title }
                            </NavLink>
                        );
                    }
                )
            }
        </nav>
    );
}