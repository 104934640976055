import { Project, ActionTypeEnum } from '@/gql/graphql'
import { useTranslation } from 'react-i18next'
import { ExclamationFilledIcon, ZapFastOutlinedIcon } from '@/assets/icons/CustomIcons'
import { DateTime } from 'luxon';
import { getDaySuffix } from '@/utils/getDaySuffix';

interface FreelancerActionItemProjectDetailProps {
    project: Project;
}

export default function FreelancerActionItemProjectDetail({ project }: FreelancerActionItemProjectDetailProps) {
    const { t } = useTranslation()


    const originalDate = DateTime.fromISO( project.createdAt );
    const day = originalDate.day;

    return (
        <div className='px-5 py-4 rounded-lg border border-blue-100 flex flex-col gap-2'>
            <span className='font-semibold'>{ project.name }</span>
            <div>
                <div className='flex gap-4'>
                    <ZapFastOutlinedIcon className='text-primary-500 text-base' />
                    {
                        project.createdAt &&
                        <div className='flex flex-col leading-5'>
                            <span>{ DateTime.fromISO(project.createdAt).toFormat(`'${ day }${ getDaySuffix( day ) }' 'of' LLLL yyyy`) }</span>
                            <span className='text-grey text-xs'>{ t('freelancer:dashboard.actionCenter.action.deadline') }</span>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}
