import { ReviewUI } from '@/components/freelance-profile/components/freelancer-profile-reviews/reviewUI';
import { useTranslation } from 'react-i18next';
import FreelancerRatingTag from '@/components/freelance-profile/components/FreelancerRatingTag';
import { Avatar, Divider, Progress, Tag } from 'antd';
import SkeletonInput from 'antd/es/skeleton/Input';

import FreelancerDashboardEditAvailability from './FreelancerDashboardEditAvailability';
import { UserOutlined } from '@ant-design/icons';
import { Freelancer, FreelancerAccountStatusEnum } from '@/gql/graphql';
import FreelancerDashboardStats from './FreelancerDashboardStats';
import { freelancerStatsHook } from '@/hooks/freelancerStatsHook';
import { useEffect } from 'react';
import { DateTime } from 'luxon';

interface FreelancerDashboardProfileProps {
    freelancer: Freelancer;
    isLoading: boolean;
    completedPercentage: number;
    isProfileComplete: boolean;
    isLoggedIn?: boolean;
    onHire?: () => void;
}

export default function FreelancerDashboardProfile( {
    isLoading = true,
    isProfileComplete,
    freelancer,
    completedPercentage
}: FreelancerDashboardProfileProps ) {

    const { t } = useTranslation();

    const { load: loadStatsSinceAccountCreation, loading: isLoadingStatsSinceAccountCreation, data: statsSinceAccountCreation } = freelancerStatsHook();
    const { load: loadStatsForLast30Days, loading: isLoadingStatsForLast30Days, data: statsForLast30Days } = freelancerStatsHook();

    useEffect( () => {
        if(freelancer?.createdAt) {            
            loadStatsSinceAccountCreation({ variables: { from: freelancer.createdAt, to: DateTime.now() } })
        }
        loadStatsForLast30Days({ variables: { from: DateTime.local().minus({ day: 30 }), to: DateTime.now() } })
    }, [ freelancer ] );

    if( isLoading ) {
        return (
            <div className="flex flex-col items-center gap-y-12">
                <SkeletonInput active={ true }
                    className="w-full h-[510px] rounded-2xl" />
            </div>
        )
    }

    const getColorForAccountStatus = ( status: FreelancerAccountStatusEnum ) => {
        switch (status) {
        case FreelancerAccountStatusEnum.APPROVED:
            return 'success';
        case FreelancerAccountStatusEnum.PENDING_APPROBATION:
            return 'orange';
        case FreelancerAccountStatusEnum.REJECTED:
            return 'red';
        default:
            return 'default';
        }
    };

    const color = getColorForAccountStatus( freelancer.accountStatus );

    return (
        <div className="rounded-4xl border border-blue-100 w-full pb-12 overflow-hidden">
            {
                !isProfileComplete && completedPercentage ?
                    <div className='flex justify-center relative' >
                        <Progress 
                            type="dashboard" 
                            className='p-8'
                            size={ 140 }
                            percent={ completedPercentage }
                            format={() => <Avatar 
                                className='border-[6px] border-white w-[calc(100%-18px)] h-[calc(100%-18px)]'
                                icon={ <UserOutlined /> }
                                src={ freelancer?.profilePicture } 
                            />}
                        />
                        <div className="w-fit absolute bottom-6 m-auto left-0 right-0 bg-primary-500 px-4 py-2 text-white font-semibold rounded-4xl">
                            { t( 'freelancer:dashboard.profile.completedPercentage', { completedPercentage } ) }
                        </div>
                    </div>
                    :
                    <div className="relative pb-20">
                        <div className="bg-[url('/img/dashboard-card-header.png')] bg-cover h-[90px] w-full">
                        </div>
                        <Avatar size={ 130 }
                            className='-translate-y-1/2 absolute m-auto left-0 right-0 border-[6px] border-white'
                            icon={ <UserOutlined /> }
                            src={ freelancer?.profilePicture } />
                    </div>
                    
                    
            }
            <div className='text-center font-sharp text-xl mb-2'>{ freelancer?.firstName } { freelancer?.lastName }</div>
            <div className="px-8">
                {
                    !isLoadingStatsSinceAccountCreation &&
                        <div className="flex justify-center items-center gap-2">
                            <FreelancerRatingTag ratingClass={ 'font-sharp text-black' }
                                rating={ statsSinceAccountCreation?.avgRating || 0 } />
                            {
                                statsSinceAccountCreation?.countRatings ? 
                                <>
                                    <span className="text-2xl font-extrabold">·</span>
                                    <span className="text-grey">
                                        {
                                            t( 'common:freelancerProfile.review.review', { count: statsSinceAccountCreation?.countRatings | 0 }
                                            )
                                        }
                                    </span>
                                </> :
                                    <span className="text-grey text-center">
                                        {
                                            t( 'common:freelancerProfile.review.noRatings', { count: 0 }
                                            )
                                        }
                                    </span>
                            }
                        </div>
                }
                <Tag color={ color }
                    className="tag-xs text-center w-fit mx-auto my-4">
                    { t( 'freelancer:dashboard.profile.accountStatus.' + freelancer.accountStatus ) }
                </Tag>
                {
                    freelancer.accountStatus === FreelancerAccountStatusEnum.APPROVED &&
                    <>
                        <Divider className='mt-0 mb-4' />
                        <FreelancerDashboardEditAvailability defaultValue={ freelancer?.isAvailable } />
                        <Divider className='mt-8 mb-4' />
                        <div className="flex flex-col gap-9">
                            <FreelancerDashboardStats
                                title={ t( 'freelancer:dashboard.stats.30DayStats' ) }
                                stats={ {
                                    countProjectsDone: statsForLast30Days?.countProjectsDone,
                                    moneyEarned: statsForLast30Days?.moneyEarned,
                                    avgRating: statsForLast30Days?.avgRating
                                } }
                                isLoading={ isLoadingStatsForLast30Days }
                            />
                            <FreelancerDashboardStats
                                title={ t( 'freelancer:dashboard.stats.lifetimeStats' ) }
                                stats={ {
                                    countProjectsDone: statsSinceAccountCreation?.countProjectsDone,
                                    moneyEarned: statsSinceAccountCreation?.moneyEarned,
                                    avgRating: statsSinceAccountCreation?.avgRating
                                } }
                                isLoading={ isLoadingStatsSinceAccountCreation }
                            />
                        </div>
                    </>
                }
            </div>
        </div>
    );
};