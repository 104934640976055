import { gql } from '@apollo/client';

export const skillsWithCountGQL = gql`
    query Skills {
        skills {
            id
            name
            i18n
            totalFreelancer
        }
    }
`;