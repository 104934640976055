import { useEffect, useState } from 'react';
import { motion, useAnimation } from 'framer-motion';
import abbreviationNumberFormatter from '@/utils/abbrevationNumberFormatter';

interface AnimatedNumberProps {
    targetValue: number;
    className?: string;
}

export default function AnimatedNumber( { targetValue, className }: AnimatedNumberProps ) {
    const [ currentValue, setCurrentValue ] = useState( 0 );
    const controls = useAnimation();

    // Calculate the increment based on the targetValue
    const increment = Math.max( 1, Math.floor( targetValue / 100 ) );

    useEffect( () => {
        controls.start( { opacity: 1 } );

        let animationFrameId;

        const updateValue = () => {
            setCurrentValue( ( prevValue ) => {
                const newValue = prevValue + increment;

                // If the new value is equal to or greater than the target value
                if( newValue >= targetValue ) {
                    // Cancel the animation frame and start the bounce effect
                    cancelAnimationFrame( animationFrameId );
                    controls.start( {
                        scale: [ 1.5, 0.8, 1.2, 0.9, 1.05, 0.95, 1 ],
                        transition: { duration: 0.8 }
                    } );
                    return targetValue;
                }
                return newValue;
            } );
            animationFrameId = requestAnimationFrame( updateValue );
        };

        updateValue();

        // Cleanup function to cancel the animation frame
        return () => cancelAnimationFrame( animationFrameId );

    }, [ targetValue, controls, increment ] );

    return (
        <motion.div initial={ { opacity: 0, scale: 1 } }
                    animate={ controls }>
            <span className={ className }>
                { abbreviationNumberFormatter( currentValue ) }
            </span>
        </motion.div>
    );
};