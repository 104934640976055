import { useTranslation } from 'react-i18next';
import channelsHook from '@/hooks/channelsHook';
import { useContext } from 'react';
import { ColorSchemeContext } from '@/core/dark-mode/colorSchemeProvider';
import ListSkeleton from '@/components/ListSkeleton';
import ChannelItem from '@/components/ChannelItem';
import { Button, Drawer, Space } from 'antd';
import { CloseOutlinedIcon, PlusOutlinedIcon } from '@/assets/icons/CustomIcons';
import { Channel } from '@/gql/graphql';

interface ChannelSwitcherTouchProps {
    isOpen: boolean;
    onClose?: () => void;

    onSelect( channel: Channel ): void;
}

export default function ChannelSwitcherTouch( { isOpen, onSelect, onClose }: ChannelSwitcherTouchProps ) {

    const { t } = useTranslation();

    const { loading, channels, addYTChannelHook, handleAddChannel } = channelsHook();
    const colorSchemeContext = useContext( ColorSchemeContext );

    const loadingJSX = (
        <ListSkeleton isLoading={ true }
                      height={ 50 }
                      count={ 6 } />
    );

    const loadingNewYTChannelJSX = (
        <ListSkeleton isLoading={ true }
                      height={ 50 }
                      count={ 1 } />
    );

    const handleClose = () => {
        if( onClose ) {
            onClose();
        }
    };

    const coreJSX = (
        <>
            {
                channels.map( ( channel, index ) => {
                    return (
                        <div key={ index }
                             className="cursor-pointer hover:bg-grey-light hover: hover:rounded-full px-4 py-2">
                            <ChannelItem channel={ channel }
                                         avatarSize={ 50 }
                                         onClick={ ( channel ) => onSelect( channel ) }
                                         key={ index } />
                        </div>
                    );
                } )
            }
            {
                addYTChannelHook.loading ? loadingNewYTChannelJSX : null
            }
        </>
    );

    return (
        <Drawer placement="bottom"
                rootClassName={ colorSchemeContext.toLowerCase() }
                className="rounded-t-4xl overflow-x-hidden bg-white "
                bodyStyle={ { padding: '0 0 24px 0' } }
                width="100%"
                height="fit-content"
                headerStyle={ { textAlign: 'center' } }
                closable={ false }
                title={ t( 'transverse:new-project.title' ) }
                open={ isOpen }
                destroyOnClose={ true }
                extra={
                    <Space>
                        <Button onClick={ () => handleClose() }
                                icon={ <CloseOutlinedIcon style={ { fontSize: '12px' } } /> }
                                shape="circle">
                        </Button>
                    </Space>
                }>
            <div className="overflow-x-hidden px-6 py-4">
                <div className="h-full w-full flex flex-col">
                    <div className="w-full">
                        <h1 className="font-extrabold text-[23px]">
                            { t( 'transverse:new-project.updateChannel.title' ) }
                        </h1>

                        <div className="mt-8 w-full flex flex-col gap-y-4">
                            {
                                loading ? loadingJSX : coreJSX
                            }
                        </div>
                    </div>

                    <div className="flex lg:justify-end mt-6">
                        <Button type="primary"
                                onClick={ () => handleAddChannel() }
                                loading={ addYTChannelHook.loading }
                                className="mt-4 w-full lg:w-[300px]">
                            <PlusOutlinedIcon /> { t( 'client:account.youtube-channels.button' ) }
                        </Button>
                    </div>
                </div>
            </div>
        </Drawer>
    );
}