import NoDataIcon from '@/components/NoDataIcon';
import ListSkeleton from '@/components/ListSkeleton';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from 'react-router-dom';
import FreelancerCard from '@/components/freelance-profile/FreelancerCard';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import { SegmentEvent, trackSegmentEvent } from '@/utils/analytics';
import { Freelancer } from '@/gql/graphql';

interface ClientFavoriteListProps {
    freelancers: (Freelancer & { new: boolean})[];
    loading: boolean;
    next: () => void;
    remove: ( value: string ) => void;
    hasMore: boolean;
}

export default function ClientFavoriteList( { freelancers, loading, next, hasMore, remove }: ClientFavoriteListProps ) {

    const { t } = useTranslation();
    const nav = useNavigate();

    const goToFreelancerProfile = ( freelancerId: string ) => {
        trackSegmentEvent(SegmentEvent.ProfileClicked, {
            profile_clicked_id: freelancerId,
            profile_clicked_origin: 'Favorites'
        });
        nav( `/client/freelancers/${ freelancerId }` );
    };

    const gridClass = 'h-fit grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 gap-6 lg:gap-9';

    if( loading ) {
        return (
            <div className={ gridClass }>
                <ListSkeleton isLoading={ true }
                              count={ 12 }
                              rounded="2xl"
                              height={ 379 } />
            </div>
        );
    }

    if( freelancers.length === 0 ) {
        return (
            <div className="flex flex-col items-center justify-center">
                <NoDataIcon message={ t( 'client:favorites.noDataFound' ) }
                            button={ {
                                text: t( 'client:favorites.noDataFoundButton' ),
                                to: '/client/discover'
                            } } />
            </div>
        );
    }


    return (
        <InfiniteScroll next={ next }
                        hasMore={ hasMore }
                        className={ twMerge( gridClass, 'pb-10' ) }
                        loader={
                            <ListSkeleton isLoading={ true }
                                          count={ 30 }
                                          rounded="2xl"
                                          height={ 198 } />
                        }
                        dataLength={ freelancers.length }>
            {
                freelancers.map( ( favorite, index ) => (
                    <div className="w-full lg:max-w-[301px]"
                         key={ index }>
                        <FreelancerCard profile={ favorite as Freelancer }
                                        newTag={ favorite.new }
                                        onDislike={ () => remove( favorite.id ) }
                                        favoriteButton={ true }
                                        enableClick={ true }
                                        viewProfile={ () => goToFreelancerProfile( favorite.id ) } />
                    </div>
                ) )
            }
        </InfiniteScroll>
    );
}