import { useTranslation } from 'react-i18next';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { stripeSupportedCountries } from '@/utils/stripeSupportedCountries';
import getFlagEmoji from '@/utils/flagEmoji';
import firstLetterUppercaseFormatter from '@/utils/firstLetterUppercaseFormatter';
import { SignUpFreelancerRepository } from '@/pages/auth/sign-up/sign-up-freelancer/signUpFrelancerRepository';
import { Button, Divider } from 'antd';
import SelectComponent from '@/components/custom-element-form/SelectComponent';
import { useForm } from 'react-hook-form';

interface SupportedCountriesUI {
    label: string;
    value: string;
}

const SignUpFreelancerStepCountry = forwardRef( function( props, ref ) {
    const { t } = useTranslation();

    const repository: SignUpFreelancerRepository = new SignUpFreelancerRepository();
    const {
              trigger,
              control,
              watch,
              formState: { errors }
          } = useForm();

    const [ supportedCountries, setSupportedCountries ] = useState<SupportedCountriesUI[]>( [] );

    const subscription = watch( ( value ) => {
        repository.companyCountry = getLabelWithoutFlag( value.country );
        repository.companyCountryCode = value.country;
    } );

    useImperativeHandle( ref, () => ( {
        async validateFields() {
            return await trigger();
        }
    } ) );

    useEffect( () => {
        getSupportedCountries();

        return () => subscription.unsubscribe();
    }, [] );

    const getLabelWithoutFlag = ( countryCode: string ) => {
        const selectedCountry: SupportedCountriesUI = supportedCountries.find(item => item.value === countryCode);
        if (selectedCountry) {
            return repository.companyCountry = selectedCountry.label.split( ' ' )[ 1 ];
        }

        return null;
    }

    const getSupportedCountries = () => {
        const supportedCountriesUI: SupportedCountriesUI[] = [];
        const supportedCountries = stripeSupportedCountries;
        for( let i = 0; i < supportedCountries.length; i++ ) {
            const country = supportedCountries[ i ];
            supportedCountriesUI.push( {
                label: `${ getFlagEmoji( country.value ) } ${ firstLetterUppercaseFormatter( country.label ) }`,
                value: country.value
            } );
        }
        setSupportedCountries( supportedCountriesUI );
    };

    const goToUnsupportedCountryPage = () => {
        repository.nextStep( 1 );
    };

    return (
        <form>
            <SelectComponent options={ supportedCountries }
                             control={ control }
                             defaultValue={ repository.companyCountryCode }
                             name="country"
                             className="w-full"
                             label={ t( 'auth:sign-up.freelancer.invoicing-page.companyCountry' ) }
                             placeholder={ t( 'auth:sign-up.freelancer.invoicing-page.companyCountryPlaceholder' ) }
                             errors={ errors }
                             filterOption={ ( input, option ) =>
                                 ( option?.label ?? '' ).toLowerCase().includes( input.toLowerCase() )
                             }
                             rules={ {
                                 required: t( 'common:error.form.required' )
                             } } />
            <Divider>{ t( 'auth:sign-up.freelancer.country-page.or' ) }</Divider>

            <Button type="link"
                    className="w-full"
                    onClick={ () => goToUnsupportedCountryPage() }>
                { t( 'auth:sign-up.freelancer.country-page.notInList' ) }
            </Button>
        </form>
    );
} );

export default SignUpFreelancerStepCountry;