import { useContext, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { Button, Drawer } from 'antd';

import { ArrowLeftFilledIcon, BoldFilledIcon } from '@/assets/icons/CustomIcons';
import { ColorSchemeEnum } from '@/core/dark-mode/colorSchemeRepository';
import { ColorSchemeContext } from '@/core/dark-mode/colorSchemeProvider';
import TransverseFreelancerProfile from '@/pages/transverse/views/freelancer/TransverseFreelancerProfile';
import { useNavigate } from 'react-router-dom';

interface TransverseFreelancerProfileDrawerProps {
    freelancerId: string;
    onClose?: () => void;
}

export default function TransverseFreelancerProfileDrawer( {
                                                               freelancerId,
                                                               onClose
                                                           }: TransverseFreelancerProfileDrawerProps ) {
    const { t } = useTranslation();

    const colorSchemeContext: ColorSchemeEnum = useContext( ColorSchemeContext );
    const navigate = useNavigate();

    const handleCloseDrawer = () => onClose?.();

    const handleHire = () => navigate( '/auth/sign-up/client' );

    useEffect( () => {
        if( freelancerId != null ) {
            document.body.style.overflow = 'hidden';
        }
        else {
            document.body.style.overflow = 'auto';
        }

        return () => {
            document.body.style.overflow = 'auto'; // Reset when component unmounts
        };
    }, [ freelancerId ] );

    return (
        <Drawer placement="bottom"
                className="overflow-x-hidden bg-white"
                bodyStyle={ { padding: '24px 24px 0 24px' } }
                width="100%"
                height="100%"
                closable={ false }
                rootClassName={ colorSchemeContext.toLowerCase() }
                open={ freelancerId != null }
                destroyOnClose={ true }>
            <div className="pb-[30px]">
                {
                    freelancerId != null ? <TransverseFreelancerProfile freelancerId={ freelancerId } /> : null
                }
            </div>
            <div className="bg-white lg:hidden sticky bottom-0 left-0 right-0 h-[70px] flex justify-between items-center gap-x-4">
                <Button shape="circle"
                        size="middle"
                        onClick={ () => handleCloseDrawer() }
                        icon={ <ArrowLeftFilledIcon style={ { fontSize: '14px' } } /> }
                />
                <Button type="primary"
                        onClick={ () => handleHire() }
                        className="w-full pulse">
                                    <span className="flex gap-x-2 justify-center">
                                    { t( 'common:freelancerProfile.card.hireButton' ) }
                                        <BoldFilledIcon style={ { fontSize: '16px' } } />
                                    </span>
                </Button>
            </div>
        </Drawer>
    );
}