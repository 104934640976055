import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SessionRepository } from '@/core/auth/sessionRepository';
import { Badge } from 'antd';
import { NavLink } from 'react-router-dom';
import MyAccountButton from '@/components/MyAccountButton';
import Header from '@/components/layout/Header';
import { DiscordLogoIcon, FileOutlinedIcon, LinkFilledIcon, MessageOutlinedIcon, HomeOutlinedIcon } from '@/assets/icons/CustomIcons';
import { Freelancer, UserTypeEnum } from '@/gql/graphql';
import SupportButton from '@/components/SupportButton';
import { freelancerProfileLinkHook } from '@/hooks/freelancerProfileLinkHook';
import ChatHook from '@/components/chat/hooks/ChatHook';
import freelancerProjectsHook from '@/hooks/freelancerProjectsHook';

export function FreelancerHeaderDesktop() {
    const { t } = useTranslation();

    const sessionRepository = new SessionRepository();
    const { totalUnreadMessages } = ChatHook();
    const { total: totalProjects } = freelancerProjectsHook();

    const [ user, setUser ] = useState<Partial<Freelancer>>( null );
    const { getProfileLink } = freelancerProfileLinkHook();

    useEffect( () => {
        const observable = sessionRepository.session$.subscribe( ( session ) => {
            setUser( session.user as Freelancer );
        } );
        return () => {
            observable.unsubscribe();
        };
    }, [] );

    const goToDiscord = () => {
        window.open( 'https://discord.com/invite/s3uMYNPtwV', '_blank', 'noreferrer' );
    };

    const navs = [
        {
            to: '/freelancer/dashboard',
            text: t( 'freelancer:header.desktop.dashboard' ),
            icon: <HomeOutlinedIcon className="text-[24px]" />,
        },
        {
            to: '/freelancer/messages',
            text: t( 'freelancer:header.desktop.messages' ),
            icon: <MessageOutlinedIcon className="text-[24px]" />,
            count: totalUnreadMessages
        },
        {
            to: '/freelancer/projects',
            text: t( 'freelancer:header.desktop.projects' ),
            icon: <FileOutlinedIcon className="text-[24px]" />,
            count: totalProjects?.inProgress
        }
    ];

    const leftJSX = (
        <>
            {
                navs.map( ( nav, index ) => {
                    return (
                        <NavLink key={ index }
                                 to={ nav.to }
                                 className={ ( { isActive } ) => `
                                            block px-4 py-2 rounded-4xl font-semibold hover:text-primary-400 text-base
                                            ${ isActive
                                               ? 'text-primary-500 bg-primary-100'
                                               : 'text-grey' }
                                            ` }>
                                <span className="flex items-center gap-x-2 text-inherit">
                                    <Badge count={ nav.count }
                                           className="text-inherit"
                                           size="small">
                                        <div className="text-inherit">
                                            { nav.icon }
                                        </div>
                                    </Badge>
                                    { nav.text }
                                </span>
                        </NavLink>
                    );
                } )
            }
        </>
    );

    const customSection = [
        {
            key: 'share-my-profile',
            label: (
                <div className="text-sm font-medium py-1.5">
                    { t( 'freelancer:account.shareProfile' ) }
                </div>
            ),
            icon: <LinkFilledIcon className="font-medium text-base" />,
            onClick: () => getProfileLink()
        },
        {
            key: 'discord',
            label: (
                <div className="text-sm font-medium py-1.5">
                    { t( 'freelancer:account.joinDiscord' ) }
                </div>
            ),
            icon: <DiscordLogoIcon className="text-base" />,
            onClick: () => goToDiscord()
        }
    ];

    const rightJSX = (
        <div className="flex items-center justify-center gap-x-4">
            <SupportButton />
            <MyAccountButton customActions={ customSection }
                             data={ {
                                 type: UserTypeEnum.FREELANCER,
                                 profilePicture: user?.profilePicture,
                                 userName: user?.firstName
                             } } />
        </div>
    );

    return (
        <Header left={ leftJSX }
                right={ rightJSX }
                logoTo="/freelancer/dashboard" />
    );
}