import { gql } from '@apollo/client';

export const CreateQuoteGQL = gql`
    mutation CreateQuoteGQL($args: CreateQuoteInput!) {
        createQuote(args: $args){
            id
            projectId
            status
            project {
                client {
                    id
                },
                freelancer {
                    id
                }
            }
        }
    }`;


