import Lottie from 'lottie-react';
import loaderAnimation from '@/assets/animations/loader.json';

export default function ChatConversationMessageTypingIndicator() {

    // TODO améliorable, refacto avec itemMessage
    return (
        <div className="w-full flex justify-start">
            <div className="py-2 font-normal border border-solid border-grey-light rounded-b-2xl rounded-tr-2xl">
                <Lottie animationData={ loaderAnimation }
                        style={ { width: 100, height: 30, transform: 'scale(3.0)' } }
                        loop={ true } />
            </div>
        </div>
    );
}