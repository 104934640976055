import { HeartFilledIcon, HeartOutlinedIcon } from '@/assets/icons/CustomIcons';
import { useState } from 'react';
import { motion } from 'framer-motion';

interface FreelancerFavoriteButtonProps {
    isFavorite: boolean;
    onLike?: () => void;
    onDislike?: () => void;
}

export default function FreelancerFavoriteButton( { isFavorite, onLike, onDislike }: FreelancerFavoriteButtonProps ) {
    const [ _isFavorite, setIsFavorite ] = useState( isFavorite );

    const handleClick = (e) => {
        e.preventDefault();
        if( _isFavorite && onDislike ) {
            onDislike();
        }

        if( !_isFavorite && onLike ) {
            onLike();
        }

        setIsFavorite( !_isFavorite );
    };

    const heartVariants = {
        initial: { scale: 1 },
        animate: { scale: 1.6, transition: { yoyo: 1, duration: 0.3 } }
    };

    return (
        <div onClick={ (e) => handleClick(e) }>
            {
                _isFavorite ?
                <motion.div initial="initial"
                            whileTap="animate"
                            variants={ heartVariants }>
                    <HeartFilledIcon className="text-white text-[24px]" />
                </motion.div>
                            :
                <motion.div initial="initial"
                            whileTap="animate"
                            variants={ heartVariants }>
                    <HeartOutlinedIcon className="text-white text-[24px]" />
                </motion.div>
            }
        </div>
    );
}
