import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { Button, Divider, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import FreelancerAccountEditPortfolioElementOrderedList from '@/pages/freelancer/views/account/freelancer-account-edit-portfolio-elements/components/FreelancerAccountEditPortfolioElementOrderedList';
import * as Sentry from '@sentry/react';
import InputComponent from '@/components/custom-element-form/InputComponent';
import { SignUpFreelancerRepository } from '@/pages/auth/sign-up/sign-up-freelancer/signUpFrelancerRepository';
import { PublicCreatePortfolioElementGQL } from '@/gql/global-queries/publicCreatePortfolioElementGQL';

export default function SignUpFreelancerStepPortfolioVideo() {
    const addPortfolioElementMutation = useMutation( PublicCreatePortfolioElementGQL );

    const repository = new SignUpFreelancerRepository();

    const [ messageApi, contextHolder ] = message.useMessage();

    const { t } = useTranslation();
    const { trigger, control, reset, formState: { errors }, handleSubmit } = useForm();

    const [ isDeleting, setIsDeleting ] = useState<string[]>( [] );

    const handleDelete = async( portfolioElement ) => {
        repository.portfolioVideos = repository.portfolioVideos.filter( ( video ) => video.id !== portfolioElement.id );
        setIsDeleting( isDeleting.filter( ( id ) => id !== portfolioElement.id ) );
    };

    const handleOrderChange = ( orderedList ) => {
        repository.portfolioVideos = orderedList;
    };

    const onSubmit = async( data ) => {
        if( !( await trigger() ) ) {
            return;
        }

        try {
            const portfolioElement = await addPortfolioElementMutation[ 0 ]( {
                variables: {
                    url: data.url
                }
            } );

            repository.portfolioVideos = [
                ...repository.portfolioVideos,
                ...portfolioElement.data.publicCreatePortfolioElement.portfolioElements
            ];

            reset( {
                url: ''
            } );

            messageApi.success( t( 'freelancer:account.edit-portfolio-elements.videoImportedWithCount', { count: portfolioElement.data.publicCreatePortfolioElement.portfolioElements?.length } ) );
        } catch( e ) {
            Sentry.captureException( 'An error occur when trying to add new portfolio element', e );
        }
    };

    return (
        <div className="w-full flex flex-col">
            <div className="mt-4">
                <FreelancerAccountEditPortfolioElementOrderedList portfolioElements={ repository.portfolioVideos }
                                                                  isDeleting={ isDeleting }
                                                                  onOrderChange={ ( orderedList ) => handleOrderChange( orderedList ) }
                                                                  onDeleteItem={ ( portfolioElement ) => handleDelete( portfolioElement ) } />
            </div>


            <Divider type="horizontal" />
            <div className="text-base font-medium">
                { t( 'freelancer:account.edit-portfolio-elements.addProject' ) }
            </div>

            <Form className="flex flex-col lg:flex-row mt-2"
                  onSubmit={ handleSubmit( onSubmit ) }>

                <InputComponent control={ control }
                                name="url"
                                rules={ {
                                    required: t( 'common:error.form.required' ),
                                    pattern: {
                                        value: /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*\??[\w=&\+\%]*)*\/?$/,
                                        message: t( 'freelancer:account.edit-portfolio-elements.wrongUrl' )
                                    }
                                } }
                                placeholder={
                                    t( 'freelancer:account.edit-portfolio-elements.inputPlaceholder' )
                                }
                                errors={ errors } />

                <Button type="primary"
                        htmlType="submit"
                        loading={ addPortfolioElementMutation[ 1 ]?.loading }
                        className="w-full lg:w-[160px] lg:ml-4 mt-4 lg:mt-0">
                    { t( 'freelancer:account.edit-portfolio-elements.submit' ) }
                </Button>
            </Form>
        </div>
    );
}
