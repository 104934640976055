import { gql } from '@apollo/client';

export const FreelancerUpdateCompanyDetailsGQL = gql`
    mutation FreelancerUpdateCompanyDetailsGQL($args: UpdateFreelancerCompanyDetailsInput!) {
        updateFreelancerCompanyDetails(args: $args) {
            id
            vatRate
            addressName
            addressStreet1
            addressStreet2
            addressCity
            addressState
            addressPostalCode
            addressCountry
        }
    }`;


