import { useQuery } from '@apollo/client';
import { PaymentMethodsGQL } from '@/pages/client/views/account/client-account-edit-payment-methods/services/paymentMethodsGQL';
import { Button, Divider, Drawer, message, Modal, Space } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PaymentMethodItem from '@/components/PaymentMethodItem';
import ListSkeleton from '@/components/ListSkeleton';
import NoDataIcon from '@/components/NoDataIcon';
import { CloseOutlinedIcon, PlusOutlinedIcon } from '@/assets/icons/CustomIcons';
import { ColorSchemeContext } from '@/core/dark-mode/colorSchemeProvider';
import NewPaymentMethod from '@/components/NewPaymentMethod';
import { PaymentMethod } from '@/gql/graphql';
import { useMediaQuery } from 'react-responsive';
import { PageName, trackPageView } from '@/utils/analytics';

export default function ClientAccountEditPaymentMethods() {

    const { t } = useTranslation();
    const isTabletOrMobile = useMediaQuery( { query: `(max-width: ${ import.meta.env.VITE_MOBILE_BREAK_POINT })` } );
    const colorSchemeContext = useContext( ColorSchemeContext );
    const [ messageApi ] = message.useMessage();
    const [ paymentMethods, setPaymentMethods ] = useState<PaymentMethod[]>( [] );
    const { data, loading, error, refetch } = useQuery( PaymentMethodsGQL );
    const [ openDrawerModal, setOpenDrawerModal ] = useState( false );

    useEffect( () => {
        if( data ) {
            setPaymentMethods( data?.retrievePaymentMethods );
        }
    }, [ data ] );

    useEffect( () => {
        if( error ) {
            messageApi.error( t( 'error.default' ) );
        }
    }, [ error ] );

    useEffect( () => {
        trackPageView( PageName.ClientEditPaymentMethods );
    }, [ ] );

    const handleNewPaymentMethod = ( paymentMethod: PaymentMethod ): void => {
        paymentMethods.push( paymentMethod );
        setOpenDrawerModal( false );
    };

    const handleRemovePaymentMethod = ( paymentMethod: PaymentMethod ): void => {
        const updatedPaymentMethods = paymentMethods.filter(
            (method) => method.id !== paymentMethod.id
        );
        setPaymentMethods(updatedPaymentMethods);
    };

    const paymentMethodsJSX = (
        <>
            {
                paymentMethods.map( ( paymentMethod, index ) => {
                    return (
                        <PaymentMethodItem onDelete={ ( paymentMethod ) => handleRemovePaymentMethod( paymentMethod ) } 
                            currentPaymentMethod={ paymentMethod } 
                            key={ index } />
                    );
                } )
            }
        </>
    );

    const isLoadingJSX = (
        <ListSkeleton isLoading={ true }
                      count={ 2 }
                      height={ 70 } />
    );

    const noDataJSX = (
        <div className="flex items-center justify-center w-full">
            <NoDataIcon message={ t( 'client:account.edit-payments.noData' ) } />
        </div>
    );


    const notLoadingJSX = (
        <>
            {
                paymentMethods.length > 0 ? paymentMethodsJSX : noDataJSX
            }
        </>
    );

    return (
        <div className="w-full">
            <h1>{ t( 'client:account.edit-payments.title' ) }</h1>
            <div className={ `flex flex-col justify-between mt-5 mb-4 ${ loading ? 'gap-4' : '' }` }>
                {
                    loading ? isLoadingJSX : notLoadingJSX
                }
            </div>

            <div className="flex lg:justify-end">
                <Button type="primary"
                        onClick={ () => setOpenDrawerModal( true ) }
                        className="mt-4 w-full lg:w-[230px]">
                    <PlusOutlinedIcon /> { t( 'client:account.edit-payments.addButton' ) }
                </Button>
            </div>

            {
                isTabletOrMobile ?
                <Drawer placement="bottom"
                        className="overflow-x-hidden bg-white  rounded-t-4xl"
                        bodyStyle={ { padding: 0 } }
                        width="100%"
                        height="fit-content"
                        headerStyle={ { textAlign: 'center' } }
                        closable={ false }
                        rootClassName={ colorSchemeContext.toLowerCase() }
                        open={ openDrawerModal }
                        title={ t( 'client:account.edit-payments.addPaymentMethodDrawer.title' ) }
                        extra={
                            <Space>
                                <Button onClick={ () => setOpenDrawerModal( false ) }
                                        icon={ <CloseOutlinedIcon style={ { fontSize: '12px' } } /> }
                                        size="small"
                                        shape="circle">
                                </Button>
                            </Space>
                        }
                        destroyOnClose={ true }>
                    <div className="relative bg-white  default-padding-layout-x default-padding-layout-y">
                        <NewPaymentMethod onSuccess={ ( paymentMethod ) => handleNewPaymentMethod( paymentMethod ) } />
                    </div>
                </Drawer> :
                <Modal open={ openDrawerModal }
                       rootClassName={ colorSchemeContext.toLowerCase() }
                       width={ 500 }
                       destroyOnClose={ false }
                       title={ t( 'client:account.edit-payments.addPaymentMethodDrawer.title' ) }
                       onCancel={ () => setOpenDrawerModal( false ) }
                       footer={ null }>
                    <Divider />
                    <div className="mt-6">
                        <NewPaymentMethod onSuccess={ ( paymentMethod ) => handleNewPaymentMethod( paymentMethod ) } />
                    </div>
                </Modal>
            }
        </div>
    );
}