import { gql } from '@apollo/client';


export const PortfolioChannelsGQL = gql`
    query PortFolioChannelsGQL {
        portfolioChannels {
            id
            name
            videoCount
            viewCount
            subscriberCount
            category
            thumbnailUrl
            freelancerToPortfolioChannels {
                id
                order
            }
        }
    }`;


