export const stripeSupportedCountries = [
    { 'value': 'AU', 'label': 'Australia' },
    { 'value': 'AT', 'label': 'Austria' },
    { 'value': 'BE', 'label': 'Belgium' },
    { 'value': 'BR', 'label': 'Brazil' },
    { 'value': 'BG', 'label': 'Bulgaria' },
    { 'value': 'CA', 'label': 'Canada' },
    { 'value': 'HR', 'label': 'Croatia' },
    { 'value': 'CY', 'label': 'Cyprus' },
    { 'value': 'CZ', 'label': 'Czech Republic' },
    { 'value': 'DK', 'label': 'Denmark' },
    { 'value': 'EE', 'label': 'Estonia' },
    { 'value': 'FI', 'label': 'Finland' },
    { 'value': 'FR', 'label': 'France' },
    { 'value': 'DE', 'label': 'Germany' },
    { 'value': 'GI', 'label': 'Gibraltar' },
    { 'value': 'GR', 'label': 'Greece' },
    { 'value': 'HK', 'label': 'Hong Kong' },
    { 'value': 'HU', 'label': 'Hungary' },
    { 'value': 'IN', 'label': 'India' },
    { 'value': 'IE', 'label': 'Ireland' },
    { 'value': 'IT', 'label': 'Italy' },
    { 'value': 'JP', 'label': 'Japan' },
    { 'value': 'LV', 'label': 'Latvia' },
    { 'value': 'LI', 'label': 'Liechtenstein' },
    { 'value': 'LT', 'label': 'Lithuania' },
    { 'value': 'LU', 'label': 'Luxembourg' },
    { 'value': 'MY', 'label': 'Malaysia' },
    { 'value': 'MT', 'label': 'Malta' },
    { 'value': 'MX', 'label': 'Mexico' },
    { 'value': 'NL', 'label': 'Netherlands' },
    { 'value': 'NZ', 'label': 'New Zealand' },
    { 'value': 'NO', 'label': 'Norway' },
    { 'value': 'PL', 'label': 'Poland' },
    { 'value': 'PT', 'label': 'Portugal' },
    { 'value': 'RO', 'label': 'Romania' },
    { 'value': 'SG', 'label': 'Singapore' },
    { 'value': 'SK', 'label': 'Slovakia' },
    { 'value': 'SI', 'label': 'Slovenia' },
    { 'value': 'ES', 'label': 'Spain' },
    { 'value': 'SE', 'label': 'Sweden' },
    { 'value': 'CH', 'label': 'Switzerland' },
    { 'value': 'TH', 'label': 'Thailand' },
    { 'value': 'AE', 'label': 'United Arab Emirates' },
    { 'value': 'GB', 'label': 'United Kingdom' },
    { 'value': 'US', 'label': 'United States' }
];