import { useEffect, useState } from 'react';
import { PortfolioElement } from '@/gql/graphql';
import FreelancerAccountEditPortfolioListItem from '@/pages/freelancer/views/account/freelancer-account-edit-portfolio-elements/components/FreelancerAccountEditPortfolioListItem';
import ListSkeleton from '@/components/ListSkeleton';
import NoDataIcon from '@/components/NoDataIcon';
import { useTranslation } from 'react-i18next';
import { Reorder } from 'framer-motion';

interface FreelancerAccountEditOrderedListProps {
    portfolioElements: PortfolioElement[];
    isLoading?: boolean;
    isDeleting: string[];

    onOrderChange( orderedList: PortfolioElement[] ): void;

    onDeleteItem( portfolioElement: PortfolioElement ): void;
}

export default function FreelancerAccountEditPortfolioElementOrderedList( {
                                                                              portfolioElements,
                                                                              isLoading,
                                                                              isDeleting,
                                                                              onOrderChange,
                                                                              onDeleteItem
                                                                          }: FreelancerAccountEditOrderedListProps ) {

    const { t } = useTranslation();
    const [ orderedList, setOrderedList ] = useState<PortfolioElement[]>( [] );

    useEffect( () => {
        setOrderedList( orderPortfolioElements( portfolioElements ) );
    }, [ portfolioElements ] );

    const orderPortfolioElements = ( orderedList: PortfolioElement[] ) => {
        return orderedList?.sort( ( a, b ) => ( a.order || 0 ) - ( b.order || 0 ) );
    };

    const handleOnDeleteItem = ( portfolioElement: PortfolioElement ) => {
        onDeleteItem( portfolioElement );
    };

    const handleReorder = ( newOrder: any ) => {
        const reorderedList = newOrder?.map( index => orderedList[ index ] );
        reorderedList.forEach( ( item, index ) => {
            item.order = index + 1;
        } );
        setOrderedList( reorderedList );
        onOrderChange( reorderedList );
    };

    const loadingJSX = (
        <div className="flex flex-col gap-y-4">
            <ListSkeleton isLoading={ true }
                          count={ 3 }
                          height={ 60 } />
        </div>
    );

    if( isLoading ) {
        return loadingJSX;
    }

    if( orderedList?.length > 0 ) {
        return (
            <Reorder.Group axis="y"
                           className="flex flex-col gap-y-3"
                           values={ orderedList.map( ( _, index ) => index ) }
                           onReorder={ handleReorder }>
                {
                    orderedList.map( ( item, index ) => (
                        <Reorder.Item key={ item.id }
                                      value={ index }>
                            <FreelancerAccountEditPortfolioListItem portfolioElement={ item }
                                                                    isDeleting={ isDeleting.includes( item.id ) }
                                                                    onDelete={ handleOnDeleteItem } />
                        </Reorder.Item>
                    ) )
                }
            </Reorder.Group>
        );
    }

    return (
        <div className="w-full h-[300px] flex items-center justify-center">
            <NoDataIcon message={ t( 'freelancer:account.edit-portfolio-elements.noData' ) }
                        subMessage={ t( 'freelancer:account.edit-portfolio-elements.subTitle' ) }
                        icon={ <span></span> } />
        </div>
    );
}
