import { useTranslation } from 'react-i18next';
import { UserTypeEnum } from '@/gql/graphql';
import { NavLink } from 'react-router-dom';
import { CompassOutlinedIcon, FileOutlinedIcon, HeartOutlinedIcon, MessageOutlinedIcon } from '@/assets/icons/CustomIcons';
import { Badge } from 'antd';
import MyAccountButton from '@/components/MyAccountButton';
import Header from '@/components/layout/Header';
import SupportButton from '@/components/SupportButton';
import ChatHook from '@/components/chat/hooks/ChatHook';
import clientProjectsHook from '@/hooks/clientProjectsHook';

interface ClientHeaderDesktopProps {
    countNewFavorites: number;
    user: any;
    customMyAccountSection?: JSX.Element[];
    channelSelected: string | JSX.Element;
    channelSelectedKey?: string;
}

export function ClientHeaderDesktop( {
                                         countNewFavorites,
                                         user,
                                         customMyAccountSection,
                                         channelSelected,
                                         channelSelectedKey
                                     }: ClientHeaderDesktopProps ) {
    const { t } = useTranslation();

    const { totalUnreadMessages } = ChatHook();
    const { totalProjectsInProgress } = clientProjectsHook();

    const navs = [
        /*{
         to: '/client/home',
         text: t( 'client:header.home' ),
         icon: <HomeOutlinedIcon className="text-[24px]" />,
         count: null
         },*/
        {
            to: '/client/discover',
            text: t( 'client:header.desktop.discover' ),
            icon: <CompassOutlinedIcon className="text-[24px]" />,
            count: null
        },
        {
            to: '/client/messages',
            text: t( 'client:header.desktop.messages' ),
            icon: <MessageOutlinedIcon className="text-[24px]" />,
            count: totalUnreadMessages | 0
        },
        {
            to: '/client/projects',
            text: t( 'client:header.desktop.projects' ),
            icon: <FileOutlinedIcon className="text-[24px]" />,
            count: totalProjectsInProgress,
        },
        {
            to: '/client/favorites',
            text: t( 'client:header.desktop.favoriteFreelancer' ),
            icon: <HeartOutlinedIcon className="text-[24px]" />,
            count: countNewFavorites
        }
    ];

    const rightJSX = (
        <div className="flex items-center justify-center gap-x-4">
            <SupportButton />
            <MyAccountButton customSection={ customMyAccountSection }
                             selectedKeys={ [ channelSelectedKey ] }
                             data={ {
                                 type: UserTypeEnum.CLIENT,
                                 userName: user?.userName,
                                 profilePicture: user?.profilePicture,
                                 subAvatar: channelSelected
                             } } />
        </div>
    );

    const leftJSX = (
        <>
            {
                navs.map( ( nav, index ) => {
                    return (
                        <NavLink key={ index }
                                 to={ nav.to }
                                 className={ ( { isActive } ) => `
                                            block px-4 py-2 rounded-4xl font-semibold hover:text-primary-400 text-base
                                            ${ isActive
                                               ? 'text-primary-500 bg-primary-100'
                                               : 'text-grey' }
                                            ` }>
                                <span className="flex items-center gap-x-2 text-inherit">
                                    <Badge count={ nav.count }
                                           className="text-inherit"
                                           size="small">
                                        <div className="text-inherit">
                                            { nav.icon }
                                        </div>
                                    </Badge>
                                    { nav.text }
                                </span>
                        </NavLink>
                    );
                } )
            }
        </>
    );

    return (
        <Header logoTo="/client/home"
                right={ rightJSX }
                left={ leftJSX } />
    );
}