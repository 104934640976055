import { PortfolioElement, PortfolioElementTypeEnum } from '@/gql/graphql';
import ReactPlayer from 'react-player';
import abbreviationNumberFormatter from '@/utils/abbrevationNumberFormatter';
import { useTranslation } from 'react-i18next';
import { useEffect, useRef, useState } from 'react';

interface FreelancerProfilePortfolioListItemProps {
    portfolioElement: PortfolioElement;
}

export default function FreelancerProfilePortfolioListItem( { portfolioElement }: FreelancerProfilePortfolioListItemProps ) {

    const { t } = useTranslation();
    const [ playerHeight, setPlayerHeight ] = useState( 0 );
    const containerRef = useRef( null );

    useEffect( () => {
        if( containerRef.current ) {
            const width = containerRef.current.offsetWidth; //get width of the container
            setPlayerHeight( ( 9 / 16 ) * width ); //set height based on 16:9 aspect ratio
        }
    }, [ containerRef.current ] );

    return (
        <div className="w-[390px]"
             ref={ containerRef }>
            <div className="text-sm font-normal truncate">
                { portfolioElement.title }
            </div>

            <div className="text-xs text-grey flex gap-1">
                <span className="truncate">
                    { portfolioElement.portfolioChannel?.name }
                </span>
                {

                    portfolioElement.type == PortfolioElementTypeEnum.VIDEO ?
                    <span className="flex-none">
                        { `• ${ abbreviationNumberFormatter( portfolioElement.viewCount ) } ${ t( 'common:freelancerProfile.card.views', { count: portfolioElement.viewCount } ) }` }
                    </span> : ''
                }
            </div>

            <div className="mt-2 w-full rounded-2xl overflow-hidden relative">
                {
                    portfolioElement.type == PortfolioElementTypeEnum.VIDEO &&
                    <ReactPlayer url={ portfolioElement.url }
                                 muted={ true }
                                 volume={ 1 }
                                 width="100%"
                                 height={ playerHeight.toString() + 'px' }
                                 light={ true }
                                 playing={ true }
                    />
                }
                {
                    portfolioElement.type == PortfolioElementTypeEnum.IMAGE &&
                    <img src={ portfolioElement.url }
                         className="object-cover w-full h-full"
                         alt="portfolio element" />
                }
            </div>
        </div>
    );
}