import { Channel } from '@/gql/graphql';
import { Avatar } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import ChannelSwitcherTouch from '@/pages/client/views/new-project/components/channel-switcher/ChannelSwitcherTouch';
import ChannelSwitcherDesktop from '@/pages/client/views/new-project/components/channel-switcher/ChannelSwitcherDesktop';
import SkeletonInput from 'antd/es/skeleton/Input';
import SkeletonAvatar from 'antd/es/skeleton/Avatar';

interface ChannelSwitcherProps {
    channel: Channel;
    isLoading: boolean;
    onSwitchChannel?: ( channel: Channel ) => void;
    onOpenChannelSwitcher?: () => void;
    onClose?: () => void;
}

export default function ChannelSwitcherButton( {
                                                   channel,
                                                   isLoading,
                                                   onSwitchChannel,
                                                   onOpenChannelSwitcher,
                                                   onClose
                                               }: ChannelSwitcherProps ) {

    const isTabletOrMobile = useMediaQuery( { query: `(max-width: ${ import.meta.env.VITE_MOBILE_BREAK_POINT })` } );
    const { t } = useTranslation();
    const [ isChannelSwitcherOpen, setIsChannelSwitcherOpen ] = useState( false );

    const handleOnSelect = ( channel ) => {
        setIsChannelSwitcherOpen( false );
        if( onSwitchChannel ) {
            onSwitchChannel( channel );
        }

    };

    const handleClose = () => {
        setIsChannelSwitcherOpen( false );
        if( onClose ) {
            onClose();
        }
    };

    const handleOnOpenChannelSwitcher = () => {
        setIsChannelSwitcherOpen( true );
        if( onOpenChannelSwitcher ) {
            onOpenChannelSwitcher();
        }
    };

    const loadingJSX = (
        <div className="flex w-full px-2 ">
            <SkeletonAvatar active={ true }
                            size={ 48 } />
            <div className="ml-2 grow flex flex-col gap-1 w-full">
                <SkeletonInput active={ true }
                               size="large"
                               className="h-[25px]" />
                <SkeletonInput active={ true }
                               size="small"
                               className="h-[15px]" />
            </div>

        </div>
    );

    const contentJSX = () => {
        if( channel == null ) {
            return (
                <div className="flex justify-between items-center w-full px-2">
                    <span>{ t( 'transverse:new-project.addYourFirstChannel' ) }</span>
                    <div className="text-primary-400 underline cursor-pointer"
                         onClick={ () => handleOnOpenChannelSwitcher() }>
                        { t( 'transverse:new-project.addChannel' ) }
                    </div>
                </div>
            )
                ;
        }
        else {
            return (
                <>
                    <Avatar src={ channel?.thumbnailUrl }
                            size={ 48 } />

                    <div className="ml-2">
                        <div className="font-semibold font-base">
                            { channel?.name }
                        </div>
                        <div className="text-primary-400 underline cursor-pointer"
                             onClick={ () => handleOnOpenChannelSwitcher() }>
                            { t( 'transverse:new-project.change' ) }
                        </div>
                    </div>
                </>
            );
        }
    };

    return (
        <>
            <div className="bg-primary-100 p-2 rounded-3xl flex items-center">
                { isLoading ? loadingJSX : contentJSX() }
            </div>
            {
                isTabletOrMobile ?
                <ChannelSwitcherTouch isOpen={ isChannelSwitcherOpen }
                                      onSelect={ ( channel: Channel ) => handleOnSelect( channel ) }
                                      onClose={ () => handleClose() } /> :
                <ChannelSwitcherDesktop isOpen={ isChannelSwitcherOpen }
                                        onSelect={ ( channel: Channel ) => handleOnSelect( channel ) }
                                        onClose={ () => handleClose() } />
            }
        </>
    );
};