import { createStore, select, withProps } from '@ngneat/elf';
import { Client, Freelancer } from '@/gql/graphql';
import { localStorageStrategy, persistState } from '@ngneat/elf-persist-state';
import { resetStores } from '@/core/resetStores';
import * as Sentry from '@sentry/react';

interface SessionProps {
    user: Partial<Client | Freelancer>;
    accessToken: string | null;
    refreshToken: string | null;
    email: string | null;
    cometchatAuthToken: string | null;
}

const sessionStore = createStore( { name: 'session' }, withProps<SessionProps>( {
    user: null,
    accessToken: null,
    refreshToken: null,
    email: null,
    cometchatAuthToken: null
} ) );

const instance = persistState( sessionStore, {
    key: 'session',
    storage: localStorageStrategy
} );

export class SessionRepository {

    session$ = sessionStore.pipe( select( ( state ) => state ) );
    isLoggedIn$ = sessionStore.pipe( select( ( state ) => !!( state.accessToken || state.refreshToken ) ) );
    userType$ = sessionStore.pipe( select( ( state ) => state.user?.type ) );
    cometchatAuthToken$ = sessionStore.pipe( select( ( state ) => state.user?.cometchatAuthToken ) );

    get instance$() {
        return instance.initialized$;
    }

    get cometChatAuthToken(): string {
        return sessionStore.getValue().cometchatAuthToken;
    }

    get accessToken(): string {
        return sessionStore.getValue().accessToken;
    }

    set accessToken( accessToken: string ) {
        const session = sessionStore.getValue();
        this.updateSession( {
            ...session,
            accessToken
        } );
    }

    get refreshToken(): string {
        return sessionStore.getValue().refreshToken;
    }

    set refreshToken( refreshToken: string ) {
        const session = sessionStore.getValue();
        this.updateSession( {
            ...session,
            refreshToken
        } );
    }

    get user() {
        return sessionStore.getValue().user;
    }

    get isLoggedIn(): boolean {
        return !!sessionStore.getValue().accessToken;
    }

    get userId(): string {
        return sessionStore.getValue()?.user?.id;
    }

    setTokens( accessToken: string, refreshToken: string, cometchatAuthToken: string ) {
        sessionStore.update( ( state ) => ( {
            ...state,
            accessToken,
            refreshToken,
            cometchatAuthToken
        } ) );
    }

    updateSession( session: SessionProps ) {
        sessionStore.update( ( state ) => ( {
            ...state,
            ...session
        } ) );
    }

    updateUser( user: Partial<Client | Freelancer> ) {
        const session = sessionStore.getValue();
        this.updateSession( {
            ...session,
            user: {
                ...session.user,
                ...user
            }
        } );
    }

    login( session: SessionProps ): void {
        session.user.email = session.email;

        this.updateSession( session );
        Sentry.setUser( {
            id: session.user?.id,
            email: session.user?.email,
            segment: session.user?.type
        } );
        Sentry.addBreadcrumb( {
            category: 'auth',
            message: 'Authenticated user ' + session.user?.email,
            level: 'info'
        } );
    }

    logout() {
        resetStores();
        Sentry.setUser( null );
        window.location.reload();
    }
}