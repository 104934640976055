import { gql } from '@apollo/client';

export const privateFreelancerGQL = gql`
    query PrivateFreelancer($id: String!) {
        privateFreelancer(id: $id) {
            id
            customUrl
            firstName
            lastName
            bio
            profilePicture
            avgPrice
            isFavorite
            priceCurrency
            experience
            isAvailable
            isCertified
            avgRate
            trainerAppreciation
            portfolioElements {
                id
                url
                title
                type
                viewCount
                portfolioChannel {
                    name
                }
            }
            creatorsIWorkedWith {
                id
                name
                channelUrl
                thumbnailUrl
                subscriberCount
            }
            mainJob
            jobToFreelancers {
                isMainJob
                job {
                    id
                    i18n
                }
            }
            expertises {
                id
                i18n
            }
            languages {
                id
                i18n
            }
            skills {
                id
                i18n
            }
        }
    }
`;
