import React from 'react';
import FreelancerActionItemLayout from './FreelancerActionItemLayout';
import FreelancerActionItemProjectDetail from './FreelancerActionItemProjectDetail';
import { Trans, useTranslation } from 'react-i18next';
import { ExclamationFilledIcon } from '@/assets/icons/CustomIcons';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { Action } from '@/gql/graphql';

interface ProjectDeadlinePassedProps {
    action: Action;
}

const ProjectDeadlinePassed: React.FC<ProjectDeadlinePassedProps> = ({ action }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    
    return (
        <div className='relative'>
            <div className="bg-red/80 h-10 w-[3px] absolute left-0 top-4" />
            <FreelancerActionItemLayout 
                title={ t( 'freelancer:dashboard.actionCenter.action.projectDeadlinePassedTitle' ) }
                datetime={ action.createdAt }
                icon={
                    <div className='bg-white rounded-full'>
                        <div className='size-6 bg-red/80 rounded-full border-2 border-white flex justify-center items-center'>
                            <ExclamationFilledIcon className='text-white' />
                        </div> 
                    </div>
                }
                clientProfilePicture={ action.project.client?.profilePicture }>
                <div className='flex flex-col gap-4'>
                    <span className='text-slate-600'>
                        <Trans 
                            i18nKey="freelancer:dashboard.actionCenter.action.projectDeadlinePassedDesc" 
                            components={[<strong className='text-rose-500' />, <strong className='text-gray-600' />]} 
                        />
                    </span>
                    <FreelancerActionItemProjectDetail project={ action.project } />
                    <Button type='default' className='w-fit text-primary-500' onClick={ () => navigate(`/freelancer/projects/status/ongoing?projectId=${ action.project.id }`) }>
                        { t( 'freelancer:dashboard.actionCenter.action.button.markAsCompleted' ) }
                    </Button>
                </div>
            </FreelancerActionItemLayout>
        </div>
    );
}

export default ProjectDeadlinePassed;
