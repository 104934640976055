import { gql } from '@apollo/client';

export const clientProjectsGQL = gql`
    query ClientProjects($args: ProjectsFiltersInput!, $page: Int, $limit: Int) {
        projects(args: $args, page: $page, limit: $limit) {
            data {
                id
                name
                description
                status
                startedAt
                clientHasReviewed
                freelancerHasReviewed
                freelancer {
                    id
                    type
                    fullName
                    mainJob
                    isVerified
                    profilePicture
                }
                payment {
                    id
                    currency
                    paymentExVat
                    freelancerAmountExVat
                    clientInvoiceUrl
                    clientAmountIncVat
                    vatRate
                    feeRate
                }
                channel {
                    id
                    name
                    thumbnailUrl
                }
                createdAt
                updatedAt
            }
            page
            limit
            lastPage
            total
        }
    }`;


