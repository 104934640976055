import { matchPath, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SessionRepository } from '@/core/auth/sessionRepository';
import { useEffect, useState } from 'react';
import { Client, UserTypeEnum } from '@/gql/graphql';
import MyAccountButton from '@/components/MyAccountButton';
import { Badge } from 'antd';
import HeaderTouch from '@/components/layout/HeaderTouch';
import SupportButton from '@/components/SupportButton';
import ChatHook from '@/components/chat/hooks/ChatHook';

export default function FreelancerHeaderTouch() {

    const location = useLocation();
    const currentPath = location.pathname;

    const { t } = useTranslation();
    const sessionRepository = new SessionRepository();
    const [ user, setUser ] = useState<Partial<Client>>( null );
    const [ currentNav, setCurrentNav ] = useState( null );

    const { totalUnreadMessages } = ChatHook();

    useEffect( () => {
        const observable = sessionRepository.session$.subscribe( ( session ) => {
            setUser( session.user as Client );
        } );
        return () => {
            observable.unsubscribe();
        };
    }, [] );

    useEffect( () => {
        const route = navs.find( nav => matchRoute( nav.route ) );
        setCurrentNav( route );
    }, [ currentPath ] );

    const navs = [
        {
            route: '/freelancer/dashboard',
            text: t( 'client:header.touch.dashboard' ),
            navBarVisible: true,
            count: totalUnreadMessages
        },
        {
            route: '/freelancer/messages',
            text: t( 'client:header.touch.messages' ),
            navBarVisible: true,
            count: totalUnreadMessages
        },
        {
            route: '/freelancer/projects',
            text: t( 'client:header.touch.projects' ),
            navBarVisible: true,
            count: null
        },
        {
            route: '/freelancer/settings',
            text: t( 'client:header.touch.settings' ),
            navBarVisible: false,
            count: null
        }
    ];

    const matchRoute = ( route: string ) => {
        return matchPath( {
                path: route,
                caseSensitive: false,
                end: false
            },
            currentPath );
    };

    const rightJSX = (
        <div className="flex items-center justify-center gap-x-2">
            <SupportButton onlyIcon={ true } />
            <MyAccountButton avatarSize={ 40 }
                             data={ {
                                 type: UserTypeEnum.FREELANCER,
                                 profilePicture: user?.profilePicture,
                                 userName: user?.firstName
                             } } />
        </div>
    );

    const leftJSX = (
        <>
            {
                navs.map( ( nav, index ) => {
                    return (
                        <div key={ index }
                             className={ matchRoute( nav.route ) ? '' : 'hidden' }>
                            <Badge count={ nav.count }
                                   className="text-inherit text-2xl font-medium font-sharp"
                                   size="small">
                                { nav.text }
                            </Badge>
                        </div>
                    );
                } )
            }
        </>
    );

    return (
        <HeaderTouch closeButton={ currentNav?.navBarVisible }
                     right={ rightJSX }
                     left={ leftJSX } />
    );
}