import { useTranslation } from 'react-i18next';
import { Button, message } from 'antd';
import * as Sentry from '@sentry/react';
import { useForm } from 'react-hook-form';
import { RecoveryPasswordGQL } from '@/gql/global-queries/recoveryPasswordGQL';
import { useMutation } from '@apollo/client';
import InputComponent from '@/components/custom-element-form/InputComponent';

export default function RecoveryPassword() {

    const { t } = useTranslation();
    const [ messageApi, contextHolder ] = message.useMessage();
    const {
              trigger,
              control,
              formState: { errors },
              handleSubmit
          } = useForm();

    const recoveryPasswordMutation = useMutation( RecoveryPasswordGQL );

    const onSubmit = async( data ): Promise<void> => {
        if( !( await trigger() ) ) {
            return;
        }

        try {
            await recoveryPasswordMutation[ 0 ]( {
                variables: {
                    email: data.email
                }
            } );
            messageApi.success( {
                content: t( 'auth:recovery-password.notification', { email: data.email } ),
                duration: 4
            } );
            control._reset();
        } catch( e ) {
            Sentry.captureException( 'An error occur when trying to get a recovery email to update password', e );
            messageApi.error( t( 'error.default' ) );
        }
    };

    return (
        <div className="px-6 flex flex-col items-center justify-center">
            { contextHolder }
            <div className="w-full max-w-[500px]">
                <h1 className="text-2xl lg:text-center mt-12">
                    { t( 'auth:recovery-password.title' ) }
                </h1>
                <h4 className="text-sm lg:text-center text-grey mt-4">
                    { t( 'auth:recovery-password.subtitle' ) }
                </h4>
                <form className="flex flex-col gap-4 w-full mt-8"
                      onSubmit={ handleSubmit( onSubmit ) }>

                    <InputComponent control={ control }
                                    name="email"
                                    label={ t( 'auth:recovery-password.input' ) }
                                    placeholder={ t( 'auth:recovery-password.inputPlaceholder' ) }
                                    rules={ {
                                        required: t( 'common:error.form.required' ),
                                        pattern: {
                                            value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                                            message: t( 'common:error.form.invalidEmail' )
                                        }
                                    } }
                                    errors={ errors } />
                    <Button type="primary"
                            htmlType="submit"
                            loading={ recoveryPasswordMutation[ 1 ].loading }
                            className="w-full mt-2">
                        { t( 'auth:recovery-password.submit' ) }
                    </Button>
                </form>
            </div>
        </div>
    );
}