import PhoneNumberCodeVerificationModal from '@/components/PhoneNumberCodeVerificationModal';
import PhoneNumberModal from '@/components/PhoneNumberModal';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { useMutation } from '@apollo/client';
import { UpdateFreelancerProfileGQL } from '@/gql/global-queries/updateFreelancerProfileGQL';
import { SessionRepository } from '@/core/auth/sessionRepository';
import { App } from 'antd';
import { useTranslation } from 'react-i18next';

const PhoneNumberVerification = forwardRef( ( props, ref ) => {

    const { t } = useTranslation( [ 'common' ] );
    const { message } = App.useApp();

    const [ _phoneNumber, setPhoneNumber ] = useState<string>( null );

    useImperativeHandle( ref, () => ( {
        open: openModal,
        close: closeModal
    } ) );

    const updateFreelancerProfileMutation = useMutation( UpdateFreelancerProfileGQL );

    const sessionRepository: SessionRepository = new SessionRepository();

    const phoneNumberModalRef = useRef( null );
    const phoneNumberCodeVerificationModal = useRef( null );

    const openModal = (): void => phoneNumberModalRef?.current.open();

    const closeModal = (): void => phoneNumberModalRef?.current.open();

    const handleOnCodeSent = ( phoneNumber: string ): void => {
        setPhoneNumber( phoneNumber );
        phoneNumberModalRef.current.close();
        phoneNumberCodeVerificationModal.current.open();
    };

    const handleCodeVerified = (): void => {
        updateFreelancerProfileMutation[ 0 ]( {
            variables: {
                args: {
                    phone: _phoneNumber
                }
            }
        } );
        sessionRepository.updateUser( {
            phone: _phoneNumber
        } );

        message.success( t( 'common:phoneNumber.verification-code-modal.success' ) );
    };

    return (
        <>
            <PhoneNumberModal ref={ phoneNumberModalRef }
                              onCodeSent={ ( value ) => handleOnCodeSent( value ) } />
            <PhoneNumberCodeVerificationModal ref={ phoneNumberCodeVerificationModal }
                                              phoneNumber={ _phoneNumber }
                                              onCodeVerified={ () => handleCodeVerified() } />
        </>
    );
} );

export default PhoneNumberVerification;