import { useLazyQuery } from '@apollo/client';
import { FreelancerIdFromCustomUrlGQL } from '@/gql/global-queries/freelancerIdFromCustomUrl';
import { useEffect, useState } from 'react';

export default function FreelancerIdFromCustomUrlHook() {

    const [ query, { data, loading, error } ] = useLazyQuery( FreelancerIdFromCustomUrlGQL );
    const [ id, setId ] = useState<string>( null );

    const getId = ( id: string ) => {
        query( {
            variables: {
                id
            }
        } );
    }

    useEffect( () => {
        if( data ) {
            setId( data.publicFreelancer.id );
        }
    }, [ data ] );

    return {
        getId,
        id,
    };

}