import { Fragment, useEffect, useRef, useState } from 'react';
import { ConversationItemMessage } from '@/components/chat/interfaces/conversationItemMessage';
import { ConversationItemQuote } from '@/components/chat/interfaces/conversationItemQuote';
import { ConversationItem } from '@/components/chat/interfaces/conversationItem';
import InfiniteScroll from 'react-infinite-scroll-component';
import ChatConversationMessageTypingIndicator from '@/components/chat/components/chat-conversation/chat-conversation-messages/components/ChatConversationMessageTypingIndicator';
import ChatConversationItem from '@/components/chat/components/chat-conversation/chat-conversation-messages/components/chat-conversation-items/ChatConversationItem';
import { ConversationItemOffer } from '@/components/chat/interfaces/conversationItemItemOffer';
import { ConversationItemActionQuote } from '@/components/chat/interfaces/conversationItemActionQuote';
import { ConversationItemActionOffer } from '@/components/chat/interfaces/conversationItemActionOffer';
import { DateTime } from 'luxon';
import { Affix, Spin } from 'antd';
import { useSearchParams } from 'react-router-dom';

interface ChatConversationMessagesListProps {
    conversationItems: ConversationItem[];
    hasMore: boolean;
    isTyping: boolean;
    isLoading: boolean;
    onLoadMore: () => void;
}

export default function ChatConversationMessagesList( {
                                                          conversationItems,
                                                          hasMore,
                                                          isLoading,
                                                          isTyping,
                                                          onLoadMore
                                                      }: ChatConversationMessagesListProps ) {

    const [ lastMessage, setLastMessage ] = useState<string>( null );
    const [ lastDate, setLastDate ] = useState<string>( null );
    const messagesEndRef = useRef<HTMLDivElement>( null );
    const scrollableMessagesRef = useRef();

    const [ messagesByDate, setMessagesByDate ] = useState( null );
    const groupMessageByDate = ( conversationItems ) => {
        const messagesByDateValue = {}
        conversationItems.forEach((message) => {            
            const messageDate = DateTime.now().diff( DateTime.fromISO( message.sentAt ), 'days' ).days <= 1
                ? DateTime.fromISO(message.sentAt).toRelativeCalendar({ locale: 'en' })
                : DateTime.fromISO(message.sentAt).toLocaleString();
    
            if (!messagesByDateValue[messageDate]) {
                messagesByDateValue[messageDate] = [];
            }
    
            messagesByDateValue[messageDate].push(message);
        });

        setMessagesByDate(messagesByDateValue);
        setLastDate( Object.keys( messagesByDateValue )[Object.keys( messagesByDateValue ).length - 1] );
    }
    const [ isScrolling, setIsScrolling ] = useState(false);

    const [ searchParams, setSearchParams ] = useSearchParams();
    const messageIdParam = searchParams.get( 'messageId' );

    useEffect( () => {
        if( conversationItems && conversationItems.length ) {
            groupMessageByDate(conversationItems);

            if( lastMessage !== conversationItems[ 0 ].id ) {
                if( lastMessage !== null ) {
                    scrollToBottom( true );
                }
                setLastMessage( conversationItems[ 0 ].id );
            }
        }
    }, [ conversationItems ] );

    useEffect( () => {
        if( messageIdParam ) {
            const element = document.getElementById( `message-${ messageIdParam }` );
            if( element ) {
                scrollToMessageId( element );
                searchParams.delete('messageId');
                setSearchParams( searchParams );
            }
        }
    }, [ messagesByDate ]);

    const scrollToMessageId = ( element: HTMLElement ) => {
        if( element ) {                        
            element.scrollIntoView( { behavior: 'smooth', block: 'start' } );
        }
    };

    let scrollTimeout;

    const handleScroll = () => {
        setIsScrolling(true);
        clearTimeout( scrollTimeout );
        scrollTimeout = setTimeout(() => {
            setIsScrolling(false);
        }, 1000);
    };

    useEffect(() => {
        const divElement = scrollableMessagesRef.current;
        if (divElement) {
            document
                .getElementById('scrollableMessages')
                ?.addEventListener('scroll', handleScroll);
        
            return () => {
            document
                .getElementById('scrollableMessages')
                ?.removeEventListener('scroll', handleScroll);
            };
        }
    }, []);
    

    const scrollToBottom = ( smoothly: boolean ) => {
        messagesEndRef.current?.scrollIntoView( { behavior: smoothly ? 'smooth' : 'auto' } );
    };

    const handleOnLoadMore = () => onLoadMore();

    return (
        <div id="scrollableMessages"
            ref={ scrollableMessagesRef }
            className='relative flex flex-col-reverse w-full overflow-y-auto h-full'>
            <InfiniteScroll next={ handleOnLoadMore }
                            scrollThreshold="20px"
                            hasMore={ hasMore }
                            inverse={ true }
                            hasChildren={ false }
                            loader={
                                <div className="w-full h-12 flex items-center justify-center">
                                    <Spin />
                                </div>
                            }
                            className="pb-4 px-2 lg:pb-6 lg:px-6 flex flex-col-reverse gap-y-6"
                            dataLength={ conversationItems.length }
                            scrollableTarget="scrollableMessages">
                    <div ref={ messagesEndRef } />
                    {
                        isTyping && 
                            <div className='py-4'>
                                <ChatConversationMessageTypingIndicator />
                            </div>
                    }
                    {
                        messagesByDate && Object.keys(messagesByDate).map(( date, index ) => (
                            <Fragment key={ date }>
                                {
                                    messagesByDate[date].map(( conversationItem : ( ConversationItemMessage | ConversationItemQuote | ConversationItemOffer | ConversationItemActionQuote | ConversationItemActionOffer)) => (
                                        <div id={ `message-${ conversationItem.id }` }
                                        key={ conversationItem.id }>
                                            <ChatConversationItem item={ conversationItem } />
                                        </div>
                                    ))
                                }
                                <Affix key={ `sticky-${ date }` }
                                    target={ () => scrollableMessagesRef.current }
                                    className={ `self-center max-w-[400px] ${ isLoading && lastDate === date ? 'hidden' : '' }` } 
                                    style={ { zIndex: 1000 - index } } offsetTop={ isScrolling ? 0 : -1000 } 
                                >
                                    <div className='flex justify-center text-center mt-3 w-full' >
                                            <div className='bg-white max-w-full min-w-[100px] rounded-lg p-1.5 uppercase border border-neutral-200'>
                                                { date }
                                            </div>
                                    </div>
                                </Affix>
                            </Fragment>
                        ))
                    }
            </InfiniteScroll>
        </div>
    );
}