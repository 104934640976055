import { gql } from '@apollo/client';

export const refreshTokenGQL = gql`
    query RefreshToken {
        refreshToken {
            refreshToken
            accessToken,
            cometchatAuthToken
        }
    }`;


