import { useMediaQuery } from 'react-responsive';
import ListSkeleton from '@/components/ListSkeleton';
import FreelancerCard from '@/components/freelance-profile/FreelancerCard';
import { useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';
import { PageName, SegmentEvent, trackPageView, trackSegmentEvent } from '@/utils/analytics';
import { SessionRepository } from '@/core/auth/sessionRepository';
import { Freelancer } from '@/gql/graphql';
import { Link } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';

interface TransverseFreelancersProps {
    freelancers: Freelancer[];
    loadMore: () => void;
    hasMore: boolean;
    limit: number,
    isLoadingFirstData?: boolean;
    onProfileSelected: ( freelancerId: string ) => void;
}

export default function TransverseFreelancerList( {
                                                      freelancers,
                                                      loadMore,
                                                      hasMore,
                                                      limit,
                                                      isLoadingFirstData,
                                                      onProfileSelected
                                                  }: TransverseFreelancersProps ) {
    const { t } = useTranslation();
    const isTabletOrMobile = useMediaQuery( { query: `(max-width: ${ import.meta.env.VITE_MOBILE_BREAK_POINT })` } );

    const session: SessionRepository = new SessionRepository();

    const handleViewProfile = ( freelancer: Freelancer ) => {
        if( !session?.userId ) {
            trackSegmentEvent( SegmentEvent.ProfileClicked, {
                profile_clicked_id: freelancer.id,
                profile_clicked_origin: 'Repository'
            } );
        }

        if( isTabletOrMobile ) {
            onProfileSelected( freelancer.id );
        }
    };

    useEffect( () => {
        trackPageView( PageName.FreelancerList );
    }, [] );

    const gridClass = 'h-fit grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 gap-6 lg:gap-9';

    return (
        <InfiniteScroll className={ `w-full pb-10 ${ gridClass } overflow-y-hidden` }
                        dataLength={ freelancers?.length } //This is important field to render the next data
                        next={ () => loadMore() }
                        pullDownToRefresh={ false }
                        loader={
                            <ListSkeleton isLoading={ true }
                                          height={ 344 }
                                          count={ limit } />
                        }
                        endMessage={
                            <div className="col-span-1 md:col-span-3 lg:col-span-4 w-full flex justify-center items-center mt-10 px-4">
                                <div className="max-w-[340px] text-center font-normal text-lg">
                                    { t( 'common:freelancerProfile.noMoreFreelancers' ) }
                                </div>
                            </div>
                        }
                        hasMore={ hasMore }>
            {
                freelancers?.map( ( freelancerItem: Freelancer,
                                    index ) => {
                    return (
                        <div className="w-full lg:cursor-pointer lg:max-w-[301px]"
                             key={ index }>
                            <Link to={ `${location.pathname.includes('/client' ) ? '/client': ''}/p/${ freelancerItem.customUrl ? freelancerItem.customUrl : freelancerItem.id }` }>
                            <FreelancerCard profile={ freelancerItem }
                                            favoriteButton={ false }
                                            enableClick={ true }
                                            viewProfile={ ( profile ) => handleViewProfile( profile ) } />
                            </Link>
                        </div>
                    );
                } )
            }
        </InfiniteScroll>
    );
}