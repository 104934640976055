import { Form } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, message } from 'antd';
import { useMutation } from '@apollo/client';
import { DeclineProjectGQL } from '@/gql/global-queries/declineProjectGQL';
import TextArea from 'antd/es/input/TextArea';
import { useEffect } from 'react';
import { Project } from '@/gql/graphql';
import * as Sentry from '@sentry/react';
import { ConversationItemOffer } from '@/components/chat/interfaces/conversationItemItemOffer';

interface ChatConversationDeclineModalFormProps {
    offerItem: ConversationItemOffer;
    onDecline( project: Project ): void;
}

export default function ChatConversationDeclineProjectModalForm( { offerItem, onDecline }: ChatConversationDeclineModalFormProps ) {
    const { trigger, control, handleSubmit } = useForm();
    const { t } = useTranslation();
    const [ messageApi, contextHolder ] = message.useMessage();

    const [ declineProject, { data, loading, error } ] = useMutation( DeclineProjectGQL );

    const onTriggerSubmit = async( data ) => {
        if( !( await trigger() ) ) {
            return;
        }

        try {
            await declineProject( {
                variables: {
                    id: offerItem.projectId,
                    reason: data.reason
                }
            } );

        } catch( e ) {
            Sentry.captureException( 'An error occur when trying to decline a project', e );
            messageApi.error( {
                content: t( 'translation:error.default' ),
                duration: 4
            } );
        }
    };

    useEffect( () => {
        if( data ) {
            onDecline( data.declineProject );
        }
    } );

    useEffect( () => {
        if( error ) {
            messageApi.error( {
                content: t( 'common:errors.submitError' ),
                duration: 4
            } );
        }
    }, [ error ] );

    return (
        <>
            { contextHolder }
            <Form onSubmit={ handleSubmit( onTriggerSubmit ) }
                  className="mt-2">
                <Controller
                    control={ control }
                    name="reason"
                    render={ ( {
                                   fieldState: { error },
                                   field: { onChange, onBlur, value, ref }
                               } ) => (
                        <>
                            <TextArea onChange={ onChange }
                                      rows={ 6 }
                                      onBlur={ onBlur }
                                      className="mt-2"
                                      value={ value }
                                      size={ 'large' }
                                      ref={ ref }
                                      status={ error ? 'error' : '' }
                                      placeholder={ t( 'common:chat.conversation.declineOffer.textareaPlaceholder' ) } />
                        </>
                    ) }
                />
            </Form>

            <Button type="primary"
                    htmlType="submit"
                    className="mt-4 w-full"
                    loading={ loading }
                    size="middle"
                    onClick={ handleSubmit( onTriggerSubmit ) }>
                { t( 'common:chat.conversation.declineOffer.sendButton' ) }
            </Button>
        </>

    );
}