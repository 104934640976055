import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Button, message, Skeleton } from 'antd';
import { useMutation, useQuery } from '@apollo/client';
import { useEffect } from 'react';
import { Form } from 'react-router-dom';
import { ClientUpdateCompanyDetailsGQL } from '@/pages/client/views/account/client-account-edit-company-details/services/clientUpdateCompanyDetailsGQL';
import { ClientCompanyDetailsGQL } from '@/pages/client/views/account/client-account-edit-company-details/services/clientCompanyDetailsGQL';
import * as Sentry from '@sentry/react';
import InputComponent from '@/components/custom-element-form/InputComponent';
import { PageName, trackPageView } from '@/utils/analytics';

export default function ClientAccountEditCompanyDetails() {

    const { t } = useTranslation();
    const { trigger, control, setValue, formState: { errors }, handleSubmit } = useForm();
    const [ messageApi, contextHolder ] = message.useMessage();

    const companyDetailsQuery = useQuery( ClientCompanyDetailsGQL );
    const updateCompanyDetailsMutation = useMutation( ClientUpdateCompanyDetailsGQL );

    useEffect( () => {
        if( companyDetailsQuery.data ) {
            const data = companyDetailsQuery.data.meClient;
            setValue( 'companyName', data.addressName );
            setValue( 'street1', data.addressStreet1 );
            setValue( 'street2', data.addressStreet2 );
            setValue( 'city', data.addressCity );
            setValue( 'state', data.addressState );
            setValue( 'postalCode', data.addressPostalCode );
            setValue( 'country', data.addressCountry );
        }
    }, [ companyDetailsQuery.data ] );

    useEffect( () => {
        trackPageView( PageName.ClientEditCompanyDetails )
    }, [ ] );

    const onSubmit = async( data ) => {
        if( !( await trigger() ) ) {
            return;
        }

        const args = {
            addressName: data.companyName,
            addressStreet1: data.street1,
            addressStreet2: data.street2,
            addressCity: data.city,
            addressState: data.state,
            addressPostalCode: data.postalCode,
            addressCountry: data.country
        };

        try {
            await updateCompanyDetailsMutation[ 0 ]( {
                variables: {
                    args
                }
            } );

            messageApi.success( t( 'client:account.edit-company-details.success' ) );
        } catch( e ) {
            Sentry.captureException( 'An error occur when trying to update company details', e );
            messageApi.error( t( 'common:form.submitError' ) );
        }

    };

    const isLoadingJSX = (
        <div className="mt-5">
            <Skeleton active />
        </div>
    );

    const formJSX: JSX.Element = (
        <Form className="flex flex-col gap-y-4 mt-5 w-full"
              onSubmit={ handleSubmit( onSubmit ) }>

            <InputComponent control={ control }
                            name="companyName"
                            label={ t( 'client:account.edit-company-details.name' ) }
                            placeholder={ t( 'client:account.edit-company-details.placeholderName' ) }
                            rules={ {
                                required: t( 'common:error.form.required' )
                            } }
                            errors={ errors } />

            <InputComponent control={ control }
                            name="street1"
                            label={ t( 'client:account.edit-company-details.street1' ) }
                            placeholder={ t( 'client:account.edit-company-details.placeholderStreet1' ) }
                            rules={ {
                                required: t( 'common:error.form.required' )
                            } }
                            errors={ errors } />

            <InputComponent control={ control }
                            name="street2"
                            label={ t( 'client:account.edit-company-details.street2' ) }
                            placeholder={ t( 'client:account.edit-company-details.placeholderStreet2' ) }
                            errors={ errors } />


            <div className="flex items-start flex-wrap lg:flex-nowrap gap-4">
                <InputComponent control={ control }
                                name="city"
                                label={ t( 'client:account.edit-company-details.city' ) }
                                placeholder={ t( 'client:account.edit-company-details.placeholderCity' ) }
                                rules={ {
                                    required: t( 'common:error.form.required' )
                                } }
                                errors={ errors } />

                <InputComponent control={ control }
                                name="state"
                                label={ t( 'client:account.edit-company-details.state' ) }
                                placeholder={ t( 'client:account.edit-company-details.placeholderState' ) } />

                <InputComponent control={ control }
                                name="postalCode"
                                label={ t( 'client:account.edit-company-details.postalCode' ) }
                                placeholder={ t( 'client:account.edit-company-details.placeholderPostalCode' ) }
                                rules={ {
                                    required: t( 'common:error.form.required' )
                                } }
                                errors={ errors } />
            </div>

            <InputComponent control={ control }
                            name="country"
                            label={ t( 'client:account.edit-company-details.country' ) }
                            placeholder={ t( 'client:account.edit-company-details.placeholderCountry' ) }
                            rules={ {
                                required: t( 'common:error.form.required' )
                            } }
                            errors={ errors } />

            <div className="flex lg:justify-end">
                <Button type="primary"
                        htmlType="submit"
                        loading={ updateCompanyDetailsMutation[ 1 ].loading }
                        className="w-full lg:w-[100px] mt-8">
                    { t( 'client:account.edit-company-details.submit' ) }
                </Button>
            </div>
        </Form>
    );

    return (
        <>
            { contextHolder }
            <h1>{ t( 'client:account.edit-company-details.title' ) }</h1>
            {
                companyDetailsQuery.loading ?
                isLoadingJSX :
                formJSX
            }
        </>
    );
}