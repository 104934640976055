import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { Button, Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import FreelancerAccountEditPortfolioChannelOrderedList from '@/pages/freelancer/views/account/freelancer-account-edit-portfolio-channels/components/FreelancerAccountEditPortfolioChannelOrderedList';
import * as Sentry from '@sentry/react';
import InputComponent from '@/components/custom-element-form/InputComponent';
import { SignUpFreelancerRepository } from '@/pages/auth/sign-up/sign-up-freelancer/signUpFrelancerRepository';
import { PublicCreatePortfolioChannelGQL } from '@/gql/global-queries/publicCreatePortfolioChannelGQL';

export default function SignUpFreelancerStepPortfolioChannel() {
    const addPortfolioChannelMutation = useMutation( PublicCreatePortfolioChannelGQL );
    const repository = new SignUpFreelancerRepository();

    const { t } = useTranslation();
    const { trigger, control, reset, formState: { errors }, handleSubmit } = useForm();

    const [ isDeleting, setIsDeleting ] = useState<string[]>( [] );

    const handleDelete = async( portfolioChannel ) => {
        repository.portfolioChannels = repository.portfolioChannels.filter( ( channel ) => channel.id !== portfolioChannel.id );
        setIsDeleting( isDeleting.filter( ( id ) => id !== portfolioChannel.id ) );
    };

    const handleOrderChange = ( orderedList ) => {
        repository.portfolioChannels = orderedList;
    };

    const onSubmit = async( data ) => {
        if( !( await trigger() ) ) {
            return;
        }

        try {
            const portfolioChannel = await addPortfolioChannelMutation[ 0 ]( {
                variables: {
                    url: data.url
                }
            } );

            repository.portfolioChannels = [
                ...repository.portfolioChannels,
                portfolioChannel.data.publicCreatePortfolioChannel
            ];

            reset( {
                url: ''
            } );
        } catch( e ) {
            Sentry.captureException( 'An error occur when trying to add new portfolio element', e );
        }
    };

    return (
        <div className="w-full flex flex-col">
            <div className="mt-4">
                <FreelancerAccountEditPortfolioChannelOrderedList portfolioChannels={ repository.portfolioChannels }
                                                                  isDeleting={ isDeleting }
                                                                  onOrderChange={ ( orderedList ) => handleOrderChange( orderedList ) }
                                                                  onDeleteItem={ ( portfolioChannel ) => handleDelete( portfolioChannel ) } />
            </div>
            <Divider type="horizontal" />
            <div className="text-base font-medium">
                { t( 'freelancer:account.edit-portfolio-channels.addCreator' ) }
            </div>

            <Form className="flex flex-col lg:flex-row mt-2"
                  onSubmit={ handleSubmit( onSubmit ) }>
                <InputComponent control={ control }
                                rules={ {
                                    required: t( 'common:error.form.required' )
                                } }
                                name="url"
                                placeholder={ t( 'freelancer:account.edit-portfolio-channels.inputPlaceholder' ) }
                                errors={ errors } />

                <Button type="primary"
                        htmlType="submit"
                        loading={ addPortfolioChannelMutation[ 1 ]?.loading }
                        className="w-full lg:w-[160px] lg:ml-4 mt-4 lg:mt-0">
                    { t( 'freelancer:account.edit-portfolio-channels.submit' ) }
                </Button>
            </Form>
        </div>
    );
};
