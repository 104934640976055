import { Controller } from 'react-hook-form';
import firstLetterUppercaseFormatter from '@/utils/firstLetterUppercaseFormatter';
import { useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import TextArea from 'antd/es/input/TextArea';

interface TextareaProps {
    className?: string;
    defaultValue?: string;
    control: any;
    name: string;
    label?: string;
    placeholder: string;
    maxLength?: number,
    rows: number;
    rules?: any;
    errors: any;
}


export default function TextAreaComponent( {
                                               className,
                                               control,
                                               defaultValue,
                                               name,
                                               label,
                                               placeholder,
                                               maxLength,
                                               rows = 6,
                                               rules,
                                               errors
                                           }: TextareaProps ) {
    const [ isFocused, setIsFocused ] = useState( false );
    const textareaRef = useRef( null );

    const focusBorder = isFocused ? 'border-black' : 'border-grey-light';
    const errorBorder = errors[ name ] ? 'border-red' : '';

    return (
        <div className={ className }
             onClick={ () => textareaRef.current.focus() }>
            <Controller control={ control }
                        name={ name }
                        defaultValue={ defaultValue }
                        rules={ rules }
                        render={ ( { fieldState: { error }, field: { onChange, onBlur, value, ref } } ) => (
                            <div className="w-full">
                                <div className={ twMerge( 'w-full relative transition-colors duration-500 ease-in-out border rounded-[10px] px-3.5 py-2.5', focusBorder, errorBorder, maxLength != null
                                                                                                                                                                                      ? 'pb-8'
                                                                                                                                                                                      : '' ) }>
                                    {
                                        label &&
                                        <label className="text-xs text-grey font-normal">
                                            { label }
                                        </label>
                                    }
                                    <TextArea onChange={ onChange }
                                              onBlur={ () => {
                                                  setIsFocused( false );
                                                  onBlur();
                                              } }
                                              count={ {
                                                  show: maxLength != null,
                                                  max: maxLength
                                              } }
                                              onFocus={ () => setIsFocused( true ) }
                                              value={ value }
                                              className="p-0"
                                              size="large"
                                              rows={ rows }
                                              ref={ ( e ) => {
                                                  ref( e );
                                                  textareaRef.current = e;
                                              } }
                                              status={ error ? 'error' : '' }
                                              placeholder={ placeholder }
                                              bordered={ false } />
                                </div>
                                {
                                    errors[ name ] &&
                                    (
                                        <div className="w-fit text-sm font-normal text-red mt-1.5">
                                            { firstLetterUppercaseFormatter( errors[ name ].message.toString() ) }
                                        </div>
                                    )
                                }
                            </div>
                        ) }
            />
        </div>
    );
}