import { gql } from '@apollo/client';

export const totalProjectsByStatusGQL = gql`
    query TotalProjectsByStatusGQL($args: ProjectsFiltersInput!, $limit: Int, $page: Int) {
        projects(args: $args, limit: $limit, page: $page) {
            data {
                channel {
                    id
                    name
                }
            }
            total
        }
    }`;
