import { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Divider } from 'antd';
import { useForm } from 'react-hook-form';

import { SessionRepository } from '@/core/auth/sessionRepository';
import { CopyIcon, RoundedCheckFilledIcon } from '@/assets/icons/CustomIcons';
import { validateCharacters, validateLength, validateReserved, validateUnique } from '@/utils/customUrlValidation';
import InputUrlComponent from '@/components/custom-element-form/InputUrlComponent';
import truncateHttpFormatter from '@/utils/truncateHttpFormatter';
import { freelancerProfileLinkHook } from '@/hooks/freelancerProfileLinkHook';
import { customUrlHook } from '@/hooks/customUrlHook';
import { useLazyQuery } from '@apollo/client';
import { FreelancerProfileGQL } from '@/gql/global-queries/freelancerProfileGQL';
import { identifySegmentUser, userTypeName } from '@/utils/analytics';
import { Freelancer } from '@/gql/graphql';

export default function SignUpFreelancerStepWelcome() {

    const { t } = useTranslation();
    const session = new SessionRepository();
    const { control, watch, getValues, formState: { errors }, handleSubmit } = useForm();

    const { checkIfCustomUrlExists, createCustomUrl } = customUrlHook();
    const { getProfileLink } = freelancerProfileLinkHook();
    const [ isLoading, setIsLoading ] = useState( false );
    const nav = useNavigate();

    const customUrl = watch( 'customUrl' );

    const handleCopyClick = async() => {
        const url = getValues( 'customUrl' );
        await getProfileLink( url );
    };

    const freelancersQuery = useLazyQuery( FreelancerProfileGQL );
    useEffect( () => {
        if( freelancersQuery[ 1 ].data ) {
            const result = freelancersQuery[ 1 ].data.meFreelancer;
            identifySegmentUser(result as Freelancer, userTypeName.Freelancer)
        }
    }, [ freelancersQuery[ 1 ].data ] );

    useEffect( () => {
        freelancersQuery[ 0 ]() 
    }, [ ] );

    const onSubmit = async( data ) => {
        setIsLoading( true );

        const result = await createCustomUrl( data.customUrl );
        if( result ) {
            nav( '/auth/sign-up/freelancer/discord' );
        }

        setIsLoading( false );
    };

    return (
        <div className="h-screen flex justify-center items-center bg-[url('/img/bg-welcome-signup.png')] bg-no-repeat bg-cover p-6">
            <div className="bg-white h-fit w-full max-w-[600px] shadow-2xl rounded-xl">
                <div className="flex flex-col items-center justify-center p-6">
                    <RoundedCheckFilledIcon className="text-[46px] text-green-200" />
                    <div className="text-xl lg:text-2xl font-sharp font-medium mt-8 text-center">
                        { t( 'auth:sign-up.freelancer.welcome-page.title', { name: session.user?.firstName } ) }
                    </div>
                    <div className="text-base font-normal mt-4 text-center">
                        { t( 'auth:sign-up.freelancer.welcome-page.description' ) }
                    </div>
                </div>
                <form onSubmit={ handleSubmit( onSubmit ) }>
                    <div className="flex flex-col px-6 mb-8 gap-1.5">
                        <div className="text-grey text-sm">
                            { t( 'auth:sign-up.freelancer.welcome-page.customUrlLabel' ) }
                        </div>
                        <div className="flex gap-4">
                            <InputUrlComponent control={ control }
                                               domain={ truncateHttpFormatter( import.meta.env.VITE_ENDPOINT ) + '/p/' }
                                               name="customUrl"
                                               placeholder={ t( 'auth:sign-up.freelancer.welcome-page.customUrlPlaceholder' ) }
                                               rules={ {
                                                   required: t( 'common:error.form.required' ),
                                                   validate: {
                                                       length: ( value ) => validateLength( value, t( 'common:error.form.lengthCustomUrl' ) ),
                                                       characters: ( value ) => validateCharacters( value, t( 'common:error.form.invalidCustomUrl' ) ),
                                                       reserved: ( value ) => validateReserved( value, t( 'common:error.form.reservedCustomUrl' ) ),
                                                       unique: ( value ) => validateUnique( value, checkIfCustomUrlExists, t( 'common:error.form.existingCustomUrl' ) )
                                                   }
                                               } }
                                               errors={ errors } />
                            <button type="button"
                                    onClick={ () => handleCopyClick() }>
                                <CopyIcon className="text-2xl text-black" />
                            </button>
                        </div>
                    </div>
                    <Divider className="w-full m-0 p-0" />
                    <div className="p-6">
                        <Button type="primary"
                                className="w-full"
                                htmlType="submit"
                                loading={ isLoading }
                                disabled={ customUrl == null || customUrl == '' || isLoading }>
                            { t( 'auth:sign-up.freelancer.welcome-page.button' ) }
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    );
}