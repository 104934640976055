import { LikeFilledIcon, LikeOutlinedIcon } from '@/assets/icons/CustomIcons';
import { Button } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export interface FavouriteButtonProps {
    isFavourite: boolean;
    withText?: boolean;
    onClick: ( isFavourite: boolean ) => void;
}

export default function FavouriteButton( { isFavourite, withText, onClick }: FavouriteButtonProps ) {

    const { t } = useTranslation();

    const [ _isFavourite, setIsFavourite ] = useState( isFavourite );

    useEffect( () => {
        setIsFavourite( isFavourite );
    }, [ isFavourite ] );

    const handleClick = ( e ) => {
        setIsFavourite( !_isFavourite );
        onClick( !_isFavourite );
        e.stopPropagation();
    };

    return (
        <Button type="default"
                icon={ _isFavourite ?
                       <LikeFilledIcon className="text-2xl" /> :
                       <LikeOutlinedIcon className="text-2xl" /> }
                onClick={ ( e ) => handleClick( e ) }>
        </Button>
    );
}