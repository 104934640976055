import React from 'react';
import { SignUpFreelancerRepository } from '@/pages/auth/sign-up/sign-up-freelancer//signUpFrelancerRepository';
import SelectMultiTags from '@/components/SelectMultiTags';
import { useQuery } from '@apollo/client';
import { jobsGQL } from '@/gql/global-queries/jobsGQL';
import { useTranslation } from 'react-i18next';
import jobToIcon from '@/utils/jobToIcon';

export default function SignUpFreelancerStepJob() {
    const { t } = useTranslation();
    const repository: SignUpFreelancerRepository = new SignUpFreelancerRepository();
    const jobsQuery = useQuery( jobsGQL );

    const handleOnChangeJobSelected = ( jobIds: string[] ) => {
        repository.jobIds = jobIds;
    };

    const handleOnChangeMainJobId = ( mainJobId: string ) => {
        repository.mainJobId = mainJobId;
        const mainJobElement = jobsQuery?.data.jobs.find( ( job ) => job.id === mainJobId );
        if( mainJobElement ) {
            repository.mainJobLabel = mainJobElement.i18n?.toUpperCase();
        }
    };

    return (
        <SelectMultiTags loading={ jobsQuery?.loading }
                         enableMainTag={ true }
                         onTagsSelected={ ( value ) => handleOnChangeJobSelected( value ) }
                         onChangeMainTag={ ( mainJobId ) => handleOnChangeMainJobId( mainJobId ) }
                         tags={ jobsQuery?.data?.jobs?.map( ( job ) => ( {
                             id: job.id,
                             label: (
                                 <div className="flex items-center gap-x-2">
                                     { jobToIcon( job.i18n?.toUpperCase() as 'VIDEO_EDITOR' | 'GRAPHIC_DESIGNER' ) }
                                     <span>
                                         { t( 'translation:enum.jobs.' + job.i18n?.toUpperCase() ) }
                                     </span>
                                 </div>
                             )
                         } ) ) }
                         defaultMainTagId={ repository.mainJobId }
                         defaultSelectedIds={ repository.jobIds } />
    );
}