import { gql } from '@apollo/client';

export const VATRateFreelancerGQL = gql`
    query VATRateFreelancerGQL {
        meFreelancer {
            id
            vatRate
        }
    }`;


