import { NavLink } from 'react-router-dom';
import { conversationPreviewUI } from '@/components/chat/interfaces/conversationPreviewUI';
import ChatConversationListItem from '@/components/chat/components/chat-conversation-list/ChatConversationListItem';


interface ChatConversationListProps {
    conversationsPreview: conversationPreviewUI[];
    endpointConversation?: string;
}

export default function ChatConversationList( {
                                                  conversationsPreview,
                                                  endpointConversation
                                              }: ChatConversationListProps ) {

    const navLinkClass = `                              
                            p-4                           
                            w-full                         
                            rounded-3xl 
                            flex                                         
                            items-center
                            group                                                                                 
                            hover:bg-primary-100 
                            hover:text-primary-400
    `;

    return (
        <nav className="flex flex-col">
            <ul>
                {
                    conversationsPreview?.map( ( item, index ) => {
                            return (
                                <div className="flex flex-col gap-y-2 lg:gap-y-0 rounded-2xl"
                                     key={ index }>
                                    <li className="lg:px-2">
                                        <NavLink to={ `${ endpointConversation }${ item.id }` }
                                                 className={ ( { isActive } ) => `
                                                    ${ navLinkClass } ${ isActive
                                                                         ? 'bg-primary-100 text-primary-400'
                                                                         : '' }
                                            ` }>
                                            <ChatConversationListItem item={ item } />
                                        </NavLink>
                                    </li>
                                </div>
                            );
                        }
                    )
                }
            </ul>
        </nav>
    );
}