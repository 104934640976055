import { forwardRef, useEffect, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import TextAreaComponent from '@/components/custom-element-form/TextAreaComponent';
import { SignUpFreelancerRepository } from '@/pages/auth/sign-up/sign-up-freelancer//signUpFrelancerRepository';
import { useForm } from 'react-hook-form';

const SignUpFreelancerStepBio = forwardRef( function( props, ref ) {
    const INPUT_MAXLENGTH: number = 1000;

    const { t } = useTranslation();
    const { trigger, control, watch, formState: { errors } } = useForm();
    const repository = new SignUpFreelancerRepository();

    const subscription = watch( ( value ) => {
        repository.updateData( value );
    } );

    useEffect( () => {
        return () => subscription.unsubscribe();
    }, [] );

    useImperativeHandle( ref, () => ( {
        async validateFields() {
            return trigger();
        }
    } ) );

    return (
        <TextAreaComponent control={ control }
                           rows={ 6 }
                           className="w-full"
                           defaultValue={ repository.bio }
                           name="bio"
                           rules={ {
                               required: t( 'common:error.form.required' ),
                               maxLength: {
                                   value: INPUT_MAXLENGTH,
                                   message: t( 'common:error.form.maxLength', { maxLength: INPUT_MAXLENGTH } )
                               }
                           } }
                           maxLength={ INPUT_MAXLENGTH }
                           placeholder={ t( 'auth:sign-up.freelancer.bio-page.inputPlaceholder' ) }
                           errors={ errors }
        />
    );
} );

export default SignUpFreelancerStepBio;
