import { CometChat } from '@cometchat-pro/chat';
import { ConversationItemOffer } from '@/components/chat/interfaces/conversationItemItemOffer';
import { ConversationItemQuote } from '@/components/chat/interfaces/conversationItemQuote';
import { useLazyQuery } from '@apollo/client';
import { ChannelGQL } from '@/gql/global-queries/channelGQL';
import { ItemTypeEnum } from '@/components/chat/enums/itemTypeEnum';
import { DateTime } from 'luxon';
import { ConversationItemAction } from '@/components/chat/interfaces/conversationItemAction';
import { ItemActionEnum } from '@/components/chat/enums/itemActionEnum';
import { UserTypeEnum } from '@/gql/graphql';
import { ConversationItemActionOffer } from '@/components/chat/interfaces/conversationItemActionOffer';
import { ConversationItemActionQuote } from '@/components/chat/interfaces/conversationItemActionQuote';

export default function ConversationCustomItemFormattingHook() {

    const channelQuery = useLazyQuery( ChannelGQL );

    const conversationItemCustomFromCometChatRawCustomMessage = async( message ): Promise<ConversationItemOffer | ConversationItemQuote> => {

        const customDataType = message[ 'metadata' ][ 'type' ];

        if( customDataType && customDataType?.toUpperCase() === 'OFFER' ) {
            return await conversationItemOfferFromCometChatCustomMessage( message );
        }
        else if( customDataType && customDataType?.toUpperCase() === 'QUOTE' ) {
            return await conversationItemQuoteFromCometChatCustomMessage( message );
        }
    };

    const conversationItemOfferFromCometChatCustomMessage = async( message: CometChat.CustomMessage ): Promise<ConversationItemOffer> => {
        const metadata = message[ 'metadata' ];
        const result = await channelQuery[ 0 ]( {
            variables: {
                id: metadata[ 'channelId' ]
            }
        } );
        return {
            id: message[ 'id' ].toString(),
            receiverId: message[ 'receiverId' ],
            type: ItemTypeEnum.OFFER,
            senderId: message[ 'sender' ][ 'uid' ],
            sentAt: DateTime.fromSeconds( message[ 'sentAt' ] ).toISO(),
            status: metadata[ 'status' ],
            name: metadata[ 'name' ],
            projectId: metadata[ 'projectId' ],
            description: metadata[ 'description' ],
            channel: result?.data?.channel
        } as ConversationItemOffer;
    };

    const conversationItemQuoteFromCometChatCustomMessage = ( message: CometChat.CustomMessage ): ConversationItemQuote => {
        const metadata = message[ 'metadata' ];

        return {
            id: message[ 'id' ].toString(),
            projectName: metadata[ 'projectName' ],
            receiverId: message[ 'receiverId' ],
            type: ItemTypeEnum.QUOTE,
            senderId: message[ 'sender' ][ 'uid' ],
            sentAt: DateTime.fromSeconds( message[ 'sentAt' ] ).toISO(),
            text: metadata[ 'text' ],
            dueAt: metadata[ 'dueAt' ],
            currency: metadata[ 'currency' ],
            quoteId: metadata[ 'quoteId' ],
            fee: metadata[ 'fee' ],
            vatRate: metadata[ 'vatRate' ],
            amountIncVAT: metadata[ 'amountIncVAT' ],
            amountExVAT: metadata[ 'amountExVAT' ],
            status: metadata[ 'status' ],
            freelancerRemuneration: metadata[ 'freelancerRemuneration' ],
            projectId: metadata[ 'projectId' ]
        } as ConversationItemQuote;
    };

    const conversationItemActionFromCometChatAction = ( action: CometChat.Action,
                                                        userType: UserTypeEnum ): ( ConversationItemActionQuote | ConversationItemActionOffer ) => {
        const metadata = ( action.getActionOn() as CometChat.User ).getMetadata();

        if( !metadata ) {
            return null;
        }

        switch( metadata[ 'type' ]?.toLowerCase() ) {
            case 'quote':
                return convertItemActionToConversationItemActionQuote( action, userType );
            case 'offer':
                return convertItemActionToConversationItemActionOffer( action, userType );
            default:
                return null;
        }

    };

    function convertItemActionToConversationItemActionQuote( action: CometChat.Action,
                                                             userType: UserTypeEnum ): ConversationItemActionQuote {
        return null; // TODO faire la conversion
    }

    const convertItemActionToConversationItemActionOffer = ( action: CometChat.Action,
                                                             userType: UserTypeEnum ): ConversationItemActionOffer => {
        const metadata: string = ( action.getActionOn() as CometChat.User ).getMetadata();
        const sender: CometChat.User = ( action.getActionOn() as CometChat.BaseMessage ).getSender();
        const receiver: CometChat.User = action.getActionFor() as CometChat.User;
        let actionType: ItemActionEnum;

        switch( metadata[ 'status' ] ) {
            case 'IN_PROGRESS':
                actionType = ItemActionEnum.PROJECT_START;
                break;
            case 'DECLINED':
                actionType = ItemActionEnum.PROJECT_DECLINED;
                break;
            default:
                actionType = null;
        }

        if( actionType == null ) {
            return null;
        }

        return {
            id: action[ 'id' ].toString(),
            type: ItemTypeEnum.ACTION,
            senderId: sender.getUid(),
            receiverId: receiver.getUid(),
            actionType,
            userType,
            projectId: metadata[ 'projectId' ],
            projectName: metadata[ 'name' ],
            clientName: sender.getName(),
            freelancerName: receiver.getName(),
            sentAt: DateTime.fromSeconds( action[ 'sentAt' ] ).toISO()
        } as ConversationItemAction;
    };

    return {
        conversationItemCustomFromCometChatRawCustomMessage,
        conversationItemActionFromCometChatAction
    };
}