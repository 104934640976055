import { conversationPreviewUI } from '@/components/chat/interfaces/conversationPreviewUI';
import { Input } from 'antd';
import { SearchOutlinedIcon } from '@/assets/icons/CustomIcons';
import ChatConversationList from '@/components/chat/components/chat-conversation-list/ChatConversationList';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

interface ChatConversationListSideBarProps {
    conversations: conversationPreviewUI[];
    endpointConversation?: string;
}

export default function ChatConversationListSideBar( {
                                                         conversations,
                                                         endpointConversation
                                                     }: ChatConversationListSideBarProps ) {

    const { t } = useTranslation();

    const [ searchTerm, setSearchTerm ] = useState( '' );

    const handleSearchChange = ( e: React.ChangeEvent<HTMLInputElement> ) => {
        setSearchTerm( e.target.value );
    };

    const filteredConversations = conversations.filter( conversation =>
        conversation.userName.toLowerCase().includes( searchTerm.toLowerCase() )
    );

    return (
        <>
            <div className="w-full lg:px-4">
                <Input placeholder={ t( 'common:chat.conversationList.searchPlaceholder' ) }
                       prefix={
                           <div className="rounded-full bg-primary-500 w-[30px] h-[30px] flex items-center justify-center">
                               <SearchOutlinedIcon className="text-white text-[18px]" />
                           </div>
                       }
                       onChange={ handleSearchChange } />
            </div>
            <div className="mt-3">
                <ChatConversationList conversationsPreview={ filteredConversations }
                                      endpointConversation={ endpointConversation } />
            </div>
        </>
    );
}