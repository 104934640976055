import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { SegmentEvent, trackSegmentEvent } from '@/utils/analytics';
import { SessionRepository } from '@/core/auth/sessionRepository';
import TextArea from 'antd/es/input/TextArea';
import { Button, Popover } from 'antd';
import { ReturnArrowIcon, SendFilledIcon, SmileyFilledIcon } from '@/assets/icons/CustomIcons';
import EmojiPicker from 'emoji-picker-react';

interface ChatConversationSendMessageProps {
    onMessageSend?: ( message: string ) => void;
    onTypingChange?: ( isTyping: boolean ) => void;
    onLooseFocus?: () => void;
    onFocus?: () => void;
}

export default function ChatConversationSendMessage( {
                                                         onMessageSend,
                                                         onTypingChange,
                                                         onLooseFocus,
                                                         onFocus
                                                     }: ChatConversationSendMessageProps ) {

    const { t } = useTranslation();
    const [ message, setMessage ] = useState<string>( '' );

    const sessionRepository: SessionRepository = new SessionRepository();

    const handleOnMessageSend = ( e, message: string ) => {
        if( !e.shiftKey ) {
            e.preventDefault();
            onMessageSend?.( message );
            trackSegmentEvent( SegmentEvent.MessageSent, { message_sent_from: sessionRepository.userId } );
            setMessage( '' );
        }
    };

    const handleOnFocus = () => {
        onTypingChange?.( true );
        onFocus?.();
    };

    const handleOnBlur = () => {
        onTypingChange?.( false );
        onLooseFocus?.();
    };

    const handleOnTyping = ( e ) => setMessage( e.target.value );

    const addEmoji = ( emojiObj ) => {
        setMessage( (message) => message + ' ' + emojiObj.emoji );
    };

    return (
        <div className="flex items-center gap-x-2 py-3 px-3 rounded-3xl border border-[F0F0F0]">
            <Popover content={ <EmojiPicker onEmojiClick={ ( emojiObj ) => addEmoji( emojiObj ) } /> }>
                <SmileyFilledIcon className="text-2xl text-grey" />
            </Popover>

            <TextArea className="overflow-hidden px-0"
                      bordered={ false }
                      placeholder={ t( 'common:chat.conversation.sendMessagePlaceholder' ) }
                      onChange={ ( e ) => handleOnTyping( e ) }
                      onFocus={ () => handleOnFocus() }
                      onBlur={ () => handleOnBlur() }
                      value={ message }
                      autoSize
                      onPressEnter={ ( e ) => handleOnMessageSend( e, message ) } />

            <div className="hidden lg:flex j<ustify-center flex-none gap-x-2">
                <span className="text-grey">
                    { t( 'common:chat.conversation.pressEnterToSend' ) }
                </span>
                <ReturnArrowIcon className="text-base" /></div>
            <Button shape="circle"
                    size="small"
                    onClick={ ( e ) => handleOnMessageSend( e, message ) }
                    className="bg-primary-500 text-white ml-2 lg:hidden"
                    icon={ <SendFilledIcon style={ { fontSize: '16px' } } /> } />
        </div>
    );
};