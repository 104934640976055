import { useEffect, useRef, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import LayoutVisibilityHook from '@/hooks/layoutVisibilityHook';
import FreelancerProfileHook from '@/hooks/freelancerProfileHook';
import FreelancerProfileReviewHook from '@/components/freelance-profile/components/freelancer-profile-reviews/freelancerProfileReviewHook';
import FreelancerProfile from '@/components/freelance-profile/FreelancerProfile';
import NewProjectModal from '@/pages/client/views/new-project/NewProjectModal';
import { PageName, trackPageView } from '@/utils/analytics';
import isUuid from '@/utils/isUuid';
import FreelancerIdFromCustomUrlHook from '@/hooks/freelancerIdFromCustomUrlHook';

interface FreelancerProfileProps {
    freelancerId?: string;
}

export default function ClientFreelancerProfile( { freelancerId }: FreelancerProfileProps ) {

    const navigate = useNavigate();

    const { id: idParams } = useParams();

    const { getId, id } = FreelancerIdFromCustomUrlHook();

    const [ userId, setUserId ] = useState<string>( null );

    const newProjectModalRef = useRef( null );

    const { load: loadProfile, freelancerProfile, isLoading: isLoadingProfile } = FreelancerProfileHook( {
        visibility: 'PUBLIC'
    } );

    const { load: loadReviews, reviews, averageRate, isLoading: isLoadingReviews } = FreelancerProfileReviewHook( {
        limit: 5
    } );

    const isLoadingCombined = isLoadingProfile || isLoadingReviews;

    LayoutVisibilityHook( {
        headerVisible: true,
        bottomNavBarVisible: false
    } );

    useEffect( () => {
        if( userId ) {
            loadProfile( userId );
            loadReviews( userId );
        }
    }, [ userId ] );

    useEffect( () => {
        if( freelancerProfile && isUuid(idParams) && freelancerProfile.customUrl ) {
            replaceUrlIdByCustomUrl( freelancerProfile.customUrl );
        }
    }, [freelancerProfile] );

    useEffect( () => {
        const userId: string | null = idParams || freelancerId;
        const isUuidId: boolean | null = isUuid( idParams || freelancerId );

        if( !isUuidId ) {
            getId( userId );
        }
        else {
            setUserId( userId );
        }

    }, [ idParams, freelancerId ] );

    useEffect( () => {
        if( id ) {
            setUserId( id );
        }
    }, [ id ] );

    useEffect( () => {
        trackPageView( PageName.FreelancerProfile );
    }, [] );

    const replaceUrlIdByCustomUrl = (newId: string) => {
        navigate(`/client/p/${newId}`, { replace: true });
    }

    const handleHire = () => newProjectModalRef?.current.open();

    return (
        <>
            <FreelancerProfile freelancer={ freelancerProfile }
                               reviews={ reviews }
                               isLoggedIn={ true }
                               isLoading={ isLoadingCombined }
                               averageRate={ averageRate }
                               onHire={ () => handleHire() } />
            <NewProjectModal ref={ newProjectModalRef }
                             freelancer={ freelancerProfile } />
        </>

    );
}