import InputComponent from '@/components/custom-element-form/InputComponent';
import { App, Button, Divider } from 'antd';
import * as Sentry from '@sentry/react';
import { useForm } from 'react-hook-form';
import loginHook from '@/hooks/loginHook';
import { useNavigate } from 'react-router-dom';
import { SessionRepository } from '@/core/auth/sessionRepository';
import { useTranslation } from 'react-i18next';
import { GoogleLogoIcon } from '@/assets/icons/CustomIcons';
import googleConnectHook from '@/hooks/googleConnectHook';
import { useEffect } from 'react';
import { identifySegmentUser, userTypeName } from '@/utils/analytics';
import { useLazyQuery } from '@apollo/client';
import { FreelancerProfileGQL } from '@/gql/global-queries/freelancerProfileGQL';
import { ClientProfileGQL } from '@/pages/client/views/account/client-account-edit-profile/queries/clientProfileGQL';
import { Client, Freelancer } from '@/gql/graphql';

export default function SignInForm() {

    const { t } = useTranslation();

    const { trigger, control, formState: { errors }, handleSubmit } = useForm();
    const { message } = App.useApp();
    const { query, loading } = loginHook();
    const navigate = useNavigate();
    const session: SessionRepository = new SessionRepository();

    const { getCode, login, isLoading, code } = googleConnectHook( {
        scopes: [
            'email',
            'profile',
            'openid'
        ]
    } );

    useEffect( () => {
        if( code ) {
            login( code );
        }
    }, [ code ] );

    const freelancersQuery = useLazyQuery( FreelancerProfileGQL );
    const clientsQuery = useLazyQuery( ClientProfileGQL, {
            variables: {
                id: session?.userId
            }
        }
     );

    useEffect( () => {
        if( clientsQuery[ 1 ].data ) {
            const result = clientsQuery[ 1 ].data.client;
            identifySegmentUser(result as Client, userTypeName.Creator)
            navigate('/client' );
        }
    }, [ clientsQuery[ 1 ].data ] );

    useEffect( () => {
        if( freelancersQuery[ 1 ].data ) {
            const result = freelancersQuery[ 1 ].data.meFreelancer;
            identifySegmentUser(result as Freelancer, userTypeName.Freelancer)
            navigate( '/freelancer' );
        }
    }, [ freelancersQuery[ 1 ].data ] );

    const onSubmit = async( data ) => {
        if( !( await trigger() ) ) {
            return;
        }

        try {
            const user = await query( { email: data.email, password: data.password } );
            session.login( user.data.loginWithPassword );            

            if( user.data.loginWithPassword?.user?.type == 'CLIENT' ) {
                await clientsQuery[ 0 ]();
            } else {
                await freelancersQuery[ 0 ]();
            }

        } catch( e ) {
            Sentry.captureException( 'An error occur when trying to login', e );
            let errorI18n: string = 'auth:sign-in.errors.loginFailed';

            if( e.message.includes( 'Email or password invalid' ) ) {
                errorI18n = 'auth:sign-in.errors.userNotFound';
            }

            message.error( t( errorI18n ) );
        }
    };

    return (
        <form className="flex flex-col items-center gap-4 w-full"
              onSubmit={ handleSubmit( onSubmit ) }>

            <InputComponent control={ control }
                            name="email"
                            label={ t( 'auth:sign-in.inputs.email' ) }
                            placeholder={ t( 'auth:sign-in.inputs.emailPlaceholder' ) }
                            rules={ {
                                required: t( 'common:error.form.required' ),
                                pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
                            } }
                            errors={ errors } />

            <InputComponent control={ control }
                            type="password"
                            name="password"
                            label={ t( 'auth:sign-in.inputs.password' ) }
                            placeholder={ t( 'auth:sign-in.inputs.passwordPlaceholder' ) }
                            rules={ {
                                required: t( 'common:error.form.required' )
                            } }
                            errors={ errors } />

            <Button type="primary"
                    htmlType="submit"
                    loading={ loading }
                    className="w-full mt-4">
                { t( 'auth:sign-in.signIn' ) }
            </Button>


            <Divider> { t( 'auth:sign-up.client.authentication-page.or' ) }</Divider>

            <Button className="w-full flex items-center justify-center gap-x-3"
                    loading={ isLoading }
                    onClick={ () => getCode() }>
                <GoogleLogoIcon className="text-xl h-5 w-5" />
                <span className="font-normal">
                    { t( 'auth:sign-in.googleButton' ) }
                </span>
            </Button>
        </form>
    );
}