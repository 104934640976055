import { AnalyticsBrowser } from '@segment/analytics-next';
import firstLetterUppercaseFormatter from '@/utils/firstLetterUppercaseFormatter';
import { getI18n } from 'react-i18next';

const analytics = AnalyticsBrowser.load({ writeKey: import.meta.env.VITE_SEGMENT_KEY, });
const enableAnalytics = import.meta.env.VITE_ANALYTICS_ENABLED === 'TRUE';

export enum SegmentEvent {
    ProfileUpdated = 'Profile Updated',
    PortfolioAdded = 'Portfolio Added',
    AddChannelClicked = 'Add Channel Clicked',
    MessageSent = 'Message Sent',
    ProjetAccepted = 'Project Accepted',
    ProjectSubmitted = 'Project Submitted',
    QuotePaid = 'Quote Paid',
    ProfileClicked = 'Profile Clicked',
    ProjectSent = 'Project Sent',
    QuoteSent = 'Quote Sent',
    QuoteAccepted = 'Quote Accepted',
    LoginClicked = 'Login Clicked',
};

export enum PageName {
    FreelancerList = 'Freelancer List',
    FreelancerProfile = 'Freelancer Profile',
    FreelancerEditProfile = 'Freelancer Edit Profile',
    FreelancerEditPortfolioChannels = 'Freelancer Edit Portfolio Channels',
    FreelancerEditPortfolioElements = 'Freelancer Edit Portfolio Elements',
    FreelancerEditPassword = 'Freelancer Edit Password',
    FreelancerEditCompanyDetails = 'Freelancer Edit Company Details',
    FreelancerEditProfilePicture = 'Freelancer Edit Profile Picture',
    FreelancerProject = 'Freelancer Project',
    FreelancerChat = 'Freelancer Chat',

    ClientProfile = 'Client Profile',
    ClientEditProfile = 'Client Edit Profile',
    ClientEditPassword = 'Client Edit Password',
    ClientEditCompanyDetails = 'Client Edit Company Details',
    ClientEditPaymentMethods = 'Client Edit Payment Methods',
    ClientProject = 'Client Project',
    ClientChat = 'Client Chat',
    ClientFavorites = 'Client Favorites',
    ClientEditChannelYT = 'Client Edit Channel YT',
    
    SignUp = 'Signup',
    SignIn = 'Signin',
};

export enum userTypeName {
    Freelancer = 'Freelancer',
    Creator = 'Creator',
  };

export async function identifySegmentUser( user, userType ) {

    if ( enableAnalytics ) {

        const segmentData: any = {
            user_type: userType,
            email: user.email || null,
        };

        if( userType === userTypeName.Freelancer ) {
            const name = `${user.firstName || ''} ${ user.lastName || ''}`
            segmentData.is_freelancer_available = typeof user.isAvailable === 'boolean' ? user.isAvailable ? 'Yes' : 'No' : null;
            segmentData.freelancer_name = name.trim().length ? name : null;
            segmentData.freelancer_status = user.accountStatus ? getI18n().t(`enum.accountStatus.${user.accountStatus}`) : null;
            segmentData.freelancer_rating = user.avgRate || null ;
            segmentData.freelancer_price = user.avgPrice;
            segmentData.freelancer_mainjob = user.mainJob ? getI18n().t(`enum.jobs.${user.mainJob}`) : null;
            segmentData.freelancer_experience = user.experience ? firstLetterUppercaseFormatter( user.experience ) : null;
            segmentData.freelancer_portfolio_views = user.totalPortfolioViews;
        } else {
            segmentData.client_name = user.userName;
            segmentData.client_rating = user.avgRate || null;
            segmentData.creator_size = user.creatorSize ? firstLetterUppercaseFormatter( user.creatorSize ) : null;
            segmentData.client_channel_count = user.channels?.length;
            segmentData.client_channel_names = user.channels?.map(( channel ) => channel.name);
        }
        
        const filteredSegmentData = Object.fromEntries(
            Object.entries(segmentData).filter(([key, value]) => value !== null && value !== undefined)
        );

        analytics.identify( user?.id, filteredSegmentData );
    };
};

export async function trackSegmentEvent(
    event: SegmentEvent,
    properties?: Record<string, any>,
) {
    if( enableAnalytics ) {
        try {
            analytics.track(event, properties);
        } catch ( error ) {
            console.error('[analytics] Unexpected error while sending segment event', error)
        };
    };
};

export async function trackPageView( title ) {
    if( enableAnalytics ) {
        try {            
            analytics.page('Page Loaded', { title });
        } catch ( error ) {
            console.error('[analytics] Unexpected error while sending segment page event', error)
        };
    };
};
