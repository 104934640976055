interface SignUpRightSideHeaderProps {
    title: string;
    subTitle: string;
}

export default function SignUpRightSideHeader( { title, subTitle }: SignUpRightSideHeaderProps ) {
    return (
        <div className="w-full">
            <div className="text-2xl font-sharp font-medium">
                { title }
            </div>
            <div className="text-normal text-base mt-4">
                { subTitle }
            </div>
        </div>
    );
}