import { gql } from '@apollo/client';

export const freelancerProjectGQL = gql`
    query FreelancerProject($id: String!) {
        project(id: $id) {
            id
            name
            description
            status
            startedAt
            clientHasReviewed
            conversationId
            users {
                id
                type
                firstName
                lastName
                isVerified
                profilePicture
                averageRate
                countProjects
            }
            payment {
                id
                currency
                paymentExVat
                freelancerAmountExVat
                clientAmountIncVat
                freelancerRemunerationAfterFee
                freelancerRemunerationIncVat
                freelancerInvoiceUrl
                clientInvoiceUrl
                vatRate
                feeRate
            }
            channel {
                id
                name
                thumbnailUrl
                videoCount
                subscriberCount
            }
            createdAt
            updatedAt
        }
    }`;


