import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { matchPath, useLocation } from 'react-router-dom';
import { Badge } from 'antd';
import { Channel, UserTypeEnum } from '@/gql/graphql';
import MyAccountButton from '@/components/MyAccountButton';
import HeaderTouch from '@/components/layout/HeaderTouch';
import { Bold2FilledIcon } from '@/assets/icons/CustomIcons';
import SupportButton from '@/components/SupportButton';
import ChatHook from '@/components/chat/hooks/ChatHook';

interface ClientHeaderDesktopProps {
    countNewFavorites: number;
    user: any;
    customMyAccountSection?: JSX.Element[];
    channelSelected: string | JSX.Element;
    channelSelectedKey?: string;
}

export function ClientHeaderTouch( {
                                       countNewFavorites,
                                       user,
                                       customMyAccountSection,
                                       channelSelected,
                                       channelSelectedKey
                                   }: ClientHeaderDesktopProps ) {

    const location = useLocation();
    const currentPath = location.pathname;

    const { t } = useTranslation();
    const [ currentNav, setCurrentNav ] = useState( null );

    const { totalUnreadMessages } = ChatHook();

    useEffect( () => {
        const route = navs.find( nav => matchRoute( nav.route ) );
        setCurrentNav( route );
    }, [ currentPath ] );

    const navs = [
        /*{
         to: '/client/home',
         text: t( 'client:header.home' ),
         icon: <HomeOutlinedIcon className="text-[24px]" />,
         count: null
         },*/
        {
            route: '/client/discover',
            icon: <Bold2FilledIcon className="text-[16px] mr-2" />,
            text: t( 'client:header.touch.discover' ),
            navBarVisible: true,
            count: null
        },
        {
            route: '/client/home',
            icon: <Bold2FilledIcon className="text-[16px] mr-2" />,
            text: t( 'client:header.touch.discover' ),
            navBarVisible: true,
            count: null
        },
        {
            route: '/client/messages',
            text: t( 'client:header.touch.messages' ),
            navBarVisible: true,
            count: totalUnreadMessages
        },
        {
            route: '/client/projects',
            text: t( 'client:header.touch.projects' ),
            navBarVisible: true,
            count: null
        },
        {
            route: '/client/favorites',
            text: t( 'client:header.touch.favoriteFreelancer' ),
            navBarVisible: true,
            count: countNewFavorites
        },
        {
            route: '/client/settings',
            text: t( 'client:header.touch.settings' ),
            navBarVisible: false,
            count: null
        }
    ];

    const matchRoute = ( route: string ) => {
        return matchPath( {
                path: route,
                caseSensitive: false,
                end: false
            },
            currentPath );
    };

    const rightJSX: JSX.Element = (
        <div className="flex items-center justify-center gap-x-2">
            <SupportButton onlyIcon={ true } />
            <MyAccountButton avatarSize={ 40 }
                             customSection={ customMyAccountSection }
                             selectedKeys={ [ channelSelectedKey ] }
                             data={ {
                                 type: UserTypeEnum.CLIENT,
                                 userName: user?.userName,
                                 profilePicture: user?.profilePicture,
                                 subAvatar: channelSelected
                             } } />
        </div>
    );

    const leftJSX: JSX.Element = (
        <>
            {
                navs.map( ( nav, index ) => {
                    return (
                        <div key={ index }
                             className={ matchRoute( nav.route ) ? '' : 'hidden' }>
                            <div className="flex justify-center items-center">
                                <span className="text-2xl">{ nav.icon }</span>
                                <Badge count={ nav.count }
                                       className="text-inherit text-2xl font-medium font-sharp"
                                       size="small">
                                    { nav.text }
                                </Badge>
                            </div>
                        </div>
                    );
                } )
            }
        </>
    );

    return (
        <HeaderTouch closeButton={ currentNav?.navBarVisible }
                     right={ rightJSX }
                     left={ leftJSX } />
    );
}