import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { languagesWithCountGQL } from '@/gql/global-queries/languagesWithCountGQL';
import { Language } from '@/gql/graphql';
import Checkbox from 'antd/es/checkbox';
import { FreelancersRepository } from '@/components/freelancers-filter-items/freelancersRepository';
import ListSkeleton from '@/components/ListSkeleton';
import { FreelancersRepositoryContext } from '@/core/FreelancerRepositoryContext';

// TODO rendre ce component générique pour les languages, jobs, skills, etc.
export default function FilterItemLanguagesCheckbox() {
    const { t } = useTranslation();
    const freelancersRepository: FreelancersRepository = useContext( FreelancersRepositoryContext );

    const defaultLimit: number = 3;

    const [ checkboxOptions, setCheckboxOptions ] = useState( null );
    const [ checkedValues, setCheckedValues ] = useState<string[]>();
    const [ limit, setLimit ] = useState( defaultLimit );

    const { data, loading } = useQuery( languagesWithCountGQL );

    const convertLanguagesToCheckbox = ( languages: Language[] ) => {
        if( languages == null || languages.length == 0 ) {
            return [];
        }

        return languages.map( ( language: Language ) => {
            return {
                label: `${ t( 'enum.languages.' + language.i18n.toUpperCase() ) } (${ language.totalFreelancer })`,
                value: language.id,
                checked: freelancersRepository.filters.languageIds.includes( language.id )
            };
        } );
    };

    useEffect( () => {
        if( data ) {
            setCheckboxOptions( convertLanguagesToCheckbox( data.languages ) );
            setCheckedValues( freelancersRepository.languageIds );
        }
    }, [ data ] );

    useEffect( () => {
        const observable = freelancersRepository.languageIds$.subscribe( ( value ) => {
            setCheckedValues( value );
        } );
        return () => {
            observable.unsubscribe();
        };
    }, [] );

    const onChange = ( checkedValues: any ) => {
        freelancersRepository.setLanguageIds( checkedValues );
    };

    const onShowMore = () => {
        setLimit( limit ? undefined : defaultLimit );
    };


    if( loading ) {
        return (
            <div className="flex flex-col gap-y-1 max-w-[150px]">
                <ListSkeleton isLoading={ true }
                              count={ 2 }
                              height={ 15 } />
            </div>
        );
    }

    return (
        <div className="checkbox-vertical">
            {
                checkboxOptions?.length > 0 &&
                <Checkbox.Group style={ { display: 'flex', flexDirection: 'row' } }
                                className="gap-y-1 text-base font-medium"
                                options={ checkboxOptions }
                                value={ checkedValues }
                                onChange={ onChange }>
                </Checkbox.Group>
            }

            <div className={ `${ data?.languages.length <= defaultLimit
                                 ? 'hidden'
                                 : '' } underline text-primary-500 cursor-pointer mt-4 text-sm` }
                 onClick={ onShowMore }>
                { limit ? t( 'common:button.showMoreButton' ) : t( 'common:button.showLessButton' ) }
            </div>
        </div>
    );
}