import { useMutation, useQuery } from '@apollo/client';
import { quoteGQL } from '@/gql/global-queries/quoteGQL';
import { Avatar, Button, Divider, App } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useRef, useState } from 'react';
import { Quote } from '@/gql/graphql';
import { priceFormatter } from '@/utils/priceFormatter'
import { defaultPaymentMethodGQL } from '@/gql/global-queries/defaultPaymentMethod';
import ListSkeleton from '@/components/ListSkeleton';
import PaymentMethodForm from '@/components/PaymentMethodForm';
import { CreatePaymentGQL } from '@/gql/global-queries/createPaymentGQL';
import { ClientCompanyDetailsGQL }  from '@/pages/client/views/account/client-account-edit-company-details/services/clientCompanyDetailsGQL';
import * as Sentry from '@sentry/react';
import ProjectPriceDetails from '@/components/ProjectPriceDetails';
import { trackSegmentEvent, SegmentEvent } from '@/utils/analytics';
import InvoicingInfoModal from '@/components/InvoicingInfoModal';
import clientProjectsHook from '@/hooks/clientProjectsHook';

interface ChatConversationPaymentModalFormProps {
    quoteId: string;
    onPaymentDone?: ( paymentId: string ) => void;
}

export default function ChatConversationPaymentModalForm( {
                                                              quoteId,
                                                              onPaymentDone
                                                          }: ChatConversationPaymentModalFormProps ) {

    const { t } = useTranslation();
    const [ quote, setQuote ] = useState<Quote>( null );
    const paymentMethodFormRef = useRef( null );
    const { message } = App.useApp();

    const [ isSaving, setIsSaving ] = useState<boolean>( false );
    const [ isMissingCompanyDetails, setIsMissingCompanyDetails ] = useState<boolean>( true );

    const { data, loading } = useQuery( quoteGQL, { variables: { id: quoteId } } );
    const { data: companyDetails, loading: loadingCompanyDetails, refetch: refetchCompanyDetails } = useQuery( ClientCompanyDetailsGQL );
    const defaultPaymentQuery = useQuery( defaultPaymentMethodGQL );
    const createPaymentQuery = useMutation( CreatePaymentGQL );

    const invoicingInfoModalRefRef = useRef( null );
    const openInvoicingInfoModal = (): void => invoicingInfoModalRefRef?.current.open();

    const {
        increaseTotalProjectsInProgress
    } = clientProjectsHook();

    useEffect( () => {
        if( data?.quote ) {
            setQuote( data.quote );
        }
    }, [ data ] );

    useEffect( () => {
        if( createPaymentQuery[ 1 ].loading ) {
            setIsSaving( true );
        }
    }, [ createPaymentQuery[ 1 ].loading ] );

    useEffect( () => {
        if( companyDetails?.meClient ) {            
            setIsMissingCompanyDetails(!(companyDetails?.meClient?.addressName && companyDetails?.meClient?.addressCity && companyDetails?.meClient?.addressCountry))
        }
    }, [ companyDetails ] );

    const content = (
        <>
            {
                loading ? (
                    <div className="w-full">
                        <ListSkeleton isLoading={ true }
                            height={ 400 }
                            count={ 1 } />
                    </div>
                ) :
                <>
                    <div className="flex justify-center items-center">
                        <Avatar.Group size={ 56 }>
                            <Avatar shape="circle"
                                icon={ <UserOutlined /> }
                                src={ quote?.project?.client?.profilePicture } />
                            <Avatar shape="circle"
                                icon={ <UserOutlined /> }
                                src={ quote?.project?.freelancer?.profilePicture } />
                        </Avatar.Group>
                    </div>

                    <h2 className="mt-4">
                        { t( 'common:chat.conversation.payment.congrats' ) }
                    </h2>

                    <div className="text-grey text-sm mt-2 text-center lg:px-10">
                        { t( 'common:chat.conversation.payment.acceptOffer', { name: quote?.project?.freelancer.firstName } ) }
                    </div>

                    <div className="w-full text-sm font-medium font-sharp mt-6">
                        { quote?.project.name }
                    </div>

                    <div className="w-full mt-4">
                        <ProjectPriceDetails data={ {
                            currency: quote?.currency,
                            amountExVAT: quote?.amountExVAT,
                            amountIncVAT: quote?.amountIncVAT
                        } } />
                    </div>
                </>
            }
        </>
    );

    const handlePayment = async() => {
        setIsSaving( true );
        const paymentMethod = await paymentMethodFormRef.current.getPaymentMethod();
        if( !isMissingCompanyDetails && paymentMethod ) {

            try {
                const { data } = await createPaymentQuery[ 0 ]( {
                    variables: {
                        args: {
                            quoteId,
                            paymentMethodId: paymentMethod.id
                        }
                    }
                } );
    
                if(onPaymentDone && data) {                    
                    trackSegmentEvent(
                        SegmentEvent.QuotePaid,
                        {
                            quote_amount: quote?.amountIncVAT,
                            project_id: quote?.project?.id,
                            project_creator: quote?.project.client.id
                        }
                    );
                    onPaymentDone( data?.createPayment.id );
                    increaseTotalProjectsInProgress()
                }
            } catch( e ) {
                Sentry.captureException( 'An error occur when trying to create a payment', e );
                message.error( t( 'translation:error.default' ) );
            }
        } else {
            message.error(t( 'common:chat.conversation.payment.missingInfoMessage' ));
        }
        setIsSaving( false );
    };

    const invoicingInfoJSX = (
        <div className="w-full mt-3">
            <div className="w-full text-sm font-medium font-sharp mt-6">
                { t( 'common:chat.conversation.payment.invoicingInfoForm.title' ) }
            </div>
            {
                loadingCompanyDetails ?
                    <ListSkeleton isLoading={ true }
                        height={ 60 }
                        count={ 1 } /> :
                <>
                        {
                            !isMissingCompanyDetails ? 
                                <div className="text-sm font-normal text-grey rounded-lg p-4 border mt-4 border-grey-light">
                                    <div>
                                        <span>{ companyDetails.meClient.addressName }</span>
                                    </div>
                                    <div>
                                        <span>{ companyDetails.meClient.addressStreet1 }</span>
                                    </div>
                                    <div>
                                        <span>{ companyDetails.meClient.addressStreet2 }</span>
                                    </div>
                                    <div>
                                        <span>{ companyDetails.meClient.addressCity }, </span>
                                        { !!companyDetails.meClient.addressState && (
                                            <span>{ companyDetails.meClient.addressState }, </span>
                                        )}
                                        <span>{ companyDetails.meClient.addressPostalCode }</span>
                                    </div>
                                    <span>{ companyDetails.meClient.addressCountry }</span>
                                </div>
                            
                                :

                                <div className="text-sm mt-2 text-red">
                                    { t( 'common:chat.conversation.payment.invoicingInfoForm.missingInfo' ) }
                                </div>
                        }

                    <Button className='mt-3' onClick={ openInvoicingInfoModal } type="default">
                        {   isMissingCompanyDetails ?
                            t( 'common:chat.conversation.payment.invoicingInfoForm.addButton' ) :
                            t( 'common:chat.conversation.payment.invoicingInfoForm.editButton' )
                        }
                    </Button>
                </>
            }
        </div>
    );

    const paymentMethodJSX = (
        <div className="w-full mt-3">
            {
                defaultPaymentQuery.loading ?
                    <ListSkeleton isLoading={ true }
                        height={ 60 }
                        count={ 1 } /> :
                <>
                    <PaymentMethodForm defaultPaymentMethod={ defaultPaymentQuery.data?.defaultPaymentMethod }
                        isLoading={ ( value ) => setIsSaving( value ) }
                        ref={ paymentMethodFormRef } />
                </>
            }
        </div>
    );
    return (
        <>
            <div className="flex flex-col items-center w-full">
                <div className="flex flex-col items-center w-full">
                    {
                        content
                    }
                    {
                        invoicingInfoJSX
                    }
                    {
                        paymentMethodJSX
                    }
                    <Divider className="w-full" />
                    {
                        !loading &&
                        <Button type="primary"
                            className="w-full"
                            loading={ isSaving }
                            onClick={ () => handlePayment() }
                            size="middle">
                            {
                                t( 'common:chat.conversation.payment.payButton', {
                                    price: priceFormatter( {
                                        amount: quote?.amountIncVAT,
                                        currency: quote?.currency,
                                        decimals: true
                                    } )
                                } )
                            }
                        </Button>
                    }
                </div>
            </div>
            <InvoicingInfoModal 
                defaultCompanyDetails={ companyDetails?.meClient }
                ref={ invoicingInfoModalRefRef } 
                onSubmit={ refetchCompanyDetails }
            />
        </>
    );
}