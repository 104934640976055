import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';

export default function RedirectFreelancer() {
    const { id } = useParams();
    const navigate = useNavigate();

    // Perform the redirection
    useEffect( () => {
        navigate( `/p/${ id }` );
    }, [ id, navigate ] );

    return <></>;
};
