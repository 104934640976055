import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Project, ProjectStatusEnum } from '@/gql/graphql';
import { Button, Divider, Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';
import { DownloadOutlinedIcon } from '@/assets/icons/CustomIcons';
import { useParams } from 'react-router-dom';
import ClientProjectFreelancer from '@/pages/client/views/project/components/ClientProjectFreelancer';
import projectHook from '@/hooks/projectHook';
import ChannelItem from '@/components/ChannelItem';
import { Interweave } from 'interweave';
import { shortDateFormatter } from '@/utils/shortDateFormatter';
import ProjectPriceDetails from '@/components/ProjectPriceDetails';
import ResponsiveModal from '@/components/ResponsiveModal';
import ClientProjectFreelancerReviewForm from '@/pages/client/views/project/components/ClientProjectFreelancerReviewForm';

interface ProjectProps {
    id?: string;
    onReviewSubmitted?: ( value: Project ) => void;
    className?: string;
}

const  ClientProject = forwardRef( ( props: ProjectProps, ref ) => {

    const { id, onReviewSubmitted, className } = props;
    const { t } = useTranslation();
    const { projectId } = useParams();

    const [ _projectId, setProjectId ] = useState( projectId );

    const reviewModalRef = useRef( null );


    useImperativeHandle( ref, () => ( {
        openReviewModal() {
            reviewModalRef.current?.open();
        }
    } ) );

    useEffect( () => {
        if( projectId ) {
            setProjectId( projectId );
        }
        else {
            setProjectId( id );
        }
    }, [] );

    const { freelancer, project, setProject, isLoading } = projectHook( { projectId: _projectId } );

    const downloadInvoice = ( url ) => {
        window.open( url, '_blank', 'noreferrer' );
    };

    const handleReviewSubmitted = ( value: Project ) => {
        onReviewSubmitted?.( value );

        setProject( {
            ...project,
            status: value?.status,
            validatedByClientAt: value?.validatedByClientAt,
            clientHasReviewed: value?.clientHasReviewed
        } );
    };

    if( isLoading && !project && !freelancer ) {
        return (
            <Skeleton active={ true }
                      className="w-full" />
        );
    }
    else {
        return (
            <div className="flex justify-center items-center w-full">
                <div className="w-full">
                    <ClientProjectFreelancer conversationId={ project?.conversationId }
                                             freelancer={ {
                                                 profilePicture: freelancer?.profilePicture,
                                                 name: freelancer?.fullName,
                                                 mainJob: freelancer?.mainJob as 'VIDEO_EDITOR' | 'GRAPHIC_DESIGNER',
                                                 avgPrice: freelancer?.avgPrice,
                                                 avgRate: freelancer?.averageRate,
                                                 experience: freelancer?.experience
                                             } } />

                    <h3 className="mt-5">
                        { t( 'client:project.projectDetails' ) }
                    </h3>

                    <div className="mt-4">
                        <ChannelItem channel={ project?.channel }
                                     avatarSize={ 32 } />
                    </div>

                    <div className="mt-3 break-words">
                        <Interweave content={ project?.description }
                                    className="text-grey font-normal text-sm" />
                    </div>

                    <div className="mt-[19px] font-medium text-sm">
                        <span>{ t( 'client:project.projectStarted' ) }</span>
                        <span className="ml-1">{ shortDateFormatter( project?.startedAt ) }</span>
                    </div>

                    <div className="mt-[19px]">
                        <ProjectPriceDetails data={ {
                            amountExVAT: project?.payment?.paymentExVat,
                            amountIncVAT: project?.payment?.clientAmountIncVat,
                            currency: project?.payment?.currency
                        } } />
                    </div>

                    {
                        project?.status === ProjectStatusEnum.DONE &&
                        project?.payment?.clientInvoiceUrl &&
                        (
                            <div className="flex justify-center items-center w-full mt-6 lg:mt-8">
                                <Button type="text"
                                        onClick={ () => downloadInvoice( project?.payment.clientInvoiceUrl ) }
                                        size="large"
                                        className="text-pink text-base flex items-center justify-center">
                                                <span className="underline">
                                                    { t( 'client:project.downloadInvoice' ) }
                                                </span>
                                    <DownloadOutlinedIcon style={ { fontSize: '20px' } } />
                                </Button>
                            </div>
                        )
                    }
                </div>
                <ResponsiveModal ref={ reviewModalRef }
                                 title={ t( 'client:projectReview.title' ) }
                                 mobileHeight="h-fit"
                                 width={ 493 }
                                 closable={ true }>
                    <Divider className="mt-0" />
                    <p className="text-center text-sm font-medium mb-8">
                        { t( 'client:projectReview.description', { name: project?.channel?.name } ) }
                    </p>
                    <ClientProjectFreelancerReviewForm reviewSubmitted={ ( project: Project ) => handleReviewSubmitted( project ) }
                                                       data={ {
                                                           projectId: project?.id,
                                                           users: project?.users,
                                                           hasReviewedByFreelancer: project?.freelancerHasReviewed
                                                       } } />
                </ResponsiveModal>
            </div>
        );
    }
} );

export default ClientProject;