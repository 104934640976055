import { Logo } from '@/assets/icons/Logo';
import { twMerge } from 'tailwind-merge';
interface SignUpLeftSideProps {
    data: {
        title: string;
        subTitle?: string;
        stepsBar?: JSX.Element;
        titleClass?: string;
    };
}

export default function SignUpLeftSide( { data }: SignUpLeftSideProps ) {

    const { title, subTitle, stepsBar, titleClass } = data;

    return (
        <div className="relative h-full flex flex-col justify-between">
            <div className="h-[50px]">
                { stepsBar }
            </div>

            <div className={ twMerge('h-full flex-grow mt-[86px]', titleClass )}>
                <div className="text-white font-sharp text-5xl leading-[80px] font-medium tracking-[-0.48px]">
                    { title }
                </div>
                {
                    subTitle ?
                    <div className="text-2xl text-normal text-white opacity-80 mt-8">
                        { subTitle }
                    </div> :
                    null
                }
            </div>

            <div>
                <Logo className="text-white h-12 self-start z-0" />
            </div>
        </div>
    );
}