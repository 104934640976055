import { useForm } from 'react-hook-form';
import { Form, NavLink } from 'react-router-dom';
import { App, Avatar, Button, Divider, Skeleton } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@apollo/client';
import { FreelancerProfileGQL } from '@/gql/global-queries/freelancerProfileGQL';
import { CurrencyEnum, ExperienceTypeEnum, Freelancer } from '@/gql/graphql';
import { SessionRepository } from '@/core/auth/sessionRepository';
import { UserOutlined } from '@ant-design/icons';
import { ChevronRightFilledIcon, PencilOutlinedIcon } from '@/assets/icons/CustomIcons';
import numberFormatter from '@/utils/numberFormatter';
import * as Sentry from '@sentry/react';
import InputComponent from '@/components/custom-element-form/InputComponent';
import InputUrlComponent from '@/components/custom-element-form/InputUrlComponent';
import TextAreaComponent from '@/components/custom-element-form/TextAreaComponent';
import SelectMultiTags from '@/components/SelectMultiTags';
import { skillsGQL } from '@/gql/global-queries/skillsGQL';
import { expertisesGQL } from '@/gql/global-queries/expertisesGQL';
import { languagesGQL } from '@/gql/global-queries/languagesGQL';
import { jobsGQL } from '@/gql/global-queries/jobsGQL';
import jobToIcon from '@/utils/jobToIcon';
import FreelancerAccountEditAvailability from '@/pages/freelancer/views/account/freelancer-account-edit-availability/FreelancerAccountEditAvailability';
import { validateCharacters, validateLength, validateReserved, validateUnique } from '@/utils/customUrlValidation';
import { customUrlHook } from '@/hooks/customUrlHook';
import truncateHttpFormatter from '@/utils/truncateHttpFormatter';
import PhoneNumberInputs from '@/components/PhoneNumberInputs';
import extractDetailsFromPhoneNumber from '@/utils/extractDetailsFromPhoneNumber';
import phoneNumberFromForm from '@/utils/phoneNumberFromForm';
import { UpdateFreelancerProfileGQL } from '@/gql/global-queries/updateFreelancerProfileGQL';
import { phoneNumberVerificationHook } from '@/hooks/phoneNumberVerificationHook';
import PhoneNumberCodeVerificationModal from '@/components/PhoneNumberCodeVerificationModal';
import { identifySegmentUser, PageName, SegmentEvent, trackPageView, trackSegmentEvent, userTypeName } from '@/utils/analytics';

export default function FreelancerProfileEditProfile() {

    const maxLengthBio: number = 1000;

    const { t } = useTranslation();
    const {
              trigger,
              control,
              getValues,
              unregister,
              register,
              setValue,
              watch,
              reset,
              formState: { errors, dirtyFields },
              handleSubmit
          } = useForm();

    const sessionRepository: SessionRepository = new SessionRepository();
    const { message } = App.useApp();

    const [ isLoading, setIsLoading ] = useState<boolean>( true );
    const [ phoneNumberFormatted, setPhoneNumberFormatted ] = useState<string>( null );
    const [ defaultExpertiseIds, setDefaultExpertiseIds ] = useState<string[]>( [] );
    const [ defaultLanguageIds, setDefaultLanguageIds ] = useState<string[]>( [] );
    const [ defaultSkillIds, setDefaultSkillIds ] = useState<string[]>( [] );
    const [ defaultJobIds, setDefaultJobIds ] = useState<string[]>( [] );
    const [ defaultExperience, setDefaultExperience ] = useState<ExperienceTypeEnum>( null );
    const [ mainJobId, setMainJobId ] = useState<string>( null );
    const [ hasEditedMultiselect, setHasEditedMultiselect ] = useState<boolean>( false );

    const [ experiencesTag, setExperiencesTag ] = useState<any[]>( [] );
    const [ hasCustomUrl, setHasCustomUrl ] = useState( false );

    const { checkIfCustomUrlExists, createCustomUrl } = customUrlHook();
    const { sendCode, isVerifying } = phoneNumberVerificationHook( {
        lastCodeSentAt: null,
        phoneNumber: phoneNumberFormatted
    } );

    const freelancerProfileQuery = useQuery( FreelancerProfileGQL );
    const skillsQuery = useQuery( skillsGQL );
    const expertisesQuery = useQuery( expertisesGQL );
    const languagesQuery = useQuery( languagesGQL );
    const jobsQuery = useQuery( jobsGQL );

    const updateFreelancerProfileMutation = useMutation( UpdateFreelancerProfileGQL );

    const expertiseTagFormRef = useRef( null );
    const languageTagFormRef = useRef( null );
    const skillTagFormRef = useRef( null );
    const jobFormRef = useRef( null );
    const experienceFormRef = useRef( null );
    const phoneVerificationRef = useRef( null );

    useEffect( () => {
        trackPageView( PageName.FreelancerEditProfile );
    }, [] );

    const subscription = watch( ( value ) => {
        const countryCode = value.phoneNumberCodeCountrySelect;
        const phoneNumber = value.phoneNumberInput;
        const fullPhoneNumber: string = phoneNumberFromForm( countryCode, phoneNumber );
        setPhoneNumberFormatted( fullPhoneNumber );
    } );

    useEffect( () => {
        const experiences: ExperienceTypeEnum[] = Object.values( ExperienceTypeEnum );
        const options: any[] = [];
        experiences.forEach( ( experience: ExperienceTypeEnum ) => {
            options.push( {
                label: t( `enum.experiences.${ experience }` ) + ' years',
                id: experience
            } );
        } );
        setExperiencesTag( options );

        return () => subscription?.unsubscribe();
    }, [] );

    useEffect( () => {
        if( freelancerProfileQuery.data ) {
            const freelancer: Freelancer = freelancerProfileQuery.data.meFreelancer as Freelancer;
            sessionRepository.updateUser( freelancer );

            setFormValues( freelancer );
            setIsLoading( false );
        }
    }, [ freelancerProfileQuery.data ] );

    const setFormValues = ( freelancer: Freelancer ) => {

        setValue( 'avgPrice', freelancer.avgPrice );
        setValue( 'firstName', freelancer.firstName );
        setValue( 'lastName', freelancer.lastName );
        setValue( 'email', freelancer.email );
        setValue( 'bio', freelancer.bio );
        setValue( 'currency', CurrencyEnum.USD );
        setValue( 'customUrl', freelancer.customUrl );

        if( freelancer.phone != null ) {
            const { countryCode, number } = extractDetailsFromPhoneNumber( freelancer.phone );
            setValue( 'phoneNumberCodeCountrySelect', countryCode );
            setValue( 'phoneNumberInput', number );
        }

        setDefaultExpertiseIds( freelancer.expertises?.map( ( expertise ) => expertise.id ) );
        setDefaultLanguageIds( freelancer.languages?.map( ( language ) => language.id ) );
        setDefaultSkillIds( freelancer.skills?.map( ( skill ) => skill.id ) );
        setDefaultJobIds( freelancer.jobToFreelancers?.map( ( jobToFreelancer ) => jobToFreelancer.jobId ) || [] );
        setMainJobId( freelancer.jobToFreelancers?.find( ( jobToFreelancer ) => jobToFreelancer.isMainJob )?.jobId || '' );
        setDefaultExperience( freelancer.experience || null );
        setHasCustomUrl( !!freelancer.customUrl );
    };

    const hasPhoneNumberChanged = ( form ) => {
        const freelancer: Freelancer = freelancerProfileQuery.data.meFreelancer as Freelancer;

        if( freelancer.phone == null ) {
            return true;
        }

        const { countryCode, number } = extractDetailsFromPhoneNumber( freelancer.phone );

        return form.phoneNumberInput != number || form.phoneNumberCodeCountrySelect != countryCode;
    };

    const handleSelectTags = ( value: string[] ) => {
        setHasEditedMultiselect( true );
    };

    const generateAnalyticsProperties = ( options ) => {
        const propertyArray = Object.keys( options );
        const resultArray = [];

        if( propertyArray.includes( 'avgPrice' ) ) {
            resultArray.push( 'Price' );
        }

        if( propertyArray.some( property => property !== 'avgPrice' ) || hasEditedMultiselect ) {
            resultArray.push( 'Personal Details' );
            setHasEditedMultiselect( false );
        }

        return resultArray.join( ', ' );
    };

    const onSubmit = async( data, codeVerified: boolean = false ): Promise<void> => {
        if( !( await trigger() ) ) {
            return;
        }

        if( hasPhoneNumberChanged( data ) && !codeVerified ) {
            await sendCode( phoneNumberFormatted );
            phoneVerificationRef.current.open();
            return;
        }

        const args = {
            avgPrice: numberFormatter( data.avgPrice ),
            firstName: data.firstName,
            lastName: data.lastName,
            bio: data.bio,
            phone: phoneNumberFormatted,
            email: data.email,
            experience: ( experienceFormRef.current as any ).getSelectedIds()?.[ 0 ],
            expertiseIds: ( expertiseTagFormRef.current as any ).getSelectedIds(),
            languageIds: ( languageTagFormRef.current as any ).getSelectedIds(),
            skillIds: ( skillTagFormRef.current as any ).getSelectedIds(),
            jobIds: ( jobFormRef.current as any ).getSelectedIds(),
            mainJobId: ( jobFormRef.current as any ).getMainTagId()
        };

        try {
            if( !hasCustomUrl && data.customUrl ) {
                await createCustomUrl( data.customUrl );
            }

            const response = await updateFreelancerProfileMutation[ 0 ]( {
                variables: {
                    args
                }
            } );

            if( response.data?.freelancerUpdate ) {
                const refetchData = await freelancerProfileQuery.refetch();
                await identifySegmentUser( refetchData.data.meFreelancer, userTypeName.Freelancer );
                unregisterCustomUrl();
                sessionRepository.updateUser( response.data.freelancerUpdate );
                message.success( t( 'common:form.successfullySubmitted' ) );
                trackSegmentEvent( SegmentEvent.ProfileUpdated, { profile_updated_type: generateAnalyticsProperties( dirtyFields ) } );
                reset( {}, { keepValues: true } );
            }
        } catch( e ) {
            Sentry.captureException( 'An error occur when trying to edit freelancer profile', e );
            message.error( t( 'common:form.submitError' ) );
        }
    };

    const handleCodeVerified = async( isVerified ) => {
        if( isVerified ) {
            onSubmit( getValues(), true );
        }
    };

    const unregisterCustomUrl = () => {
        setHasCustomUrl( true );
        const customUrl = getValues( 'customUrl' );
        unregister( 'customUrl' );
        register( 'customUrl' );
        setValue( 'customUrl', customUrl, { shouldValidate: false } );
    };

    const loadingJSX = (
        <Skeleton active={ true } />
    );

    const contentJSX = (
        <Form className="flex flex-col gap-y-4"
              onSubmit={ handleSubmit( ( data ) => onSubmit( data, false ) ) }>

            <NavLink to="/freelancer/settings/edit-profile-picture"
                     replace={ true }>
                <div className="flex justify-between items-center w-full">
                    <div className="flex items-center">
                        <Avatar size={ 32 }
                                icon={ <UserOutlined /> }
                                className="flex-none"
                                src={ freelancerProfileQuery?.data?.meFreelancer.profilePicture } />
                        <div className="ml-2 text-sm font-normal">
                            { t( 'freelancer:account.edit-profile.editMyProfilePicture' ) }
                        </div>
                    </div>

                    <ChevronRightFilledIcon className="text-xs" />
                </div>
            </NavLink>

            <Divider type="horizontal"
                     className="my-2 lg:my-6" />

            <FreelancerAccountEditAvailability defaultValue={ freelancerProfileQuery?.data?.meFreelancer.isAvailable } />

            <Divider type="horizontal"
                     className="my-2 lg:my-6" />

            <div className="lg:flex-nowrap gap-2 gap-y-6">
                <div className="mb-3">
                    <label className="text-base text-grey font-normal">
                        { t( 'freelancer:account.edit-profile.myCustomUrl' ) }
                    </label>
                </div>
                <div>
                    <InputUrlComponent control={ control }
                                       domain={ truncateHttpFormatter( import.meta.env.VITE_ENDPOINT ) + '/p/' }
                                       name="customUrl"
                                       placeholder={ t( 'freelancer:account.edit-profile.myCustomUrlPlaceholder' ) }
                                       rules={ !hasCustomUrl ? {
                                           required: t( 'common:error.form.required' ),
                                           validate: {
                                               length: ( value ) => validateLength( value, t( 'common:error.form.lengthCustomUrl' ) ),
                                               characters: ( value ) => validateCharacters( value, t( 'common:error.form.invalidCustomUrl' ) ),
                                               reserved: ( value ) => validateReserved( value, t( 'common:error.form.reservedCustomUrl' ) ),
                                               unique: ( value ) => validateUnique( value, checkIfCustomUrlExists, t( 'common:error.form.existingCustomUrl' ) )
                                           }
                                       } : {} }
                                       errors={ errors }
                                       disabled={ hasCustomUrl }
                    />
                </div>
            </div>

            <Divider type="horizontal"
                     className="my-2 lg:my-6" />

            <InputComponent control={ control }
                            name="avgPrice"
                            label={ t( 'freelancer:account.edit-profile.myAveragePrice' ) }
                            tooltipLabel={ t( 'freelancer:account.edit-profile.myAveragePriceDescription' ) }
                            placeholder={ t( 'freelancer:account.edit-profile.myAveragePricePlaceholder' ) }
                            suffix="$"
                            errors={ errors }
                            rules={ {
                                required: t( 'common:error.form.required' ),
                                min: {
                                    value: 0,
                                    message: t( 'freelancer:account.edit-profile.error.min' )
                                }
                            } } />

            <Divider type="horizontal"
                     className="my-2 lg:my-6" />

            <div className="flex flex-wrap lg:flex-nowrap gap-2 gap-y-6">
                <InputComponent control={ control }
                                name="firstName"
                                label={ t( 'freelancer:account.edit-profile.firstName' ) }
                                placeholder={ t( 'freelancer:account.edit-profile.firstNamePlaceholder' ) }
                                rules={ {
                                    required: t( 'common:error.form.required' )
                                } }
                                errors={ errors } />

                <InputComponent control={ control }
                                name="lastName"
                                label={ t( 'freelancer:account.edit-profile.lastName' ) }
                                placeholder={ t( 'freelancer:account.edit-profile.lastNamePlaceholder' ) }
                                rules={ {
                                    required: t( 'common:error.form.required' )
                                } }
                                errors={ errors } />
            </div>

            <InputComponent control={ control }
                            name="email"
                            label={ t( 'client:account.edit-profile.inputs.email.label' ) }
                            placeholder={ t( 'client:account.edit-profile.inputs.email.placeholder' ) }
                            rules={ {
                                required: t( 'common:error.form.required' ),
                                pattern: {
                                    value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
                                    message: t( 'common:error.form.invalidEmail' )
                                }
                            } }
                            errors={ errors } />

            <PhoneNumberInputs control={ control }
                               inputName="phoneNumberInput"
                               selectName="phoneNumberCodeCountrySelect"
                               errors={ errors }
                               watch={ watch } />

            <TextAreaComponent control={ control }
                               name="bio"
                               rows={ 6 }
                               label={ t( 'freelancer:account.edit-profile.aboutYourself' ) }
                               placeholder={ t( 'freelancer:account.edit-profile.placeholderAboutYourself' ) }
                               maxLength={ maxLengthBio }
                               rules={ {
                                   required: t( 'common:error.form.required' ),
                                   maxLength: {
                                       value: maxLengthBio,
                                       message: t( 'common:error.form.maxLength', { maxLength: maxLengthBio } )
                                   }
                               } }
                               errors={ errors } />

            <Divider type="horizontal"
                     className="my-2 lg:my-6" />

            <div className="flex flex-col justify-start w-full">
                <label className="text-base text-grey font-normal mb-3">
                    { t( 'freelancer:account.edit-profile.myJobs' ) }
                </label>
                <SelectMultiTags loading={ jobsQuery?.loading }
                                 enableMainTag={ true }
                                 tags={ jobsQuery?.data?.jobs?.map( ( job ) => ( {
                                     id: job.id,
                                     label: (
                                         <div>
                                             { jobToIcon( job.i18n?.toUpperCase() as 'VIDEO_EDITOR' | 'GRAPHIC_DESIGNER' ) }
                                             <span className="ml-2">
                                                 { t( 'translation:enum.jobs.' + job.i18n?.toUpperCase() ) }
                                            </span>
                                         </div>
                                     )
                                 } ) ) }
                                 onTagsSelected={ ( value ) => handleSelectTags( value ) }
                                 ref={ jobFormRef }
                                 defaultMainTagId={ mainJobId }
                                 defaultSelectedIds={ defaultJobIds } />
            </div>

            <div className="flex flex-col justify-start w-full mt-6">
                <label className="font-semibold text-base mb-2">
                    { t( 'freelancer:account.edit-profile.experience' ) }
                </label>
                <SelectMultiTags tags={ experiencesTag }
                                 limit={ 1 }
                                 ref={ experienceFormRef }
                                 onTagsSelected={ ( value ) => handleSelectTags( value ) }
                                 defaultSelectedIds={ [ ExperienceTypeEnum[ defaultExperience ] ] } />
            </div>

            <div className="flex flex-col justify-start w-full mt-6">
                <label className="font-semibold text-base mb-2">
                    { t( 'freelancer:account.edit-profile.mySkills' ) }
                </label>
                <SelectMultiTags defaultSelectedIds={ defaultSkillIds }
                                 tags={ skillsQuery?.data?.skills?.map( ( skill ) => ( {
                                     id: skill.id,
                                     label: t( 'translation:enum.skills.' + skill.i18n.toUpperCase() )
                                 } ) ) }
                                 ref={ skillTagFormRef }
                                 onTagsSelected={ ( value ) => handleSelectTags( value ) }
                                 loading={ skillsQuery.loading } />
            </div>

            <div className="flex flex-col justify-start w-full mt-6">
                <label className="font-semibold text-base mb-2">
                    { t( 'freelancer:account.edit-profile.myAreaOfExpertise' ) }
                </label>
                <SelectMultiTags defaultSelectedIds={ defaultExpertiseIds }
                                 tags={ expertisesQuery?.data?.expertises?.map( ( expertise ) => ( {
                                     id: expertise.id,
                                     label: t( 'translation:enum.expertises.' + expertise.i18n.toUpperCase() )
                                 } ) ) }
                                 ref={ expertiseTagFormRef }
                                 onTagsSelected={ ( value ) => handleSelectTags( value ) }
                                 loading={ expertisesQuery.loading } />

            </div>

            <div className="flex flex-col justify-start w-full mt-6">
                <label className="font-semibold text-base mb-2">
                    { t( 'freelancer:account.edit-profile.myLanguages' ) }
                </label>
                <SelectMultiTags defaultSelectedIds={ defaultLanguageIds }
                                 tags={ languagesQuery?.data?.languages?.map( ( language ) => ( {
                                     id: language.id,
                                     label: t( 'translation:enum.languages.' + language.i18n.toUpperCase() )
                                 } ) ) }
                                 ref={ languageTagFormRef }
                                 onTagsSelected={ ( value ) => handleSelectTags( value ) }
                                 loading={ languagesQuery.loading } />
            </div>

            <Button type="primary"
                    htmlType="submit"
                    loading={ updateFreelancerProfileMutation[ 1 ].loading || isVerifying }
                    className="w-full lg:w-[160px] mt-8">
                { t( 'freelancer:account.edit-profile.submit' ) }
            </Button>
        </Form>
    );

    return (
        <>
            <div className="w-full mt-4">
                <h1 className="lg:hidden text-2xl font-bold">
                    { t( 'client:account.edit-profile.title' ) }
                    <Divider type="horizontal" />
                </h1>
                {
                    isLoading ?
                    loadingJSX :
                    contentJSX
                }
            </div>
            <PhoneNumberCodeVerificationModal ref={ phoneVerificationRef }
                                              phoneNumber={ phoneNumberFormatted }
                                              onCodeVerified={ ( isVerified ) => handleCodeVerified( isVerified ) } />
        </>
    );
};