import { useEffect, useState } from 'react';

import { useLazyQuery } from '@apollo/client';

import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';

import { Project, ProjectStatusEnum } from '@/gql/graphql';
import { clientProjectsGQL } from '@/gql/global-queries/clientProjectsGQL';
import { totalProjectsByStatusGQL } from '@/gql/global-queries/totalProjectsByStatusGQL';
import { projectsInProgressByChannelGQL } from '@/gql/global-queries/projectsInProgressByChannelGQL';

import jobToIcon from '@/utils/jobToIcon';
import firstLetterUppercaseFormatter from '@/utils/firstLetterUppercaseFormatter';
import { ProjectCardInterface } from '@/components/projects/project-card/ProjectCard';
import { TotalProjectsUI } from '@/core/interfaces/totalProjectsUI';
import { ProjectsRepository } from '@/components/projects/projectsRepository';
import { App } from 'antd';

interface ClientProjectListDesktopHookProps {
    limit?: number;
}

export default function clientProjectsHook( { limit }: ClientProjectListDesktopHookProps = {}  ) {

    const { t } = useTranslation();
    const { message } = App.useApp();
    const projectsStore: ProjectsRepository = new ProjectsRepository();

    const [ projects, setProjects ] = useState<ProjectCardInterface[]>( [] );
    const [ projectSelected, setProjectSelected ] = useState<ProjectCardInterface>( null );
    const [ statusSelected, setStatusSelected ] = useState<ProjectStatusEnum>( ProjectStatusEnum.IN_PROGRESS );
    const [ hasMore, setHasMore ] = useState<boolean>( true );
    const [ currentPage, setCurrentPage ] = useState<number>( 0 );
    const [ totalProjectsInProgress, setTotalProjectsInProgress ] =  useState<number>(0)
    const [ totalByStatus, setTotal ] = useState<TotalProjectsUI>( {
        inProgress: 0,
        done: 0,
        cancelled: 0
    } );

    const [ projectsQuery, { data, loading, error, refetch } ] = useLazyQuery( clientProjectsGQL );

    const loadProjects = ( status, channelIds?: string[] | null ) => {

        setProjects( [] );
        setCurrentPage( 0 );
        setStatusSelected( status );
        projectsQuery( {
            variables: {
                args: {
                    status,
                    channelIds
                },
                limit,
                page: 1
            },
            notifyOnNetworkStatusChange: true
        } );

        getTotals( channelIds );
    };

    const getTotals = ( channelIds?: string[] | null ) => {
        totalProjectsInProgressQuery[ 0 ]( {
            variables: {
                args: {
                    status: ProjectStatusEnum.IN_PROGRESS,
                    channelIds
                },
                limit: 1,
                page: 0
            }
        } );

        totalProjectsDoneQuery[ 0 ]( {
            variables: {
                args: {
                    status: ProjectStatusEnum.DONE,
                    channelIds
                },
                limit: 1,
                page: 0
            }
        } );

        totalProjectsCancelledQuery[ 0 ]( {
            variables: {
                args: {
                    status: ProjectStatusEnum.CANCELLED,
                    channelIds
                },
                limit: 1,
                page: 0
            }
        } );
    }

    const totalProjectsInProgressQuery = useLazyQuery( totalProjectsByStatusGQL );

    const totalProjectsDoneQuery = useLazyQuery( totalProjectsByStatusGQL );

    const totalProjectsCancelledQuery = useLazyQuery( totalProjectsByStatusGQL );

    // Incognito mode + Channel projects
    const totalProjectsInProgressByChannelQuery = useLazyQuery( projectsInProgressByChannelGQL );

    useEffect( () => {
        if( totalProjectsInProgressByChannelQuery[ 1 ].data ) {
            projectsStore.clientTotalProjects = totalProjectsInProgressByChannelQuery[ 1 ].data.projectsInProgressByChannel.totalCount;
        }
    }, [ totalProjectsInProgressByChannelQuery[ 1 ].data ] );

    useEffect( () => {
        if( totalProjectsInProgressQuery[ 1 ].data ) {
            setTotal( totalByStatus => {
                return {
                    ...totalByStatus,
                    inProgress: totalProjectsInProgressQuery[ 1 ].data.projects.total
                };
            } );
        }
    }, [ totalProjectsInProgressQuery[ 1 ].data ] );

    useEffect( () => {
        if( totalProjectsDoneQuery[ 1 ].data ) {
            setTotal( totalByStatus => {
                return {
                    ...totalByStatus,
                    done: totalProjectsDoneQuery[ 1 ].data.projects.total
                };
            } );
        }
    }, [ totalProjectsDoneQuery[ 1 ].data ] );

    useEffect( () => {
        if( totalProjectsCancelledQuery[ 1 ].data ) {
            setTotal( totalByStatus => {
                return {
                    ...totalByStatus,
                    cancelled: totalProjectsCancelledQuery[ 1 ].data.projects.total
                };
            } );
        }
    }, [ totalProjectsCancelledQuery[ 1 ].data ] );

    useEffect( () => {
        if( data ) {
            managedData( data.projects );
        }
    }, [ data ] );

    useEffect( () => {   
        totalProjectsInProgressByChannelQuery[0]()
        const observableTotalProjects = projectsStore.clientTotalProjects$.subscribe( ( state ) => {
            setTotalProjectsInProgress(state);
        } );

        return () => {
            observableTotalProjects.unsubscribe();
        };
    }, [] );

    const increaseTotalProjectsInProgress = () => {
        projectsStore.clientTotalProjects = totalProjectsInProgress + 1;
    };

    const projectFromDataGQL = ( data ): ProjectCardInterface[] => {
        return data?.map( ( project: Project ) => {
            const freelancer = project.freelancer;

            return {
                id: project.id,
                user: {
                    name: freelancer.fullName,
                    imageUrl: freelancer.profilePicture,
                    description: (
                        <div>
                            { jobToIcon( freelancer.mainJob as 'VIDEO_EDITOR' | 'GRAPHIC_DESIGNER' ) }
                            <span className="ml-2">
                                { t( 'enum.jobs.' + freelancer.mainJob ) }
                            </span>
                        </div>
                    )
                },
                name: firstLetterUppercaseFormatter( project.name ),
                channel: {
                    thumbnailUrl: project.channel?.thumbnailUrl,
                    name: project.channel?.name
                },
                clientInvoiceUrl: project.payment?.clientInvoiceUrl,
                amount: project.payment?.clientAmountIncVat,
                hasReviewed: project.clientHasReviewed,
                status: project.status,
                hasReviewedByFreelancer: project.freelancerHasReviewed,
                currency: project.payment?.currency,
                startedAt: DateTime.fromISO( project.startedAt ).toLocaleString( DateTime.DATE_SHORT )
            };
        } );
    };

    const loadMore = (channelIds: string[] | null) => {
        refetch( {
                args: {
                    status: statusSelected,
                    channelIds
                },
                limit,
                page: currentPage + 1
            }
        );
    };

    const handleRefresh = ( project: Project ) => {
        const updatedProjects = [...projects]
        let index = projects.findIndex(existingroject => existingroject.id === project.id);        
        
        if ( index !== -1 ) {
            updatedProjects[ index ].hasReviewedByFreelancer = project.freelancerHasReviewed;
            updatedProjects[ index ].hasReviewed = project.clientHasReviewed;
            if( project.clientHasReviewed && project.freelancerHasReviewed ) {
                updatedProjects.splice( index, 1 );
                projectsStore.clientTotalProjects = projectsStore.clientTotalProjects - 1;
                setTotal( totalByStatus => {
                    return {
                        ...totalByStatus,
                        inProgress: totalByStatus.inProgress - 1,
                        done: totalByStatus.done + 1,
                    };
                } );
                message.success( t( 'freelancer:project.projectDone' ) );
            }
            setProjects( updatedProjects )
        }
    };

    const managedData = ( result ) => {
        const mergedArray = [
            ...projects,
            ...projectFromDataGQL( result.data ).filter(obj2 => !projects.some(obj1 => obj1.id === obj2.id))
        ];
        setProjects( mergedArray );
        setHasMore( result.page < result.lastPage );
        setCurrentPage( result.page );
    };

    return {
        loadProjects,
        projects,
        projectSelected,
        totalByStatus,
        totalProjectsInProgress,
        loading,
        loadMore,
        hasMore,
        setProjectSelected,
        handleRefresh,
        increaseTotalProjectsInProgress
    };
}