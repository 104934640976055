import { SpeechBubbleIcon } from '@/assets/icons/CustomIcons'
import { twMerge } from 'tailwind-merge';

interface FreelancerLanguagesProps {
    languages: string[];
    iconClassName?: string;
    className?: string;
}

export default function FreelancerLanguages( { languages, iconClassName, className }: FreelancerLanguagesProps ) {

    const aggregatedLanguages = languages?.join( ', ' );

    return (
        <div className={ twMerge( 'flex flex-wrap', className ) }>
            <SpeechBubbleIcon className={ iconClassName } />
            <span className="ml-2">
                { aggregatedLanguages }
            </span>
        </div>
    );
}