import { gql } from '@apollo/client';


export const PortfolioElementsGQL = gql`
    query PortFolioElementsGQL {
        portfolioElements {
            id
            type
            title
            thumbnailUrl
            order
            viewCount
            portfolioChannel {
                id
                name
                subscriberCount
                viewCount
                videoCount
            }
        }
    }`;


