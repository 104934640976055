import { FreelancersRepository } from '@/components/freelancers-filter-items/freelancersRepository';
import { useContext, useEffect, useState } from 'react';
import { FreelancersRepositoryContext } from '@/core/FreelancerRepositoryContext';

export default function freelancersFiltersHook() {

    const freelancersRepository: FreelancersRepository = useContext( FreelancersRepositoryContext );

    const [ isOpenMenu, setIsOpenMenu ] = useState( freelancersRepository.isOpen );

    useEffect( () => {
        const observable = freelancersRepository.isOpen$.subscribe( ( isOpenMenu: boolean ) => {
            setIsOpenMenu( isOpenMenu );
        } );

        return () => {
            observable.unsubscribe();
        };
    }, [ isOpenMenu ] );

    const handleMenuOpening = () => freelancersRepository.setInvertOpen();

    return {
        handleMenuOpening,
        isOpenMenu,
        limit: freelancersRepository.paginationData.perPage
    };
}