import { gql } from '@apollo/client';

export const jobsWithCountGQL = gql`
    query Jobs {
        jobs {
            id
            i18n
            name
            totalFreelancer
        }
    }
`;