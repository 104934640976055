import { TotalProjectsUI } from '@/core/interfaces/totalProjectsUI';
import { createStore, select, withProps } from '@ngneat/elf';

interface ProjectStore {
    clientTotalProjects: number,
    freelancerTotalProjects: TotalProjectsUI,
}

const projectStore = createStore(
    { name: 'projectStore' },
    withProps<ProjectStore>({
        clientTotalProjects: 0,
        freelancerTotalProjects: {
            inProgress: 0,
            done: 0,
            cancelled: 0
        },
    })
);

export class ProjectsRepository {
    clientTotalProjects$ = projectStore.pipe(
        select((state) => state.clientTotalProjects)
    );
        
    freelancerTotalProjects$ = projectStore.pipe(
        select((state) => state.freelancerTotalProjects)
    );

    get clientTotalProjects() {
        return projectStore.getValue().clientTotalProjects;
    }

    get freelancerTotalProjects() {
        return projectStore.getValue().freelancerTotalProjects;
    }

    set clientTotalProjects(value) {
        projectStore.update((state) => {
            return {
                ...state,
                clientTotalProjects: value,
            };
        });
    };

    set freelancerTotalProjects(value) {
        projectStore.update((state) => {
            return {
                ...state,
                freelancerTotalProjects: value,
            };
        });
    };
}
