import { useTranslation } from 'react-i18next';
import { BoldFilledIcon, ShareOutlinedIcon } from '@/assets/icons/CustomIcons';
import { Avatar, Button } from 'antd';
import MainIndicatorCardProfile from '@/components/MainIndicatorCardProfile';
import { UserOutlined } from '@ant-design/icons';
import FreelancerMainJob from '@/components/freelance-profile/components/FreelancerMainJob';
import FreelancerAvailabilityTag from '@/components/freelance-profile/components/FreelancerAvailabilityTag';
import FavouriteButton from '@/components/FavouriteButton';
import { freelancerProfileLinkHook } from '@/hooks/freelancerProfileLinkHook';
import { Freelancer } from '@/gql/graphql';

export interface FreelancerProfileCardProps {
    freelancer: Freelancer;
    hireButton?: boolean;
    favouriteButton?: boolean;
    onLike?: () => void;
    onDislike?: () => void;
    onHire?: () => void;
}

export default function FreelancerProfileHeader( {
                                                     freelancer,
                                                     favouriteButton,
                                                     onLike,
                                                     onDislike,
                                                     onHire
                                                 }: FreelancerProfileCardProps ) {

    const { t } = useTranslation();
    const { getProfileLink } = freelancerProfileLinkHook();

    const handleOnLike = ( e ) => {
        if( onLike ) {
            onLike();
            e.stopPropagation();
        }
    };

    const handleOnDislike = () => onDislike?.();

    const handleLikeDislike = ( e ) => {
        if( freelancer?.isFavorite ) {
            handleOnDislike();
        }
        else {
            handleOnLike( e );
        }
    };

    const copyCustomUrlInClipboard = async(): Promise<void> => {
        const id: string = freelancer.customUrl ? freelancer.customUrl : freelancer.id;
        getProfileLink( id );
    };

    const handleOnHire = () => onHire?.();

    return (
        <div className="relative flex">
            <div className="flex-none hidden lg:block">
                <Avatar size={ 160 }
                        icon={ <UserOutlined /> }
                        src={ freelancer?.profilePicture } />
            </div>

            <div className="w-full flex flex-col lg:ml-8">
                <div className="flex justify-between">

                    <div className="lg:hidden flex-none mr-4">
                        <Avatar size={ 60 }
                                icon={ <UserOutlined /> }
                                src={ freelancer?.profilePicture } />
                    </div>

                    <div className="w-full">
                        <div className="text-3xl leaning-[45px] font-medium font-sharp">
                            { freelancer?.firstName } { freelancer?.lastName?.toUpperCase() }.
                        </div>

                        <div className="flex gap-x-4 mt-1 items-center">
                            <FreelancerMainJob job={ freelancer?.mainJob as 'VIDEO_EDITOR' | 'GRAPHIC_DESIGNER' } />
                            <FreelancerAvailabilityTag isAvailable={ freelancer?.isAvailable }
                                                       firstName={ freelancer?.firstName } />
                        </div>
                    </div>

                    <div className="flex items-top gap-x-4">
                        <Button type="default"
                                onClick={ () => copyCustomUrlInClipboard() }
                                icon={ <ShareOutlinedIcon className="text-xl" /> }>
                        </Button>
                        {
                            favouriteButton &&
                            <div className="absolute lg:relative">
                              <FavouriteButton isFavourite={ freelancer?.isFavorite }
                                               onClick={ ( isFavourite ) => handleLikeDislike( isFavourite ) } />
                            </div>
                        }

                        <Button type="primary"
                                className="hidden pulse w-[120px] lg:flex justify-center items-center font-semibold"
                                onClick={ () => handleOnHire() }>
                            <BoldFilledIcon style={ { fontSize: '19px' } } /> { t( 'common:freelancerProfile.card.hireButton' ) }
                        </Button>
                    </div>

                </div>

                <div className="mt-9">
                    <MainIndicatorCardProfile data={ {
                        avgPrice: freelancer?.avgPrice,
                        priceCurrency: freelancer?.currency,
                        avgRate: freelancer?.avgRate,
                        experience: freelancer?.experience,
                        languages: freelancer?.languages.map( ( language ) => language.name),
                        countProjects: freelancer?.countProjects | 0
                    } } />
                </div>
            </div>
        </div>
    );
}