import jobToIcon from '@/utils/jobToIcon';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface FreelancerMainJobProps {
    job: 'VIDEO_EDITOR' | 'GRAPHIC_DESIGNER';
}

export default function FreelancerMainJob( { job }: FreelancerMainJobProps ) {
    const { t } = useTranslation();

    return (
        <div className="text-primary-500 whitespace-nowrap">
            { jobToIcon( job?.toUpperCase() as 'VIDEO_EDITOR' | 'GRAPHIC_DESIGNER' ) }
            <span className="ml-2">
                { t( 'translation:enum.jobs.' + job?.toUpperCase() ) }
            </span>
        </div>
    );
}