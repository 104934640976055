import { Tag } from 'antd';
import { useTranslation } from 'react-i18next';

interface isOnlineTagProps {
    isOnline: boolean;
    bordered?: boolean;
    size?: 'large' | 'small';
}

export default function IsOnlineTag( { isOnline, bordered = true, size = 'small' }: isOnlineTagProps ) {

    const { t } = useTranslation();

    return (
        <Tag className="h-fit w-fit"
             bordered={ bordered }>
            <div className={ `w-2 h-2 rounded-full ${ size == 'large' ? 'mr-2' : 'mr-1' } ${ isOnline
                                                                                             ? 'bg-green-200'
                                                                                             : 'bg-red' }` }>
            </div>
            <span className="text-xs">
                            {
                                isOnline ?
                                t( 'common:online' ) :
                                t( 'common:offline' )
                            }
                        </span>
        </Tag>
    );
}