import { CompassOutlinedIcon, FileOutlinedIcon, HeartOutlinedIcon, MessageOutlinedIcon } from '@/assets/icons/CustomIcons';
import { NavLink } from 'react-router-dom';
import FavoritesHook from '@/hooks/favoritesHook';
import { useTranslation } from 'react-i18next';
import { Divider } from 'antd';
import { twMerge } from 'tailwind-merge';

export default function ClientNavBarTouch() {

    const { t } = useTranslation();
    const { countNewFavorites } = FavoritesHook();

    const navs = [
        {
            to: '/client/discover',
            icon: <CompassOutlinedIcon className="text-[24px] text-inherit" />,
            count: null
        },
        {
            to: '/client/messages',
            icon: <MessageOutlinedIcon className="text-[24px] text-inherit" />,
            count: null
        },
        {
            to: '/client/projects',
            icon: <FileOutlinedIcon className="text-[24px] text-inherit" />,
            count: null
        },
        {
            to: '/client/favorites',
            icon: <HeartOutlinedIcon className="text-[24px] text-inherit" />,
            count: countNewFavorites
        }
    ];

    return (
        <div className="flex-none w-full h-[60px] fixed bottom-0 left-0 right-0 bg-white z-50 flex items-center justify-around border-t border-grey-light">
            {
                navs.map( ( nav, index ) => (
                    <NavLink to={ nav.to }
                             key={ index }
                             className={ ( { isActive, isPending } ) => {
                                 const state = isPending ? 'pending' : isActive ? 'text-primary-400' : '';
                                 return twMerge( 'w-[52px] h-[52px] flex flex-col items-center justify-center flex-none text-grey gap-y-1', state );
                             } }>
                        {
                            ( { isActive, isPending } ) => (
                                <div className="w-fit">
                                    { nav.icon }
                                    <Divider className={ twMerge( 'w-full h-[2px] rounded-full m-0 mt-2 p-0', isActive
                                                                                                              ? 'block bg-primary-500'
                                                                                                              : 'hidden' ) } />
                                </div>
                            )
                        }
                    </NavLink>
                ) )
            }
        </div>
    );
}