import { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';

import { FreelancersRepository } from '@/components/freelancers-filter-items/freelancersRepository';
import { Freelancer } from '@/gql/graphql';
import { privateFreelancersGQL } from '@/gql/global-queries/privateFreelancersGQL';
import FavoritesHook from '@/hooks/favoritesHook';
import { useObservable } from '@ngneat/react-rxjs';

interface PrivateFreelancersHookProps {
    freelancersRepository: FreelancersRepository;
}

export default function privateFreelancersHook(props: PrivateFreelancersHookProps) {

    const { freelancersRepository } = props;

    const [ freelancers ] = useObservable( freelancersRepository.freelancers$ );
    const [ countFilterActive] = useObservable( freelancersRepository.updatedFiltersCount$ );
    const [ totalFreelancers ] = useObservable( freelancersRepository.totalFreelancers$ );
    const [ hasMore ] = useObservable( freelancersRepository.hasMore$ );
    const { add, remove } = FavoritesHook();

    const freelancersQuery = useLazyQuery( privateFreelancersGQL, {
            notifyOnNetworkStatusChange: true
        }
    );

    useEffect( () => {
        if( freelancersQuery[ 1 ].data ) {
            const result = freelancersQuery[ 1 ].data.privateFreelancers;
            freelancersRepository.addFreelancers( result.data, result.page, result.total, result.lastPage );
        }
    }, [ freelancersQuery[ 1 ].data ] );

    const load = async(): Promise<void> => {
        if( freelancers.length > 0 ) {
            return;
        }

        await freelancersQuery[ 0 ]( {
            variables: {
                args: {
                    ...freelancersRepository.getFiltersForQuery()
                },
                limit: freelancersRepository.perPage,
                page: 1
            }
        } );
    };

    const loadMore = async( { resetFilters = false, resetPage = false, resetData = false } = {} ): Promise<void> => {
        if( resetFilters ) {
            freelancersRepository.resetFilters();
        }

        if( resetPage ) {
            freelancersRepository.setPage(0);
        }

        if( resetData ) {
            freelancersRepository.resetFreelancers();
        }

        if( freelancersQuery[ 1 ]?.data?.privateFreelancers?.page != null && !resetData && !resetPage && !resetFilters ) {
            freelancersQuery[ 1 ].refetch( {
                args: {
                    ...freelancersRepository.getFiltersForQuery()
                },
                limit: freelancersRepository.paginationData.perPage,
                page: resetPage ? 1 : freelancersRepository.paginationData.currentPage + 1
            } );
        }
        else {
            await load();
        }
    };

    const handleApplyFilters = async() => {
        freelancersRepository.isOpen = false;
        await loadMore( { resetFilters: false, resetPage: true, resetData: true } );
    };

    const handleResetFilters = async() => {
        freelancersRepository.isOpen = false;
        await loadMore( { resetFilters: true, resetPage: true, resetData: true } );
    };

    const handleLike = ( freelancer: Freelancer ): void => {
        add( freelancer.id );
        updateLikeInFreelancerList( freelancer.id, true );
    };

    const handleDislike = ( freelancer: Freelancer ): void => {
        remove( freelancer.id );
        updateLikeInFreelancerList( freelancer.id, false );
    };

    const updateLikeInFreelancerList = ( freelancerId: string, isFavorite: boolean ) => {
        freelancersRepository.updateIsFavorite( freelancerId, isFavorite );
    };

    return {
        loading: freelancersQuery[ 1 ].loading,
        error: freelancersQuery[ 1 ].error,
        freelancers,
        totalFreelancers,
        load,
        loadMore,
        hasMore,
        handleApplyFilters,
        handleResetFilters,
        handleLike,
        handleDislike,
        countFilterActive
    };
}