import { forwardRef, useEffect, useImperativeHandle } from 'react';
import InputComponent from '@/components/custom-element-form/InputComponent';
import CheckPasswordValidity from '@/components/CheckPasswordValidity';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Button, Divider } from 'antd';
import { GoogleLogoIcon } from '@/assets/icons/CustomIcons';
import googleConnectHook from '@/hooks/googleConnectHook';
import { SignUpClientRepository } from '@/pages/auth/sign-up/sign-up-client/signUpClientRepository';
import { inputEmailValidationUnique } from '@/utils/customEmailVerification';
import emailVerificationHook from '@/hooks/emailVerificationHook';

const SignUpClientStepAuthentication = forwardRef( ( props, ref ) => {

    const { t } = useTranslation( 'auth' );
    const { trigger, getValues, watch, control, formState: { errors } } = useForm();
    const repository = new SignUpClientRepository();

    useImperativeHandle( ref, () => ( {
        async validateFields(): Promise<boolean> {
            repository.isLoading = true;
            const isValid: boolean = await trigger();

            if( isValid ) {
                repository.email = getValues( 'email' );
                repository.password = getValues( 'password' );
            }

            repository.isLoading = false;
            return isValid;
        }
    } ) );

    const { isAvailable } = emailVerificationHook();

    const { getCode, isLoading, code } = googleConnectHook( {
        scopes: [
            'email',
            'profile',
            'openid'
        ]
    } );

    useEffect( () => {
        if( code ) {
            repository.code = code;
            repository.step = 1;
        }
    }, [ code ] );

    const passwordWatch = watch( 'password' );

    return (
        <form className="flex flex-col gap-6 w-full mt-6">
            <InputComponent control={ control }
                            name="email"
                            label={ t( 'auth:sign-up.client.inputs.email' ) }
                            placeholder={ t( 'auth:sign-up.client.inputs.emailPlaceholder' ) }
                            rules={ {
                                required: t( 'common:error.form.required' ),
                                pattern: {
                                    value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                                    message: t( 'common:error.form.invalidEmail' )
                                },
                                validate: {
                                    unique: ( value ) => inputEmailValidationUnique( value, isAvailable, t( 'auth:sign-up.emailAlreadyUsed' ) )
                                }
                            } }
                            errors={ errors } />

            <div className="flex flex-col gap-y-4">
                <InputComponent control={ control }
                                name="password"
                                type="password"
                                label={ t( 'auth:sign-up.client.inputs.password' ) }
                                placeholder={ t( 'auth:sign-up.client.inputs.passwordPlaceholder' ) }
                                rules={ {
                                    required: t( 'common:error.form.required' ),
                                    pattern: {
                                        value: /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[~`!@#$%^&*\(\)_\-+=\[\]{}|;:'",.<>?/]).*$/,
                                        message: t( 'common:error.form.invalidPassword' )
                                    }
                                } }
                                errors={ errors } />

                <CheckPasswordValidity password={ passwordWatch } />
            </div>

            <Divider> { t( 'auth:sign-up.client.authentication-page.or' ) }</Divider>

            <Button className="w-full flex items-center justify-center gap-x-3"
                    loading={ isLoading }
                    onClick={ () => getCode() }>
                <GoogleLogoIcon className="text-xl h-5 w-5" />
                <span className="font-normal">
                    { t( 'auth:sign-up.client.authentication-page.googleButton' ) }
                </span>
            </Button>
        </form>
    );
} );

export default SignUpClientStepAuthentication;