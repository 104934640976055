import { gql } from '@apollo/client';

export const ClientUpdateCompanyDetailsGQL = gql`
    mutation ClientUpdateCompanyDetailsGQL($args: UpdateClientCompanyDetailsInput!) {
        updateClientCompanyDetails(args: $args) {
            id
            addressName
            addressStreet1
            addressStreet2
            addressCity
            addressState
            addressPostalCode
            addressCountry
        }
    }`;


