import { useTranslation } from 'react-i18next';
import { SignUpFreelancerRepository } from '@/pages/auth/sign-up/sign-up-freelancer//signUpFrelancerRepository';
import SelectMultiTags from '@/components/SelectMultiTags';
import { useQuery } from '@apollo/client';
import { languagesGQL } from '@/gql/global-queries/languagesGQL';

export default function SignUpFreelancerStepLanguage() {
    const { t } = useTranslation();
    const repository = new SignUpFreelancerRepository();
    const languagesQuery = useQuery( languagesGQL );

    return (
        <SelectMultiTags defaultSelectedIds={ repository.languageIds || [] }
                         tags={ languagesQuery?.data?.languages?.map( ( language ) => ( {
                             id: language.id,
                             label: t( 'translation:enum.languages.' + language.i18n.toUpperCase() )
                         } ) ) }
                         onTagsSelected={ ( languageIds ) => repository.languageIds = languageIds }
                         loading={ languagesQuery.loading } />
    );
}
