import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useMatch, useNavigate } from 'react-router-dom';
import NewProjectHook from '@/pages/client/views/new-project/components/new-project/NewProjectHook';
import { Divider, Skeleton } from 'antd';
import { Channel } from '@/gql/graphql';
import ResponsiveModal from '@/components/ResponsiveModal';
import NewProjectForm from '@/pages/client/views/new-project/components/NewProjectForm';

interface NewProjectDesktopProps {
    freelancer: {
        id: string;
        firstName?: string;
    };
    onClose?: () => void;
}

const NewProjectModal = forwardRef( ( props: NewProjectDesktopProps, ref ) => {
    const { freelancer, onClose } = props;

    const { t } = useTranslation();
    const navigate = useNavigate();

    const { channel, setChannel, freelancerFirstName, loading, error } = NewProjectHook( { freelancer } );
    const modalRef = useRef( null );

    const match = useMatch( '/client/messages/:conversationId' );

    useImperativeHandle( ref, () => ( {
            open() {
                modalRef.current.open();
            },
            close() {
                handleClose();
            }
        } )
    );

    const handleOnProjectCreated = ( project ) => {
        onClose?.();

        if( !match ) {
            navigate( `/client/messages/${ project.conversationId }` );
        }

    };

    const handleSwitchChannel = ( channel: Channel ): void => {
        modalRef.current.open();

        setChannel( channel );
    };

    const handleOnOpenChannelSwitcher = (): void => modalRef.current.close();

    const handleOnCloseChannelSwitcher = (): void => modalRef.current.open();

    const handleClose = () => modalRef.current.close();

    return (
        <ResponsiveModal ref={ modalRef }
                         onClose={ () => handleClose() }
                         title={ t( 'transverse:new-project.title' ) }
                         width={ 420 }
                         mobileHeight="fit-content"
                         destroyOnClose={ false }>
            <Divider />
            {
                freelancer == null ?
                <Skeleton active={ true } /> :
                <NewProjectForm channel={ channel }
                                onProjectCreated={ ( project ) => handleOnProjectCreated( project ) }
                                isLoadingChannel={ loading }
                                onChangeChannel={ ( channel ) => handleSwitchChannel( channel ) }
                                onOpenChannelSwitcher={ () => handleOnOpenChannelSwitcher() }
                                onCloseChannelSwitcher={ () => handleOnCloseChannelSwitcher() }
                                freelancer={ {
                                    id: freelancer?.id,
                                    firstName: freelancerFirstName
                                } } />
            }
        </ResponsiveModal>
    );
} );
export default NewProjectModal;