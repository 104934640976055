import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { UpdatePasswordGQL } from '@/gql/global-queries/updatePasswordGQL';
import { useForm } from 'react-hook-form';
import { Button, message } from 'antd';
import React, { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import InputComponent from '@/components/custom-element-form/InputComponent';
import CheckPasswordValidity from '@/components/CheckPasswordValidity';
import { PageName, trackPageView } from '@/utils/analytics';

export default function ClientAccountEditPassword() {
    const { t } = useTranslation();
    const [ messageApi, contextHolder ] = message.useMessage();

    const updatePasswordGQL = useMutation( UpdatePasswordGQL );

    const {
              trigger,
              control,
              watch,
              formState: { errors },
              handleSubmit
          } = useForm();

    const newPasswordValue = watch( 'newPassword' );

    const onSubmit = async( data ): Promise<void> => {
        if( !( await trigger() ) ) {
            return;
        }

        try {
            await updatePasswordGQL[ 0 ]( {
                variables: {
                    args: {
                        newPassword: data.newPassword
                    }
                }
            } );
            messageApi.success( {
                content: t( 'client:account.edit-password.inputs.success' ),
                duration: 4
            } );
            control._reset();
        } catch( e ) {
            Sentry.captureException( 'An error occur when trying to edit password', e );
            messageApi.error( t( 'error.default' ) );
        }
    };

    useEffect( () => {
        trackPageView( PageName.ClientEditPassword );
    }, [ ] );

    return (
        <div className="w-full flex flex-col">
            { contextHolder }
            <h1>{ t( 'client:account.edit-password.title' ) }</h1>

            <form className="flex flex-col gap-5 w-full mt-6"
                  onSubmit={ handleSubmit( onSubmit ) }>
                <InputComponent control={ control }
                                type="password"
                                name="newPassword"
                                label={ t( 'client:account.edit-password.inputs.new-password.input' ) }
                                placeholder={ t( 'client:account.edit-password.inputs.new-password.placeholderNewPassword' ) }
                                rules={ {
                                    required: t( 'common:error.form.required' ),
                                    pattern: {
                                        value: /^(?=.*[a-zA-Z])(?=.*\d)(?=.*\W).{8,}$/,
                                        message: t( 'client:account.edit-password.inputs.new-password.validation' )
                                    }
                                } }
                                errors={ errors } />

                <InputComponent control={ control }
                                type="password"
                                name="confirmNewPassword"
                                label={ t( 'client:account.edit-password.inputs.new-password.input' ) }
                                placeholder={ t( 'client:account.edit-password.inputs.new-password.placeholderConformiation' ) }
                                rules={ {
                                    required: t( 'common:error.form.required' ),
                                    validate: ( value ) =>
                                        value === newPasswordValue || 'Passwords do not match'
                                } }
                                errors={ errors } />

                <CheckPasswordValidity password={ newPasswordValue } />

                <div className="flex lg:justify-end">
                    <Button type="primary"
                            htmlType="submit"
                            loading={ updatePasswordGQL[ 1 ].loading }
                            className="mt-5 w-full lg:w-[100px]">
                        { t( 'client:account.edit-password.inputs.buttonSave' ) }
                    </Button>
                </div>
            </form>
        </div>
    );
}