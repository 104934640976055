import { gql } from '@apollo/client';

export const CreatePaymentGQL = gql`
    mutation CreatePaymentGQL($args: CreatePaymentInput! ) {
        createPayment( args: $args ) {
            id
            status
        }
    }
`;
