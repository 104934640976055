import { useTranslation } from 'react-i18next';
import { useContext, useMemo, useState } from 'react';
import { Switch } from 'antd';
import { FreelancersRepository } from '@/components/freelancers-filter-items/freelancersRepository';
import { FreelancersRepositoryContext } from '@/core/FreelancerRepositoryContext';

export default function FilterItemAvailabilityButton() {

    const { t } = useTranslation();
    const freelancersRepository: FreelancersRepository = useContext( FreelancersRepositoryContext );

    const [ isAvailable, setIsAvailable ] = useState( freelancersRepository.isAvailable );

    useMemo( () => {
        const observable = freelancersRepository.isAvailable$.subscribe( ( value ) => {
            setIsAvailable( value );

        } );
        return () => observable.unsubscribe();
    }, [] );

    const onChangeAvailability = ( checked: boolean ) => {
        freelancersRepository.setAvailable( checked ? true : null );
    };

    return (
        <>
            <div className="font-sharp font-bold text-base">
                { t( 'transverse:freelancers.filters.availability.title' ) }
            </div>

            <div className="flex justify-between mt-1">
                <div className="text-xs text-grey">
                    { t( 'transverse:freelancers.filters.availability.description' ) }
                </div>
                <Switch className="primary"
                        checked={ isAvailable }
                        onChange={ onChangeAvailability } />
            </div>
        </>
    );
}