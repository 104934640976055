import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import ResponsiveModal from '@/components/ResponsiveModal';
import { App, Button, Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import { phoneNumberVerificationHook } from '@/hooks/phoneNumberVerificationHook';
import PhoneNumberInputs from '@/components/PhoneNumberInputs';
import { useForm } from 'react-hook-form';
import phoneNumberFromForm from '@/utils/phoneNumberFromForm';

interface PhoneNumberMissingModalProps {
    onCodeSent?: ( phoneNumber: string ) => void;
}

const PhoneNumberModal = forwardRef( ( props: PhoneNumberMissingModalProps, ref ) => {

    const { onCodeSent } = props;

    const { t } = useTranslation( [ 'common' ] );
    const { message } = App.useApp();

    const {
              trigger,
              control,
              watch,
              formState: { errors },
              handleSubmit
          } = useForm();

    const modalRef = useRef( null );

    const [ phoneNumberFormatted, setPhoneNumberFormatted ] = useState<string>( null );

    const { sendCode, isSendingNewCode } = phoneNumberVerificationHook( {
        lastCodeSentAt: null,
        phoneNumber: phoneNumberFormatted
    } );

    useImperativeHandle( ref, () => ( {
        open: openModal,
        close: closeModal
    } ) );

    const openModal = () => modalRef?.current?.open();

    const closeModal = () => modalRef?.current?.close();

    const onSubmit = async( data ): Promise<void> => {
        if( !( await trigger() ) ) {
            return;
        }

        const phoneNumberFormatted: string = phoneNumberFromForm( data.phoneNumberSelect, data.phoneNumber );

        if( phoneNumberFormatted == null ) {
            message.error( t( 'common:phoneNumber.verification-code-modal.invalidPhoneNumber' ) );
            return;
        }

        await sendCode( phoneNumberFormatted );
        onCodeSent?.( phoneNumberFormatted );
        modalRef.current.close();
    };

    return (
        <ResponsiveModal ref={ modalRef }
                         desktopWidth="600px"
                         mobileHeight="h-fit"
                         destroyOnClose={ true }
                         closable={ false }
                         title={ t( 'common:phoneNumber.verification-code-modal.title' ) }>
            <Divider className="mt-0" />
            <form>
                <PhoneNumberInputs control={ control }
                                   defaultValueSelect="US"
                                   inputName="phoneNumber"
                                   selectName="phoneNumberSelect"
                                   errors={ errors }
                                   watch={ watch } />

                <Divider />
                <Button type="primary"
                        className="w-full"
                        onClick={ handleSubmit( onSubmit ) }
                        disabled={ isSendingNewCode }
                        loading={ isSendingNewCode }>
                    { t( 'common:phoneNumber.verification-code-modal.verifyButton' ) }
                </Button>
            </form>
        </ResponsiveModal>
    );
} );

export default PhoneNumberModal;