import { UserTypeEnum } from '@/gql/graphql';
import { useTranslation } from 'react-i18next';
import ChatConversationItemStatusTemplate, { ChatConversationItemStatusTemplateUI } from '@/components/chat/components/chat-conversation/chat-conversation-messages/components/chat-conversation-items/components/ChatConversationItemStatusTemplateUI';
import { useEffect, useState } from 'react';
import { ItemActionEnum } from '@/components/chat/enums/itemActionEnum';
import { ConversationItemActionQuote } from '@/components/chat/interfaces/conversationItemActionQuote';
import { ConversationItemActionOffer } from '@/components/chat/interfaces/conversationItemActionOffer';
import { DateTime } from 'luxon';

interface ChatConversationItemActionProps {
    item: ConversationItemActionQuote | ConversationItemActionOffer;
}

export default function ChatConversationItemAction( { item }: ChatConversationItemActionProps ) {

    const { t } = useTranslation();
    const [ message, setMessage ] = useState<ChatConversationItemStatusTemplateUI | null>( null );

    useEffect( () => {
        if( item != null ) {
            setMessage( getMessage( item.userType, item.actionType ) );
        }
    }, [ item ] );

    const getMessage = ( userType: UserTypeEnum, action: ItemActionEnum ) => {
        return messages.find( message => message.userType === userType && message.status == action );
    };

    const messages = [
        {
            userType: UserTypeEnum.FREELANCER,
            status: 'PROJECT_DECLINE',
            icon: '✖',
            sentAt: DateTime.fromISO( item.sentAt ).toLocaleString( DateTime.TIME_SIMPLE ),
            text: t( 'common:chat.conversation.items.status.projectDeclined.freelancer' )
        },
        {
            userType: UserTypeEnum.FREELANCER,
            status: 'PROJECT_DECLINE',
            icon: '✖',
            sentAt: DateTime.fromISO( item.sentAt ).toLocaleString( DateTime.TIME_SIMPLE ),
            text: t( 'common:chat.conversation.items.status.projectDeclined.client', { freelancerName: item.freelancerName } )
        },
        {
            userType: UserTypeEnum.FREELANCER,
            status: 'PROJECT_START',
            sentAt: DateTime.fromISO( item.sentAt ).toLocaleString( DateTime.TIME_SIMPLE ),
            icon: '🎉',
            text: t( 'common:chat.conversation.items.status.projectAccepted.freelancer', { clientName: item.clientName } ),
            button: t( 'common:chat.conversation.items.status.projectAccepted.button' ),
            goTo: `/freelancer/projects/${ item.projectId }`
        },
        {
            userType: UserTypeEnum.CLIENT,
            status: 'PROJECT_START',
            icon: '🎉',
            sentAt: DateTime.fromISO( item.sentAt ).toLocaleString( DateTime.TIME_SIMPLE ),
            text: t( 'common:chat.conversation.items.status.projectAccepted.client', { projectName: item.projectName } ),
            button: t( 'common:chat.conversation.items.status.projectAccepted.button' ),
            goTo: `/client/projects/${ item.projectId }`
        }
    ];

    return (
        <ChatConversationItemStatusTemplate data={ message } />
    );
}