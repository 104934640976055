import SelectComponent from '@/components/custom-element-form/SelectComponent';
import { useTranslation } from 'react-i18next';
import InputComponent from '@/components/custom-element-form/InputComponent';
import getFlagEmoji from '@/utils/flagEmoji';
import { getCountries, getCountryCallingCode, isValidPhoneNumber } from 'libphonenumber-js';
import { SelectProps } from 'antd';

interface PhoneNumberInputsProps {
    control: any;
    inputName: string;
    selectName: string;
    defaultValueSelect?: string;
    defaultValueInput?: string;
    errors: any;
    watch: any;
}

export default function PhoneNumberInputs( {
                                               control,
                                               selectName,
                                               inputName,
                                               defaultValueInput,
                                               defaultValueSelect,
                                               errors,
                                               watch
                                           }: PhoneNumberInputsProps ) {

    const { t } = useTranslation();
    const selectedCountryCode = watch( selectName );

    const getSelectOptions = (): SelectProps['options'] => {
        return getCountries()?.map( ( countryCode ) => {
            return {
                value: countryCode,
                label: `${ getFlagEmoji( countryCode ) } +${ getCountryCallingCode( countryCode ) }`
            };
        } ).sort( ( a, b ) => a.value.localeCompare( b.value ) );
    };

    const selectOptions = getSelectOptions();

    const filterOption = ( inputValue, option ) => {
        return option?.label.toLowerCase().includes( inputValue.toLowerCase() );
    };

    const isPhoneNumberValid = ( value ) => {
        if( !selectedCountryCode ) {
            return false;
        }

        const fullPhoneNumber: string = `+${ getCountryCallingCode( selectedCountryCode ) }${ value }`;
        return isValidPhoneNumber( fullPhoneNumber );
    };

    return (
        <div className="flex items-center justify-center gap-x-2">
            <SelectComponent options={ selectOptions }
                             defaultValue={ defaultValueSelect ?? 'US' }
                             control={ control }
                             filterOption={ filterOption }
                             name={ selectName }
                             className="max-w-[154px]"
                             label={ t( 'common:form.phoneNumberLabel' ) }
                             rules={ {
                                 required: t( 'common:error.form.required' )
                             } }
                             placeholder={ t( 'common:form.phoneNumberPlaceholder' ) }
                             errors={ errors } />

            <InputComponent control={ control }
                            name={ inputName }
                            defaultValue={ defaultValueInput }
                            label={ t( 'common:form.phoneNumberLabel' ) }
                            rules={ {
                                required: t( 'common:error.form.required' ),
                                validate: {
                                    valid: ( value ) => isPhoneNumberValid( value )
                                }
                            } }
                            placeholder={ t( 'common:form.phoneNumberPlaceholder' ) }
                            errors={ errors } />
        </div>
    );
}