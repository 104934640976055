import { NavLink } from 'react-router-dom';
import { Logo } from '@/assets/icons/Logo';

interface HeaderProps {
    logoTo?: string;
    left?: JSX.Element;
    right?: JSX.Element;
}

export default function Header( { logoTo, left, right }: HeaderProps ) {

    return (
        <header className="fixed bg-white w-full flex items-center justify-between py-4 px-4 lg:px-10 h-[72px] border-b border-grey-light z-50">
            <div className="flex items-center justify-center">
                <NavLink to={ logoTo }
                         className="flex justify-center items-center">
                    <Logo className="text-black h-8 lg:h-10" />
                </NavLink>
                <div className="ml-2 lg:ml-16 flex justify-center items-center">
                    { left }
                </div>
            </div>

            <div className="h-full flex items-center justify-end">
                { right }
            </div>
        </header>
    );
}