import { gql } from '@apollo/client';

export const expertisesWithCountGQL = gql`
    query Expertises {
        expertises {
            id
            i18n
            name
            totalFreelancer
        }
    }
`;