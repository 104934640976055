import { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { projectGQL } from '@/pages/client/views/project/services/projectGQL';
import { freelancerPreviewProfileGQL } from '@/gql/global-queries/freelancerPreviewProfileGQL';
import { Freelancer, Project, User, UserTypeEnum } from '@/gql/graphql';
import { DateTime } from 'luxon';

interface ProjectHookProps {
    projectId: string;
}

export default function projectHook( { projectId }: ProjectHookProps ) {

    const [ project, setProject ] = useState<Project>( null );
    const [ freelancer, setFreelancer ] = useState<Freelancer>( null );
    const [ overallLoading, setOverallLoading ] = useState<boolean>( false );

    const projectQuery = useLazyQuery( projectGQL );
    const freelancerQuery = useLazyQuery( freelancerPreviewProfileGQL );


    const error = projectQuery[ 1 ].error || freelancerQuery[ 1 ].error;

    useEffect( () => {
        if( projectId != null ) {
            setOverallLoading( true ); // Set loading state when initiating first query
            projectQuery[ 0 ]( {
                variables: {
                    id: projectId
                }
            } );
        }
    }, [ projectId ] );

    useEffect( () => {
        const data = projectQuery[ 1 ].data;
        if( data != null ) {
            setProject( data.project );
            const freelancer: User = data.project?.users?.find( ( user: User ) => user.type == UserTypeEnum.FREELANCER );
            if( freelancer != null ) {
                freelancerQuery[ 0 ]( {
                    variables: {
                        id: freelancer.id
                    }
                } );
            }
            else {
                setOverallLoading( false ); // If there's no freelancer, set loading state to false
            }
        }
    }, [ projectQuery[ 1 ].data ] );

    useEffect( () => {
        const data = freelancerQuery[ 1 ].data;
        if( data ) {
            setFreelancer( data.publicFreelancer );
            setOverallLoading( false ); // Set loading state to false when the freelancer data is fetched
        }
    }, [ freelancerQuery[ 1 ].data ] );

    const convertDateIntoShortFormat = ( date: Date ) => {
        return DateTime.fromISO( date ).toLocaleString( DateTime.DATE_SHORT );
    };

    return {
        project,
        freelancer,
        isLoading: overallLoading,
        isLoadingFreelancer: freelancerQuery[ 1 ].loading,
        isLoadingProject: projectQuery[ 1 ].loading,
        convertDateIntoShortFormat,
        setProject,
        error
    };
}