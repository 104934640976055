import channelsHook from '@/hooks/channelsHook';
import ListSkeleton from '@/components/ListSkeleton';
import ChannelItem from '@/components/ChannelItem';
import { Button } from 'antd';
import { PlusOutlinedIcon } from '@/assets/icons/CustomIcons';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import NoDataIcon from '@/components/NoDataIcon';
import { twMerge } from 'tailwind-merge';
import { trackSegmentEvent, SegmentEvent } from '@/utils/analytics';

interface YoutubeChannelListManagementProps {
    buttonPlacement: 'left' | 'centered' | 'right';
}

export default function YoutubeChannelListManagement( { buttonPlacement }: YoutubeChannelListManagementProps ) {

    const { t } = useTranslation( 'client' );

    const { loading, getChannels, channels, addYTChannelHook, handleAddChannel } = channelsHook();

    const buttonPlacementClass = buttonPlacement == 'left' ? 'lg:justify-start' : buttonPlacement == 'centered'
                                                                                  ? 'lg:justify-center'
                                                                                  : 'lg:justify-end';

    const addChannel = () => {
        trackSegmentEvent( SegmentEvent.AddChannelClicked );
        handleAddChannel();
    }

    useEffect( () => {
        getChannels();
    }, [] );

    const loadingJSX = (
        <ListSkeleton isLoading={ true }
                      height={ 76 }
                      count={ 3 } />
    );

    const loadingNewYTChannelJSX = (
        <ListSkeleton isLoading={ true }
                      height={ 76 }
                      count={ 1 } />
    );

    const coreJSX = (
        <>
            {
                channels?.map( ( channel, index ) => {
                    return (
                        <ChannelItem channel={ channel }
                                     avatarSize={ 50 }
                                     className="border border-grey-light rounded-2xl py-3 px-4"
                                     titleClass="hover:text-black group-hover:text-black"
                                     key={ index } />
                    );
                } )
            }
            {
                addYTChannelHook.loading ? loadingNewYTChannelJSX : null
            }
        </>
    );

    if( channels?.length == 0 ) {
        return (
            <div className="h-full w-full flex flex-col mt-14">
                <div className="flex items-center justify-center">
                    <NoDataIcon icon={ <></> }
                                message={ t( 'common:youtubeChannel.noChannel' ) }
                                subMessage={ t( 'common:youtubeChannel.noChannelDescription' ) } />
                </div>
                <div className="flex lg:justify-end mt-14">
                    <Button type="primary"
                            onClick={ addChannel }
                            loading={ addYTChannelHook.loading }
                            className="mt-4 w-full lg:w-fit">
                        <PlusOutlinedIcon /> { t( 'client:account.youtube-channels.button' ) }
                    </Button>
                </div>
            </div>
        );
    }

    return (
        <div className="h-full w-full flex flex-col">
            <div className="mt-5 w-full flex flex-col gap-y-4">
                {
                    loading ? loadingJSX : coreJSX
                }
            </div>

            <div className={ twMerge( `flex mt-6`, buttonPlacementClass ) }>
                <Button type="primary"
                        onClick={ addChannel }
                        loading={ addYTChannelHook.loading }
                        className="mt-4 w-full lg:w-fit">
                    <PlusOutlinedIcon /> { t( 'client:account.youtube-channels.button' ) }
                </Button>
            </div>
        </div>
    );
}