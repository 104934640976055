import React from 'react';
import FreelancerActionItemLayout from './FreelancerActionItemLayout';
import FreelancerActionItemProjectDetail from './FreelancerActionItemProjectDetail';
import { Trans, useTranslation } from 'react-i18next';
import { CheckFilledIcon } from '@/assets/icons/CustomIcons';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { Action } from '@/gql/graphql';

interface ProjectInProgressItemProps {
    action: Action;
}

const ProjectInProgressItem: React.FC<ProjectInProgressItemProps> = ({ action }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <div className='relative'>
            <div className="bg-emerald-400 h-10 w-[3px] absolute left-0 top-4" />
            <FreelancerActionItemLayout
                title={ t( 'freelancer:dashboard.actionCenter.action.projectInProgressTitle' ) }
                datetime={ action.createdAt }
                icon={
                    <CheckFilledIcon className='text-emerald-400 text-xl border-2 border-white rounded-full' />
                }
                clientProfilePicture={ action.project.client?.profilePicture }>
                <div className='flex flex-col gap-4' >
                    <span className='text-slate-600'>
                        <Trans 
                            i18nKey="freelancer:dashboard.actionCenter.action.projectInProgressDesc" 
                            components={[<strong />]} 
                            values={{ creatorName: action.project.client?.userName }}
                        />
                    </span>
                    <FreelancerActionItemProjectDetail project={ action.project } />
                    <span className='text-slate-600'>
                        <Trans 
                            i18nKey="freelancer:dashboard.actionCenter.action.projectInProgressIndication" 
                            components={[<strong className='text-gray-600' />]} 
                        />
                    </span>
                    <Button type='default' className='w-fit text-primary-500' onClick={ () => navigate(`/freelancer/projects/status/ongoing?projectId=${ action.project.id }`) }>
                        { t( 'freelancer:dashboard.actionCenter.action.button.markAsCompleted' ) }
                    </Button>
                </div>
            </FreelancerActionItemLayout>
        </div>

    );
}

export default ProjectInProgressItem;
