import { useMediaQuery } from 'react-responsive';
import ChatConversationHook from '@/components/chat/hooks/ChatConversationHook';
import ChatConversation from '@/components/chat/components/chat-conversation/chat-conversation/ChatConversation';
import React, { useEffect, useRef } from 'react';
import LayoutVisibilityHook from '@/hooks/layoutVisibilityHook';
import { useTranslation } from 'react-i18next';
import { AddMissionOutlinedIcon } from '@/assets/icons/CustomIcons';
import NoDataIcon from '@/components/NoDataIcon';
import ChatConversationSkeleton from '@/components/chat/components/chat-conversation/chat-conversation-messages/components/ChatConversationSkeleton';
import { Button } from 'antd';
import NewProjectModal from '@/pages/client/views/new-project/NewProjectModal';

export default function ClientConversation() {
    const isTabletOrMobile = useMediaQuery( { query: `(max-width: ${ import.meta.env.VITE_MOBILE_BREAK_POINT })` } );
    const { t } = useTranslation();
    const newProjectModalRef = useRef( null );

    const {
              conversationItems,
              hasMore,
              isLoadingMessages,
              isLoadingConversation,
              receiver,
              receiverId,
              isOnline,
              isReceiverTyping,
              handleOnLoadMore,
              handleOnMessageSend,
              handleOnTypingChange
          } = ChatConversationHook();

    const { refreshLayoutVisibility } = LayoutVisibilityHook( {
        headerVisible: '!isTabletOrMobile',
        bottomNavBarVisible: false
    } );

    useEffect( () => {
        refreshLayoutVisibility( {
            headerVisible: '!isTabletOrMobile',
            bottomNavBarVisible: false
        } );
    }, [] );

    const handleOnOpenOfferJobModal = () => newProjectModalRef.current?.open();

    const handleOnCloseOfferJobModal = () => newProjectModalRef.current?.close();

    const customHeaderTouch = (
        <Button shape="circle"
                type="primary"
                size="small"
                onClick={ () => handleOnOpenOfferJobModal() }>
            <AddMissionOutlinedIcon />
        </Button>
    );

    const customHeaderDesktop = (
        <Button type="primary"
                size="small"
                className="px-4"
                onClick={ () => handleOnOpenOfferJobModal() }>
            <AddMissionOutlinedIcon />
            { t( 'client:chat.conversation.buttons.newProject' ) }
        </Button>
    );


    if( isLoadingConversation ) {
        return <ChatConversationSkeleton />;
    }

    if( conversationItems.length === 0 ) {
        return (
            <div className="mt-[10%] flex justify-center items-center">
                <NoDataIcon message={ t( 'error.noConversation' ) } />
            </div>
        );
    }

    return (
        <>
            <ChatConversation conversationItems={ conversationItems }
                              hasMore={ hasMore }
                              isLoading={ isLoadingMessages }
                              receiver={ receiver }
                              enableClickOnProfile={ true }
                              customHeader={ isTabletOrMobile ? customHeaderTouch : customHeaderDesktop }
                              isOnline={ isOnline }
                              isReceiverTyping={ isReceiverTyping }
                              handleOnLoadMore={ () => handleOnLoadMore() }
                              handleOnMessageSend={ ( e ) => handleOnMessageSend( e ) }
                              handleOnTypingChange={ ( e ) => handleOnTypingChange( e ) } />
            {
                <NewProjectModal ref={ newProjectModalRef }
                                 freelancer={ { id: receiverId, firstName: receiver?.userName } }
                                 onClose={ () => handleOnCloseOfferJobModal() } />
            }
        </>
    );

}