import { ReviewUI } from './components/freelancer-profile-reviews/reviewUI';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import FavoritesHook from '@/hooks/favoritesHook';
import FreelancerProfileReviewList from './components/freelancer-profile-reviews/FreelancerProfileReviewList';
import FreelancerRatingTag from '@/components/freelance-profile/components/FreelancerRatingTag';
import { Button } from 'antd';
import FreelancerProfileHeader from '@/components/freelance-profile/components/FreelancerProfileHeader';
import FreelancerPortfolioStats from '@/components/freelance-profile/components/FreelancerPortfolioStats';
import FreelancerProfileCreatorList from '@/components/freelance-profile/components/FreelancerProfileCreatorList';
import { Freelancer, PortfolioChannel, PortfolioElementStatsOutput, Skill } from '@/gql/graphql';
import FreelancerProfilePortfolioList from '@/components/freelance-profile/components/FreelancerProfilePortfolioList';
import Paragraphs from '@/components/Paragraphs';
import FreelancerProfileTagList from '@/components/freelance-profile/components/FreelancerProfileTagList';
import SkeletonInput from 'antd/es/skeleton/Input';

interface FreelancerProfileProps {
    freelancer: Freelancer & { portfolioElementsStats: PortfolioElementStatsOutput }

    reviews: ReviewUI[],
    isLoading: boolean;
    averageRate: number,
    isLoggedIn?: boolean;
    onHire?: () => void;
}

export default function FreelancerProfile( {
                                               isLoggedIn,
                                               isLoading,
                                               freelancer,
                                               onHire,
                                               reviews,
                                               averageRate
                                           }: FreelancerProfileProps ) {

    const { t } = useTranslation();

    const [ showAllReviews, setShowAllReviews ] = useState( false );
    let reviewsFiltered = reviews?.filter( review => review.comment?.trim().length );
    const reviewsToDisplay = showAllReviews ? reviewsFiltered : reviewsFiltered.slice( 0, 4 );

    const toggleReviews = () => setShowAllReviews( !showAllReviews );

    const { add, remove } = FavoritesHook();

    const handleLike = () => {
        add( freelancer.id );
        freelancer.isFavorite = true;
    };

    const handleDislike = () => {
        remove( freelancer.id );
        freelancer.isFavorite = false;
    };

    const handleHire = () => onHire?.();

    if( isLoading ) {
        return (
            <div className="flex flex-col items-center gap-y-12">
                <SkeletonInput active={ true }
                               className="w-full h-[210px] rounded-2xl" />
                <SkeletonInput active={ true }
                               className="w-full h-[900px] rounded-2xl" />
            </div>
        );
    }

    return (
        <div className="flex flex-col gap-y-12">
            <div className="rounded-3xl border border-blue-100 w-full p-4 lg:p-6">
                <FreelancerProfileHeader freelancer={ freelancer }
                                         onLike={ () => handleLike() }
                                         onDislike={ () => handleDislike() }
                                         onHire={ () => handleHire() }
                                         favouriteButton={ isLoggedIn } />
            </div>

            {

                <section>
                    <h2>{ t( 'common:freelancerProfile.card.portfolioStats' ) }</h2>
                    <div className="mt-4">
                        <FreelancerPortfolioStats stats={ {
                            countViews: freelancer?.portfolioElementsStats?.countViews | 0,
                            countCreators: freelancer?.creatorsIWorkedWith?.length | 0,
                            countLikes: freelancer?.portfolioElementsStats?.countLikes | 0,
                            countElements: freelancer?.portfolioElementsStats?.countElements | 0
                        } } />
                    </div>
                </section>
            }

            {
                freelancer?.creatorsIWorkedWith?.length > 0 &&
                <section>
                  <h2>
                      {
                          t( 'common:freelancerProfile.card.creatorIWorkWithTitleWithCount_other', {
                                  count: freelancer?.creatorsIWorkedWith?.length | 0
                              }
                          )
                      }
                  </h2>
                  <div className="mt-4">
                    <FreelancerProfileCreatorList channels={ freelancer?.creatorsIWorkedWith as PortfolioChannel[] }
                                                  gap={ 2 }
                                                  avatarSize={ 64 } />
                  </div>
                </section>
            }

            {
                freelancer?.portfolioElements?.length > 0 &&
                <section>
                  <h2>{ t( 'common:freelancerProfile.card.portfolioTitle', { count: freelancer?.portfolioElements?.length } ) }</h2>

                  <div className="mt-4">
                    <FreelancerProfilePortfolioList portfolio={ freelancer?.portfolioElements } />
                  </div>
                </section>
            }

            {
                freelancer?.bio &&
                <section>
                  <h2>{ t( 'common:freelancerProfile.card.bio' ) }</h2>

                  <div className="mt-4">
                    <Paragraphs text={ freelancer?.bio }
                                noText={ t( 'transverse:freelancers.profile.noBio' ) }
                                className="mt-6"
                                maxLength={ 1000 } />
                  </div>
                </section>
            }

            <section className="flex flex-col lg:flex-row gap-12">
                <div className="lg:max-x-1/2">
                    <h2>{ t( 'common:freelancerProfile.card.skillsTitle' ) }</h2>
                    <div className="mt-4">
                        <FreelancerProfileTagList tags={ freelancer?.skills?.map( ( skill: Skill ) => skill.i18n ) }
                                                  limit={ 4 }
                                                  className="text-sm px-2 py-1"
                                                  color="black" />
                    </div>
                </div>

                <div className="lg:max-x-1/2">
                    <h2>{ t( 'common:freelancerProfile.card.expertisesTitle' ) }</h2>
                    <div className="mt-4">
                        <FreelancerProfileTagList tags={ freelancer?.expertises.map( ( expertise ) => expertise.i18n ) }
                                                  limit={ 4 }
                                                  className="text-black text-sm px-2 py-1"
                                                  color="#C1DEFF" />
                    </div>
                </div>
            </section>
            {
                !!reviews?.length && averageRate &&
                <section>
                  <div className="flex items-center gap-2 mb-10">
                    <FreelancerRatingTag ratingClass={ 'font-sharp text-black' }
                                         rating={ averageRate } />
                    <span className="text-2xl font-extrabold">·</span>
                    <span className="text-grey">
                            {
                                t( 'common:freelancerProfile.review.review', {
                                        count: reviews?.length | 0
                                    }
                                )
                            }
                        </span>
                  </div>
                  <FreelancerProfileReviewList reviews={ reviewsToDisplay } />
                    {
                        reviewsFiltered?.length > 4 &&
                        <div className="flex mt-12">
                          <Button
                            onClick={ () => toggleReviews() }
                            type="default"
                            htmlType="submit"
                            className="w-full lg:w-[190px] mt-4 lg:mt-0">
                              {
                                  showAllReviews ?
                                  t( 'common:freelancerProfile.review.showLessReviews' )
                                                 :
                                  t( 'common:freelancerProfile.review.seeAllReviews' )
                              }
                          </Button>
                        </div>
                    }
                </section>
            }
        </div>
    );
};