import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { Progress } from 'antd';
import SkeletonInput from 'antd/es/skeleton/Input';
import { useTranslation } from 'react-i18next';
import { DollarCicleFilledIcon } from '@/assets/icons/CustomIcons';

interface FreelancerDashboardProfileIncompleteProps {
    completedPercentage: number;
}

const FreelancerDashboardProfileIncomplete = ({ completedPercentage }: FreelancerDashboardProfileIncompleteProps) => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <div className="flex flex-col gap-4">
            <div className="flex flex-col lg:flex-row items-center lg:items-start gap-7 rounded-xl border border-blue-100 py-6 p-8">
                <div className="w-[72px]">
                    {
                        completedPercentage && 
                        <Progress 
                            type="dashboard" 
                            percent={ completedPercentage }
                            size={ 72 }
                            gapDegree={ 1 }
                            strokeWidth={ 8 }
                            format={( percent ) => <span className='text-primary-500 font-bold'>{ percent }%</span>}
                        />
                    }
                </div>
                <div>
                    <span className='font-semibold text-base'>{t( 'freelancer:dashboard.profileIncomplete.title' )}</span>
                    <p className='text-grey mt-3 mb-5'>
                        {t( 'freelancer:dashboard.profileIncomplete.description' )}
                    </p>
                    <Button type="primary" className='bg-black' onClick={ () => navigate('/freelancer/settings/verify-profile') }>{t( 'freelancer:dashboard.profileIncomplete.complteProfileButton' )}</Button>
                </div>
            </div>
            <div className="flex flex-col lg:flex-row items-center lg:items-start gap-7 rounded-xl border border-blue-100 py-6 p-8">
                <div className="w-[72px]">
                    <DollarCicleFilledIcon className='p-1 text-[68px] text-emerald-500/30' />
                </div>
                <div>
                    <span className='font-semibold text-base'>{t( 'freelancer:dashboard.setUpPreferences.title' )}</span>
                    <p className='text-grey mt-3 mb-5'>
                        {t( 'freelancer:dashboard.setUpPreferences.description' )}
                    </p>
                    <Button type="primary" className='bg-black' onClick={ () => navigate('/freelancer/settings/edit-profile') }>{t( 'freelancer:dashboard.setUpPreferences.complteProfileButton' )}</Button>
                </div>
            </div>
        </div>
       
    );
};

export default FreelancerDashboardProfileIncomplete;