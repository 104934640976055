import { gql } from '@apollo/client';

export const ClientProfileGQL = gql`
    query ClientProfile($id: String!) {
        client(id: $id) {
            id
            firstName
            lastName
            userName
            email
            phone
            profilePicture
            currency
            creatorSize
            channels {
                id
                name
            }
            avgRate
            languages {
                name
            }
        }
    }`;


