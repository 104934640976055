import { useMediaQuery } from 'react-responsive';
import { Button, Drawer, Modal, Space } from 'antd';
import React, { forwardRef, useContext, useImperativeHandle, useState } from 'react';
import { ColorSchemeContext } from '@/core/dark-mode/colorSchemeProvider';
import FreelancerCharter from '@/components/freelancer-charter/FreelancerCharter';
import { CloseOutlinedIcon } from '@/assets/icons/CustomIcons';
import { useTranslation } from 'react-i18next';

const FreelancerCharterModalDrawer = forwardRef( function( props, ref ) {
    const { t } = useTranslation();
    const isTabletOrMobile = useMediaQuery( { query: `(max-width: ${ import.meta.env.VITE_MOBILE_BREAK_POINT })` } );
    const colorSchemeContext = useContext( ColorSchemeContext );
    const [ isOpen, setIsOpen ] = useState( false );

    useImperativeHandle( ref, () => ( {
        open() {
            handleOpen();
        },
        close() {
            handleClose();
        }
    } ) );

    const handleOpen = () => {
        setIsOpen( true );
    };

    const handleClose = () => {
        setIsOpen( false );
    };

    const modalJSX = (
        <Modal open={ isOpen }
               rootClassName={ colorSchemeContext.toLowerCase() }
               width={ 668 }
               destroyOnClose={ true }
               onCancel={ () => handleClose() }
               footer={ null }>
            <FreelancerCharter />
        </Modal>
    );

    const drawerJSX = (
        <Drawer placement="bottom"
                className="overflow-x-hidden bg-white  rounded-t-4xl mt-[15%]"
                bodyStyle={ { padding: 0 } }
                width="100%"
                height="100%"
                headerStyle={ { textAlign: 'center' } }
                closable={ false }
                rootClassName={ colorSchemeContext.toLowerCase() }
                open={ isOpen }
                title={ t( 'freelancer:charter.drawerTitle' ) }
                extra={
                    <Space>
                        <Button onClick={ () => handleClose() }
                                size="small"
                                icon={ <CloseOutlinedIcon style={ { fontSize: '12px' } } /> }
                                shape="circle">
                        </Button>
                    </Space>
                }
                destroyOnClose={ true }>
            <div className="p-6">
                <FreelancerCharter />
            </div>
        </Drawer>
    );

    return (
        <>
            {
                isTabletOrMobile ?
                drawerJSX :
                modalJSX
            }
        </>
    );
} );

export default FreelancerCharterModalDrawer;