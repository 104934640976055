import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';

interface ClientItemProps {
    avatarSize?: number | null;
    client: {
        userName: string;
        profilePicture: string;
    };
}

export default function ClientItem( { avatarSize, client }: ClientItemProps ) {
    return (
        <div className="flex items-center justify-center gap-x-4">
            <Avatar size={ avatarSize | 42 }
                    icon={ <UserOutlined /> }
                    className="flex-none"
                    src={ client?.profilePicture } />
            <span>{ client.userName }</span>
        </div>
    );
}