import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, Divider, message, Skeleton } from 'antd';
import { Form } from 'react-router-dom';
import React, { useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { FreelancerCompanyDetailsGQL } from '@/pages/freelancer/views/account/freelancer-account-edit-company-details/freelancerCompanyDetailsGQL';
import { FreelancerUpdateCompanyDetailsGQL } from '@/pages/freelancer/views/account/freelancer-account-edit-company-details/freelancerUpdateCompanyDetailsGQL';
import * as Sentry from '@sentry/react';
import InputComponent from '@/components/custom-element-form/InputComponent';
import { PageName, trackPageView } from '@/utils/analytics';

export default function FreelancerAccountEditCompanyDetails() {

    const { t } = useTranslation();
    const { trigger, control, getValues, setValue, formState: { errors }, handleSubmit, reset } = useForm();
    const [ messageApi, contextHolder ] = message.useMessage();

    const companyDetailsQuery = useQuery( FreelancerCompanyDetailsGQL );
    const updateCompanyDetailsMutation = useMutation( FreelancerUpdateCompanyDetailsGQL );

    useEffect( () => {
        trackPageView( PageName.FreelancerEditCompanyDetails );
    }, [ ] );

    useEffect( () => {
        if( companyDetailsQuery.data ) {
            const data = companyDetailsQuery.data.meFreelancer;
            setValue( 'vatRate', data.vatRate );
            setValue( 'companyName', data.addressName );
            setValue( 'street1', data.addressStreet1 );
            setValue( 'street2', data.addressStreet2 );
            setValue( 'city', data.addressCity );
            setValue( 'state', data.addressState );
            setValue( 'postalCode', data.addressPostalCode );
            setValue( 'country', data.addressCountry );
        }
    }, [ companyDetailsQuery.data ] );

    const onSubmit = async( data ) => {
        if( !( await trigger() ) ) {
            return;
        }

        const args = {
            vatRate: +data.vatRate,
            addressName: data.companyName,
            addressStreet1: data.street1,
            addressStreet2: data.street2,
            addressCity: data.city,
            addressState: data.state,
            addressPostalCode: data.postalCode,
            addressCountry: data.country
        };

        try {
            const result = await updateCompanyDetailsMutation[ 0 ]( {
                variables: {
                    args
                }
            } );

            if( result.data.updateFreelancerCompanyDetails ) {
                messageApi.success( t( 'common:form.successfullySubmitted' ) );
            }

        } catch( e ) {
            Sentry.captureException( 'An error occur when trying to update company details', e );
            messageApi.error( t( 'common:form.submitError' ) );
        }
    };

    const loadingJSX = (
        <Skeleton active />
    );

    const contentJSX = (
        <Form className="flex flex-col gap-y-4 mt-2"
              onSubmit={ handleSubmit( onSubmit ) }>

            <InputComponent control={ control }
                            className="max-w-[230px]"
                            name="vatRate"
                            label={ t( 'freelancer:account.edit-company-details.vatRate' ) }
                            placeholder={ t( 'freelancer:account.edit-company-details.placeholderVatRate' ) }
                            tooltipLabel={ t( 'freelancer:account.edit-company-details.tooltipVatRate' ) }
                            rules={ {
                                required: t( 'common:error.form.required' ),
                                min: {
                                    value: 0,
                                    message: t( 'freelancer:account.edit-company-details.error.min' )
                                },
                                max: {
                                    value: 100,
                                    message: t( 'freelancer:account.edit-company-details.error.max' )
                                }
                            } }
                            suffix="%"
                            errors={ errors } />

            <Divider type="horizontal"
                     className="my-2 lg:my-6" />

            <InputComponent control={ control }
                            name="companyName"
                            label={ t( 'freelancer:account.edit-company-details.name' ) }
                            placeholder={ t( 'freelancer:account.edit-company-details.placeholderName' ) }
                            rules={ {
                                required: t( 'common:error.form.required' )
                            } }
                            errors={ errors } />

            <InputComponent control={ control }
                            name="street1"
                            label={ t( 'freelancer:account.edit-company-details.street1' ) }
                            placeholder={ t( 'freelancer:account.edit-company-details.placeholderStreet1' ) }
                            rules={ {
                                required: t( 'common:error.form.required' )
                            } }
                            errors={ errors } />

            <InputComponent control={ control }
                            name="street2"
                            label={ t( 'freelancer:account.edit-company-details.street2' ) }
                            placeholder={ t( 'freelancer:account.edit-company-details.placeholderStreet2' ) }
                            errors={ errors } />

            <div className="flex items-start flex-wrap lg:flex-nowrap gap-4">
                <InputComponent control={ control }
                                className="w-1/3"
                                name="city"
                                label={ t( 'freelancer:account.edit-company-details.city' ) }
                                placeholder={ t( 'freelancer:account.edit-company-details.placeholderCity' ) }
                                rules={ {
                                    required: t( 'common:error.form.required' )
                                } }
                                errors={ errors } />

                <InputComponent control={ control }
                                className="w-1/3"
                                name="state"
                                label={ t( 'freelancer:account.edit-company-details.state' ) }
                                placeholder={ t( 'freelancer:account.edit-company-details.placeholderState' ) }
                                errors={ errors } />

                <InputComponent control={ control }
                                name="postalCode"
                                className="w-1/3"
                                label={ t( 'freelancer:account.edit-company-details.postalCode' ) }
                                placeholder={ t( 'freelancer:account.edit-company-details.placeholderPostalCode' ) }
                                rules={ {
                                    required: t( 'common:error.form.required' )
                                } }
                                errors={ errors } />
            </div>

            <InputComponent control={ control }
                            name="country"
                            label={ t( 'freelancer:account.edit-company-details.country' ) }
                            placeholder={ t( 'freelancer:account.edit-company-details.placeholderCity' ) }
                            rules={ {
                                required: t( 'common:error.form.required' )
                            } }
                            errors={ errors } />

            <div className="flex lg:justify-end">
                <Button type="primary"
                        htmlType="submit"
                        loading={ updateCompanyDetailsMutation[ 1 ].loading }
                        className="w-full lg:w-[120px] mt-8">
                    { t( 'freelancer:account.edit-profile.submit' ) }
                </Button>
            </div>
        </Form>
    );

    return (
        <>
            { contextHolder }
            <h1 className="mb-6">
                { t( 'freelancer:account.edit-company-details.title' ) }
            </h1>
            {
                companyDetailsQuery.loading ?
                loadingJSX :
                contentJSX
            }
        </>
    );
}