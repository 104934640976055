import { useForm } from 'react-hook-form';
import { Button, message } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { createProjectGQL } from '@/pages/client/views/new-project/services/createProjectGQL';
import { Channel } from '@/gql/graphql';
import ChannelSwitcherButton from '@/pages/client/views/new-project/components/channel-switcher/ChannelSwitcherButton';
import * as Sentry from '@sentry/react';
import InputComponent from '@/components/custom-element-form/InputComponent';
import TextAreaComponent from '@/components/custom-element-form/TextAreaComponent';
import { trackSegmentEvent, SegmentEvent } from '@/utils/analytics';

interface NewProjectProps {
    freelancer: {
        id: string;
        firstName: string;
    };
    channel: Channel;
    onProjectCreated: ( project ) => void;
    isLoadingChannel: boolean;
    onOpenChannelSwitcher: () => void;
    onCloseChannelSwitcher: () => void;
    onChangeChannel?: ( channel: Channel ) => void;
}

export default function NewProjectForm( {
                                            freelancer,
                                            channel,
                                            isLoadingChannel,
                                            onProjectCreated,
                                            onOpenChannelSwitcher,
                                            onCloseChannelSwitcher,
                                            onChangeChannel
                                        }: NewProjectProps ) {

    const {
              trigger,
              control,
              handleSubmit,
              reset,
              formState: { errors }
          } = useForm();
    const [ messageApi, contextHolder ] = message.useMessage();
    const { t } = useTranslation();

    const [ createProject, { loading, error } ] = useMutation( createProjectGQL );

    if( error ) {
        messageApi.error( t( 'translation:error.default' ) );
    }

    const onSubmit = async( data ) => {
        if( !( await trigger() ) ) {
            return;
        }

        try {
            const response = await createProject( {
                variables: {
                    args: {
                        name: data.name,
                        description: data.description,
                        freelancerId: freelancer.id,
                        channelId: channel?.id || null
                    }
                }
            } );

            if( response.data ) {
                trackSegmentEvent(
                    SegmentEvent.ProjectSent,
                    {
                        project_id: response.data?.createProject?.id,
                        project_creator: response.data?.createProject?.client.id,
                        project_receiver: freelancer.id,
                    }
                );
            };


            reset();
            onProjectCreated( response.data.createProject );
        } catch( e ) {
            Sentry.captureException( 'An error occur when trying to create a new project', e );
            messageApi.error( t( 'translation:error.default' ) );
        }
    };

    const handleChannelChange = ( channel: Channel ) => onChangeChannel?.( channel );

    const handleOnCloseChannelSwitcher = () => onCloseChannelSwitcher?.();

    return (
        <form onSubmit={ handleSubmit( onSubmit ) }
              className="flex flex-col gap-y-8">
            { contextHolder }
            <ChannelSwitcherButton channel={ channel }
                                   onOpenChannelSwitcher={ () => onOpenChannelSwitcher() }
                                   onClose={ () => handleOnCloseChannelSwitcher() }
                                   isLoading={ isLoadingChannel }
                                   onSwitchChannel={ ( channel ) => handleChannelChange( channel ) } />

            <InputComponent control={ control }
                            name="name"
                            label={ t( 'transverse:new-project.inputFields.name.label' ) }
                            placeholder={ t( 'transverse:new-project.inputFields.name.placeholder' ) }
                            rules={ {
                                required: t( 'common:error.form.required' )
                            } }
                            errors={ errors } />

            <TextAreaComponent control={ control }
                               name="description"
                               label={ t( 'transverse:new-project.inputFields.description.label' ) }
                               placeholder={ t( 'transverse:new-project.inputFields.description.placeholder' ) }
                               rows={ 6 }
                               rules={ {
                                   required: t( 'common:error.form.required' )
                               } }
                               errors={ errors } />

            <div className="flex items-center justify-center mt-4 lg:mt-6">
                <Button type="primary"
                        htmlType="submit"
                        loading={ loading }
                        className="w-full">
                    { t( 'transverse:new-project.send', { name: freelancer.firstName } ) }
                </Button>
            </div>
        </form>
    );
}