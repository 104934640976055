import { Avatar } from 'antd';
import firstLetterUppercaseFormatter from '@/utils/firstLetterUppercaseFormatter';
import abbreviationNumberFormatter from '@/utils/abbrevationNumberFormatter';
import { PortfolioChannel } from '@/gql/graphql';
import { cuttingTextFormatter } from '@/utils/cuttingTextFormatter';
import { useMediaQuery } from 'react-responsive';
import { useEffect, useState } from 'react';
import ScrollableContainer from '@/components/scrollable-container/ScrollableContainer';

interface FreelancerProfileCreatorListProps {
    channels: PortfolioChannel[];
    avatarSize?: number;
    limit?: number;
    gap?: number;
    stats?: boolean;
    nameClass?: string;
}

export default function FreelancerProfileCreatorList( {
                                                          channels,
                                                          avatarSize,
                                                          limit,
                                                          gap = 0,
                                                          stats,
                                                          nameClass = ''
                                                      }: FreelancerProfileCreatorListProps ) {

    if( channels == null || channels.length == 0 ) {
        return null;
    }

    const isTabletOrMobile = useMediaQuery( { query: `(max-width: ${ import.meta.env.VITE_MOBILE_BREAK_POINT })` } );

    const [ cuttingChannelName, setCuttingChannelName ] = useState<number>( 6 );

    if( limit ) {
        channels = channels?.slice( 0, limit );
    }

    useEffect( () => {
        if( isTabletOrMobile ) {
            setCuttingChannelName( 4 );
        }
        else {
            setCuttingChannelName( 6 );
        }
    }, [ isTabletOrMobile ] );

    const handleChannelClick = ( url: string ) => window.open( url, '_blank' );


    return (
        <ScrollableContainer>
            <div className={ `relative w-full flex items-center gap-${ gap }` }>
                {
                    channels?.map( ( creator, index ) => (
                            <div key={ index }
                                 onClick={ () => handleChannelClick( creator.channelUrl ) }
                                 className={ `flex-none flex flex-col items-center justify-top  cursor-pointer ${ limit
                                                                                                                  ? ''
                                                                                                                  : 'w-[65px] lg:w-[85px]' }` }>
                                <Avatar size={ avatarSize }
                                        key={ creator.id + index }
                                        src={ creator.thumbnailUrl } />
                                <div className={ `text-sm font-bold text-center mt-2 ${ nameClass }` }>
                                    {
                                        creator.name.length > cuttingChannelName + 3
                                        ? cuttingTextFormatter( firstLetterUppercaseFormatter( creator.name ), cuttingChannelName )
                                        : creator.name
                                    }
                                </div>
                                {
                                    stats &&
                                    (
                                        <div className="text-xs  font-grey-light text-center mt-1">
                                            { abbreviationNumberFormatter( creator.subscriberCount ) }
                                        </div>
                                    )
                                }
                            </div>
                        )
                    )
                }
            </div>
        </ScrollableContainer>
    );
}