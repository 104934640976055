import SignUpLayout from '@/pages/auth/sign-up/components/SignUpLayout';
import SignUpLeftSide from '@/pages/auth/sign-up/components/SignUpLeftSide';
import { useTranslation } from 'react-i18next';
import Steps from '@/components/Step';
import { signUpClientHook } from '@/hooks/signUpClientHook';
import SignUpFreelancerStepMain from '@/pages/auth/sign-up/sign-up-freelancer/sign-up-freelancer-steps/SignUpFreelancerStepMain';

export default function SignUpClient() {

    const { t } = useTranslation( 'auth' );

    const {
              items,
              progressBar,
              currentStep,
              isLoading,
              nextStep,
              previousStep,
              currentProgressBarIndex
          } = signUpClientHook();

    const progressBarSteps = [
        t( 'auth:sign-up.client.steps.pairChannel' ),
        t( 'auth:sign-up.client.steps.personalInfo' ),
        t( 'auth:sign-up.client.steps.welcome' )
    ];

    const leftSide: JSX.Element = (
        <SignUpLeftSide data={ {
            title: items[ currentStep ]?.titleLeft,
            stepsBar: <Steps steps={ progressBar.map( item => item.label ) }
                             currentStep={ currentProgressBarIndex() } />
        } } />
    );

    return (
        <SignUpLayout leftSide={ leftSide }
                      rightSide={
                          <SignUpFreelancerStepMain steps={ items as any }
                                                    currentStep={ currentStep } />
                      }
                      loadingNext={ isLoading }
                      onNext={ () => nextStep() }
                      onBack={ () => previousStep() } />
    );
}