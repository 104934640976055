import { FilmsOutlinedIcon, PencilOutlinedIcon } from '@/assets/icons/CustomIcons';

export default function jobToIcon( job: 'VIDEO_EDITOR' | 'GRAPHIC_DESIGNER' ) {
    switch( job ) {
        case 'VIDEO_EDITOR':
            return <FilmsOutlinedIcon className="text-lg" />;
        case 'GRAPHIC_DESIGNER':
            return <PencilOutlinedIcon className="text-lg" />;
        default:
            return null;
    }
}