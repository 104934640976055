import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FreelancerChatQuoteForm from '@/pages/freelancer/views/chat/components/freelancer-chat-quote/FreelancerChatQuoteForm';
import { Project } from '@/gql/graphql';
import { useQuery } from '@apollo/client';
import { freelancerProjectGQL } from '@/pages/freelancer/views/project/services/projectGQL';
import ResponsiveModal from '@/components/ResponsiveModal';
import { useParams } from 'react-router-dom';
import { Divider } from 'antd';

interface FreelancerChatQuoteModalProps {
    projectId?: string;
}

const FreelancerChatQuoteModal = forwardRef( ( props: FreelancerChatQuoteModalProps, ref ) => {

    const { projectId } = props;
    const { projectId: projectIdParam } = useParams();

    const [ project, setProject ] = useState<Project>( null );
    const [ _projectId, setProjectId ] = useState<string>( projectId || projectIdParam );

    const { t } = useTranslation();
    const responsiveModalRef = useRef( null );

    const projectQuery = useQuery( freelancerProjectGQL, {
        variables: {
            id: _projectId
        }
    } );

    useImperativeHandle( ref, () => ( {
            open() {
                responsiveModalRef?.current.open();
            },
            close() {
                responsiveModalRef?.current.close();
            }
        } )
    );

    useEffect( () => {
        if( projectQuery?.data ) {
            setProject( projectQuery?.data?.project );
        }
    }, [ projectQuery?.data ] );

    return (
        <ResponsiveModal width={ 550 }
                         ref={ responsiveModalRef }
                         destroyOnClose={ false }
                         title={ t( 'freelancer:chat.quote.title', { projectName: project?.name } ) }>
            <Divider />
            <FreelancerChatQuoteForm projectId={ _projectId }
                                     onSubmit={ () => responsiveModalRef.current.close() } />
        </ResponsiveModal>
    );
} );

export default FreelancerChatQuoteModal;