import { useEffect } from 'react';

import LayoutVisibilityHook from '@/hooks/layoutVisibilityHook';
import { useTranslation } from 'react-i18next';

import ClientFavoriteList from '@/pages/client/views/favorites/components/ClientFavoriteList';
import FavoritesHook from '@/hooks/favoritesHook';
import { PageName, trackPageView } from '@/utils/analytics';

export default function ClientFavorites() {
    LayoutVisibilityHook( {
        headerVisible: true,
        bottomNavBarVisible: 'isTabletOrMobile'
    } );

    const { load, favorites, loading, next, remove, hasMore, total, resetNewFavorites } = FavoritesHook();
    const { t } = useTranslation();

    useEffect( () => {
        load();
        resetNewFavorites();
    }, [] );

    useEffect( () => {
        trackPageView( PageName.ClientFavorites );
    }, [ ] );

    return (
        <div className="default-padding-layout-x w-full">
            <div className="hidden lg:block font-medium text-2xl font-sharp">
                { t( 'client:favorites.title' ) }
            </div>
            <div className="mt-2 mb-4 text-base font-normal">
                { t( 'client:favorites.freelancersCountWithCount', { count: total | 0 } ) }
            </div>
            <div className="mt-8">
                <ClientFavoriteList freelancers={ favorites }
                                    loading={ loading }
                                    next={ next }
                                    remove={ remove }
                                    hasMore={ hasMore } />
            </div>
        </div>
    );
}