import ErrorBoundary from '@/pages/errors/ErrorBoundary';
import TransverseFreelancers from '@/pages/transverse/views/freelancers/TransverseFreelancers';
import TransverseFreelancerProfile from '@/pages/transverse/views/freelancer/TransverseFreelancerProfile';
import TransverseRoot from '@/pages/transverse/views/TransverseRoot';
import RedirectFreelancer from '@/components/RedirectFreelancers';

export const TransverseRouting = {
    path: '',
    element: <TransverseRoot />,
    errorElement: <ErrorBoundary />,
    children: [
        {
            path: '',
            element: <TransverseFreelancers />,
            errorElement: <ErrorBoundary />
        },
        {
            path: 'freelancers/:id',
            element: <RedirectFreelancer />
        },
        {
            path: 'p/:id',
            element: <TransverseFreelancerProfile />,
            errorElement: <ErrorBoundary />
        }
    ]
};