import { SignUpFreelancerRepository } from '@/pages/auth/sign-up/sign-up-freelancer//signUpFrelancerRepository';
import SelectMultiTags from '@/components/SelectMultiTags';
import { useQuery } from '@apollo/client';
import { skillsGQL } from '@/gql/global-queries/skillsGQL';
import { useTranslation } from 'react-i18next';


function SignUpFreelancerStepSkill() {
    const { t } = useTranslation();
    const repository = new SignUpFreelancerRepository();
    const { data, loading, error } = useQuery( skillsGQL );

    return (
        <div className="mt-4">
            <SelectMultiTags defaultSelectedIds={ repository.skillIds || [] }
                             tags={ data?.skills.map( ( skill ) => ( {
                                 id: skill.id,
                                 label: t( 'translation:enum.skills.' + skill.i18n.toUpperCase() )
                             } ) ) }
                             loading={ loading }
                             onTagsSelected={ ( skillIds ) => repository.skillIds = skillIds } />
        </div>
    );
}

export default SignUpFreelancerStepSkill;
