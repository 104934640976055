import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar, Button } from 'antd';
import { UserOutlined } from '@ant-design/icons';

import { ExperienceTypeEnum } from '@/gql/graphql';

import FreelancerPrice from '@/components/freelance-profile/components/FreelancerPrice';
import FreelancerRatingTag from '@/components/freelance-profile/components/FreelancerRatingTag';
import FreelancerExperienceTag from '@/components/freelance-profile/components/FreelancerExperienceTag';
import jobToIcon from '@/utils/jobToIcon';
import { Link } from 'react-router-dom';

interface ClientProjectFreelancerProps {
    freelancer: {
        profilePicture: string;
        name: string;
        mainJob: 'VIDEO_EDITOR' | 'GRAPHIC_DESIGNER';
        avgPrice: number;
        avgRate: number;
        experience: ExperienceTypeEnum;
    },
    conversationId: string;
}

export default function ClientProjectFreelancer( { freelancer, conversationId }: ClientProjectFreelancerProps ) {

    const { t } = useTranslation();
    const [ _freelancer, setFreelancer ] = useState( null );

    useEffect( () => {
        if( freelancer ) {
            transformFreelancer( freelancer );
        }
    }, [ freelancer ] );

    const transformFreelancer = ( freelancer ) => {
        setFreelancer( {
            ...freelancer,
            mainJob: (
                <div>
                    {
                        jobToIcon( freelancer.mainJob )
                    }
                    <span className="ml-2">
                      {
                          t( 'translation:enum.jobs.' + freelancer.mainJob )
                      }
                  </span>
                </div>
            )
        } );
    };

    return (
        <div className="w-full flex flex-col lg:flex-row rounded-3xl border border-grey-light p-6 items-end gap-x-4">
            <div className="w-full">
                <div className="flex">
                    <Avatar size={ 64 }
                            icon={ <UserOutlined /> }
                            className="flex-none"
                            src={ _freelancer?.profilePicture } />
                    <div className="flex flex-col ml-5">
                        <h4 className="text-lg font-medium text-black">
                            { _freelancer?.name }
                        </h4>
                        <div className="mt-1 text-primary-500">
                            { _freelancer?.mainJob }
                        </div>
                    </div>
                </div>
                <div className="flex gap-x-2 mt-2.5">
                    <FreelancerPrice price={ _freelancer?.avgPrice }
                                     className="border-grey-light bg-transparent text-grey" />

                    <FreelancerRatingTag rating={ _freelancer?.avgRate }
                                         bordered={ true }
                                         displayStars={ false } />


                    <FreelancerExperienceTag experience={ _freelancer?.experience } />
                </div>
            </div>
            <Link to={ `/client/messages/${ conversationId }` }
                  className="mt-6 lg:mt-0"
                  replace={ true }>
                <Button type="default">
                    { t( 'client:project.goToConversation' ) }
                </Button>
            </Link>
        </div>
    );
}