import { useTranslation } from 'react-i18next';
import { GraduationHatOutlinedIcon } from '@/assets/icons/CustomIcons';
import { ExperienceTypeEnum } from '@/gql/graphql';
import { useEffect, useState } from 'react';
import { Tag } from 'antd';

interface FreelancerPriceProps {
    experience: ExperienceTypeEnum;
    bordered?: boolean;
    className?: string;
    iconClassName?: string;
}

export default function FreelancerExperienceTag( {
                                                     experience = ExperienceTypeEnum.BEGINNER,
                                                     bordered = true,
                                                     className,
                                                     iconClassName
                                                 }: FreelancerPriceProps ) {

    const { t } = useTranslation();

    const [ _experience, setExperience ] = useState<ExperienceTypeEnum>();

    useEffect( () => {
        if( _experience == null ) {
            setExperience( experience );
        }
    }, [ experience ] );

    return (
        <Tag bordered={ bordered }
             className={ className }>
            <GraduationHatOutlinedIcon className={ iconClassName } />
            <span className="ml-2">
                { t( 'common:freelancerProfile.card.experience.' + _experience?.toUpperCase(), { defaultValue: t( 'common:freelancerProfile.card.experience.unknown' ) } ) }
            </span>
        </Tag>
    );
}