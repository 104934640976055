import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import Checkbox from 'antd/es/checkbox';
import { ExperienceTypeEnum } from '@/gql/graphql';
import { convertEnumToArray } from '@/utils/convertEnumToArray';
import { FreelancersRepository } from '@/components/freelancers-filter-items/freelancersRepository';
import { FreelancersRepositoryContext } from '@/core/FreelancerRepositoryContext';

export default function FilterItemExperiencesCheckbox() {
    const { t } = useTranslation();
    const freelancersRepository: FreelancersRepository = useContext( FreelancersRepositoryContext );

    const defaultLimit: number = 20;
    const experiences: ExperienceTypeEnum[] = convertEnumToArray( ExperienceTypeEnum );

    const [ checkboxOptions, setCheckboxOptions ] = useState( [] );
    const [ checkedValues, setCheckedValues ] = useState<ExperienceTypeEnum[]>();
    const [ limit, setLimit ] = useState( defaultLimit );

    const convertExperiencesToCheckbox = ( experiences: ExperienceTypeEnum[] ) => {
        if( experiences == null || experiences.length == 0 ) {
            return [];
        }

        return experiences.map( ( experience: ExperienceTypeEnum ) => {
            return {
                label: `${ t( 'enum.experiences.' + experience.toUpperCase(), { defaultValue: t( 'common:freelancerProfile.card.experience.unknown' ) } ) } ${ t( 'transverse:freelancers.filters.years' ) }`,
                value: experience
            };
        } );
    };

    useEffect( () => {
        setCheckboxOptions( convertExperiencesToCheckbox( experiences ) );
        setCheckedValues( freelancersRepository.experiences );

        const observable = freelancersRepository.experience$.subscribe( ( value ) => {
            setCheckedValues( value );
        } );
        return () => {
            observable.unsubscribe();
        };
    }, [] );

    const onChange = ( checkedValues: any ) => {
        freelancersRepository.setExperiences( checkedValues );
    };

    const onShowMore = () => {
        setLimit( limit ? undefined : defaultLimit );
    };

    return (
        <div className="checkbox-vertical">
            {
                checkboxOptions?.length > 0 &&
                <Checkbox.Group style={ { display: 'flex', flexDirection: 'row' } }
                                className="gap-y-1 text-base font-medium"
                                options={ checkboxOptions }
                                value={ checkedValues }
                                onChange={ onChange }>
                </Checkbox.Group>
            }

            <div className={ `${ experiences?.length <= defaultLimit
                                 ? 'hidden'
                                 : '' } underline text-wild-strawberry cursor-pointer mt-4 text-sm` }
                 onClick={ onShowMore }>
                { limit ? t( 'common:button.showMoreButton' ) : t( 'common:button.showLessButton' ) }
            </div>
        </div>
    );
}