import i18next from 'i18next';
import { priceFormatter } from '@/utils/priceFormatter';
import { CurrencyEnum } from '@/gql/graphql';

interface PriceComponentProps {
    currency: CurrencyEnum;
    amount: number;
    lng?: string;
    vat?: boolean | null;
    className?: string;
    integerClass?: string;
    decimalClass?: string;
    vatClass?: string;
    decimals?: boolean;
}

export function PriceComponent( {
                                    currency = CurrencyEnum.USD,
                                    amount,
                                    lng = i18next.language,
                                    vat,
                                    className,
                                    decimals,
                                    decimalClass,
                                    vatClass,
                                    integerClass
                                }: PriceComponentProps ) {
    let res: string = priceFormatter( { currency, amount, lng, vat, decimals } );

    const [ price, vatString ] = res.split( ' ' );
    const [ integerPart, decimalPart ] = price.split( '.' );

    return (
        <div className={ className }>
            <span className={ integerClass }>{ integerPart }</span>
            <span className={ decimalClass }>.{ decimalPart }</span>
            { vat && <span className={ `ml-2 ${ vatClass }` }>{ vatString }</span> }
        </div>
    );
}
