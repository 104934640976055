import { useTranslation } from 'react-i18next';
import { DiscordLogoIcon } from '@/assets/icons/CustomIcons';
import { Button } from 'antd';
import { Link } from 'react-router-dom';

export default function SignUpFreelancerStepDiscord() {

    const { t } = useTranslation();

    const goToDiscord = () => {
        window.open( 'https://discord.com/invite/s3uMYNPtwV', '_blank', 'noreferrer' );
    };

    return (
        <div className="h-screen flex justify-center items-center bg-[url('/img/bg-welcome-signup.png')] bg-no-repeat bg-cover p-6">
            <div className="bg-white h-fit w-full max-w-[422px] shadow-2xl rounded-xl">
                <div className="flex flex-col items-center justify-center p-6">
                    <DiscordLogoIcon className="text-[62px]" />
                    <div className="text-xl lg:text-2xl font-sharp font-medium mt-8 text-center">
                        { t( 'auth:sign-up.freelancer.discord-page.title' ) }
                    </div>
                    <div className="text-base font-normal mt-4 text-center">
                        { t( 'auth:sign-up.freelancer.discord-page.subtitle' ) }
                    </div>

                    <Button type="primary"
                            onClick={ () => goToDiscord() }
                            className="mt-8 flex justify-center items-center">
                        <DiscordLogoIcon className="text-[16px]" />
                        { t( 'auth:sign-up.freelancer.discord-page.joinButton' ) }
                    </Button>

                    <Link to="/freelancer"
                          className="w-full">
                        <Button type="default"
                                className="w-full mt-8">
                            { t( 'auth:sign-up.freelancer.discord-page.goToMyAccount' ) }
                        </Button>
                    </Link>
                </div>
            </div>
        </div>
    );
}