import { AuthGuard } from '@/core/auth/AuthGuard';
import { RoleGuard } from '@/core/auth/RoleGuard';
import { Navigate } from 'react-router-dom';
import { UserTypeEnum } from '@/gql/graphql';
import FreelancerProfileEditProfile from '@/pages/freelancer/views/account/freelancer-account-edit-profile/FreelancerProfileEditProfile';
import FreelancerAccountEditPortfolioElements from '@/pages/freelancer/views/account/freelancer-account-edit-portfolio-elements/FreelancerAccountEditPortfolioElements';
import FreelancerAccountEditPassword from '@/pages/freelancer/views/account/freelancer-account-edit-password/FreelancerAccountEditPassword';
import FreelancerConversation from '@/pages/freelancer/views/chat/FreelancerConversation';
import FreelancerAccountEditPortfolioChannels from '@/pages/freelancer/views/account/freelancer-account-edit-portfolio-channels/FreelancerAccountEditPortfolioChannels';
import FreelancerAccountEditCompanyDetails from '@/pages/freelancer/views/account/freelancer-account-edit-company-details/FreelancerAccountEditCompanyDetails';
import FreelancerProject from '@/pages/freelancer/views/project/FreelancerProject';
import FreelancerAccountEditProfilePicture from '@/pages/freelancer/views/account/freelancer-account-edit-profile-picture/freelancerAccountEditProfilePicture';
import FreelancerAccountProfileValidation from '@/pages/freelancer/views/account/freelancer-account-profile-validation/FreelancerAccountProfileValidation';
import FreelancerCharter from '@/components/freelancer-charter/FreelancerCharter';
import ErrorBoundary from '@/pages/errors/ErrorBoundary';
import FreelancerRoot from '@/pages/freelancer/views/FreelancerRoot';
import FreelancerSettings from '@/pages/freelancer/views/account/FreelancerSettings';
import FreelancerChat from '@/pages/freelancer/views/chat/FreelancerChat';
import FreelancerChatQuoteModal from '@/pages/freelancer/views/chat/components/freelancer-chat-quote/FreelancerChatQuoteModal';
import FreelancerProjects from '@/pages/freelancer/views/projects/FreelancerProjects';
import FreelancerDashboard from '@/pages/freelancer/views/dashboard/FreelancerDashboard';

export const FreelancerRouting = {
    path: 'freelancer',
    errorElement: <ErrorBoundary />,
    element:
        <AuthGuard redirectPath="/">
            <RoleGuard allowedUserType={ UserTypeEnum.FREELANCER }
                       redirectPath="/client">
                <FreelancerRoot />
            </RoleGuard>
        </AuthGuard>,
    children: [
        {
            path: '',
            element: <Navigate to="/freelancer/dashboard"
                               replace={ true } />,
            errorElement: <ErrorBoundary />
        },
        {
            path: 'dashboard',
            element: <FreelancerDashboard />,
            errorElement: <ErrorBoundary />
        },
        {
            path: 'projects',
            children: [
                {
                    path: '',
                    element: <Navigate to={ '/freelancer/projects/status/ongoing' }
                                       replace />
                },
                {
                    path: 'status',
                    element: <FreelancerProjects />,
                    children: [
                        {
                            path: ':status',
                            element: <FreelancerProjects />
                        }
                    ]
                },
                {
                    path: ':projectId',
                    element: <FreelancerProject />,
                    errorElement: <ErrorBoundary />
                }
            ]
        },
        {
            path: 'settings',
            element: <FreelancerSettings />,
            children: [
                {
                    path: '',
                    element: <Navigate to="/freelancer/settings/edit-profile" />,
                    errorElement: <ErrorBoundary />
                },
                {
                    path: 'verify-profile',
                    element: <FreelancerAccountProfileValidation />,
                    errorElement: <ErrorBoundary />
                },
                {
                    path: 'edit-profile',
                    element: <FreelancerProfileEditProfile />,
                    errorElement: <ErrorBoundary />
                },
                {
                    path: 'edit-profile-picture',
                    element: <FreelancerAccountEditProfilePicture />,
                    errorElement: <ErrorBoundary />
                },
                {
                    path: 'edit-portfolio',
                    element: <FreelancerAccountEditPortfolioElements />,
                    errorElement: <ErrorBoundary />
                },
                {
                    path: 'edit-creators',
                    element: <FreelancerAccountEditPortfolioChannels />,
                    errorElement: <ErrorBoundary />
                },
                {
                    path: 'edit-password',
                    element: <FreelancerAccountEditPassword />,
                    errorElement: <ErrorBoundary />
                },
                {
                    path: 'edit-company-details',
                    element: <FreelancerAccountEditCompanyDetails />,
                    errorElement: <ErrorBoundary />
                },
                {
                    path: 'verify-profile',
                    element: <FreelancerAccountProfileValidation />,
                    errorElement: <ErrorBoundary />
                },
                {
                    path: 'charter',
                    element: <FreelancerCharter />,
                    errorElement: <ErrorBoundary />
                }
            ]
        },
        {
            path: 'messages',
            element: <FreelancerChat />,
            errorElement: <ErrorBoundary />,
            children: [
                {
                    path: ':conversationId',
                    element: <FreelancerConversation />,
                    errorElement: <ErrorBoundary />
                },
                {
                    path: ':conversationId/quote/:projectId',
                    element: <FreelancerChatQuoteModal />,
                    errorElement: <ErrorBoundary />
                }
            ]
        }
    ]
};