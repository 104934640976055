import { createStore } from '@ngneat/elf';
import { addEntities, deleteEntities, selectEntities, setEntities, UIEntitiesRef, unionEntities, withEntities, withUIEntities } from '@ngneat/elf-entities';
import { Favorite, Freelancer } from '@/gql/graphql';
import { map } from 'rxjs';

export interface FavoriteUI {
    id: number;
    new: boolean;
}

const favoriteStore = createStore(
    { name: 'favorite' },
    withEntities<Favorite>(),
    withUIEntities<FavoriteUI>()
);

export class FavoritesRepository {

    favorites$ = favoriteStore.combine( {
        entities: favoriteStore.pipe( selectEntities(), map( ( entities: Record<string, Favorite> ) => {
            const favorites = Object.values( entities );
            if( favorites.length == 0 ) {
                return [];
            }

            // sort favorites by createdAt
            favorites.sort( ( a, b ) => {
                return new Date( b.createdAt ).getTime() - new Date( a.createdAt ).getTime();
            } );

            return favorites;
        } ) ),
        UIEntities: favoriteStore.pipe( selectEntities( { ref: UIEntitiesRef } )
        )
    } ).pipe( unionEntities() );

    newFavorites$ = favoriteStore.pipe( selectEntities( { ref: UIEntitiesRef } ), map( ( entities: Record<string, FavoriteUI> ) => {
        return Object.values( entities ).filter( favorite => favorite.new );
    } ) );

    get favorites(): Favorite[] {
        return Object.values( favoriteStore.getValue().entities );
    }

    set favorites( favorites: Favorite[] ) {
        favoriteStore.update( setEntities( favorites ) );
    }

    get freelancers(): Freelancer[] {
        return this.favorites.map( favorite => favorite.freelancer );
    }

    addFavorite( favorite: Favorite ) {
        favoriteStore.update(
            addEntities( favorite ),
            addEntities( { id: favorite.id, new: true }, { ref: UIEntitiesRef } )
        );
    }

    addFavorites( favorites: Favorite[] ) {
        favoriteStore.update( addEntities( favorites ) );
    }

    removeFavorite( favoriteId: number ) {
        favoriteStore.update( deleteEntities( favoriteId ) );
    }

    resetNewFavorites() {
        favoriteStore.update( setEntities( this.favorites.map( favorite => {
            return {
                ...favorite,
                new: false
            };
        } ) ), setEntities( this.favorites.map( favorite => {
            return {
                id: favorite.id,
                new: false
            };
        } ), { ref: UIEntitiesRef } ) );
    }

    reset() {
        favoriteStore.reset();
    }
}