import React, { useEffect, useState } from 'react';
import { PortfolioChannel } from '@/gql/graphql';
import ListSkeleton from '@/components/ListSkeleton';
import FreelancerAccountEditPortfolioChannelListItem from '@/pages/freelancer/views/account/freelancer-account-edit-portfolio-channels/components/FreelancerAccountEditPortfolioChannelListItem';
import NoDataIcon from '@/components/NoDataIcon';
import { Reorder } from 'framer-motion';
import { useTranslation } from 'react-i18next';

interface FreelancerAccountEditOrderedListProps {
    portfolioChannels: PortfolioChannel[];
    isLoading?: boolean;
    isDeleting: string[];

    onOrderChange( orderedList: PortfolioChannel[] ): void;

    onDeleteItem( portfolioChannel: PortfolioChannel ): void;
}

export default function FreelancerAccountEditPortfolioChannelOrderedList( {
                                                                              portfolioChannels,
                                                                              isLoading,
                                                                              isDeleting,
                                                                              onOrderChange,
                                                                              onDeleteItem
                                                                          }: FreelancerAccountEditOrderedListProps ) {

    const { t } = useTranslation();

    const [ orderedList, setOrderedList ] = useState<PortfolioChannel[]>( [] );

    useEffect( () => {
        setOrderedList( orderPortfolioChannels( portfolioChannels ) );
    }, [ portfolioChannels ] );

    const orderPortfolioChannels = ( orderedList: PortfolioChannel[] ) => {
        return orderedList?.sort( ( a,
                                    b ) => ( a.freelancerToPortfolioChannels?.order || 0 ) - ( b.freelancerToPortfolioChannels?.order || 0 ) );
    };

    const handleOnDeleteItem = ( portfolioChannel: PortfolioChannel ) => {
        onDeleteItem( portfolioChannel );
    };

    const handleReorder = ( newOrder: any ) => {
        const currentList = [ ...orderedList ];
        const reorderedList = newOrder.map( index => currentList[ index ] );

        if( reorderedList.some( ( o ) => o.hasOwnProperty( 'order' ) ) ) {
            reorderedList.forEach( ( item, index ) => {
                item.freelancerToPortfolioChannels.order = index + 1;
            } );
        }
        setOrderedList( reorderedList );
        onOrderChange( reorderedList );
    };

    if( isLoading ) {
        return (
            <div className="flex flex-col gap-y-4">
                <ListSkeleton isLoading={ true }
                              count={ 3 }
                              height={ 60 } />
            </div>
        );
    }

    if( orderedList?.length > 0 ) {
        return (
            <Reorder.Group axis="y"
                           className="flex flex-col gap-y-3"
                           values={ orderedList.map( ( _, index ) => index ) }
                           onReorder={ handleReorder }>
                {
                    orderedList.map( ( item, index ) => (
                        <Reorder.Item key={ item.id }
                                      value={ index }>
                            <FreelancerAccountEditPortfolioChannelListItem
                                portfolioChannel={ item }
                                isDeleting={ isDeleting.includes( item.id ) }
                                onDelete={ handleOnDeleteItem }
                            />
                        </Reorder.Item>
                    ) )
                }
            </Reorder.Group>
        );
    }

    return (
        <div className="w-full h-[300px] flex items-center justify-center">
            <NoDataIcon message={ t( 'freelancer:account.edit-portfolio-channels.noCreator' ) }
                        subMessage={ t( 'freelancer:account.edit-portfolio-channels.subTitle' ) }
                        icon={ <span></span> } />
        </div>
    );
}
