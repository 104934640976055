import NoDataIcon from '@/components/NoDataIcon';
import { useTranslation } from 'react-i18next';
import LayoutVisibilityHook from '@/hooks/layoutVisibilityHook';
import ChatConversationHook from '@/components/chat/hooks/ChatConversationHook';
import ChatConversation from '@/components/chat/components/chat-conversation/chat-conversation/ChatConversation';
import ChatConversationSkeleton from '@/components/chat/components/chat-conversation/chat-conversation-messages/components/ChatConversationSkeleton';

export default function FreelancerConversation() {

    LayoutVisibilityHook( {
        headerVisible: '!isTabletOrMobile',
        bottomNavBarVisible: false
    } );

    const { t } = useTranslation();
    const {
              conversationItems,
              hasMore,
              isLoadingConversation,
              isLoadingMessages,
              receiver,
              isOnline,
              isReceiverTyping,
              handleOnLoadMore,
              handleOnMessageSend,
              handleOnTypingChange
          } = ChatConversationHook();

    if( isLoadingConversation ) {
        return <ChatConversationSkeleton />;
    }

    if( conversationItems.length === 0 ) {
        return (
            <div className="w-full h-full bg-white  rounded-4xl flex justify-center pt-16 flex-grow">
                <NoDataIcon message={ t( 'error.noConversation' ) } />
            </div>
        );
    }

    return (
        <ChatConversation conversationItems={ conversationItems }
                          hasMore={ hasMore }
                          isLoading={ isLoadingMessages }
                          receiver={ receiver }
                          isOnline={ isOnline }
                          isReceiverTyping={ isReceiverTyping }
                          handleOnLoadMore={ () => handleOnLoadMore() }
                          handleOnMessageSend={ ( e ) => handleOnMessageSend( e ) }
                          handleOnTypingChange={ ( e ) => handleOnTypingChange( e ) } />
    );

}