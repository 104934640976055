import { gql } from '@apollo/client';

export const FreelancerProfileGQL = gql`
    query FreelancerProfileGQL {
        meFreelancer {
            id
            isAvailable
            firstName
            lastName
            bio
            experience
            phone
            profilePicture
            priceCurrency
            avgPrice
            avgRate
            accountStatus
            rejectedReason
            customUrl
            createdAt
            jobToFreelancers {
                id
                jobId
                isMainJob
            }
            skills {
                id
                name
                i18n
            }
            email
            totalPortfolioViews
            mainJob
            languages {
                id
                i18n
                name
            }
            expertises {
                id
                name
                i18n
            }
        }
    }`;


