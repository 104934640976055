import { motion } from 'framer-motion';

export default function AnimationCards() {
    const images = [
        '/img/cards/img-jelly1.webp',
        '/img/cards/img-jelly2.webp',
        '/img/cards/img-jelly3.webp',
        '/img/cards/img-jelly6.webp',
        '/img/cards/img-jelly5.webp',
        '/img/cards/img-jelly11.webp',
        '/img/cards/img-jelly7.webp',
        '/img/cards/img-jelly1.webp',
        '/img/cards/img-jelly9.webp',
        '/img/cards/img-jelly10.webp',
        '/img/cards/img-jelly11.webp',
        '/img/cards/img-jelly12.webp',
        '/img/cards/img-jelly1.webp',
        '/img/cards/img-jelly2.webp',
        '/img/cards/img-jelly3.webp',
        '/img/cards/img-jelly11.webp'
    ];
    const columns = 4;

    // Animation variants
    const variants = {
        moveUp: {
            y: [ 0, 20, 0, -20, 0 ], // Moves down, then up, and back to the starting position
            transition: {
                repeat: Infinity,
                duration: 20, // Duration for one complete cycle
                ease: 'easeInOut'
            }
        },
        moveDown: {
            y: [ 0, -20, 0, 20, 0 ], // Moves down, then up, and back to the starting position
            transition: {
                repeat: Infinity,
                duration: 20, // Duration for one complete cycle
                ease: 'easeInOut'
            }
        }
    };

    return (
        <div className="w-full h-full">
            <div className="grid grid-cols-4 gap-4">
                {
                    images.map( ( value, index ) => {
                        // Determine if the card should move up or down based on its column
                        const variant = Math.floor( index % columns ) % 2 === 0 ? 'moveUp' : 'moveDown';

                        return (
                            <motion.img src={ value }
                                        alt="img jellysmack"
                                        key={ index }
                                        variants={ variants }
                                        initial="start"
                                        animate={ variant } />
                        );
                    } )
                }
            </div>
        </div>
    );
}
