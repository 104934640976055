import { gql } from '@apollo/client';

export const languagesWithCountGQL = gql`
    query Languages {
        languages {
            id
            i18n
            totalFreelancer
        }
    }
`;