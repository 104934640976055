import { Avatar, Badge, Select } from 'antd';
import channelsHook from '@/hooks/channelsHook';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IncognitoFilledIcon, ProjectFolderOutlinedIcon } from '@/assets/icons/CustomIcons';
import { useQuery } from '@apollo/client';
import { projectsInProgressByChannelGQL } from '@/gql/global-queries/projectsInProgressByChannelGQL';

interface YoutubeChannelsProps {
    defaultValue: string | 'all' | 'incognito';
    onChannelSelected?: ( channel: string | 'all' | 'incognito' ) => void;
    countProjectsInProgressByChannel: { totalCount : number, countByChannel: any }
}

export default function YoutubeChannelsSelect( { defaultValue = 'all', onChannelSelected, countProjectsInProgressByChannel }: YoutubeChannelsProps ) {

    const { t } = useTranslation( [ 'common' ] );
    const { getChannels, channels, loading } = channelsHook();

    useEffect( () => {
        getChannels();
    }, [] );

    let options = [
        {
            label: (
                <div className="flex items-center ">
                    <ProjectFolderOutlinedIcon className="text-base" />
                    <span className="ml-2">
                        { t( 'common:youtubeChannelsSelect.all' ) }
                    </span>
                    <Badge className="ml-2"
                           count={ countProjectsInProgressByChannel?.totalCount } />
                </div>
            ),
            value: 'all'
        },
        {
            label: (
                <div className="flex items-center ">
                    <IncognitoFilledIcon className="text-base" />
                    <span className="ml-2">
                        { t( 'common:youtubeChannelsSelect.incognito' ) }
                    </span>
                    <Badge className="ml-2"
                           count={ countProjectsInProgressByChannel?.countByChannel?.find( ( value ) => value.channelId == 'incognito' )?.totalCount } />
                </div>
            ),
            value: 'incognito'
        },
        ...channels?.map( ( channel ) => ( {
            value: channel.id,
            label: (
                <div className="flex items-center">
                    <Avatar src={ channel?.thumbnailUrl }
                            className="flex-none"
                            size={ 20 } />
                    <span className="ml-2">
                        { channel.name }
                    </span>
                    <Badge className="ml-2"
                           count={ countProjectsInProgressByChannel?.countByChannel?.find( ( value ) => value.channelId == channel.id )?.totalCount } />
                </div>
            )
        } ) )
    ];

    return (
        <>
            {
                countProjectsInProgressByChannel !== null ?
                <Select options={ options }
                        defaultValue={ defaultValue }
                        popupMatchSelectWidth={ false }
                        size="large"
                        loading={ loading }
                        onSelect={ ( value ) => onChannelSelected?.( value ) }
                        className="flex-none w-fit">
                </Select> :
                null
            }
        </>

    );
}