import { Tag, Tooltip } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface FreelancerAvailabilityTagProps {
    isAvailable: boolean;
    firstName: string;
}

export default function FreelancerAvailabilityTag( { isAvailable, firstName }: FreelancerAvailabilityTagProps ) {
    const { t } = useTranslation( 'translation' );

    return (
        <Tooltip title={ t( isAvailable
                            ? 'common:freelancerProfile.card.availableTooltip'
                            : 'common:freelancerProfile.card.unavailableTooltip', { firstName } ) }
                 placement="top"
                 overlayClassName="tooltip-text-align">
            <Tag color={ isAvailable ? 'success' : 'error' }>
                {
                    isAvailable ?
                    t( 'common:freelancerProfile.card.available' ) :
                    t( 'common:freelancerProfile.card.notAvailable' )
                }
            </Tag>
        </Tooltip>
    );
}