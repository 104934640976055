import { useTranslation } from 'react-i18next';
import ListSkeleton from '@/components/ListSkeleton';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useContext, useEffect, useState } from 'react';
import { ColorSchemeContext } from '@/core/dark-mode/colorSchemeProvider';
import { useMediaQuery } from 'react-responsive';
import ClientFreelancerListNotRecommendedFilter from '@/pages/client/views/freelancers/components/ClientFreelancerListNotRecommendedFilter';
import { useLocation, useNavigate } from 'react-router-dom';
import { setFreelancerSelectedStore } from '@/pages/client/views/freelancers/drawerFullProfileStore';
import FreelancerCard from '@/components/freelance-profile/FreelancerCard';
import freelancersFiltersHook from '@/hooks/freelancersFiltersHook';
import privateFreelancersHook from '@/hooks/privateFreelancersHook';
import { PageName, SegmentEvent, trackPageView, trackSegmentEvent } from '@/utils/analytics';
import { Freelancer } from '@/gql/graphql';
import { FreelancersRepositoryContext } from '@/core/FreelancerRepositoryContext';
import captureUrlParamsFiltersHook from '@/hooks/captureUrlParamsFiltersHook';
import { filtersFreelancerHook } from '@/hooks/filtersFreelancerHook';
import { FreelancersRepository } from '@/components/freelancers-filter-items/freelancersRepository';
import ResponsiveModal from '@/components/ResponsiveModal';
import TransverseFreelancerListHeaderOptions from '@/pages/transverse/views/freelancers/components/TransverseFreelancerListHeaderOptions';

export default function ClientFreelancerListNotRecommended() {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const colorSchemeContext = useContext( ColorSchemeContext );
    const isTabletOrMobile = useMediaQuery( { query: `(max-width: ${ import.meta.env.VITE_MOBILE_BREAK_POINT })` } );
    const freelancersRepository: FreelancersRepository = useContext( FreelancersRepositoryContext );

    const {
              freelancers,
              error,
              loading,
              totalFreelancers,
              load,
              loadMore,
              hasMore,
              handleLike,
              handleDislike,
              handleApplyFilters,
              handleResetFilters,
              countFilterActive
          } = privateFreelancersHook( { freelancersRepository } );

    const [ firstLoad, setFirstLoad ] = useState<boolean>( freelancers == null || freelancers?.length == 0 );

    const { params } = captureUrlParamsFiltersHook( {
        allowedParams: [
            'isAvailable',
            'isCertified',
            'avgPriceMin',
            'avgPriceMax',
            'experiences',
            'jobIds',
            'skillIds',
            'expertiseIds',
            'languageIds'
        ]
    } );

    filtersFreelancerHook( { freelancersRepository } );

    const { handleMenuOpening, limit, isOpenMenu } = freelancersFiltersHook();

    useEffect( () => {
        trackPageView( PageName.FreelancerList );
    }, [] );

    useEffect( () => {
        if( params != null && firstLoad && ( !freelancers || freelancers.length === 0 ) ) {
            freelancersRepository.setFilters( params );
            load().then( () => {
                setFirstLoad( false );
            } );
        }
    }, [ firstLoad, params ] );

    useEffect( () => {
        const savedScrollPosition = location.state?.scrollPosition;
        if( savedScrollPosition ) {
            window.scrollTo( 0, savedScrollPosition );
        }

        window.addEventListener( 'beforeunload', saveScrollPosition );

        return () => {
            window.removeEventListener( 'beforeunload', saveScrollPosition );
        };
    }, [ navigate, location ] );

    const saveScrollPosition = () => {
        navigate( location.pathname, {
            state: {
                ...location.state,
                scrollPosition: window.scrollY
            },
            replace: true
        } );
    };

    const handleViewProfile = ( freelancer: Freelancer ) => {
        if( isTabletOrMobile ) {
            setFreelancerSelectedStore( freelancer.id );
        }
        else {
            navigate( `/client/p/${ freelancer.customUrl ? freelancer.customUrl : freelancer.id }` );
        }
        trackSegmentEvent( SegmentEvent.ProfileClicked, {
            profile_clicked_id: freelancer.id,
            profile_clicked_origin: 'Discover'
        } );
    };


    const gridClass = 'h-fit grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 gap-6 lg:gap-9';

    return (
        <>
            <div className="-mt-6">
                <TransverseFreelancerListHeaderOptions totalFreelancers={ totalFreelancers }
                                                       countFilterActive={ countFilterActive } />
            </div>

            <div className="relative flex flex-col items-center mt-12">
                <div className="w-full max-w-[1312px]">

                    {
                        firstLoad &&
                        <div className={ `w-full mt-4 lg:mt-10 pb-10 ${ gridClass } overflow-y-hidden` }>
                          <ListSkeleton isLoading={ true }
                                        height={ 344 }
                                        count={ limit } />
                        </div>
                    }

                    <InfiniteScroll className={ `w-full mt-4 lg:mt-10 pb-10 ${ gridClass } overflow-y-hidden` }
                                    dataLength={ freelancers.length }
                                    next={ () => loadMore() }
                                    pullDownToRefresh={ false }
                                    hasMore={ firstLoad ? true : hasMore }
                                    endMessage={
                                        <div className="col-span-1 md:col-span-3 lg:col-span-4 w-full flex justify-center items-center mt-10 px-4">
                                            <div className="max-w-[340px] text-center font-normal text-lg">
                                                { t( 'common:freelancerProfile.noMoreFreelancers' ) }
                                            </div>
                                        </div>
                                    }
                                    loader={
                                        <ListSkeleton isLoading={ true }
                                                      height={ 344 }
                                                      count={ limit } />
                                    }>
                        {
                            freelancers?.map( ( freelancerItem: Freelancer,
                                                index ) => {
                                return (
                                    <div className="w-full lg:cursor-pointer lg:max-w-[301px]"
                                         key={ index }>
                                        <FreelancerCard profile={ freelancerItem }
                                                        onLike={ ( freelancer: Freelancer ) => handleLike( freelancer ) }
                                                        onDislike={ ( freelancer: Freelancer ) => handleDislike( freelancer ) }
                                                        favoriteButton={ true }
                                                        enableClick={ true }
                                                        viewProfile={ ( profile ) => handleViewProfile( profile ) } />
                                    </div>
                                );
                            } )
                        }
                    </InfiniteScroll>
                </div>
            </div>
            <ResponsiveModal title={ t( 'transverse:freelancers.filters.filterMenuTitle' ) }
                             isOpen={ isOpenMenu }
                             onClose={ () => handleMenuOpening() }
                             desktopWidth="800px"
                             destroyOnClose={ false }>
                <div className="overflow-y-scroll sm:max-h-[700px] pr-4">
                    <ClientFreelancerListNotRecommendedFilter handleApplyFilters={ () => handleApplyFilters() }
                                                              handleResetFilters={ () => handleResetFilters() } />
                </div>
            </ResponsiveModal>
        </>
    );
}