import { twMerge } from 'tailwind-merge';

interface ChatLayoutProps {
    leftSideBar: JSX.Element;
    content: JSX.Element;
    hideLeftSideBar?: boolean;
    className?: string;
}

export default function ChatLayout( {
                                        leftSideBar,
                                        content,
                                        hideLeftSideBar = false,
                                        className = ''
                                    }: ChatLayoutProps ) {
    return (
        <div className={ twMerge( 'overflow-hidden h-full w-full lg:border-2 lg:border-blue-100 lg:rounded-4xl flex', className ) }>
            <div className={ twMerge( 'lg:flex-none w-full lg:w-[360px] lg:border-r lg:border-blue-100 lg:py-5', hideLeftSideBar
                                                                                                                 ? 'hidden'
                                                                                                                 : '' ) }>
                { leftSideBar }
            </div>
            <div className={ twMerge( 'lg:block w-full lg:py-5', !hideLeftSideBar ? 'hidden' : '' ) }>
                { content }
            </div>
        </div>
    );
}