import { Controller } from 'react-hook-form'
import firstLetterUppercaseFormatter from '@/utils/firstLetterUppercaseFormatter'
import { Radio, Space } from 'antd'
import React, { ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'
import { CheckOutlinedIcon } from '@/assets/icons/CustomIcons'

interface RadioProps {
    optionsRadio: any;
    className?: string;
    defaultValue?: string | number;
    control: any;
    name?: string;
    rules?: any;
    errors: any;
    renderOptionContent: (option: any) => ReactNode;
}

export default function RadioCheckmarkComponent({
    optionsRadio,
    className,
    defaultValue,
    control,
    name,
    rules,
    errors,
    renderOptionContent
}: RadioProps) {

    return (
        <div className={twMerge('radio-checkmark-container w-full', className)} >
            <Controller
                control={ control }
                name={ name}
                rules={ rules }
                defaultValue={ defaultValue }
                render={ ( { fieldState: { error }, field: { onChange, onBlur, value, ref } } ) => (
                    <div className="w-full">
                        <Radio.Group 
                            onChange={ onChange }
                            defaultValue={ defaultValue }
                            optionType="button"
                            className='w-full'
                            value={value}
                        >
                            <Space direction="vertical" className='w-full'>

                                {optionsRadio?.map((option) => (
                                    <Radio key={option.id} value={option.id} checked={value === option.id} className='radio-checkmark'>
                                        <div className='flex items-center gap-3 px-5 w-full'>
                                            <CheckOutlinedIcon className='custom-icon' />
                                            <div className='w-full'>
                                                {renderOptionContent(option)}
                                            </div>
                                        </div>
                                    </Radio>
                                ))}
                            </Space>
                        </Radio.Group>
                        {errors[name] && !value && (
                            <div className="w-fit text-sm font-normal text-red mt-1.5">
                                {firstLetterUppercaseFormatter(errors[name].message.toString())}
                            </div>
                        )}
                    </div>
                )}
            />
        </div>
    );
}
