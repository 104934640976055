import { gql } from '@apollo/client';


export const jobsGQL = gql`
    query Jobs {
        jobs {
            name
            i18n
            id
        }
    }`;


