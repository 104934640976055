import React, { forwardRef, useImperativeHandle } from 'react';
import InputComponent from '@/components/custom-element-form/InputComponent';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SignUpClientRepository } from '@/pages/auth/sign-up/sign-up-client/signUpClientRepository';

const SignUpClientStepPersonalInfo = forwardRef( ( props, ref ) => {

    const { t } = useTranslation( 'auth' );
    const { trigger, control, getValues, formState: { errors } } = useForm();

    const repository = new SignUpClientRepository();

    useImperativeHandle( ref, () => ( {
            async validateFields(): Promise<boolean> {
                const isValid = await trigger();

                if( isValid ) {
                    repository.userName = getValues( 'userName' );
                }

                return isValid;
            }
        } )
    );

    return (
        <form>
            <InputComponent control={ control }
                            name="userName"
                            label={ t( 'auth:sign-up.client.inputs.userName' ) }
                            placeholder={ t( 'auth:sign-up.client.inputs.userNamePlaceholder' ) }
                            rules={ {
                                required: t( 'common:error.form.required' )
                            } }
                            errors={ errors } />
        </form>
    );
} );

export default SignUpClientStepPersonalInfo;