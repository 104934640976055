import { useState, useEffect } from 'react';
import { PaymentMethod } from '@/gql/graphql';
import { CreditCardOutlinedIcon } from '@/assets/icons/CustomIcons';
import firstLetterUppercaseFormatter from '@/utils/firstLetterUppercaseFormatter';
import { Button, Modal } from 'antd';
import { EditIcon } from '@/assets/icons/CustomIcons';
import { TrashOutlinedIcon } from '@/assets/icons/CustomIcons';
import { useTranslation } from 'react-i18next';
import paymentMethodHook from '@/hooks/paymentMethodHook';

interface PaymentMethodItemProps {
    currentPaymentMethod: PaymentMethod;
    onDelete: ( currentPaymentMethod: PaymentMethod ) => void;
}
export default function PaymentMethodItem({ currentPaymentMethod, onDelete }: PaymentMethodItemProps) {

    const { t } = useTranslation();

    const { handleDetachPaymentMethod, errorMessage, isDetached } = paymentMethodHook();


    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => setIsModalVisible(true);
    const handleOk = () => handleDetachPaymentMethod(currentPaymentMethod);
    const handleCancel = () => setIsModalVisible(false);

    useEffect( () => {
        if( isDetached ) {
            onDelete( currentPaymentMethod );
            setIsModalVisible(false);
        }
    }, [ isDetached ] );

    return(
        <div className="flex items-center justify-between h-[80px] gap-5 relative w-full">
            <div className='flex items-center'>
                <div className="flex-none bg-blue-100 rounded-full w-10 h-10 flex items-center justify-center">
                    <CreditCardOutlinedIcon style={ { fontSize: '16px' } } />
                </div>

                <div className="ml-4">
                    <h4 className="font-black text-base">
                        { firstLetterUppercaseFormatter(currentPaymentMethod.brand) }
                    </h4>
                    <div className="text-grey-medium text-[13px]">
                    •••• - •••• - •••• - { currentPaymentMethod.last4 }
                    </div>
                </div>
            </div>
            <div className='flex gap-2'>
                <Button type='link' className='p-0' onClick={showModal}>
                    <TrashOutlinedIcon className='text-xl stroke-current hover:text-primary-500' />
                </Button>
            </div>
            <Modal
                title={t( 'client:account.remove-payment-method.title' )}
                open={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                okText={t( 'client:account.remove-payment-method.removeButton' )}
                cancelText={t( 'client:account.remove-payment-method.cancelButton' )}
            >
                <div className='mb-5'>
                    <p>{t( 'client:account.remove-payment-method.description' )}</p>
                    {
                        errorMessage &&
                        <div className="mt-2 text-red">
                            errorMessage
                        </div>
                    }
                </div>
            </Modal>
        </div>
    );
}