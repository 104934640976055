import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useQuery } from '@apollo/client';
import { jobsGQL } from '@/gql/global-queries/jobsGQL';
import SelectMultiTags from '@/components/SelectMultiTags';
import { Job } from '@/gql/graphql';
import { useTranslation } from 'react-i18next';

interface JobFormProps {
    defaultJobIds?: string[];
    defaultMainJobId?: string;
    error?: string;

    onJobsSelected?( jobIds: string[] ): void;

    onChangeMainJob?( mainJob: { id: string, name: string } ): void;
}

export const JobForm = forwardRef( ( props: JobFormProps, ref ) => {

    const { t } = useTranslation();
    const { defaultJobIds, defaultMainJobId, error, onJobsSelected, onChangeMainJob } = props;

    const [ jobs, setJobs ] = useState<any[]>( [] );
    const [ jobsSelected, setJobsSelected ] = useState<string[]>( defaultJobIds || [] );
    const [ mainJobId, setMainJobId ] = useState<string>( null );
    const { data, loading } = useQuery( jobsGQL );

    useImperativeHandle( ref, () => ( {
            getSelectedIds(): string[] {
                return jobsSelected;
            },
            getJobsSelected(): string[] {
                return getLabelJobSelected();
            },
            getMainJobId(): string {
                return mainJobId;
            }
        } )
    );

    useEffect( () => {
        if( data != null ) {
            setJobs( data.jobs.map( ( job: Job ) => ( {
                id: job.id,
                label: t( 'translation:enum.jobs.' + job.name.toUpperCase() )
            } ) ) );

            if( defaultMainJobId ) {
                setMainJobId( defaultMainJobId );
            }
        }
    }, [ data, defaultMainJobId ] );

    const handleSelect = ( jobIds: string[] ) => {
        onJobsSelected?.( jobIds );
        setJobsSelected( jobIds );
    };

    const handleMainJobIdChange = ( mainJobId: string ) => {
        onChangeMainJob?.( {
            id: mainJobId,
            name: jobs.find( ( job ) => job.id === mainJobId ).label
        } );

        setMainJobId( mainJobId );
    };

    const getLabelJobSelected = (): string[] => {
        return jobsSelected.map( ( jobId ) => {
            return jobs.find( ( job ) => job.id === jobId ).label;
        } );
    };

    return (
        <div className="flex flex-col gap-y-2">
            <SelectMultiTags loading={ loading }
                             onTagsSelected={ ( value ) => handleSelect( value ) }
                             onChangeMainTag={ ( value ) => handleMainJobIdChange( value ) }
                             tags={ jobs }
                             defaultMainTagId={ defaultMainJobId }
                             defaultSelectedIds={ defaultJobIds } />
            {
                error &&
                <p className="text-sm text-red">
                    { error }
                </p>
            }
        </div>
    );
} );

export default JobForm;