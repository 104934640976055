import { ChatRepository, Conversation, ConversationUI } from '@/components/chat/ChatRepository';
import { useContext, useEffect, useState } from 'react';
import { CometChatIsLoggedInContext } from '@/plugins/CometChatProvider';
import { useNavigate } from 'react-router-dom';
import { conversationPreviewUI } from '@/components/chat/interfaces/conversationPreviewUI';
import { CometChat } from '@cometchat-pro/chat';
import { checkIfDataIsMasking, conversationFromCometChatConversation, conversationItemMessageFromCometChatMessage, conversationItemMessageFromCometChatRawMessage, conversationUIFromCometChatConversation } from '@/components/chat/chatUtils';
import { useMediaQuery } from 'react-responsive';
import generateRandomString from '@/utils/generateRandomString';
import { useLazyQuery } from '@apollo/client';
import { userProfilePictureGQL } from '@/gql/global-queries/userProfilePictureGQL';
import ConversationCustomItemFormattingHook from '@/components/chat/hooks/ConversationCustomItemFormattingHook';
import { useTranslation } from 'react-i18next';
import CometChatHelper = CometChat.CometChatHelper;
import * as Sentry from '@sentry/react';

interface ChatHookProps {
    conversationId?: string;
    autoRedirectToConversation?: boolean;
}

export default function ChatHook( props: Partial<ChatHookProps> = {} ) {
    const { conversationId, autoRedirectToConversation = false } = props;

    const cometChatIsLoggedIn: boolean = useContext( CometChatIsLoggedInContext );

    const maxMessage: number = 50;

    const { t } = useTranslation();
    const navigate = useNavigate();
    const isTabletOrMobile = useMediaQuery( { query: `(max-width: ${ import.meta.env.VITE_MOBILE_BREAK_POINT })` } );

    const [ messageListenerID, setMessageListenerID ] = useState<string>( null );
    const [ userListenerID, setUserListenerID ] = useState<string>( null );
    const [ conversationsPreviewUI, setConversationsPreviewUI ] = useState<conversationPreviewUI[]>( null );
    const [ totalUnreadMessages, setTotalUnreadMessages ] = useState<number>( 0 );

    const { conversationItemCustomFromCometChatRawCustomMessage } = ConversationCustomItemFormattingHook();

    const chatRepository: ChatRepository = new ChatRepository();

    const getProfilePictureQuery = useLazyQuery( userProfilePictureGQL );

    useEffect( () => {
        if( cometChatIsLoggedIn && conversationsPreviewUI === null ) {
            setMessageListenerID( generateRandomString( 30 ) );
            setUserListenerID( generateRandomString( 30 ) );

            messageListener( messageListenerID );
            addUserListener( userListenerID );

            watchConversations();
            watchTotalUnreadMessage();
            fetchAllConversations();
        }
    }, [ cometChatIsLoggedIn, conversationsPreviewUI ] );

    const watchTotalUnreadMessage = () => {
        return chatRepository.totalUnreadMessageCount$.subscribe( ( totalUnreadMessages ) => {
            setTotalUnreadMessages( totalUnreadMessages );
        } );
    };

    const watchConversations = () => {
        return chatRepository.conversations$.subscribe( ( conversations ) => {
            setConversationsPreviewUI( conversationPreviewUIFromConversation( conversations ) );
        } );
    };

    const fetchAllConversations = async() => {
        let hasNext: boolean = true;

        let conversationRequest = new CometChat.ConversationsRequestBuilder()
            .setLimit( maxMessage )
            .setConversationType( 'user' )
            .build();

        while( hasNext ) {
            const response: CometChat.Conversation[] | [] = await conversationRequest.fetchNext();

            if( response.length < 50 ) {
                hasNext = false;
            }

            const conversations: Conversation[] = response.map( ( conversation: CometChat.Conversation ) => conversationFromCometChatConversation( conversation ) );

            lazyLoadProfilePictures( conversations );

            if( conversations !== null && conversations.length > 0 ) {
                chatRepository.totalUnreadMessageCount = conversations?.reduce( ( accumulator, currentValue ) => {
                    accumulator.unreadMessageCount = currentValue.unreadMessageCount + accumulator.unreadMessageCount;
                    return accumulator;
                } )?.unreadMessageCount;
            }

            const conversationsUI: ConversationUI[] = response.map( ( conversationCometChat ) => {
                return conversationUIFromCometChatConversation( conversationCometChat );
            } );

            chatRepository.addOrUpdateConversations( conversations, conversationsUI );
        }

        chatRepository.isLoadingConversations = false;
    };

    useEffect( () => {
        if( autoRedirectToConversation && conversationsPreviewUI.length > 0 && !conversationId && !isTabletOrMobile ) {
            navigate( `${ conversationsPreviewUI[ 0 ].id }` );
        }
    }, [ conversationsPreviewUI, isTabletOrMobile ] );

    const lazyLoadProfilePictures = async( conversations: Conversation[] ): Promise<void> => {
        for( const conversation of conversations ) {
            const profilePicture: string = await getUserProfilePicture( conversation.receiver.id );
            chatRepository.addOrUpdateProfilePictures( conversation.id, profilePicture );
        }
    };

    const messageListener = ( messageListenerID ) => {
        CometChat.addMessageListener(
            messageListenerID,
            new CometChat.MessageListener( {
                onTextMessageReceived: async( textMessage: CometChat.TextMessage ) => {
                    await onTextMessageReceived( textMessage );
                },
                onMessageEdited: async( message: CometChat.CustomMessage | CometChat.TextMessage ) => {
                    await onMessageEdited( message );
                },
                onTypingStarted: typingIndicator => {
                    chatRepository.setUserTypingStatus( typingIndicator.getSender().getUid(), true );
                },
                onTypingEnded: typingIndicator => {
                    chatRepository.setUserTypingStatus( typingIndicator.getSender().getUid(), false );
                }
            } )
        );
    };

    const onMessageEdited = async( message: CometChat.CustomMessage | CometChat.TextMessage ) => {
        let messageFormatted;

        if( message.getCategory() === 'message' ) {
            messageFormatted = conversationItemMessageFromCometChatRawMessage( message as CometChat.TextMessage );
        }
        else if( message.getCategory() === 'custom' ) {
            messageFormatted = await conversationItemCustomFromCometChatRawCustomMessage( message as CometChat.CustomMessage );
        }
        chatRepository.updateConversationItem( message.getConversationId(), messageFormatted );
    };

    const onTextMessageReceived = async( textMessage: CometChat.TextMessage ) => {
        const category: CometChat.MessageCategory = textMessage.getCategory();
        const conversationId: string = textMessage.getConversationId();
        const metadata: Object = textMessage.getMetadata();

        if( category !== 'action' ) {
            if( category === 'message' ) {
                try {
                    chatRepository.addConversationItems( conversationId, [ conversationItemMessageFromCometChatMessage( textMessage ) ] );
                } catch( e ) {
                    const conversation: CometChat.Conversation = await CometChatHelper.getConversationFromMessage( textMessage );
                    chatRepository.addConversation( conversationFromCometChatConversation( conversation ) );
                }
            }
            else if( textMessage.getCategory() === 'custom' ) {
                try {
                    chatRepository.addConversationItems( conversationId, [ await conversationItemCustomFromCometChatRawCustomMessage( textMessage ) ] );

                } catch( e ) {
                    const conversation: CometChat.Conversation = await CometChatHelper.getConversationFromMessage( textMessage );
                    chatRepository.addConversation( conversationFromCometChatConversation( conversation ) );
                }

                chatRepository.setLastMessage( conversationId, metadata[ 'name' ] );
            }

            chatRepository.setLastMessage( conversationId, getLastMessage( textMessage ) );
            chatRepository.addUnreadMessageCount( conversationId, 1 );
        }
    };

    const getLastMessage = ( message: CometChat.TextMessage ): string => {
        const maskingData  = checkIfDataIsMasking( message );

        if( message.getCategory() === 'message' ) {
            return maskingData ? maskingData : message.getText();
        }
        else {
            const metadata = message.getMetadata();
            if( metadata[ 'type' ] == 'offer' ) {
                return t( 'common:chat.conversation.header.receiverOffer' );
            }
        }
    };

    const getUserProfilePicture = async( userId: string ): Promise<string> => {
        const { data } = await getProfilePictureQuery[ 0 ]( { variables: { id: userId } } );
        return data?.user?.profilePicture;
    };

    const addUserListener = ( userListenerID ) => {
        CometChat.addUserListener(
            userListenerID,
            new CometChat.UserListener( {
                onUserOnline: ( onlineUser: CometChat.User ) => {
                    chatRepository.setUserOnlineStatus( onlineUser.getUid(), true );
                },
                onUserOffline: ( offlineUser: CometChat.User ) => {
                    chatRepository.setUserOnlineStatus( offlineUser.getUid(), false );
                }
            } ) );
    };

    const removeMessageListener = ( messageListenerID ) => {
        CometChat.removeMessageListener( messageListenerID );
    };

    const removeUserListener = ( userListenerID ) => {
        CometChat.removeUserListener( userListenerID );
    };

    const conversationPreviewUIFromConversation = ( conversations: ( Conversation | ConversationUI )[] ): conversationPreviewUI[] => {
        return conversations.map( ( conversation: Conversation ) => {
            return {
                id: conversation.id,
                userName: conversation.receiver.userName,
                userPicture: conversation.receiver.avatar,
                lastMessage: conversation.lastMessage,
                lastMessageAt: conversation.lastMessageAt,
                unreadMessageCount: conversation.unreadMessageCount,
                isOnline: conversation[ 'isOnline' ],
                isTyping: conversation[ 'isTyping' ]
            };
        } );
    };

    return {
        conversationsPreviewUI,
        chatRepository,
        cometChatIsLoggedIn,
        totalUnreadMessages
    };
}