import { gql } from '@apollo/client';

export const DeclineProjectGQL = gql`
    mutation DeclineProjectGQL($id: String!, $reason: String ) {
        declineProject(id: $id, reason: $reason) {
            id
            status
            declineReason
        }
    }
`;