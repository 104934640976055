import { gql } from '@apollo/client';

export const PublicCreatePortfolioElementGQL = gql`
    mutation PublicCreatePortfolioElementGQL($url: String!) {
        publicCreatePortfolioElement(url: $url) {
            portfolioElements {
                id
                type
                title
                thumbnailUrl
                viewCount
                portfolioChannel {
                    id
                    name
                    subscriberCount
                    viewCount
                    videoCount
                }
            }
            failed
        }
    }
`;