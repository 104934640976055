import { CurrencyEnum, ProjectStatusEnum } from '@/gql/graphql';
import { Avatar, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { priceFormatter } from '@/utils/priceFormatter';
import { useMediaQuery } from 'react-responsive';

import '@/components/projects/project-card/project-card.scss';
import { BankNoteOutlinedIcon, ClockOutlinedIcon, DotFilledIcon, DownloadOutlinedIcon } from '@/assets/icons/CustomIcons';
import { UserOutlined } from '@ant-design/icons';
import { SessionRepository } from '@/core/auth/sessionRepository';
import { UserTypeEnum } from '@/gql/graphql';
import { downloadFile } from '@/utils/downloadFile';
export interface ProjectCardInterface {
    id: string;
    status: ProjectStatusEnum;
    user: {
        name: string;
        imageUrl?: string;
        description: string | JSX.Element;
    };
    name: string;
    description: string;
    channel?: {
        thumbnailUrl: string;
        name: string;
    };
    clientInvoiceUrl?: string,
    freelancerInvoiceUrl?: string,
    amount: number;
    currency: CurrencyEnum;
    startedAt: string;
    hasReviewed: boolean;
    hasReviewedByFreelancer?: boolean;
    hasReviewedByClient?: boolean;
}

interface ProjectCardProps {
    project?: ProjectCardInterface;
    isLoading?: boolean;
    className?: string;
}

export default function ProjectCard( {
    project,
    className = ''
}: ProjectCardProps ) {
    const isTabletOrMobile = useMediaQuery( { query: `(max-width: ${ import.meta.env.VITE_MOBILE_BREAK_POINT })` } );
    const { t } = useTranslation( 'translation' );

    const sessionRepository = new SessionRepository();
    const isClient = sessionRepository.user.type === UserTypeEnum.CLIENT;

    /* const handleDownloadInvoices = ( ) => {
        if( isClient ) {
            downloadFile( project.clientInvoiceUrl )
        } else {
            downloadFile( project.clientInvoiceUrl )
            downloadFile( project.freelancerInvoiceUrl )
        }
    }; */

    return (
        <div className={ `project-card p-4 flex flex-col lg:flex-row ${ className }` }>
            <div className="w-full">
                <div className="flex flex-none">
                    <Avatar src={ project?.user?.imageUrl }
                        icon={ <UserOutlined /> }
                        className="flex-none"
                        size={ isTabletOrMobile ? 48 : 64 } />

                    <div className="flex flex-col ml-4">
                        <h4 className="text-lg font-medium font-sharp text-black truncate">
                            { project?.name }
                        </h4>

                        <div className="flex text-primary-400 items-center gap-x-2 lg:gap-x-3 mt-1">
                            <div className="whitespace-nowrap">
                                { project?.user.name }
                            </div>
                            {
                                project?.user.description != null ?
                                <>
                                    <DotFilledIcon className="text-[8px]" />
                                    <div className="whitespace-nowrap">
                                        { project?.user.description }
                                    </div>
                                </> :
                                null
                            }
                        </div>
                    </div>
                </div>
                <div className="flex flex-col lg:flex-row lg:items-center mt-4 lg:mt-2 gap-x-4 text-grey font-medium">
                    {
                        project.channel &&
                        <div className="flex items-center">
                            <Avatar src={ project?.channel?.thumbnailUrl }
                                icon={ <UserOutlined /> }
                                className="flex-none"
                                size={ 20 } />
                            <span className="ml-2">
                                { project?.channel?.name }
                            </span>
                        </div>
                    }
                    <div className="flex gap-x-4 mt-2 lg:mt-0">
                        <div className="flex items-center">
                            <BankNoteOutlinedIcon className="text-[20px]" />
                            <span className="ml-2">
                            {
                                priceFormatter( {
                                    currency: project?.currency,
                                    amount: project?.amount,
                                    decimals: true
                                } )
                            }
                            </span>
                        </div>
                        <div className="flex items-center gap-x-2">
                            <ClockOutlinedIcon className="text-[20px]" />
                            <span>{ t( 'common:project-card.startedAt' ) }</span>
                            <span>{ project?.startedAt }</span>
                        </div>
                    </div>
                </div>
            </div>
            {
                project.status == ProjectStatusEnum.IN_PROGRESS && (
                    <div className="flex flex-col items-center gap-2 justify-end whitespace-nowrap">
                        {isClient && !project.hasReviewed && (
                            <>
                                { project.hasReviewedByFreelancer && (
                                    <div className='text-red flex gap-2 items-center'>
                                        <DotFilledIcon className="text-[8px]" />
                                        {t('common:project-card.requestApproval', { name: project.user.name })}
                                    </div>
                                )}

                                <Button
                                    type="primary"
                                    className="w-full lg:w-fit mt-4 lg:mt-0"
                                >
                                    {t('common:project-card.validePayment')}
                                </Button>
                            </>
                        )}

                        {!isClient && !project.hasReviewed && (
                            <>
                                {(!project.hasReviewedByClient && (
                                    <Button
                                        type="primary"
                                        className="w-full lg:w-fit mt-4 lg:mt-0"
                                    >
                                        {t('common:project-card.dropAReviewFirst')}
                                    </Button>
                                )) || (
                                    <>
                                        <div className='flex gap-2 items-center'>
                                            {t('common:project-card.paymentApprouved', { name: project.user.name })}
                                        </div>
                                        <Button
                                            type="primary"
                                            className="w-full lg:w-fit mt-4 lg:mt-0"
                                        >
                                            {t('common:project-card.dropAReviewSecond', { name: project.user.name })}
                                        </Button>
                                    </>
                                )}
                            </>
                        )}
                        { project.hasReviewed && (
                            <div className='italic'>
                                {t('common:project-card.waitingApproval', { name: project.user.name })}
                            </div>
                        )}
                    </div>
                )
            }

            {/* {
                project.status == ProjectStatusEnum.DONE && ( project.clientInvoiceUrl || project.freelancerInvoiceUrl ) && (
                    <div className="flex flex-col items-center gap-2 justify-end whitespace-nowrap">
                        <Button type="text"
                                onClick={(e) => {
                                    e.stopPropagation(); 
                                    handleDownloadInvoices()
                                }}
                                size="large"
                                className="text-pink text-base flex items-center justify-center">
                                            <span className="underline">
                                                { t( 'common:project-card.downloadInvoiceClient', { count: isClient ? 1 : 2 } ) }
                                            </span>
                            <DownloadOutlinedIcon style={ { fontSize: '20px' } } />
                        </Button>
                    </div>
                )
            } */}
        </div>
    );
}