import { ReviewUI } from './reviewUI';
import FreelancerProfileReviewItem from './components/FreelancerProfileReviewItem';

interface FreelancerProfileReviewListProps {
    reviews: ReviewUI[];
}

export default function FreelancerProfileReviewList( { reviews }: FreelancerProfileReviewListProps ) {

    return (
        <div className="grid md:grid-cols-2 grid-cols-1 gap-10 md:gap-x-24 md:gap-y-10">
            {
                reviews?.map( ( review, index ) => {
                    return (
                        <FreelancerProfileReviewItem key={ index }
                                                     review={ review } />
                    );
                } )
            }
        </div>
    );
}