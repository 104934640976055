import TransverseFreelancerList from '@/pages/transverse/views/freelancers/components/TransverseFreelancerList';
import TransverseFreelancerProfileDrawer from '@/pages/transverse/views/freelancer/TransverseFreelancerProfileDrawer';
import { useContext, useEffect, useState } from 'react';
import TransverseFreelancerListHeaderOptions from '@/pages/transverse/views/freelancers/components/TransverseFreelancerListHeaderOptions';
import TransverseFreelancerListFilter from '@/pages/transverse/views/freelancers/components/TransverseFreelancerListFilter';
import freelancersFiltersHook from '@/hooks/freelancersFiltersHook';
import { useTranslation } from 'react-i18next';
import publicFreelancersHook from '@/hooks/publicFreelancersHook';
import { FreelancersRepositoryContext } from '@/core/FreelancerRepositoryContext';
import { FreelancersRepository } from '@/components/freelancers-filter-items/freelancersRepository';
import captureUrlParamsFiltersHook from '@/hooks/captureUrlParamsFiltersHook';
import { filtersFreelancerHook } from '@/hooks/filtersFreelancerHook';
import ResponsiveModal from '@/components/ResponsiveModal';
import { useLocation, useNavigate } from 'react-router-dom';
import ListSkeleton from '@/components/ListSkeleton';

export default function TransverseFreelancers() {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const [ freelancerId, setFreelancerId ] = useState<string>( null );
    const { handleMenuOpening, limit, isOpenMenu } = freelancersFiltersHook();

    const freelancersRepository: FreelancersRepository = useContext( FreelancersRepositoryContext );

    const {
              freelancers,
              totalFreelancers,
              loading,
              loadMore,
              load,
              hasMore,
              handleApplyFilters,
              handleResetFilters,
              countFilterActive
          } = publicFreelancersHook( { freelancersRepository } );

    const [ firstLoad, setFirstLoad ] = useState<boolean>( freelancers == null || freelancers?.length == 0 );

    const { params } = captureUrlParamsFiltersHook( {
        allowedParams: [
            'isAvailable',
            'isCertified',
            'avgPriceMin',
            'avgPriceMax',
            'experiences',
            'jobIds',
            'skillIds',
            'expertiseIds',
            'languageIds'
        ]
    } );

    filtersFreelancerHook( { freelancersRepository } );

    const saveScrollPosition = () => {
        navigate( location.pathname, {
            state: {
                ...location.state,
                scrollPosition: window.scrollY
            },
            replace: true
        } );
    };

    useEffect( () => {
        const savedScrollPosition = location.state?.scrollPosition;
        if( savedScrollPosition ) {
            window.scrollTo( 0, savedScrollPosition );
        }

        window.addEventListener( 'beforeunload', saveScrollPosition );

        return () => {
            window.removeEventListener( 'beforeunload', saveScrollPosition );
        };
    }, [ navigate, location ] );

    useEffect( () => {
        if( params != null && firstLoad && ( !freelancers || freelancers.length === 0 ) ) {
            freelancersRepository.setFilters( params );
            load().then( () => {
                setFirstLoad( false );
            } );
        }
    }, [ params ] );

    const gridClass = 'h-fit grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 gap-6 lg:gap-9';

    return (
        <>
            <div className="-mt-6">
                <TransverseFreelancerListHeaderOptions totalFreelancers={ totalFreelancers }
                                                       countFilterActive={ countFilterActive } />
            </div>

            {
                firstLoad &&
                <div className={ `w-full mt-24 pb-10 ${ gridClass } overflow-y-hidden` }>
                  <ListSkeleton isLoading={ true }
                                height={ 344 }
                                count={ 16 } />
                </div>
            }

            <div className="relative flex flex-col items-center min-h-screen mt-24">
                <div className="w-full max-w-[1312px]">
                    <TransverseFreelancerList freelancers={ freelancers }
                                              isLoadingFirstData={ firstLoad }
                                              loadMore={ loadMore }
                                              limit={ limit }
                                              hasMore={ firstLoad ? true : hasMore }
                                              onProfileSelected={ ( id ) => setFreelancerId( id ) } />
                </div>
            </div>

            <ResponsiveModal title={ t( 'transverse:freelancers.filters.filterMenuTitle' ) }
                             isOpen={ isOpenMenu }
                             onClose={ () => handleMenuOpening() }
                             desktopWidth="800px"
                             destroyOnClose={ false }>
                <div className="overflow-y-scroll sm:max-h-[700px] pr-4">
                    <TransverseFreelancerListFilter handleApplyFilters={ () => handleApplyFilters() }
                                                    handleResetFilters={ () => handleResetFilters() } />
                </div>
            </ResponsiveModal>
            <TransverseFreelancerProfileDrawer freelancerId={ freelancerId }
                                               onClose={ () => setFreelancerId( null ) } />
        </>
    );
}